/* eslint-disable no-param-reassign */

/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_EXPERIENCE_LOADING,
  CLEAR_ERRORS,
  EXPERIENCE_ADD_SUCCESS,
  EXPERIENCE_FAIL,
  UPDATE_EXPERIENCE,
  STOP_ADD_SUCCESS,
  STOPS_FAIL,
} from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Experience
export const addExperience = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.title) {
    data.title = data.title.trim();
  }
  form.append("title", data.title);
  form.append("weatherCondition", data.weatherCondition);
  if (data?.day) {
    form.append("timeNeededToFinishExperience", "fullday");
  } else {
    form.append("timeNeededToFinishExperience", data.timeNeededToFinishExperience);
  }
  if (data?.day && data?.kidsFriendly) {
    form.append("kidsFriendly", data?.kidsFriendly);
  }

  form.append("transportation", data.transportation);
  form.append("city", data.city);
  form.append("subHeader", data.subHeader);
  form.append("description", data.description);
  // if (image) form.append('image', image);
  form.append("top", data.top);
  form.append("day", data.day);
  form.append("highlight", data.highlight);
  if (data.style.length > 0) {
    form.append("style", data.style);
  }
  if (data.interests.length > 0) {
    form.append("interests", data.interests);
  }

  data.images?.map((file) => {
    // console.log(file);

    form.append("images", file);
    return file;
  });

  try {
    const res = await axios.post(`${URL}/experiences`, form, config);

    dispatch({
      type: EXPERIENCE_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EXPERIENCE_FAIL,
      payload: err?.response.data.message,
    });
  }
};

//  Update Experience
export const updateExperience = (data, originalTitle) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  if (data.title && data.title !== originalTitle) form.append("title", data.title.trim());
  form.append("city", data.city);
  if (!data?.day) {
    if (data.subHeader) form.append("subHeader", data.subHeader);
  } else if (data.subHeader) form.append("subHeader", "");
  if (data?.weatherCondition) form.append("weatherCondition", data.weatherCondition);
  if (data?.day) {
    form.append("timeNeededToFinishExperience", "fullday");
  } else if (data?.timeNeededToFinishExperience) {
    form.append("timeNeededToFinishExperience", data.timeNeededToFinishExperience);
  }
  if (data?.transportation) form.append("transportation", data.transportation);
  if (!data?.day) {
    if (data.description) form.append("description", data.description);
  } else if (data.description) form.append("description", "");
  // if (image) form.append('image', image);
  if (!data?.day) {
    if (data.highlight) form.append("highlight", data.highlight);
    else form.append("highlight", false);
  }
  if (data.day) form.append("day", data.day);
  if (data.day) {
    if (data.style.length > 0) form.append("style", data.style);
  } else form.append("style", []);
  if (data.day) {
    if (data.interests.length > 0) form.append("interests", data.interests);
  } else form.append("interests", []);
  if (!data?.day) {
    if (data.top) form.append("top", data.top);
    else form.append("top", false);
  }
  if (data?.day && data.kidsFriendly !== undefined) {
    form.append("kidsFriendly", data?.kidsFriendly);
  }
  // to tell backend that images were cleared
  if (data?.images?.length === 0) form.append("cleared", true);
  if (data.day) {
    form.append("images", []);
  } else {
    data?.images?.map((file) => {
      form.append("images", file);
      return file;
    });
  }
  if (data?.day && data?.subHeader?.length === 0) {
    form.append("subHeader", "");
  }
  if (data?.day && data?.description?.length === 0) {
    form.append("description", "");
  }
  try {
    const res = await axios.patch(`${URL}/experiences/${data._id}`, form, config);

    dispatch({
      type: UPDATE_EXPERIENCE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EXPERIENCE_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Experience - set active as false
export const deleteExperience = (id) =>
  factory.patch(
    { active: false },
    `${URL}/experiences/${id}`,
    "DELETE_EXPERIENCE",
    "EXPERIENCE_FAIL"
  );

// Get All Experiences

export const getExperiences = () =>
  factory.get(`${URL}/experiences?sort=name&dir=asc`, "GET_EXPERIENCES", "EXPERIENCE_FAIL");

export const updateExperienceTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/experiences/translations/${id}?language=ar`,
    "UPDATE_EXPERIENCE_TRANSLATION",
    "EXPERIENCE_FAIL"
  );

// get One Experience
export const getOneExperience = (id) =>
  factory.get(`${URL}/experiences/${id}`, "GET_ONE_EXPERIENCE", "EXPERIENCE_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_EXPERIENCE_LOADING });

// Get Location Recommendations

export const getPartnerExperiences = (partner) =>
  factory.get(
    `${URL}/partners/${partner}/experiences?sort=name&dir=asc`,
    "GET_EXPERIENCES",
    "EXPERIENCE_FAIL"
  );

// Add Stop
export const addStop = (data, id, count) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  try {
    const res = await axios.patch(
      `${URL}/experiences/${id}/stops/type/${data.type}s`,
      {
        data: [
          {
            message: data.message,
            stop: data.stop,
            duration: data.duration,
            alternative: data.alternative,
            order: count,
          },
        ],
      },
      config
    );

    dispatch({
      type: STOP_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STOPS_FAIL,
      payload: err?.response,
    });
  }
};

// Delete stop
export const deleteStop = (experienceId, oldData) =>
  factory.patch(
    {
      data: {
        stop: oldData?.stop?._id,
        order: oldData?.order,
        alternative: oldData?.alternative,
      },
    },
    `${URL}/experiences/${experienceId}/stops/remove/type/${oldData.type}`,
    "DELETE_STOP",
    "STOPS_FAIL"
  );

// Sort Stop
export const sortStop = (sortedArr, experienceId) =>
  factory.patch(
    { data: sortedArr },
    `${URL}/experiences/${experienceId}/stops/sort`,
    "SORT_STOPS",
    "STOPS_FAIL"
  );
