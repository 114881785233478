/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import MenuInfo from "pages/settingsPages/menus/new-menu/components/MenuInfo";
import MenuFilter from "pages/settingsPages/menus/new-menu/components/MenuFilter";

const MenuDetail = ({
  formData,
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleDestinationsChange = () => {},
  selectedDestinations = [],
  handleLocationsChange = () => {},
  selectedLocations = [],
  handleTagsChange = () => {},
  selectedTags = [],
  handleEdit,
  setSelectedDestinations = () => {},
  setSelectedTags = () => {},
  setSelectedLocations = () => {},
  handleTicketChange = () => {},
  selectedTickets = [],
  setSelectedTickets = () => {},
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    if (type === "information") {
      return "Menu Info";
    }
    return "Filter";
  };

  const handleInfoCard = () => {
    if (type === "information") {
      return (
        <MenuInfo
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedDestinations={selectedDestinations}
          handleDestinationsChange={handleDestinationsChange}
          selectedLocations={selectedLocations}
          handleLocationsChange={handleLocationsChange}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          handleTagsChange={handleTagsChange}
          setSelectedLocations={setSelectedLocations}
          setSelectedDestinations={setSelectedDestinations}
          handleTicketChange={handleTicketChange}
          selectedTickets={selectedTickets}
          setSelectedTickets={setSelectedTickets}
        />
      );
    }
    return <MenuFilter formData={formData} editPage detailPage={detailPage} />;
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          // stateName="menus"
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
            setSelectedDestinations(null);
            setSelectedTags(null);
            setSelectedLocations(null);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>
        <MDBox>{handleInfoCard()}</MDBox>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for MenuDetail
MenuDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  type: PropTypes.string,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  handleDestinationsChange: PropTypes.func,
  selectedDestinations: PropTypes.instanceOf(Array),
  handleLocationsChange: PropTypes.func,
  selectedLocations: PropTypes.instanceOf(Array),
  handleTagsChange: PropTypes.func,
  selectedTags: PropTypes.instanceOf(Array),
  setSelectedTags: PropTypes.func,
  setSelectedLocations: PropTypes.func,
  setSelectedDestinations: PropTypes.func,
  handleTicketChange: PropTypes.func,
  selectedTickets: PropTypes.instanceOf(Array),
  setSelectedTickets: PropTypes.func,
};

export default MenuDetail;
