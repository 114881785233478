/* eslint-disable no-unsafe-optional-chaining */

import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";

const ItemsTable = ({ items = [] }) => (
  <Table
    style={{
      marginBottom: "25px",
      borderBottom: "hidden",
    }}
  >
    {items.map((value, index) => (
      <React.Fragment key={index?.length}>
        {value?.tickets?.length > 0 ? (
          <>
            <TableHead>
              <TableRow>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  {value?.product}
                </TableCell>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Quantity
                </TableCell>
                {/* <TableCell component="th" style={{ color: "dimgray" }}>
                  ID
                </TableCell> */}
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Unit Price
                </TableCell>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Total Price
                </TableCell>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value.tickets.map((ticket, i) => (
                <TableRow key={i.length}>
                  <TableCell>{ticket.ticketName}</TableCell>
                  <TableCell>{ticket?.ticketQuantity}</TableCell>
                  {/* <TableCell>{page === "concierge" ? ticket._id : ticket.ticketId}</TableCell> */}
                  <TableCell>{ticket?.ticketPrice}</TableCell>
                  <TableCell>{ticket?.totalPrice || 0}</TableCell>
                  <TableCell>{value?.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <>
            <TableHead>
              <TableRow>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Product
                </TableCell>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Quantity
                </TableCell>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Unit Price
                </TableCell>
                <TableCell component="th" style={{ color: "dimgray" }}>
                  Total Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={index.length}>
                <TableCell>{value?.product}</TableCell>
                <TableCell>{value?.orderItemTickets}</TableCell>
                <TableCell>
                  {(value?.itemAmount / value?.orderItemTickets || 0).toFixed(2)}
                </TableCell>
                <TableCell>{(value?.itemAmount || 0).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </>
        )}
      </React.Fragment>
    ))}
  </Table>
);

ItemsTable.propTypes = {
  items: PropTypes.instanceOf(Array),
  // page: PropTypes.string,
};

export default ItemsTable;
