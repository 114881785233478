/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

// Custom components
import MDBox from "components/MDBox";
import FormField from "components/FormField";

const MsgOrUrl = ({ formData }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { callToAction, description } = formField;
  const { callToAction: callToActionV, description: descriptionV, type: typeV } = values;

  return (
    <MDBox>
      <MDBox>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Message Or URL</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={typeV || "url"}
            name="radio-buttons-group"
            value={typeV}
            onChange={(event, value) => {
              if (value === "url") {
                setFieldValue("type", value);
                setFieldValue("callToAction.urlType", true);
              } else {
                setFieldValue("type", value);
                setFieldValue("callToAction.urlType", false);
              }
            }}
          >
            <Grid container>
              <Grid item>
                <FormControlLabel
                  //   disabled={values?.travelDesk.length > 0}
                  value="url"
                  control={<Radio />}
                  label="URL"
                />
              </Grid>
              <Grid item>
                <FormControlLabel value="msg" control={<Radio />} label="Message" />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item container size={{ sm: 4 }}>
            <FormField
              type={callToAction.type}
              label={callToAction.label}
              name="callToAction.label" // add this str so validation text can be visible
              value={callToActionV?.label}
              onChange={(event) => setFieldValue("callToAction.label", event.target.value)}
              error={errors?.callToAction?.label && touched?.callToAction?.label}
              success={callToActionV?.label?.length > 0 && !errors?.callToAction?.label}
            />
          </Grid>
          {typeV === "url" && (
            <Grid item container size={8}>
              <FormField
                type={callToAction.type}
                label={callToAction.urlLabel}
                name="callToAction.url" // add this str so validation text can be visible
                value={callToActionV?.url}
                onChange={(event) => setFieldValue("callToAction.url", event.target.value.trim())}
                error={errors?.callToAction?.url && touched?.callToAction?.url}
                success={callToActionV?.url?.length > 0 && !errors?.callToAction?.url}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
      {typeV === "msg" && (
        <MDBox>
          <FormField
            type={description.type}
            label={description.label}
            name={description.name}
            value={descriptionV}
            multiline
            rows={5}
            outlined
            error={errors?.description && touched?.description}
            success={descriptionV?.length > 0 && !errors?.description}
          />
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for MsgOrUrl
MsgOrUrl.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default MsgOrUrl;
