/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import { Typography } from "@mui/material";

const TicketsPicker = ({
  tickets = {},
  ticketsV = [],
  handleChange = () => {},
  setFieldValue,
  destination = "",
  isCuratorPage = false,
  isMenuPage = false,
  destinations = [],
  locations = [],
  selectedType = "Global",
}) => {
  const [ticketOptions, setTicketOptions] = useState([]);

  const filterTicket = (data) =>
    data.map((ticketData) => ({
      label: ticketData.title,
      value: ticketData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tickets?limit=20&sort=title`;
      let ids = "";

      if (isMenuPage) {
        if (destinations?.length > 0 && selectedType === "destinations") {
          ids = destinations.map((dest) => dest.value).join(",");
        } else if (locations?.length > 0 && selectedType === "location") {
          ids = locations.map((loc) => loc._id).join(",");
        }
      }

      if (isMenuPage && (destinations?.length > 0 || locations?.length > 0)) {
        url += `&${selectedType === "destinations" ? "destinations" : "locations"}=${ids}`;
      }
      if (isCuratorPage) url += `&source=viator`;
      if (destination) url += `&destinations=${encodeURIComponent(destination)}`;

      if (inputValue && inputValue.length > 0) {
        url += `&title=${encodeURIComponent(inputValue)}`;
      }
      url += "&dir=asc";

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          const filteredData = filterTicket(result.data);
          resolve(filteredData);
        })
        .catch((error) => console.log(error.message));
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((fetchedOptions) => {
        callback(fetchedOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000);

  useEffect(() => {
    // Fetch new options whenever selectedType, destinations, or locations change
    promiseOptions("").then((newOptions) => setTicketOptions(newOptions));
  }, [selectedType, destinations.length, locations.length]);

  return (
    <div>
      <Typography
        style={{
          wordBreak: "break-word",
          fontSize: "0.875rem",
          fontWeight: 400,
        }}
      >
        {tickets.label}
      </Typography>
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions={ticketOptions}
        defaultValue={ticketsV}
        placeholder="Search for a Ticket"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={(selectedOptions) => {
          if (!selectedOptions || selectedOptions.length === 0) {
            setFieldValue("tickets", []);
            setFieldValue("recommendedExperiences", []);
            handleChange([]);
          } else if (isCuratorPage) {
            setFieldValue("recommendedExperiences", selectedOptions);
            handleChange(selectedOptions);
          } else {
            setFieldValue("tickets", selectedOptions);
            handleChange(selectedOptions);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

TicketsPicker.propTypes = {
  tickets: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  ticketsV: PropTypes.instanceOf(Array),
  destination: PropTypes.string,
  isCuratorPage: PropTypes.bool,
  isMenuPage: PropTypes.bool,
  destinations: PropTypes.instanceOf(Array),
  locations: PropTypes.instanceOf(Array),
  selectedType: PropTypes.string,
};

export default TicketsPicker;
