/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import Information from "pages/content/recommendations/new-recommendation/components/Information";
import Address from "pages/content/recommendations/new-recommendation/components/Address";
import AdditionalInfo from "pages/content/recommendations/new-recommendation/components/AdditionalInfo";
import DateAndTimeInfo from "pages/content/recommendations/new-recommendation/components/DateAndTimeInfo";
import Rating from "pages/content/recommendations/new-recommendation/components/Rating";

import { useEffect } from "react";

const RecommendationDetail = ({
  formData,
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  selectedLocation = {},
  handleLocationChange = () => {},
  setSelectedPrimaryDestination = () => {},
  handlePrimaryDestinationChange = () => {},
  handleSecondaryDestinationChange = () => {},
  selectedPrimaryDestination = {},
  selectedSecondaryDestination = [],
  handleTagsChange = () => {},
  selectedTags = [],
  handleDietsChange = () => {},
  selectedSpecialDiets = [],
  handleMealsChange = () => {},
  selectedMeals = [],
  recommendationLocation = {},
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    if (type === "recommendationInfo") {
      return "Recommendation info";
    }
    if (type === "address") {
      return "Address";
    }
    if (type === "dateAndTimeInfo") {
      return "Date And Time Info";
    }
    if (type === "rating") {
      return "Rating";
    }

    return "Additional info";
  };

  useEffect(() => {
    if (type === "address" && detailPage && recommendationLocation) {
      handleLocationChange(recommendationLocation);
    }
  }, [type, detailPage, recommendationLocation]);

  const handleInfoCard = () => {
    if (type === "recommendationInfo") {
      return (
        <Information
          formField={formData?.formField}
          editPage
          detailPage={detailPage}
          selectedTags={selectedTags}
          handleTagsChange={handleTagsChange}
        />
      );
    }
    if (type === "address") {
      return (
        <Address
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          handlePrimaryDestinationChange={handlePrimaryDestinationChange}
          handleSecondaryDestinationChange={handleSecondaryDestinationChange}
          selectedPrimaryDestination={selectedPrimaryDestination}
          selectedSecondaryDestination={selectedSecondaryDestination}
          setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        />
      );
    }

    if (type === "rating") {
      return <Rating />;
    }
    if (type === "dateAndTimeInfo") {
      return <DateAndTimeInfo formData={formData} editPage detailPage={detailPage} />;
    }

    return (
      <AdditionalInfo
        formField={formData?.formField}
        editPage
        detailPage={detailPage}
        selectedSpecialDiets={selectedSpecialDiets}
        handleDietsChange={handleDietsChange}
        selectedMeals={selectedMeals}
        handleMealsChange={handleMealsChange}
      />
    );
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>

        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          noEditIcon={type === "rating"}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>

      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// PropTypes validation
RecommendationDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  setSelectedPrimaryDestination: PropTypes.func,
  handlePrimaryDestinationChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  selectedSecondaryDestination: PropTypes.instanceOf(Array),
  handleTagsChange: PropTypes.func,
  selectedTags: PropTypes.instanceOf(Array),
  handleDietsChange: PropTypes.func,
  selectedSpecialDiets: PropTypes.instanceOf(Array),
  handleMealsChange: PropTypes.func,
  selectedMeals: PropTypes.instanceOf(Array),
  recommendationLocation: PropTypes.instanceOf(Object),
};

export default RecommendationDetail;
