import React from "react";
// import PropTypes from "prop-types";
import { Grid2, Typography } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HeadsetIcon from "@mui/icons-material/Headset";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PropTypes from "prop-types";

const LanguagesHeader = ({ values, languagesV = [] }) =>
  values.languages &&
  languagesV?.length > 0 && (
    <Grid2 item size={{ xs: 12 }}>
      <Typography variant="h6">Languages</Typography>
      <Grid2 container spacing={2} mt={2}>
        <Grid2 item size={{ xs: 3 }}>
          <Typography fontSize="medium">Languages</Typography>
        </Grid2>
        <Grid2 item size={{ xs: 2 }} display="flex" alignItems="center">
          <PersonOutlineIcon style={{ marginRight: "8px" }} />
          <Typography fontSize="medium">In-person</Typography>
        </Grid2>
        <Grid2 item size={{ xs: 2 }} display="flex" alignItems="center">
          <HeadsetIcon style={{ marginRight: "8px" }} />
          <Typography fontSize="medium">Audio</Typography>
        </Grid2>
        <Grid2 item size={{ xs: 2 }} display="flex" alignItems="center">
          <LibraryBooksIcon style={{ marginRight: "8px" }} />
          <Typography fontSize="medium">Written</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );

LanguagesHeader.propTypes = {
  languagesV: PropTypes.instanceOf(Array),
  values: PropTypes.instanceOf(Object).isRequired,
};

export default LanguagesHeader;
