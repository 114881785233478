import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const TagFilter = ({ onFilterChanged, columnDef }) => {
  const [selectedTag, setSelectedTag] = useState(null);

  const handleChange = (value) => {
    if (!value) {
      setSelectedTag(null);
    } else {
      setSelectedTag(value);
    }
    const val = value?.value;
    onFilterChanged(columnDef.tableData.id, val);
  };

  const filterRec = (data) =>
    data
      .map((TagData) => ({
        label: TagData.title,
        value: TagData._id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort the tags alphabetically

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tags?`;
      if (inputValue) url += `title=${encodeURIComponent(inputValue)}`;
      url += "&sort=title&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          console.error(error.message);
        });
    });

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>
            <AsyncSelect
              aria-label="Tag"
              cacheOptions
              defaultOptions
              defaultValue={selectedTag}
              isClearable
              menuPosition="fixed"
              loadOptions={promiseOptions}
              onChange={(option) => {
                handleChange(option);
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "48px",
                  width: "200px",
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                input: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menuList: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                }),
                placeholder: (base) => ({
                  ...base,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "100%",
                  WebkitLineClamp: 1,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                singleValue: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
              }}
            />
          </th>
        </tr>
      </thead>
    </table>
  );
};

TagFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
};

export default TagFilter;
