/* eslint-disable no-nested-ternary */

/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewPartner page components
import AddressLoc from "components/AddressLoc";
import MyMapPicker from "components/MyMapPicker";
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";

const Address = ({
  formData,
  detailPage = true,
  editPage = false,
  selectedLocation = {},
  handleLocationChange = () => {},
}) => {
  const { values, errors, touched, setFieldValue } = formData;

  const user = useSelector((state) => state.users.user);

  const [currentLocation, setCurrentLocation] = useState({
    lat: "",
    lng: "",
  });

  const getLocation = async () => {
    try {
      await navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);
  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Address</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <>
          <DetailList
            data={{
              address: values?.address,
              country: values?.country,
              city: values?.geoLocation?.city || values?.city,
              lat: values?.lat,
              lng: values?.lng,
            }}
          />
          <div style={{ marginTop: "16px" }}>
            <MyMapPicker
              lat={values.lat !== "" ? values.lat?.toString() : currentLocation.lat.toString()}
              lng={values.lng !== "" ? values.lng?.toString() : currentLocation.lng.toString()}
              setFieldValue={setFieldValue}
              disabled
            />
          </div>
        </>
      ) : (
        <MDBox mt={1.625}>
          <Grid item sx={{ marginBottom: "24px" }}>
            <AddressLoc
              formData={formData}
              setFieldValue={setFieldValue}
              currentLocation={currentLocation}
              selectedLocation={selectedLocation}
              handleLocationChange={handleLocationChange}
              error={user.role === "admin" && errors.address && touched.address && errors.address}
              disabled={user?.role === "partner"}
              recPage
            />
          </Grid>
          <Grid item size={{ xs: 12 }}>
            <MyMapPicker
              lat={values.lat !== "" ? values.lat?.toString() : currentLocation.lat.toString()}
              lng={values.lng !== "" ? values.lng?.toString() : currentLocation.lng.toString()}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
};

export default Address;
