import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { recommendation, item, trendingStartD },
} = checkout;

const validations = [
  Yup.object().shape({
    [item.name]: Yup.string().when("type", {
      is: "item",
      then: () => Yup.string().required(item.errorMsg),
    }),
    [recommendation?.name]: Yup.object().when("type", {
      is: "recommendation",
      then: () => Yup.object().required(recommendation.errorMsg),
    }),
    [trendingStartD.name]: Yup.string().required(trendingStartD.errorMsg),
  }),
];

export default validations;
