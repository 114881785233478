import {
  SET_PROMOTION_LOADING,
  PROMOTION_ADD_SUCCESS,
  PROMOTION_FAIL,
  GET_PROMOTIONS,
  CLEAR_ERRORS,
  DELETE_PROMOTION,
  GET_ONE_PROMOTION,
  UPDATE_PROMOTION,
  LOGOUT,
  UPDATE_PROMOTION_TRANSLATION,
  DELETE_SINGLE_PROMOTION,
  SET_PROMOTION_EMPTY,
  GET_PROMOTION_TRANSLATION,
} from "../actions/Types";

const initialState = {
  loading: false,
  promotions: [],
  error: null,
  added: false,
  promotion: null,
  translationPromotion: {},
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case PROMOTION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        promotions: [...state.promotions, action.payload.data.data],
        added: true,
        promotion: null,
      };
    case GET_ONE_PROMOTION:
      return {
        ...state,
        loading: false,
        error: null,
        promotion: action.payload.data,
        added: false,
      };
    case UPDATE_PROMOTION_TRANSLATION:
      return {
        ...state,
        translationPromotion: action.payload.data,
      };
    case GET_PROMOTION_TRANSLATION:
      return {
        ...state,
        translationPromotion: action.payload.data,
      };
    case GET_PROMOTIONS:
      return {
        ...state,
        loading: false,
        error: null,
        promotions: action.payload.data,
        added: false,
        promotion: null,
      };
    case DELETE_PROMOTION:
      return {
        ...state,
        loading: false,
        promotions: state.promotions.filter(
          (promotion) => promotion._id !== action.payload.data.data._id
        ),
      };
    case DELETE_SINGLE_PROMOTION:
      return {
        ...state,
        loading: false,
        promotion: null,
      };
    case UPDATE_PROMOTION:
      return {
        ...state,
        added: true,
        promotion: action.payload.data.data,
        loading: false,
        promotions: state.promotions.map((promotion) =>
          promotion._id === action.payload.data._id ? action.payload.data : promotion
        ),
      };
    case SET_PROMOTION_EMPTY:
      return {
        ...state,
        loading: false,
        error: null,
        promotion: {},
      };
    case PROMOTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PROMOTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        promotions: [],
        error: null,
        added: false,
        promotion: null,
      };

    default:
      return state;
  }
};
export default variable;
