/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid2, Switch, Typography, Card, CardContent } from "@mui/material";
import MDBox from "components/MDBox";
import CopytoClipboard from "components/CopytoClipboard";
import ViewEditActions from "components/DetailList/ViewEditActions";
import FormField from "components/FormField";
import ServiceJobsCompanyPicker from "components/AutoComplete/ٍServiceJobs/ٍServiceJobsCompanyPicker";
import ServiceJobsSystemPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsSystemPicker";
import ServiceJobsVersionPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsVersionPicker";
import DetailList from "components/DetailList";
import { addJobs, setLoading } from "store/actions/locationAction";
import { getUpdatedFilter } from "store/actions/userActions";
import { Formik, Form } from "formik";
import validationsCompanion from "../schemas/validationsCompanion";
import initialValuesCompanion from "../schemas/initialValuesCompanion";
import formCompanion from "../schemas/formCompanion";

const webUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://lokalee.app"
    : "https://staging.lokalee.app";

const GuestCompanion = ({ location, selectedTab }) => {
  /* *** Selectors *** */
  const reduxFilters = useSelector((state) => state.users.filters);

  /* *** Constants *** */
  const dispatch = useDispatch();
  const { apikey, url, portalId, diningId, natsPropertyId } = formCompanion.formField;

  /* *** Refs *** */
  const formRef = useRef();
  // const tableRef = createRef();

  /* *** State *** */
  const [detailPage, setDetailPage] = useState(true);
  const [showInDining, setShowInDining] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesCompanion);

  useEffect(() => {
    dispatch(
      getUpdatedFilter({
        filters: reduxFilters?.filters,
        row_nb: reduxFilters?.row_nb,
        pageValue: reduxFilters?.pageValue,
        search: "",
      })
    );
  }, []);
  useEffect(() => {
    if (location) {
      const manipulated = {};
      manipulated.company = location.guestRequestType || "";
      manipulated.propertyCode = location.guestRequestPropertyId || "";
      manipulated.inRoomDiningPropertyId = location.inRoomDiningPropertyId || "";
      manipulated.inRoomDining = location.inRoomDining ? location.inRoomDining : false;
      manipulated.serviceJobs = location.serviceJobs || [];
      manipulated.system = location.guestRequestType === "sky" ? "guestapp" : "servicerequest";
      manipulated.version = location.guestRequestType === "sky" ? "v1" : "v0.1";
      manipulated.title = "";
      manipulated.code = "";
      manipulated.serviceJobsObj = null;
      manipulated.showGuestCompanion = location.showGuestCompanion
        ? location.showGuestCompanion
        : false;
      manipulated.url = location?.skyUrl || "";
      manipulated.apikey = location?.skyApikey || "";
      manipulated.portalId = location?.skyPortalId || "";
      manipulated.diningId = location?.skyDiningId || "";
      manipulated.diningCompany = location.inRoomDiningPropertyId ? "digi" : "sky";
      manipulated.natsPropertyId = location?.natsPropertyId || "";

      setShowInDining(location.inRoomDining);
      setInitialValues(manipulated);
    }
  }, [location]);

  // set detail page to true when selctedTab changes
  useEffect(() => {
    setDetailPage(true);
  }, [selectedTab]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm1 = async (values) => {
    await sleep(1000);
    dispatch(setLoading());
    dispatch(addJobs(location?._id, values, selectedTab));
    setDetailPage(true);
  };

  // const handleAddJob = (values, setFieldValue) => {
  // removed the functionallity of adding jobs to the guest companion table and delete job
  // };

  return (
    <MDBox>
      <Grid2 container justifyContent="center">
        <Grid2 item size={{ xs: 12, md: 12, lg: 12 }}>
          <Formik
            key={selectedTab}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationsCompanion[selectedTab]}
            onSubmit={(values, actions) => submitForm1(values, actions)}
            innerRef={(f) => {
              formRef.current = f;
            }}
          >
            {({ values, errors, touched, setFieldValue, resetForm, submitForm }) => (
              <Form autoComplete="off">
                <Card>
                  <CardContent>
                    <MDBox>
                      <Grid2
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid2 item />
                        <Grid2 item>
                          <ViewEditActions
                            detailPage={detailPage}
                            stateName="locations"
                            handleEdit={() => {
                              setDetailPage(false);
                            }}
                            handleClose={() => {
                              setDetailPage(true);
                              resetForm();
                              setFieldValue("serviceJobs", location.serviceJobs);
                            }}
                            handleSave={(event) => {
                              event.preventDefault();
                              submitForm();
                            }}
                          />
                        </Grid2>
                      </Grid2>

                      <Divider sx={{ marginY: 2 }} />

                      {detailPage && selectedTab === 0 && (
                        <DetailList
                          data={{
                            company: values?.company !== "none" && values?.company,
                            propertyCode: values?.propertyCode,
                            apiKey: values?.apikey,
                            url: values?.url,
                            portalId: values?.company === "sky" && values?.portalId,
                            diningId: values?.company === "sky" && values?.diningId,
                            natsPropertyId: values?.company === "sky" && values?.natsPropertyId,
                            showGuestCompanion:
                              values?.company === "sky" && values?.showGuestCompanion,
                          }}
                        />
                      )}
                      {detailPage && selectedTab === 1 && (
                        <DetailList
                          data={{
                            company: values?.inRoomDining
                              ? values?.diningCompany === "digi"
                                ? "Digi Menu"
                                : "Sky Software"
                              : "",
                            propertyCode: values?.inRoomDiningPropertyId,
                          }}
                        />
                      )}

                      {!detailPage && selectedTab === 0 && (
                        <>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: "inline", mr: 2 }}
                          >
                            Guest Companion
                          </Typography>
                          <Switch
                            checked={values?.showGuestCompanion}
                            onChange={() =>
                              setFieldValue("showGuestCompanion", !values?.showGuestCompanion)
                            }
                            color="primary"
                          />

                          {values?.showGuestCompanion && (
                            <>
                              <Grid2 item size={{ xs: 12 }} style={{ marginBottom: 4 }}>
                                <CopytoClipboard
                                  text={`${webUrl}/locations/${location?.slug}/companion`}
                                />
                              </Grid2>
                              <Typography style={{ fontSize: "0.875rem", fontWeight: 400 }}>
                                Choose your vendor:
                              </Typography>
                              <Grid2 container spacing={2} alignItems="center">
                                <Grid2 item size={{ xs: 3 }}>
                                  <ServiceJobsCompanyPicker
                                    companyV={values?.company}
                                    setFieldValue={setFieldValue}
                                    companyOptions={[
                                      { label: "Sky Software", value: "sky" },
                                      { label: "Message Box", value: "messagebox" },
                                    ]}
                                  />
                                </Grid2>
                                <Grid2 item size={{ xs: 3 }}>
                                  <ServiceJobsSystemPicker
                                    company={values.company}
                                    systemV={values?.system}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid2>
                                <Grid2 item size={{ xs: 3 }}>
                                  <ServiceJobsVersionPicker
                                    company={values.company}
                                    system={values.system}
                                    versionV={values?.version}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid2>
                                <Grid2 item size={{ xs: 3 }}>
                                  <FormField
                                    type="text"
                                    label="Property Code"
                                    name="propertyCode"
                                    value={values?.propertyCode}
                                    placeholder="Property Code"
                                    error={errors?.propertyCode && touched?.propertyCode}
                                    success={
                                      values?.propertyCode?.length > 0 && !errors?.propertyCode
                                    }
                                  />
                                </Grid2>
                              </Grid2>
                              <Grid2 container spacing={2} alignItems="center">
                                <Grid2 item size={{ xs: 3 }}>
                                  <FormField
                                    type={apikey.type}
                                    name={apikey.name}
                                    label={apikey.label}
                                    value={values?.apikey}
                                    placeholder={apikey.label}
                                  />
                                </Grid2>
                                <Grid2 item size={{ xs: 9 }}>
                                  <FormField
                                    name={url.name}
                                    type={url.type}
                                    label={url.label}
                                    value={values?.url}
                                    placeholder={url.label}
                                    error={errors?.url && touched?.url}
                                    success={values?.url?.length > 0 && !errors?.url}
                                  />
                                </Grid2>
                              </Grid2>
                              {values?.company === "sky" && (
                                <Grid2 container spacing={2} alignItems="center">
                                  <Grid2 item size={{ xs: 4 }}>
                                    <FormField
                                      type={portalId.type}
                                      name={portalId.name}
                                      label={portalId.label}
                                      value={values?.portalId}
                                      placeholder={portalId.label}
                                    />
                                  </Grid2>

                                  <Grid2 item size={{ xs: 4 }}>
                                    <FormField
                                      type={diningId.type}
                                      name={diningId.name}
                                      label={diningId.label}
                                      value={values?.diningId}
                                      placeholder={diningId.label}
                                    />
                                  </Grid2>
                                  <Grid2 item size={{ xs: 4 }}>
                                    <FormField
                                      type={natsPropertyId.type}
                                      name={natsPropertyId.name}
                                      label={natsPropertyId.label}
                                      value={values?.natsPropertyId}
                                      placeholder={natsPropertyId.label}
                                    />
                                  </Grid2>
                                </Grid2>
                              )}
                              {/* removed add jobs with the guest companion table */}
                              {/* <Grid2 item size={{ xs: 3 }}>
                                <MDButton
                                  onClick={() => handleAddJob(values, setFieldValue)}
                                  variant="gradient"
                                  color="dark"
                                >
                                  Add Job
                                </MDButton>
                              </Grid2> */}
                            </>
                          )}
                        </>
                      )}
                      {!detailPage && selectedTab === 1 && (
                        <>
                          <Grid2 item>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{ display: "inline", mr: 2 }}
                            >
                              In Room Dining
                            </Typography>

                            <Switch
                              color="primary"
                              checked={showInDining}
                              onChange={() => {
                                setShowInDining(!showInDining);
                                setFieldValue("inRoomDining", !values?.inRoomDining);
                              }}
                            />
                          </Grid2>

                          {showInDining && (
                            <Grid2 container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                              <Grid2 item size={{ xs: 3 }}>
                                <ServiceJobsCompanyPicker
                                  isDiningTab
                                  setFieldValue={setFieldValue}
                                  companyV={values?.diningCompany}
                                  companyOptions={[
                                    { label: "Digi Menu", value: "digi" },
                                    { label: "Sky Software", value: "sky" },
                                  ]}
                                />
                              </Grid2>

                              <Grid2 item size={{ xs: 3 }}>
                                <ServiceJobsSystemPicker
                                  setFieldValue={setFieldValue}
                                  company={values.diningCompany}
                                  systemV={values?.diningSystem}
                                />
                              </Grid2>

                              <Grid2 item size={{ xs: 3 }}>
                                <ServiceJobsVersionPicker
                                  setFieldValue={setFieldValue}
                                  company={values.diningCompany}
                                  versionV={values?.diningVersion}
                                />
                              </Grid2>

                              {values?.diningCompany === "digi" && (
                                <Grid2 item size={{ xs: 3 }}>
                                  <FormField
                                    type="text"
                                    label="Property Code"
                                    placeholder="Property Code"
                                    name="inRoomDiningPropertyId"
                                    value={values?.inRoomDiningPropertyId}
                                    error={
                                      errors?.inRoomDiningPropertyId &&
                                      touched?.inRoomDiningPropertyId
                                    }
                                    success={
                                      values?.inRoomDiningPropertyId?.length > 0 &&
                                      !errors?.inRoomDiningPropertyId
                                    }
                                  />
                                </Grid2>
                              )}
                            </Grid2>
                          )}
                        </>
                      )}
                    </MDBox>
                  </CardContent>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid2>
      </Grid2>
    </MDBox>
  );
};

GuestCompanion.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default GuestCompanion;
