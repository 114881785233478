import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, days, introText, travelStyle, curator, destination, images },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [introText.name]: Yup.string()
      .matches(/([A-Za-z])/, introText.invalidMsg)
      .required(introText.errorMsg),
    [travelStyle.name]: Yup.string()
      .matches(/([A-Za-z])/, travelStyle.invalidMsg)
      .required(travelStyle.errorMsg),
    [days.name]: Yup.number()
      .positive("Days must be a positive number")
      .required("Days is required"),
    [curator.name]: Yup.object().required(curator?.errorMsg),
    [destination.name]: Yup.object().required(destination?.errorMsg),
    [images.name]: Yup.array().min(1, images.errorMsg).required(),
  }),
];

export default validations;
