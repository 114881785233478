/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import Information from "pages/corporate/venues/new-venue/components/Information";
import Address from "pages/corporate/venues/new-venue/components/Address";

// Pages

const VenueDetails = ({
  formData,
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  setSelectedPrimaryDestination = () => {},
  handlePrimaryDestinationChange = () => {},
  selectedPrimaryDestination = {},
}) => {
  const { submitForm, resetForm, values } = useFormikContext(); // added values to access to previous form saved values

  const handleCardTitle = () => {
    if (type === "information") {
      return "Venue Info";
    }
    return "Address";
  };

  const handleInfoCard = () => {
    if (type === "information") {
      return (
        <Information
          formData={formData}
          editPage
          detailPage={detailPage}
          handlePrimaryDestinationChange={handlePrimaryDestinationChange}
          selectedPrimaryDestination={selectedPrimaryDestination}
          setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        />
      );
    }
    return <Address formData={formData} editPage detailPage={detailPage} />;
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
            // resetting primary destination to the original value from form values
            setSelectedPrimaryDestination({
              value: values.primaryDestinationId,
              label: values.primaryDestinationName,
            });
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// typechecking props for VenueDetails
VenueDetails.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  setSelectedPrimaryDestination: PropTypes.func,
  handlePrimaryDestinationChange: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
};

export default VenueDetails;
