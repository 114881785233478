/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import BannerImageDisplay from "components/DetailList/BannerImageDisplay";

// Pages
import Information from "pages/content/banners/new-banner/components/Information";

const BannerDetail = ({
  formData,
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  handlePositionChange = () => {},
  handleDestinationChange = () => {},
  selectedPosition = {},
  selectedDestination = {},
}) => {
  const { values, submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => "Banner Info";

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            // First reset the form
            resetForm();

            // Pass information to parent component that edit was canceled
            // and parent should reset selected values
            setDetailPage(true, true); // Pass a second parameter to indicate cancellation
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>
        {detailPage ? (
          <>
            <Information
              formData={formData}
              editPage
              detailPage={detailPage}
              handlePositionChange={handlePositionChange}
              handleDestinationChange={handleDestinationChange}
              selectedPosition={selectedPosition}
              selectedDestination={selectedDestination}
            />
            <MDBox>
              {values.image?.[0] && values.position?.value && (
                <BannerImageDisplay
                  desktopImage={values.image[0]}
                  mobileImage={values.mobileImage[0]}
                  position={values.position.value || ""}
                />
              )}
            </MDBox>
          </>
        ) : (
          <Information
            formData={formData}
            editPage
            detailPage={detailPage}
            handlePositionChange={handlePositionChange}
            handleDestinationChange={handleDestinationChange}
            selectedPosition={selectedPosition}
            selectedDestination={selectedDestination}
          />
        )}
      </MDBox>
    </MDBox>
  );
};

// typechecking props for BannerDetail
BannerDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  handlePositionChange: PropTypes.func,
  handleDestinationChange: PropTypes.func,
  selectedPosition: PropTypes.instanceOf(Object),
  selectedDestination: PropTypes.instanceOf(Object),
};

export default BannerDetail;
