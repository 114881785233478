/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */

import axios from "axios";
import {
  SET_RECOMMENDATION_LOADING,
  CLEAR_ERRORS,
  RECOMMENDATION_ADD_SUCCESS,
  RECOMMENDATION_FAIL,
  UPDATE_RECOMMENDATION,
  DELETE_SINGLE_RECOMMENDATION,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Recommendation
export const addRecommendation = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  form.append("title", data.title?.trim());
  form.append("subHeader", data.subHeader?.trim());
  form.append("description", data.description?.trim());
  form.append("price", data.price);

  if (data.cuisines) {
    data.cuisines.map((cuisine) => {
      form.append("cuisines", cuisine?.label ? cuisine?.label : cuisine);
      return cuisine?.label ? cuisine?.label : cuisine;
    });
  } else {
    form.append("cuisines", []);
  }

  if (data.features) {
    data.features.map((feature) => {
      form.append("features", feature?.label ? feature?.label : feature);
      return feature?.label ? feature?.label : feature;
    });
  } else {
    form.append("features", []);
  }
  form.append("phone", data.phone);
  form.append("email", data.email);
  if (data.address || data.city) form.append("address", data.address || data.city);
  form.append("city", data.city?.trim());
  form.append("region", data.region);
  form.append("country", data.country);
  form.append("callToAction", JSON.stringify(data.callToAction));
  form.append("callToAction2", JSON.stringify(data.callToAction2));
  form.append("highlight", data.highlight);
  form.append("sponsored", data.sponsored);
  form.append("currated", data.currated);
  form.append("special", data.special);
  form.append("experienceX", data.experienceX);
  form.append("exclusive", data.exclusive);
  form.append("insider", data.insider);
  form.append("exclusiveLocation", data.exclusiveLocation);
  form.append("exclusivePartner", data.exclusivePartner);
  form.append("webAddresses", data.webAddresses);
  form.append("traveltype", data.traveltype);
  form.append("top", data.top);
  if (data.location)
    form.append(
      "location",
      data?.location?._id ? data?.location?._id : data?.location ? data?.location : null
    );
  form.append("lat", data.lat);
  form.append("lng", data.lng);
  if (image) {
    image.path = image?.name;
    form.append("images", image);
    form.append("hasLogo", true);
  }
  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });

  data.openHours?.map((openHour) => {
    form.append("openHours", JSON.stringify(openHour));
    return openHour;
  });
  if (data?.kidsFriendly) {
    form.append("kidsFriendly", data?.kidsFriendly);
  }
  if (data?.style?.length > 0) {
    form.append("style", data.style);
  }
  if (data?.interests?.length > 0) {
    form.append("interests", data.interests);
  }
  form.append("timeSlotPreference", data.timeSlotPreference);
  form.append("seasonality", data.seasonality);
  form.append("genderPreference", data.genderPreference);
  form.append("primaryDestination", data.primaryDestination);
  data.destinations?.map((destination) => {
    form.append("destinations", destination?.value);
    return destination;
  });
  if (data?.badges?.value) form.append("badges", data.badges?.value);

  if (data?.tags?.length > 0) {
    data.tags.forEach((tag) => {
      form.append("tags", tag.value);
    });
  }

  if (data.bookingEmail) form.append("bookingEmail", data.bookingEmail);
  if (data.minGuests) form.append("minGuests", data.minGuests);
  if (data.maxGuests) form.append("maxGuests", data.maxGuests);
  if (data.needsConfirmation) form.append("needsConfirmation", data.needsConfirmation);

  if (data.specialDiets) {
    data.specialDiets.map((diet) => {
      form.append("specialDiets", diet?.label ? diet?.label : diet);
      return diet?.label ? diet?.label : diet;
    });
  } else {
    form.append("specialDiets", []);
  }
  if (data.meals) {
    data.meals.map((meal) => {
      form.append("meals", meal?.label ? meal?.label : meal);
      return meal?.label ? meal?.label : meal;
    });
  } else {
    form.append("meals", []);
  }
  if (data?.min && data?.max) {
    form.append("minPrice", data.min);
    form.append("maxPrice", data.max);
  }

  try {
    const res = await axios.post(`${URL}/recommendations`, form, config);

    dispatch({
      type: RECOMMENDATION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECOMMENDATION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Get All Cuisines
export const getCuisines = (extraParam) =>
  factory.get(
    `${URL}/recommendations/cuisines?sort=title&dir=asc&${extraParam ? `${extraParam}` : ""}`,
    "GET_CUISINES",
    "CUISINES_FAIL"
  );

// Get All Features
export const getFeatures = (extraParam) =>
  factory.get(
    `${URL}/recommendations/features?sort=title&dir=asc${extraParam ? `${extraParam}` : ""}`,
    "GET_FEATURES",
    "FEATURES_FAIL"
  );

// Delete Recommendation - set active as false
export const deleteRecommendation = (id) =>
  factory.patch(
    { active: false },
    `${URL}/recommendations/${id}`,
    "DELETE_RECOMMENDATION",
    "RECOMMENDATION_FAIL"
  );
// Duplicate Recommendation - adds DUP to title
export const duplicateRecommendation = (id) =>
  factory.post(
    { id },
    `${URL}/recommendations/${id}`,
    "RECOMMENDATION_ADD_SUCCESS",
    "RECOMMENDATION_FAIL"
  );

// Update Recommendation
export const updateRecommendation = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  if (data.title) form.append("title", data.title?.trim());
  if (data.subHeader) form.append("subHeader", data?.subHeader?.trim());
  if (data.address) form.append("address", data.address);
  if (data.description) form.append("description", data?.description?.trim());
  if (data.city) form.append("city", data?.city?.trim());
  else form.append("city", data.geoLocation?.city?.trim());
  if (data.region) form.append("region", data.region?.trim());
  if (data.country) form.append("country", data.country?.trim());
  if (data.partner?._id) form.append("partner", data.partner._id);
  if (data.lat) form.append("lat", data.lat);
  if (data.lng) form.append("lng", data.lng);
  if (data.price) form.append("price", data.price);

  if (data.price) form.append("phone", data.phone);
  if (data.price) form.append("email", data.email);
  if (data.callToAction) form.append("callToAction", JSON.stringify(data?.callToAction));
  if (data.callToAction2) form.append("callToAction2", JSON.stringify(data?.callToAction2));
  if (data.price) form.append("webAddresses", data.webAddresses);
  if (data.insider !== undefined) form.append("insider", data.insider);
  if (data.sponsored !== undefined) form.append("sponsored", data.sponsored);
  if (data.currated !== undefined) form.append("currated", data.currated);
  if (data.special !== undefined) form.append("special", data.special);
  if (data.experienceX !== undefined) form.append("experienceX", data.experienceX);
  if (data.exclusive !== undefined) form.append("exclusive", data.exclusive);
  if (data.highlight !== undefined) form.append("highlight", data.highlight);
  if (data.exclusiveLocation !== undefined)
    form.append("exclusiveLocation", data.exclusiveLocation);
  if (data.exclusivePartner !== undefined) form.append("exclusivePartner", data.exclusivePartner);
  if (data.traveltype) form.append("traveltype", data.traveltype);
  if (data.top !== undefined) form.append("top", data.top);
  if (data.location) form.append("location", data?.location?._id ? data?.location?._id : null);
  if (data.geoLocation) {
    form.append("address", data.geoLocationData.address);
    // form.append("city", data.geoLocation.city);
    form.append("region", data.geoLocationData.region);
    form.append("country", data.geoLocationData.country);
    form.append("lat", data.geoLocation.coordinates[1]);
    form.append("lng", data.geoLocation.coordinates[0]);
  }
  if (data.primaryDestination)
    form.append(
      "primaryDestination",
      typeof data.primaryDestination === "string"
        ? data.primaryDestination
        : data.primaryDestination?._id
    );
  data.destinations?.map((destination) => {
    form.append("destinations", destination?.value || destination?.id);
    return destination;
  });
  // For logo
  if (image) {
    image.path = image?.name;
    form.append("images", image);
    form.append("hasLogo", true);
  }

  // to tell backend that images were cleared
  if (data?.images?.length === 0) form.append("cleared", true);

  data.images?.map((file) => {
    form.append("images", file);
    return file;
  });
  if (data.badges?.value) form.append("badges", data.badges?.value);

  if (data.cuisines) {
    data.cuisines.map((cuisine) => {
      form.append("cuisines", cuisine?.label ? cuisine?.label : cuisine);
      return cuisine?.label ? cuisine?.label : cuisine;
    });
  } else {
    form.append("cuisines", []);
  }

  if (data.features) {
    data.features.map((feature) => {
      form.append("features", feature?.label ? feature?.label : feature);
      return feature?.label ? feature?.label : feature;
    });
  } else {
    form.append("features", []);
  }

  if (data?.tags?.length > 0) {
    data.tags.forEach((tag) => {
      form.append("tags", tag.value);
    });
  }

  data.openHours?.map((openHour) => {
    form.append("openHours", JSON.stringify(openHour));
    return openHour;
  });

  if (data.kidsFriendly) form.append("kidsFriendly", data?.kidsFriendly);

  if (data?.style?.length > 0) {
    form.append("style", data.style);
  }
  if (data?.interests?.length > 0) {
    form.append("interests", data.interests);
  }
  if (data.timeSlotPreference) form.append("timeSlotPreference", data.timeSlotPreference);
  if (data.seasonality) form.append("seasonality", data.seasonality);
  if (data.genderPreference) form.append("genderPreference", data.genderPreference);
  if (data.bookingEmail) form.append("bookingEmail", data.bookingEmail);
  if (data.minGuests) form.append("minGuests", data.minGuests);
  if (data.maxGuests) form.append("maxGuests", data.maxGuests);
  if (data.needsConfirmation) form.append("needsConfirmation", data.needsConfirmation);
  if (!data.reservation) form.append("reservation", null);

  if (data.specialDiets) {
    data.specialDiets.map((diet) => {
      form.append("specialDiets", diet?.label ? diet?.label : diet);
      return diet?.label ? diet?.label : diet;
    });
  } else {
    form.append("specialDiets", []);
  }
  if (data.meals) {
    data.meals.map((meal) => {
      form.append("meals", meal?.label ? meal?.label : meal);
      return meal?.label ? meal?.label : meal;
    });
  } else {
    form.append("meals", []);
  }
  if (data?.min && data?.max) {
    form.append("minPrice", data.min);
    form.append("maxPrice", data.max);
  }

  try {
    const res = await axios.patch(`${URL}/recommendations/${data._id}`, form, config);

    dispatch({
      type: UPDATE_RECOMMENDATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECOMMENDATION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Get All Recommendations

export const getRecommendations = () =>
  factory.get(
    `${URL}/recommendations?sort=title&dir=asc`,
    "GET_RECOMMENDATIONS",
    "RECOMMENDATION_FAIL"
  );

// get One Recommendation
export const getOneRecommendation = (id) =>
  factory.get(`${URL}/recommendations/${id}`, "GET_ONE_RECOMMENDATION", "RECOMMENDATION_FAIL");

export const updateRecommendationTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/recommendations/translations/${id}?language=ar`,
    "UPDATE_RECOMMENDATION_TRANSLATION",
    "RECOMMENDATION_FAIL"
  );

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_RECOMMENDATION_LOADING });

// Get Partner Recommendations

export const getPartnerRecommendations = (partner) =>
  factory.get(
    `${URL}/partners/${partner}/recommendations`,
    "GET_RECOMMENDATIONS",
    "RECOMMENDATION_FAIL"
  );

// Get Location Recommendations

export const getLocationRecommendations = (location) =>
  factory.get(
    `${URL}/locations/${location?._id || location}/recommendations`,
    "GET_RECOMMENDATIONS",
    "RECOMMENDATION_FAIL"
  );

// Clear single recommendation
export const deleteSingleRecommendation = () => ({ type: DELETE_SINGLE_RECOMMENDATION });
