import * as Yup from "yup";
import checkout from "../../new-menu/schema/form";

const {
  formField: { title, tags, destinations, locations, type, option, url, tickets },
} = checkout;

const validations = [
  Yup.object().shape({
    // [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [type.name]: Yup.string().required(type.errorMsg),
    // [tags.name]: Yup.array().min(1, tags?.invalidMsg).required(tags?.errorMsg),
    [destinations.name]: Yup.array().when("type", {
      is: "destinations",
      then: () => Yup.array().min(1, destinations?.invalidMsg).required(destinations?.errorMsg),
    }),
    [locations.name]: Yup.array().when("type", {
      is: "location",
      then: () => Yup.array().min(1, locations?.invalidMsg).required(locations?.errorMsg),
    }),
    [option.name]: Yup.string().required(option.errorMsg),
    [tags.name]: Yup.array().when(["option", "filterBy"], {
      is: (optionVal, filterBy) =>
        optionVal === "Recommendation" || (optionVal === "Ticket" && filterBy === "tags"),
      then: () => Yup.array().min(1, tags?.invalidMsg).required(tags?.errorMsg),
    }),
    [tickets.name]: Yup.array().when(["option", "filterBy"], {
      is: (optionVal, filterBy) => optionVal === "Ticket" && filterBy === "tickets",
      then: () => Yup.array().min(1, tickets?.invalidMsg).required(tickets?.errorMsg),
    }),
    [url.name]: Yup.string().when("option", {
      is: "URL",
      then: () =>
        Yup.string()
          .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
            url.invalidMsg
          )
          .required(url.errorMsg),
    }),
  }),
];

export default validations;
