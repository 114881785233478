// Libraries
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";
import {
  updateBanner,
  disableBannerToTrue,
  disableBannerToFalse,
  deleteBanner,
  clearErrors,
} from "store/actions/bannerActions";
import setAlert from "store/actions/alertActions";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const AllBanners = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();
  const firstRenderRef = useRef(true);

  const [isClicked, setIsClicked] = useState(false);

  const loading = useSelector((state) => state.banners.loading);
  const added = useSelector((state) => state.banners.added);
  const error = useSelector((state) => state.banners.error);

  useEffect(() => {
    if (firstRenderRef.current) {
      // Skip calling onQueryChange on the *very first* render
      firstRenderRef.current = false;
    } else if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [isClicked, added]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  const disableBannerFn = async (val) => {
    setIsClicked(true);
    try {
      if (val?.disabled === false) {
        await dispatch(disableBannerToTrue(val));
      } else {
        await dispatch(disableBannerToFalse(val));
      }
    } catch (err) {
      console.error("Error disabling banner:", err);
    } finally {
      setIsClicked(false);
    }
  };

  const updateBannerFn = (data) => {
    const updatedData = { _id: data._id, title: data.title };
    dispatch(updateBanner(updatedData));
  };

  const deleteBannerFn = (id) => {
    dispatch(deleteBanner(id));
  };

  const actionsChildFn = (rowData) => {
    let actionsChildRole = {};
    actionsChildRole = {
      icon: rowData.disabled === false ? CloseIcon : DoneIcon,
      tooltip: rowData.disabled === false ? "Disable" : "Enable",

      iconProps: {
        style: {
          marginLeft: "10px",
          fontSize: "25px",
          color: rowData.disabled === false ? "#f50057" : "green",
        },
      },
      onClick: (ww, rowDataId) => {
        disableBannerFn(rowDataId);
      },
    };
    return actionsChildRole;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3} pt={3}>
        <Grid item size={{ xs: 12 }}>
          <MDBox>
            <MaterialTablePicker
              title="Banners"
              columns={[columnsMap.bannerText, columnsMap.position, columnsMap.statusDisabled]}
              tableRef={tableRef}
              searchField="banners"
              //   exportMenuField="banners"
              asyncTable
              asyncURL={`${process.env.REACT_APP_API}/banners?`}
              currentUrl="banners"
              addUrl="banners/new-banner"
              onRowClickURL="/admin/banners"
              idSynonymName="_id"
              sortField="createdAt"
              direction="desc"
              editableUpdate={updateBannerFn}
              editableDelete={deleteBannerFn}
              actionsChild={actionsChildFn}
              updateRoleAction
              deleteRoleAction
              editableRole
              filteringOptions={false}
              isLoading={loading}
              isExportable={false}
              actionsName="Banner"
              actionsRole
            />
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllBanners;
