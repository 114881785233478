import { useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLocations, setLoading, getUserPartnerLocations } from "store/actions/locationAction";

const LocationFilterHeader = ({ handleChange = () => {}, selectedLocation = {} }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.user);
  const locations = useSelector((state) => state.locations.locations);

  // get locations
  useEffect(() => {
    setLoading();
    if (user?.role !== "partner") dispatch(getLocations());
    else {
      dispatch(getUserPartnerLocations(user?._id));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDBox height="100%" mt={0.5}>
              <MDTypography variant="body2" color="text" fontWeight="medium">
                Please select a Location
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item size={{ xs: 12 }} sx={{ paddingTop: "0px!important" }}>
            <Autocomplete
              id="location"
              value={selectedLocation}
              options={[...locations, {}]}
              onChange={(event, option) => {
                handleChange(option);
              }}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={!selectedLocation?._id ? " " : undefined}
                  InputLabelProps={{ shrink: true }}
                  placeholder={!selectedLocation?._id ? "Select Location" : undefined}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Remove all borders
                        borderBottom: "1px solid #003005", // Keep only bottom border
                      },
                      "&:hover fieldset": {
                        borderBottom: "2px solid #003005", // Thicker bottom border on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderBottom: "2px solid #003005", // Thicker bottom border on focus
                      },
                    },
                    "& .MuiInputBase-root": {
                      padding: "8px 0", // Adjust padding to align text
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// typechecking props for LocationFilterHeader
LocationFilterHeader.propTypes = {
  selectedLocation: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
};

export default LocationFilterHeader;
