import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getLocationRecommendations, setLoading } from "store/actions/recommendationActions";
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";

const RecommendationLocPicker = ({
  setFieldValue = () => {},
  locationObj = {},
  recommendation = {},
  selectedRecommendation = {},
  handleChange = () => {},
  ...rest
}) => {
  const dispatch = useDispatch();

  const recommendations = useSelector((state) =>
    state.recommendations.recommendations.sort((a, b) => a.title.localeCompare(b.title))
  );
  useEffect(() => {
    dispatch(setLoading());
    dispatch(getLocationRecommendations(locationObj?._id));

    if (selectedRecommendation) {
      setFieldValue("recommendation", selectedRecommendation);
    } else {
      setFieldValue("recommendation", null);
    }

    // eslint-disable-next-line
  }, [locationObj?._id]);

  useEffect(() => {
    if (selectedRecommendation) {
      setFieldValue("recommendation", selectedRecommendation);
    } else {
      setFieldValue("recommendation", null);
    }
  }, [selectedRecommendation?._id]);

  return (
    <div>
      <Autocomplete
        onChange={(event, val) => {
          handleChange(val, setFieldValue);
          setFieldValue("recommendation", val);
        }}
        id="recommendations"
        options={[...recommendations, {}]}
        value={selectedRecommendation || null}
        getOptionLabel={(option) => (typeof option.title === "string" ? option.title : "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <FormField
            {...params}
            {...rest}
            label={recommendation.label}
            name={recommendation.name}
            onChange={() => null}
          />
        )}
      />
    </div>
  );
};

RecommendationLocPicker.propTypes = {
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  locationObj: PropTypes.instanceOf(Object),
  recommendation: PropTypes.instanceOf(Object),
  selectedRecommendation: PropTypes.instanceOf(Object),
};

export default RecommendationLocPicker;
