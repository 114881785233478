const formCompanion = {
  formId: "companion-location-form",
  formField: {
    jobName: {
      name: "jobName",
      label: "Job Name",
      type: "text",
    },
    jobCode: {
      name: "jobCode",
      label: "Job Code",
      type: "text",
    },
    propertyCode: {
      name: "propertyCode",
      label: "Property Code",
      type: "text",
      errorMsg: "Property code is required!",
    },
    inRoomDiningPropertyId: {
      name: "inRoomDiningPropertyId",
      label: "Property ID",
      type: "text",
      errorMsg: "Property code is required!",
    },
    inRoomDining: {
      name: "inRoomDining",
      label: "In-Room Dining",
    },

    company: {
      name: "company",
      label: "Company",
      type: "text",
    },
    system: {
      name: "system",
      label: "System",
      type: "text",
    },
    version: {
      name: "version",
      label: "Version",
      type: "text",
    },
    apikey: {
      name: "apikey",
      label: "API Key",
      type: "text",
    },
    url: {
      name: "url",
      label: "URL",
      type: "text",
      invalidMsg: "The URL must start with http or https!",
    },
    portalId: {
      name: "portalId",
      label: "Portal ID",
      type: "text",
    },
    diningId: {
      name: "diningId",
      label: "Dining ID",
      type: "text",
    },
    natsPropertyId: {
      name: "natsPropertyId",
      label: "NATS Property ID",
      type: "text",
    },
  },
};

export default formCompanion;
