/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import UploadImage from "pages/content/locations/new-location/components/Sliders/UploadImage";
import { capitalizeFirstLetter } from "components/helper";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import TagPicker from "components/AsyncSelect/TagPicker";
import SubTagPicker from "components/AsyncSelect/SubTagPicker";
import Danger from "components/Danger";
import { Checkbox, FormControlLabel, InputAdornment, Typography } from "@mui/material";

const ProductInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleCurrencyChange = () => {},
  selectedCurrency = { code: "USD", name: "USD" },
  handleTagChange = () => {},
  selectedTag = {},
  handleSubTagChange = () => {},
  selectedSubTag = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;

  const {
    productName,
    jobCode,
    price,
    netPrice,
    defaultCurrencyCode,
    tag,
    subTag,
    phone,
    email,
    images,
    description,
    label,
  } = formField;

  const {
    productName: productNameV,
    jobCode: jobCodeV,
    price: priceV,
    netPrice: netPriceV,
    phone: phoneV,
    email: emailV,
    images: imagesV,
    description: descriptionV,
    label: labelV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Job info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            name: values?.name,
            website: values?.website,
            licence: values?.licence,
            commissionPercentage: values?.commissionNumber,
            top: values?.top,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={productName.type}
                label={productName.label}
                name={productName.name}
                value={capitalizeFirstLetter(productNameV)}
                placeholder={productName.placeholder}
                error={errors.productName && touched.productName}
                success={productNameV?.length > 0 && !errors.productName}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={jobCode.type}
                label={jobCode.label}
                name={jobCode.name}
                value={jobCodeV}
                placeholder={jobCode.placeholder}
                error={errors.jobCode && touched.jobCode}
                success={jobCodeV?.length > 0 && !errors.jobCode}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={price.type}
                label={price.label}
                name={price.name}
                value={priceV}
                disabled={values?.deliveryMethods?.includes("grms")}
                placeholder={price.placeholder}
                step=".01"
                error={errors.price && touched.price}
                success={priceV?.length > 0 && !errors.price}
              />
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={netPrice.type}
                label={netPrice.label}
                name={netPrice.name}
                value={netPriceV}
                disabled={values?.deliveryMethods?.includes("grms")}
                placeholder={netPrice.placeholder}
                step=".01"
                error={errors.netPrice && touched.netPrice}
                success={netPriceV?.length > 0 && !errors.netPrice}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <TagPicker
                tag={tag}
                handleChange={handleTagChange}
                setFieldValue={setFieldValue}
                selectedTag={selectedTag || {}}
                isJobType
              />
              {errors?.tag && !values?.tag?.label && touched?.tag && (
                <Danger validation={errors?.tag} />
              )}
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <SubTagPicker
                key={selectedTag?.id}
                subTag={subTag}
                handleChange={handleSubTagChange}
                setFieldValue={setFieldValue}
                selectedSubTag={selectedSubTag || {}}
                selectedTag={selectedTag || {}}
                isClearable
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <MDTypography variant="h6" color="text" fontWeight="regular">
                {defaultCurrencyCode.label}
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "8px",
                  backgroundColor: "white",
                }}
                disabled={values?.deliveryMethods?.includes("grms")}
              >
                <CurrencyPicker
                  currency={selectedCurrency}
                  handleSelectCurrency={handleCurrencyChange}
                  setFieldValue={setFieldValue}
                />
              </MDBox>
            </Grid>
          </Grid>{" "}
          <Typography
            style={{
              wordBreak: "break-word",
              fontSize: "1rem",
              fontWeight: 400,
              marginTop: "15px",
            }}
          >
            Job delivery
          </Typography>
          <Grid container size={{ xs: 9 }}>
            {["grms", "email", "whatsapp", "noknok"].map((option) => (
              <Grid item size={{ xs: 3 }} key={option}>
                <FormControlLabel
                  label={option.charAt(0).toUpperCase() + option.slice(1)}
                  control={
                    <Checkbox
                      name={option}
                      checked={values?.deliveryMethods?.includes(option)}
                      onChange={({ target }) => {
                        const { checked, name } = target;
                        const currentDeliveryMethods = values.deliveryMethods || [];

                        if (checked) {
                          // If noknok is selected, clear all other delivery methods
                          if (name === "noknok") {
                            setFieldValue("deliveryMethods", [name]);
                          }
                          // If any other delivery method is selected and noknok was previously selected,
                          // remove noknok and add the new method
                          else if (currentDeliveryMethods.includes("noknok")) {
                            setFieldValue("deliveryMethods", [name]);
                          } else {
                            if (name === "grms") {
                              setFieldValue("price", "");
                              setFieldValue("netPrice", "");
                            }
                            setFieldValue("deliveryMethods", [...currentDeliveryMethods, name]);
                          }
                        } else {
                          setFieldValue(
                            "deliveryMethods",
                            currentDeliveryMethods.filter((type) => type !== name)
                          );
                        }
                      }}
                      disabled={option === "grms" && Boolean(values?.price)} // Disable 'grms' if price exists
                      sx={{
                        color: option === "grms" && Boolean(values?.price) ? "grey" : "inherit", // Grey out label
                        opacity: option === "grms" && Boolean(values?.price) ? 0.5 : 1, // Reduce opacity if disabled
                      }}
                    />
                  }
                />
              </Grid>
            ))}{" "}
            <Danger validation={errors?.deliveryMethods} />
          </Grid>{" "}
          <Grid container size={{ xs: 12 }} spacing={2}>
            {values?.deliveryMethods?.includes("whatsapp") && (
              <Grid item size={{ xs: 12, sm: 6 }} mt={2}>
                <FormField
                  type={phone.type}
                  label={phone.label}
                  name={phone.name}
                  value={phoneV}
                  placeholder={phone.placeholder}
                  error={errors.phone && touched.phone}
                  success={phoneV.length > 0 && !errors.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{ fontSize: "1rem" }}>+</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {values?.deliveryMethods?.includes("email") && (
              <Grid item size={{ xs: 12, sm: 6 }} mt={2}>
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={emailV}
                  placeholder={email.placeholder}
                  error={errors.email && touched.email}
                  success={emailV.length > 0 && !errors.email}
                />
              </Grid>
            )}

            {/* Additional Info section for noknok */}
            {values?.deliveryMethods?.includes("noknok") && (
              <Grid container spacing={2} my={3}>
                <Grid item xs={12}>
                  <MDTypography variant="h5">Additional Info</MDTypography>
                </Grid>

                <Grid size={{ sm: 12 }}>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {images?.label}
                  </MDTypography>
                  <UploadImage
                    key={values?.images}
                    defaultImage={imagesV?.[0]}
                    setFieldValue={setFieldValue}
                    fieldName="images"
                  />
                </Grid>

                <Grid size={{ xs: 12 }}>
                  <FormField
                    type={description.type}
                    label={description.label}
                    name={description.name}
                    value={descriptionV}
                    placeholder={description.placeholder}
                  />
                </Grid>

                <Grid size={{ xs: 12 }}>
                  <FormField
                    type={label.type}
                    label={label.label}
                    name={label.name}
                    value={labelV}
                    placeholder={label.placeholder}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for ProductInfo
ProductInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleCurrencyChange: PropTypes.func,
  selectedCurrency: PropTypes.instanceOf(Object),
  handleTagChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  handleSubTagChange: PropTypes.func,
  selectedSubTag: PropTypes.instanceOf(Object),
};

export default ProductInfo;
