import checkout from "./form";

const {
  formField: {
    title,
    subHeader,
    description,
    travelDesk,
    retail_price,
    destination,
    newLabel,
    sponsored,
    currated,
    special,
    experienceX,
    exclusive,
    tag,
    insider,
    callToAction,
    type,
    logo,
  },
} = checkout;

const initialValues = {
  // attraction page
  [title.name]: "",
  [subHeader.name]: "",
  [description.name]: "",
  [newLabel.name]: false,
  [sponsored.name]: false,
  [currated.name]: false,
  [special.name]: false,
  [experienceX.name]: false,
  [exclusive.name]: false,
  [insider.name]: false,
  [tag.name]: "",
  [logo.name]: "",
  [destination.name]: "",
  // additional attraction info
  [travelDesk.name]: "",
  [callToAction.name]: {
    label: "",
    url: "",
    urlType: true,
  },
  [type.name]: "url",
  [retail_price.name]: {},
};

export default initialValues;
