import { useEffect, useRef, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { scrollToTop, useHandleError } from "components/helper";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

// NewUser page components
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import setAlert from "store/actions/alertActions";

// NewUser layout schemas for form and form feilds
import { addWidget, clearErrors } from "store/actions/widgetActions";
import * as Yup from "yup";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import TransportationServicesInfo from "./components/TransportationServiceInfo";
import Review from "./components/Review";

function getSteps() {
  return ["Transportation Service Info", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  selectedLocation,
  handleLocationChange,
  selectedIcon,
  handleIconChange
) {
  switch (stepIndex) {
    case 0:
      return (
        <TransportationServicesInfo
          formData={formData}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          selectedIcon={selectedIcon}
          handleIconChange={handleIconChange}
        />
      );
    case 1:
      return <Review formData={formData} />;
    default:
      return null;
  }
}

const NewWidget = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;

  const locationSchemaValidation = Yup.object().shape({
    location: Yup.object().required("Please select a location!"),
  });
  const currentValidation = validations[activeStep]?.concat(locationSchemaValidation);
  const isLastStep = activeStep === steps.length - 1;
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedIcon, setSelectedIcon] = useState({});

  // selector
  const error = useSelector((state) => state.widgets.error);

  // useEffect
  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    dispatch(addWidget(values, values?.logo));
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      navigate("/admin/widgets");
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  const handleLocationChange = (value) => {
    if (!value || !value._id) {
      setSelectedLocation({});
    } else {
      setSelectedLocation(value);
    }
  };
  const handleIconChange = (value) => {
    if (!value || !value.name) {
      setSelectedIcon({});
    } else {
      setSelectedIcon(value);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          selectedLocation,
                          handleLocationChange,
                          selectedIcon,
                          handleIconChange
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => navigate("/admin/widgets")}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewWidget;
