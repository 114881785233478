/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_WIDGET_LOADING,
  CLEAR_ERRORS,
  WIDGET_ADD_SUCCESS,
  WIDGET_FAIL,
  UPDATE_WIDGET,
  ELEMENTS_FAIL,
  ELEMENT_ADD_SUCCESS,
  SET_WIDGET_EMPTY,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

export const addWidget = (data, logo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("location", data.location._id ? data?.location?._id : data?.location);
  form.append("logoName", data?.logoName && data?.logoName);
  form.append("logo", data?.logo && data?.logo);

  if (logo?.value?.type) form.append("logo", logo?.value?.type ? logo?.value?.type : data?.logo);

  try {
    const res = await axios.post(`${URL}/widgets`, form, config);
    dispatch({
      type: WIDGET_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: WIDGET_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

//  Update widget
export const updateWidget = (data, logo) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  form.append("title", data.title.trim());
  form.append("location", data.location._id ? data?.location?._id : data?.location);
  if (data?.logoName) form.append("logoName", data?.logoName && data?.logoName);

  if (logo) form.append("logo", logo?.value?.type ? logo?.value?.type : logo);

  try {
    const res = await axios.patch(`${URL}/widgets/update/${data._id}`, form, config);

    dispatch({
      type: UPDATE_WIDGET,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: WIDGET_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Widget - set active as false
export const deleteWidget = (id) =>
  factory.deleteById(id, `${URL}/widgets`, "DELETE_WIDGET", "WIDGET_FAIL");

// get One widget
export const getOneWidget = (id) =>
  factory.get(`${URL}/widgets/${id}`, "GET_ONE_WIDGET", "WIDGET_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_WIDGET_LOADING });

export const getLocationWidgets = (location) =>
  factory.get(`${URL}/widgets/location/${location}`, "GET_WIDGETS", "WIDGET_FAIL");

// Delete Element
export const deleteElement = (widgetId, oldData) =>
  factory.patch(
    {
      elementId: oldData?._id,
    },
    `${URL}/widgets/${widgetId}/remove/element`,
    "DELETE_ELEMENT",
    "ELEMENTS_FAIL"
  );

// get One element
export const getOneElement = (id, elementId) =>
  factory.get(`${URL}/widgets/${id}/element/${elementId}`, "GET_ONE_ELEMENT", "ELEMENTS_FAIL");

// add element
export const addElement = (data, widget_id, order) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  // eslint-disable-next-line no-param-reassign
  delete data.ctaType;
  form.append("title", data.title);
  if (data.description) form.append("description", data.description);
  if (data?.ctaTitle) form.append("ctaTitle", data.ctaTitle);
  if (data?.ctaUrl) form.append("ctaUrl", data.ctaUrl);
  if (data?.ctaEmail) form.append("ctaEmail", data.ctaEmail);

  form.append("order", order);

  if (data?.files?.length > 0)
    data.files?.map((file) => {
      // console.log(file);
      form.append("files", file);
      return file;
    });

  try {
    const res = await axios.patch(`${URL}/widgets/${widget_id}`, form, config);
    dispatch({
      type: ELEMENT_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ELEMENTS_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Sort Element
export const sortElement = (sortedArr, widgetId) =>
  factory.patch(
    { data: sortedArr },
    `${URL}/widgets/${widgetId}/elements/sort`,
    "SORT_ELEMENTS",
    "STOPS_FAIL"
  );

export const setWidgetEmpty = () => ({ type: SET_WIDGET_EMPTY });

export const updateWidgetTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/widgets/translations/${id}?language=ar`,
    "UPDATE_WIDGET_TRANSLATION",
    "WIDGET_FAIL"
  );
