/* eslint-disable no-unused-expressions */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, logo, description, recommendation, value },
} = checkout;
// const isEmptyOrNoId = (field) => !field || !field._id || field._id.length === 0;
const validations = [
  // validation for Ticket Info page
  Yup.object().shape({
    [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [description?.name]: Yup.string().required(description?.errorMsg),
    [recommendation?.name]: Yup.object().required(recommendation?.errorMsg),
  }),

  // validations for additional info Page
  Yup.object().shape({
    [value?.name]: Yup.string().when("type", {
      is: "percentage",
      then: () =>
        Yup.string()
          .test("isPercentage", "Value must be a percentage between 0 and 100", (valueV) => {
            if (!valueV) return true; // Allow empty values
            const percentageValue = parseFloat(valueV);
            return percentageValue > 0 && percentageValue < 100;
          })
          .required(value?.errorMsg1),
      otherwise: () => Yup.string().required(value?.errorMsg2),
    }),
  }),
];

export default validations;
