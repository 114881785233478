import checkout from "./form";

const {
  formField: {
    // change password page
    newPassword,
    currentPassword,
    passwordConfirm,
  },
} = checkout;

const initialValues = {
  // change password page
  [newPassword.name]: "",
  [currentPassword.name]: "",
  [passwordConfirm.name]: "",
};

export default initialValues;
