// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Divider } from "@mui/material";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";
import BannerImageDisplay from "components/DetailList/BannerImageDisplay";

const Review = ({ formData }) => {
  const { values } = formData;
  const [bannerInfoData, setBannerInfoData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setBannerInfoData({
        bannerText: values?.title,
        position: values?.position?.label || values?.position,
        destination: values?.destination?.label,
        url: values?.url,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([{ id: 0, title: "Banner info", data: bannerInfoData }]);
  }, [bannerInfoData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid>
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              size={{ xs: 12 }}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList bannerDetailsPage data={field.data} title={field?.title} />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}
            </Grid>
          ))}

          {/* Display banner images */}
          <Grid item xs={12}>
            <BannerImageDisplay
              desktopImage={values?.image?.[0]}
              mobileImage={values?.mobileImage?.[0]}
              position={values?.position?.value || values?.position}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default Review;
