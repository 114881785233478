import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const LocationsQuickeditPicker = ({ value = [], onChange }) => {
  const filterLoc = (data) =>
    data.map((locsData) => ({
      label: locsData.name || locsData.label, // Change "name" to "label"
      value: locsData._id || locsData.value, // Use "_id" or "id" as "value"
    }));

  const [selectedLocs, setSelectedLocs] = useState(filterLoc(value) || []);
  useEffect(() => {
    // Sync selectedLocs when value from props changes
    setSelectedLocs(value || []);
  }, [value]);

  useEffect(() => {
    // Ensure value is always correctly formatted
    setSelectedLocs(filterLoc(value));
  }, [value?.length]);

  const handleChange = (values) => {
    setSelectedLocs(values || []);

    // Send selected values to MaterialTable
    onChange(values || []);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/locations?`;
      url += inputValue ? `&name=${inputValue}` : "";
      url += "&sort=name&dir=asc";

      setTimeout(() => {
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterLoc(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  return (
    <AsyncSelect
      aria-label="Location"
      cacheOptions
      isMulti
      defaultOptions
      value={selectedLocs}
      menuPosition="fixed"
      loadOptions={promiseOptions}
      onChange={handleChange}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          height: "48px",
          width: "150px",
          minHeight: "48px",
          maxHeight: "150px",
          overflowY: "auto",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        input: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "0.875rem",
          maxHeight: "200px",
          overflowY: "auto",
        }),
        placeholder: (base) => ({
          ...base,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: "100%",
          WebkitLineClamp: 1,
          fontSize: "0.875rem",
        }),
        singleValue: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        menu: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
      }}
    />
  );
};

LocationsQuickeditPicker.propTypes = {
  value: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
};

export default LocationsQuickeditPicker;
