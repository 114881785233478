/* eslint-disable no-unused-expressions */
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getUpdatedFilter } from "store/actions/userActions";

const useDynamicTable = (initialPageValue, currentUrl) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageValue, setPageValue] = useState(initialPageValue);
  const [queryString, setQueryString] = useState("");
  const [total, setTotal] = useState(0);

  const user = useSelector((state) => state.users.user);
  const reduxFilters = useSelector((state) => state.users.filters);

  const handleFilterChange = useCallback(
    (filters) => {
      dispatch(
        getUpdatedFilter({
          ...reduxFilters,
          filters,
        })
      );
      user?.role === "partner" && user?.partnerPower
        ? navigate(`/partnerPower/${currentUrl}`)
        : navigate(`/${user.role}/${currentUrl}`);
    },
    [dispatch, reduxFilters, navigate, user.role, currentUrl]
  );

  const handleSearchChange = useCallback(
    (searchText) => {
      dispatch(
        getUpdatedFilter({
          ...reduxFilters,
          search: searchText,
        })
      );
    },
    [dispatch, reduxFilters]
  );

  const handlePageChange = useCallback(
    (newPage) => {
      setPageValue(newPage);
      dispatch(
        getUpdatedFilter({
          ...reduxFilters,
          pageValue: newPage,
        })
      );
    },
    [dispatch, reduxFilters]
  );

  return {
    pageValue,
    setPageValue,
    queryString,
    total,
    setQueryString,
    setTotal,
    handleFilterChange,
    handleSearchChange,
    handlePageChange,
  };
};
export default useDynamicTable;
