import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const CuratorPicker = ({
  handleChange = () => {},
  setFieldValue = () => {},
  selectedCurator = {},
  isClearable = false,
  disabled = false,
  curator,
  withDestination = false,
}) => {
  const filterRec = (data) =>
    data.map((curatorData) => ({
      label: curatorData.name,
      value: curatorData._id,
      email: curatorData.email,
      phone: curatorData.phone,
      destinations: curatorData.destinations,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url;
      url = `${process.env.REACT_APP_API}/users?role=curator`;

      if (inputValue) url += `&name=${inputValue}`;
      url += "&sort=name&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          const sortedData = result.data.sort((a, b) => a.name.localeCompare(b.name));
          resolve(filterRec(sortedData));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {curator.label}
      </Typography>{" "}
      <AsyncSelect
        // ref={selectRef}
        cacheOptions
        defaultOptions
        placeholder={
          selectedCurator?.destinationName ||
          selectedCurator?.label ||
          selectedCurator?.name ||
          "Search for a curator"
        }
        isClearable={isClearable}
        isDisabled={disabled}
        menuPosition="fixed"
        loadOptions={promiseOptions}
        onChange={(option, { action }) => {
          if (option) {
            handleChange(option);
            setFieldValue("curator", option);
            if (withDestination) setFieldValue("destination", null);
          }
          if (action === "clear") {
            handleChange(null);
            setFieldValue("curator", {});
            if (withDestination) setFieldValue("destination", null);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          // option: (base) => ({
          //   ...base,
          //   padding: "18px 18px", // Adjust the padding as needed
          // }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            overflow: "unset",
          }),
        }}
      />
    </div>
  );
};

CuratorPicker.propTypes = {
  curator: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  selectedCurator: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  withDestination: PropTypes.bool,
};

export default CuratorPicker;
