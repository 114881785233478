import checkout from "./form";

const {
  addProductsFields: {
    productName,
    jobCode,
    price,
    netPrice,
    deliveryMethods,
    email,
    phone,
    defaultCurrencyCode,
    tag,
    subTag,
    tripPlanner,
    images,
    description,
    label,
  },
} = checkout;

const initialValues = {
  [productName.name]: "",
  [jobCode.name]: null,
  [price.name]: null,
  [netPrice.name]: null,
  [deliveryMethods.name]: [],
  [email.name]: "",
  [phone.name]: "",
  [defaultCurrencyCode.name]: { code: "USD", name: "USD" },
  [tag.name]: {},
  [subTag.name]: {},
  [tripPlanner.name]: false,
  [images.name]: [],
  [description.name]: "",
  [label.name]: "",
};

export default initialValues;
