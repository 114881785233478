/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PartnerPicker from "components/AutoComplete/PartnerPicker";
import PartnerLocationPicker from "components/AutoComplete/PartnerLocationPicker";
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";

const AdditionalPartnerInfo = ({
  formData,
  partnerObj = {},
  detailPage,
  edit = false,
  selectedPartner = {},
  handlePartnerChange = () => {},
  setSelectedPartner = () => {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { partner, locations, allLocations } = formField;
  const {
    partner: partnerV,
    locations: locationsV,
    allLocations: allLocationsV,
    partnerPower: partnerPowerV,
  } = values;
  const partners = useSelector((state) => state.partners.partners);
  const user = useSelector((state) => state.users.user);
  const selectedPartners = partners.filter((part) => part.id === values.partner);
  const selectedPartnerNames = selectedPartners.map((part) => part.name);

  useEffect(() => {
    if (user.role === "partner" && user.partnerPower) {
      setFieldValue("partner", user?.partner?._id);
      setSelectedPartner(user?.partner);
    }
  }, [user?.role, user?.partnerPower]);

  return (
    <MDBox>
      {!edit && (
        <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
            Partner info
          </MDTypography>
        </MDBox>
      )}
      {detailPage ? (
        <DetailList
          data={{
            partner: values?.partner
              ? values?.partner
              : selectedPartner?.name
              ? selectedPartner?.name
              : selectedPartnerNames || "",
            locations: values?.locations && values?.locations,
            allLocations: values?.allLocations,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <PartnerPicker
                key={selectedPartner}
                partner={partner}
                handleChange={handlePartnerChange}
                setFieldValue={setFieldValue}
                disabled={user.role === "partner" && user.partnerPower}
                selectedPartner={selectedPartner || partnerObj || {}}
                error={errors.partner && touched.partner && errors.partner?._id}
                success={partnerV?.name && !errors.partner && errors.partner?._id}
              />
            </Grid>
            {(values?.role === "partner" && values?.partnerPower) ||
            (values?.role === "partner" && values?.partnerPower && !allLocationsV) ? (
              <Grid item size={{ xs: 6 }} style={{ display: "flex", marginTop: "auto" }}>
                <FormControlLabel
                  label={allLocations?.label}
                  control={
                    <Checkbox
                      name={allLocations}
                      checked={values?.allLocations}
                      onChange={(event) => {
                        const isChecked = event?.target?.checked;
                        setFieldValue("allLocations", isChecked);
                        setFieldValue("locations", []);
                      }}
                    />
                  }
                />
              </Grid>
            ) : null}
            {(partnerV && !partnerPowerV) || (partnerV && partnerPowerV && !allLocationsV) ? (
              <Grid item size={{ xs: 12, sm: 6 }}>
                <PartnerLocationPicker
                  partnerId={partnerV?._id || partnerV}
                  locations={locations}
                  locationsV={locationsV}
                  setFieldValue={setFieldValue}
                  error={errors.locations && touched.locations}
                  success={locationsV && !errors.locations}
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for AdditionalCuratorInfo
AdditionalPartnerInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  partnerObj: PropTypes.instanceOf(Object),
  detailPage: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  selectedPartner: PropTypes.instanceOf(Object),
  handlePartnerChange: PropTypes.func,
  setSelectedPartner: PropTypes.func,
};

export default AdditionalPartnerInfo;
