// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Redux Actions
import { clearErrors, addPromoCode } from "store/actions/promoCodeActions";
import setAlert from "store/actions/alertActions";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import { useHandleError } from "components/helper";
import PromoCodeInfo from "./components/PromoCodeInfo";
import Review from "./components/Review";

// Schema
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

function getSteps() {
  return ["PromoCode Info", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  categories,
  selectedLocations,
  setSelectedLocations,
  handleLocationChange,
  handleDestinationChange,
  selectedDestination
) {
  switch (stepIndex) {
    case 0:
      return (
        <PromoCodeInfo
          formData={formData}
          categories={categories}
          selectedLocations={selectedLocations}
          setSelectedLocations={setSelectedLocations}
          handleLocationChange={handleLocationChange}
          handleDestinationChange={handleDestinationChange}
          selectedDestination={selectedDestination}
        />
      );
    case 1:
      return <Review formData={formData} />;
    default:
      return null;
  }
}

const NewPromoCode = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const error = useSelector((state) => state.promoCodes.error);
  const categories = useSelector((state) => state.categories.categories);

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;
  const [formChange, setFormChange] = useState(null);
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (formChange) {
      setSteps(getSteps());
    }
  }, [formChange]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
  };
  const submitForm = async (values, actions) => {
    const updatedValues = { ...values };

    if (updatedValues && updatedValues?.destinations) {
      const destinations = updatedValues?.destinations
        .filter((destination) => destination && destination.value)
        .map((destination) => destination.value);
      updatedValues.destinations = destinations;
    }
    if (updatedValues && updatedValues?.subcategories) {
      const subcategories = updatedValues?.subcategories.map((subCategory) => subCategory);
      updatedValues.subcategories = subcategories;
    }
    if (updatedValues && updatedValues?.locations) {
      const locations = updatedValues?.locations
        .filter((location) => location && location?._id)
        .map((location) => location?._id);
      updatedValues.locations = locations;
    }
    if (updatedValues && updatedValues?.tickets) {
      const itemsArr = updatedValues?.tickets
        .filter((ticket) => ticket && (ticket._id || ticket.value)) // Check for _id or value property
        .map((ticket) => ticket._id || ticket.value);
      updatedValues.tickets = itemsArr;
    }

    await sleep(1000);
    dispatch(addPromoCode(updatedValues));
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      navigate(`/admin/promoCodes`);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
    }
  };

  const handleDestinationChange = (value) => {
    if (value) {
      setSelectedDestination(value);
    } else {
      setSelectedDestination([]);
    }
  };

  const handleLocationChange = (value) => {
    if (value) {
      setSelectedLocations(value);
    } else {
      setSelectedLocations([]);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          categories,
                          selectedLocations,
                          setSelectedLocations,
                          handleLocationChange,
                          handleDestinationChange,
                          selectedDestination
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => navigate(`/admin/promoCodes`)}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewPromoCode;
