const form = {
  formId: "new-user-form",
  formField: {
    // user page
    name: {
      name: "name",
      label: "Name *",
      type: "text",
      errorMsg: "Name is required!",
      invalidMsg: "Name should have at least 1 letter!",
    },
    description: {
      name: "description",
      label: "Description *",
      type: "text",
      errorMsg: "Description is required!",
      invalidMsg: "Description should have at least 1 letter!",
    },
    email: {
      name: "email",
      label: "Email *",
      type: "email",
      errorMsg: "Email address is required!",
      invalidMsg: "Your email address is invalid!",
    },
    role: {
      name: "role",
      label: "Role *",
      type: "text",
      errorMsg: "Role is required!",
    },
    partnerPower: {
      name: "partnerPower",
      label: "Power User",
      type: "boolean",
    },
    partner: {
      name: "partner",
      label: "Partner *",
      // type: "text",
      errorMsg: "Partner is required!",
      invalidMsg: "Partner should have at least 1 letter!",
    },
    allLocations: {
      name: "allLocations",
      label: "All Locations",
      type: "boolean",
    },
    locDestinations: {
      name: "locDestinations",
    },
    balance: {
      name: "balance",
      label: "Initial balance $ *",
      type: "number",
    },
    commission: {
      name: "commission",
      label: "Commission % *",
      type: "number",
      errorMsg: "Commission is required!",
    },
    location: {
      name: "location",
      label: "Location *",
      errorMsg: "Location is required!",
      // type: "text",
    },

    locations: {
      name: "locations",
      label: "Locations",
      // type: "text",
    },
    promoCodes: {
      name: "promoCodes",
      label: "Promocodes",
      // type: "text",
    },
    // additional curator info
    photo: {
      name: "photo",
      label: "Photo *",
      type: "text",
      errorMsg: "photo is required!",
    },
    bio: {
      name: "bio",
      label: "Intro Text *",
      type: "text",
      errorMsg: "Intro text is required!",
    },
    reference: {
      name: "reference",
      label: "Referral code",
      type: "text",
    },

    languages: {
      name: "languages",
      label: "Languages *",
      type: "array",
      invalidMsg: "Please select at least one language!",
    },
    top: {
      name: "top",
      label: "Top",
      type: "boolean",
    },
    city: {
      name: "city",
      label: "City *",
      type: "text",
      errorMsg: "City is required!",
    },
    interests: {
      name: "interests",
      label: "Interests *",
      type: "array",
      errorMsg: "Please select at least one interest!",
    },

    // destinations: {
    //   name: "destinations",
    //   label: "Destinations *",
    //   type: "array",
    //   errorMsg: "Please select at least one destination!",
    // },
    destinations: {
      name: "destinations",
      label: "Destination *",
      errorMsg: "Destination is required!",
      type: "text",
    },

    tickets: {
      name: "tickets",
      label: "Recommended Experiences",
    },
  },
};

export default form;
