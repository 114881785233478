import checkout from "./form";

const {
  formField: {
    // facility page
    title,
    position,
    // added destination and url
    destination,
    url,
    image,
    mobileImage,
  },
} = checkout;

const initialValues = {
  // facility page
  [title?.name]: "",
  [position?.name]: null,
  [destination?.name]: null,
  [url?.name]: "",
  [image?.name]: [],
  [mobileImage?.name]: [],
};

export default initialValues;
