/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import AdditionalInfo from "pages/items/vouchers/new-voucher/components/AdditionalInfo";
import Information from "pages/items/vouchers/new-voucher/components/Information";

const VoucherDetail = ({
  formData,
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  handleRecommendationChange = () => {},
  selectedRecommendation = {},
  selectedLocation = {},
  handleLocationChange = () => {},
  setSelectedRecommendation = () => {},
  setSelectedLocation = () => {},
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    if (type === "Information") {
      return "Information";
    }
    return "Additional Info";
  };

  const handleInfoCard = () => {
    if (type === "information") {
      return (
        <Information
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          selectedRecommendation={selectedRecommendation}
          handleRecommendationChange={handleRecommendationChange}
        />
      );
    }
    return <AdditionalInfo formData={formData} editPage detailPage={detailPage} />;
  };
  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          stateName="vouchers"
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
            setSelectedRecommendation(null);
            setSelectedLocation(null);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// typechecking props for TicketDetail
VoucherDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedRecommendation: PropTypes.instanceOf(Object),
  handleRecommendationChange: PropTypes.func,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  setSelectedRecommendation: PropTypes.func,
  setSelectedLocation: PropTypes.func,
};

export default VoucherDetail;
