/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material UI components
import { Card } from "@mui/material";
import { useFormikContext } from "formik";

const Rating = () => {
  const { values } = useFormikContext();

  const { ratingsAverage, ratingsQuantity, rating } = values;

  return (
    <Card sx={{ boxShadow: "none", width: "100%" }}>
      {/* Total Rate */}
      <MDBox mb={1}>
        <MDTypography component="span" variant="button" fontWeight="medium" pr={1}>
          Total Rate:
        </MDTypography>
        <MDTypography component="span" variant="button" fontWeight="regular" color="text">
          {ratingsAverage ?? "N/A"}
        </MDTypography>
      </MDBox>

      {/* Review Number */}
      <MDBox mb={1}>
        <MDTypography component="span" variant="button" fontWeight="medium" pr={1}>
          Review Number:
        </MDTypography>
        <MDTypography component="span" variant="button" fontWeight="regular" color="text">
          {ratingsQuantity ?? 0}
        </MDTypography>
      </MDBox>

      {/* Sub-heading for rating breakdown */}
      <MDBox mb={1}>
        <MDTypography variant="h6" fontWeight="bold">
          Rating:
        </MDTypography>
      </MDBox>

      {rating && (
        <MDBox>
          {Object.entries(rating).map(([key, val]) => (
            <MDTypography
              key={key}
              variant="button"
              fontWeight="regular"
              color="text"
              display="block"
              mb={0.5}
            >
              {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${val ?? "N/A"}`}
            </MDTypography>
          ))}
        </MDBox>
      )}
    </Card>
  );
};

Rating.propTypes = {};

export default Rating;
