/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { parse, isEqual } from "date-fns";

import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Grid from "@mui/material/Grid2";
import MDTimePicker from "components/MDTimePicker";

const FabContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const FabButton = styled(Fab)`
  background-color: ${(props) => (props.active ? "#00acc1" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid ${(props) => (props.active ? "#00acc1" : "black")};
  &:hover {
    background-color: ${(props) => (props.active ? "#00acc1" : "white")};
  }
`;

const defaultOpenHours = {
  _id: 0,
  open: "09:00",
  close: "17:00",
};
const DAYS = [
  { day: "Monday", _id: 1 },
  { day: "Tuesday", _id: 2 },
  { day: "Wednesday", _id: 3 },
  { day: "Thursday", _id: 4 },
  { day: "Friday", _id: 5 },
  { day: "Saturday", _id: 6 },
  { day: "Sunday", _id: 7 },
];

const OpeningHoursDialog = ({
  title = "",
  isVisible = false,
  selectedDay = { current: 0 },
  setFieldValue = () => {},
  openHoursValue = [],
  closeDialogBox = () => {},
  setConflictedData = () => {},
}) => {
  /* *** Refs *** */
  const groupedEntries = useRef([]);

  /* *** States *** */
  const [error, setError] = useState("");
  const [dateTime, setDateTime] = useState({
    openDays: [],
    openHours: [],
    openAllDay: false,
    closedAllDay: false,
  });

  /* *** Constants *** */
  const isEdit = selectedDay.current !== 0;
  const { openHours, closedAllDay, openAllDay, openDays } = dateTime;
  const showTimePicker = !closedAllDay && !openAllDay;

  useEffect(() => {
    if (isEdit) {
      const editedDay = openHoursValue.find((dayEntry) => dayEntry.weekDay === selectedDay.current);
      const { openHours: editedOpenHours, weekDay, closed, open_all_day } = editedDay || {};
      setDateTime({
        openDays: [weekDay],
        closedAllDay: closed,
        openAllDay: open_all_day,
        openHours: editedOpenHours,
      });
    } else {
      setDateTime((prev) => ({
        ...prev,
        openHours: [defaultOpenHours],
      }));
    }
  }, []);

  const validateChosenOpenHours = () => {
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const timeToHours = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
    };

    // Convert open and close times to minutes
    const intervals = openHours.map((entry) => ({
      open: timeToMinutes(entry.open),
      close: timeToMinutes(entry.close),
      _id: entry._id,
    }));
    intervals.sort((a, b) => a.open - b.open);

    // Check for invalid intervals where open and close times are the same
    for (let i = 0; i < intervals.length; i += 1) {
      const { open, close } = intervals[i];

      if (open === close) {
        setError(
          `* Invalid interval: ${timeToHours(open)} - ${timeToHours(
            close
          )} (open and close times cannot be the same)`
        );
        return false;
      }
    }

    // Check for overlaps with the next interval
    for (let i = 0; i < intervals.length; i += 1) {
      const { close } = intervals[i];

      if (i < intervals.length - 1 && close > intervals[i + 1].open) {
        setError(
          `* ${timeToHours(intervals[i].open)} - ${timeToHours(intervals[i].close)} overlaps with
            ${timeToHours(intervals[i + 1].open)} - ${timeToHours(intervals[i + 1].close)}
          `
        );
        return false;
      }
    }

    return true;
  };

  const validateConflicts = () => {
    const compareEntries = (oldEntry, newEntry) => {
      const oldKeys = Object.keys(oldEntry);

      return oldKeys.every((key) => {
        const value1 = oldEntry[key];
        const value2 = newEntry[key];

        if (key === "openHours") {
          if (value1.length !== value2.length) return false;

          return value1.every((hour1, index) => {
            const hour2 = value2[index];
            if (!hour2) return false;

            const keys = Object.keys(hour1);
            return keys.every((hourKey) => {
              const hourValue1 = hour1[hourKey];
              const hourValue2 = hour2[hourKey];

              if (hourKey === "open" || hourKey === "close") {
                const timeFormat = "H:mm";
                try {
                  const parsedValue1 = parse(hourValue1, timeFormat, new Date());
                  const parsedValue2 = parse(hourValue2, timeFormat, new Date());
                  return isEqual(parsedValue1, parsedValue2);
                } catch (err) {
                  return false;
                }
              }

              return hourValue1 === hourValue2;
            });
          });
        }

        if (key === "closed" || key === "open_all_day") {
          if (typeof value1 === "boolean" && typeof value2 === "string") {
            return value1 === (value2.toLowerCase() === "closed");
          }
          return value1 === value2;
        }

        return value1 === value2;
      });
    };

    return groupedEntries.current.some((group) => {
      if (group.length < 2) {
        return false;
      }

      const [oldEntry, newEntry] = group;
      if (!compareEntries(oldEntry, newEntry)) {
        return true;
      }

      group.splice(1);
      return false;
    });
  };

  const handleClose = () => {
    selectedDay.current = 0;
    closeDialogBox();
  };
  const handleSave = (actionType) => {
    setError("");

    switch (actionType) {
      case "add":
        const constructedDays = [];

        // Constructing an object for every chosen day
        openDays.forEach((day) => {
          constructedDays.push({
            openHours,
            weekDay: day,
            closed: closedAllDay,
            open_all_day: openAllDay,
          });
        });

        if (openHours.length > 0 && !validateChosenOpenHours()) {
          return;
        }

        // Grouping the constructed days with the existing data by weekDays
        [...openHoursValue, ...constructedDays].forEach((entry) => {
          const { weekDay } = entry;
          const index = weekDay - 1;

          if (groupedEntries.current[index]) {
            groupedEntries.current[index].push(entry);
          } else {
            groupedEntries.current[index] = [entry];
          }
        });

        const conflictsExits = validateConflicts();
        if (conflictsExits) {
          setConflictedData(groupedEntries.current);
        } else {
          const deconstructedData = groupedEntries.current.flat();
          setFieldValue("openHours", deconstructedData);
        }
        break;
      case "edit":
        const timeRangeChosen = !closedAllDay && !openAllDay;

        if (timeRangeChosen && !validateChosenOpenHours(openHours)) {
          return;
        }

        const newData = openHoursValue.map((entry) => {
          if (entry.weekDay === selectedDay.current) {
            return {
              ...entry,
              openHours,
              closed: closedAllDay,
              open_all_day: openAllDay,
            };
          }

          return entry;
        });

        setFieldValue("openHours", newData);
        break;

      default:
        break;
    }

    handleClose();
  };

  const handleDayBttns = (chosenDay) => {
    const { day, _id } = chosenDay;
    const dayIsSelected = openDays.includes(_id);

    const handleBttnClick = () => {
      if (dayIsSelected) {
        setDateTime({ ...dateTime, openDays: openDays.filter((item) => item !== _id) });
      } else {
        setDateTime({ ...dateTime, openDays: [...openDays, _id] });
      }
    };

    return (
      <FabButton
        aria-label="add"
        active={dayIsSelected}
        onClick={handleBttnClick}
        disabled={isEdit && !dayIsSelected}
      >
        <span>{day[0]}</span>
      </FabButton>
    );
  };
  const handleTimeIntervals = (openHour, closeHour, _id) => (
    <Grid container spacing={2} alignItems="center">
      <Grid item size={{ xs: 5 }}>
        <Typography
          variant="body2"
          style={{ wordBreak: "break-word", fontWeight: 400, marginBottom: 8 }}
        >
          Open Time:
        </Typography>

        <MDTimePicker
          onClose={(_, time) =>
            setDateTime((prev) => ({
              ...prev,
              openHours: prev.openHours.map((entry) =>
                entry._id === _id ? { open: time, close: entry.close, _id } : entry
              ),
            }))
          }
          input={{
            type: "text",
            name: "start",
            fullWidth: true,
            variant: "outlined",
            value: openHour || "09:00",
          }}
        />
      </Grid>

      <Grid item size={{ xs: 5 }}>
        <Typography
          variant="body2"
          style={{ wordBreak: "break-word", fontWeight: 400, marginBottom: 8 }}
        >
          Close Time:
        </Typography>

        <MDTimePicker
          onClose={(_, time) => {
            setDateTime((prev) => ({
              ...prev,
              openHours: prev.openHours.map((entry) =>
                entry._id === _id ? { open: entry.open, close: time, _id } : entry
              ),
            }));
          }}
          input={{
            type: "text",
            name: "end",
            fullWidth: true,
            variant: "outlined",
            value: closeHour || "17:00",
          }}
        />
      </Grid>

      {_id !== 0 && (
        <Grid
          item
          size={{ xs: 2 }}
          style={{
            display: "flex",
            marginBottom: 10,
            alignSelf: "flex-end",
            justifyContent: "center",
          }}
        >
          <Fab
            size="small"
            color="secondary"
            aria-label="delete"
            onClick={() => {
              setDateTime({
                ...dateTime,
                openHours: openHours.filter((_, i) => i !== _id),
              });
            }}
          >
            <DeleteForeverIcon />
          </Fab>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Dialog
      fullWidth
      keepMounted
      open={isVisible}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-paper": {
          height: "auto",
          padding: "24px",
          minWidth: { md: "680px" },
          width: { sm: "100%", md: "680px" },
        },
      }}
    >
      <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>

      <DialogContent>
        <FabContainer>{DAYS.map((day) => handleDayBttns(day))}</FabContainer>

        <div
          style={{
            display: "flex",
            marginTop: "2em",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <FormControlLabel
            label="Open 24 hours"
            control={
              <Checkbox
                checked={openAllDay === "Open 24 hours"}
                onChange={({ target }) =>
                  setDateTime({
                    ...dateTime,
                    closedAllDay: false,
                    openHours: target.checked ? [] : [defaultOpenHours],
                    openAllDay: target.checked ? "Open 24 hours" : false,
                  })
                }
              />
            }
          />

          <FormControlLabel
            label="Closed"
            control={
              <Checkbox
                checked={closedAllDay === "Closed"}
                onChange={({ target }) =>
                  setDateTime((prev) => ({
                    ...prev,
                    openAllDay: false,
                    closedAllDay: target.checked ? "Closed" : false,
                    openHours: target.checked ? [] : [defaultOpenHours],
                  }))
                }
              />
            }
          />

          {!isEdit && (
            <FormControlLabel
              label="All Week"
              control={
                <Checkbox
                  checked={openDays.length === 7}
                  onChange={({ target }) =>
                    setDateTime({
                      ...dateTime,
                      openDays: target.checked ? [1, 2, 3, 4, 5, 6, 7] : [],
                    })
                  }
                />
              }
            />
          )}
        </div>

        {showTimePicker && (
          <div
            style={{
              gap: "20px",
              display: "flex",
              marginTop: "50px",
              flexDirection: "column",
            }}
          >
            {openHours.map((timeInterval) =>
              handleTimeIntervals(timeInterval.open, timeInterval.close, timeInterval._id)
            )}

            <Fab
              size="small"
              color="primary"
              aria-label="add"
              variant="extended"
              style={{ alignSelf: "flex-start", zIndex: 1 }}
              onClick={() =>
                setDateTime((prev) => {
                  const _id = prev.openHours.length;
                  return {
                    ...prev,
                    openHours: [...prev.openHours, { open: "09:00", close: "17:00", _id }],
                  };
                })
              }
            >
              <AddIcon />

              <Typography style={{ fontSize: "10px", margin: "0px 10px" }}>
                Add another time
              </Typography>
            </Fab>
          </div>
        )}
      </DialogContent>

      {error && (
        <div
          style={{
            color: "red",
            fontSize: "12px",
            marginLeft: "16px",
          }}
        >
          {error}
        </div>
      )}

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>

        <Button
          disabled={openDays.length === 0}
          onClick={() => handleSave(isEdit ? "edit" : "add")}
        >
          {isEdit ? "Edit" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OpeningHoursDialog.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  setFieldValue: PropTypes.func,
  closeDialogBox: PropTypes.func,
  setConflictedData: PropTypes.func,
  selectedDay: PropTypes.shape({ current: PropTypes.number }),
  openHoursValue: PropTypes.arrayOf(
    PropTypes.shape({
      closed: PropTypes.bool,
      weekDay: PropTypes.number,
      open_all_day: PropTypes.bool,
      openHours: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.number,
          open: PropTypes.string,
          close: PropTypes.string,
        })
      ),
    })
  ),
};

export default OpeningHoursDialog;
