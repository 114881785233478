import { useEffect, useRef, useState } from "react";

// formik components
import { Formik, Form } from "formik";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// NewUser page components
import { addProduct, clearErrors, setLoading } from "store/actions/locationAction";
import { useDispatch, useSelector } from "react-redux";
import setAlert from "store/actions/alertActions";
import { scrollToTop, useHandleError } from "components/helper";

// NewUser layout schemas for form and form feilds
import form from "../../schemas/OnlineStore/form";
import initialValues from "../../schemas/OnlineStore/initialValues";
import validations from "../../schemas/OnlineStore/validation";
import ProductInfo from "./ProductInfo";
import ProductReview from "./ProductReview";

function getSteps() {
  return ["Job Info", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  handleCurrencyChange,
  selectedCurrency,
  handleTagChange,
  selectedTag,
  handleSubTagChange,
  selectedSubTag
) {
  switch (stepIndex) {
    case 0:
      return (
        <ProductInfo
          formData={formData}
          handleCurrencyChange={handleCurrencyChange}
          selectedCurrency={selectedCurrency}
          handleTagChange={handleTagChange}
          selectedTag={selectedTag}
          handleSubTagChange={handleSubTagChange}
          selectedSubTag={selectedSubTag}
        />
      );
    case 1:
      return <ProductReview formData={formData} />;
    default:
      return null;
  }
}

const ProductForm = ({ setIconPressed = () => {}, locationId }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({ code: "USD", name: "USD" });
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedSubTag, setSelectedSubTag] = useState(null);

  const { formId, addProductsFields: formField } = form;

  const { addProductValidation } = validations;

  const isLastStep = activeStep === steps.length - 1;

  // selector
  const error = useSelector((state) => state.locations.error);

  // useEffect
  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const submitForm2 = async (values, actions) => {
    await sleep(1000);
    dispatch(setLoading());

    dispatch(addProduct(values, locationId));
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm2(values, actions);
      setIconPressed("");
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  const handleCurrencyChange = (value) => {
    if (!value || !value.name) {
      setSelectedCurrency({ code: "USD", name: "USD" });
    } else {
      setSelectedCurrency(value);
    }
  };

  const handleTagChange = (value) => {
    if (value && value?.value) {
      setSelectedTag(value);
      setSelectedSubTag({});
    } else {
      setSelectedTag({});
      setSelectedSubTag({});
    }
  };

  const handleSubTagChange = (value) => {
    if (value && value?.value) {
      setSelectedSubTag(value);
    } else {
      setSelectedSubTag({});
    }
  };

  return (
    // <MDBox py={3} mb={20}>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", mt: 8, width: "100%" }}
    >
      <Grid item size={{ xs: 12 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={steps[activeStep] === "Review" ? undefined : addProductValidation}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue, submitForm }) => (
            <Form id={formId} autoComplete="off">
              <Card sx={{ height: "100%", width: "100%" }}>
                <MDBox mx={2} mt={-3}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </MDBox>
                <MDBox p={3}>
                  <MDBox>
                    {getStepContent(
                      activeStep,
                      {
                        values,
                        touched,
                        formField,
                        errors,
                        setFieldValue,
                      },
                      handleCurrencyChange,
                      selectedCurrency,
                      handleTagChange,
                      selectedTag,
                      handleSubTagChange,
                      selectedSubTag
                    )}
                    <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                      {activeStep === 0 ? (
                        <MDBox />
                      ) : (
                        <MDButton variant="gradient" color="light" onClick={handleBack}>
                          back
                        </MDButton>
                      )}
                      <div>
                        <MDButton
                          onClick={() => setIconPressed("")}
                          rel="noreferrer"
                          variant="outlined"
                          color="dark"
                          sx={{ marginRight: "8px" }}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          disabled={isSubmitting}
                          type="button"
                          variant="gradient"
                          color="dark"
                          onClick={() => submitForm()}
                        >
                          {isLastStep ? "add" : "next"}
                        </MDButton>
                      </div>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
    // </MDBox>
  );
};

export default ProductForm;

ProductForm.propTypes = {
  setIconPressed: PropTypes.func,
  locationId: PropTypes.string.isRequired,
};
