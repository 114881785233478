import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { isSameDay } from "date-fns";

// Material UI components
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import DynamicDateRange from "components/DynamicDateRange";
import MaterialTablePicker from "components/MaterialTablePicker";
import columnsMap from "components/MaterialTablePicker/TableColumns";
import { getLocationRequests, markAsOrdered, setLoading } from "store/actions/requestActions";
import { dateFormatWithDash } from "components/helper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const LocationRequests = ({ locationId, currentTabValue }) => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  // States
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
    },
  ]);
  const [hideDaterange, setHideDaterange] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // Selectors
  const requests = useSelector((state) => state.requests.requests);
  const loading = useSelector((state) => state.requests.loading);
  const added = useSelector((state) => state.requests.added);

  // Load requests when component mounts or tab changes to this component
  useEffect(() => {
    if (locationId && currentTabValue === 3) {
      dispatch(setLoading());
      dispatch(getLocationRequests(locationId));
    }
  }, [locationId, currentTabValue]);

  // Reset search and page when locationId changes
  useEffect(() => {
    setSearchValue("");
    // setPageValue(0);
  }, [locationId]);

  useEffect(() => {
    if (added) {
      dispatch(setLoading());
      dispatch(getLocationRequests(locationId));
    }
  }, [added]);

  const handleDateChange = (date) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    }
  };

  const handleApplyFilter = () => {
    setFilterApplied(true);
    setHideDaterange(true);

    const startDateStr = dateFormatWithDash(dateRange[0].startDate, false);
    const endDateStr = dateFormatWithDash(dateRange[0].endDate, false);

    dispatch(setLoading());
    dispatch(getLocationRequests(locationId, startDateStr, endDateStr));
  };

  const handleResetFilter = () => {
    setFilterApplied(false);
    setDateRange([
      {
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        endDate: new Date(),
      },
    ]);

    dispatch(setLoading());
    dispatch(getLocationRequests(locationId));
  };

  const handleLocalSearchChange = useCallback((searchText) => {
    setSearchValue(searchText);
  }, []);

  const handleMarkAsOrdered = (event, rowData) => {
    dispatch(markAsOrdered(rowData.orderId));
  };

  const actionsChildFn = (rowData) => {
    let actionsChildRole = {};
    if (rowData.deliveryMethods.includes("noknok") && rowData.type === "Order") {
      const isOrdered = rowData.status;

      // Set action properties based on order status
      actionsChildRole = {
        icon: isOrdered === "succeeded" ? CheckCircleIcon : CheckCircleOutlineIcon,
        tooltip: isOrdered === "pending" ? "Mark as Ordered" : "",

        iconProps: {
          style: {
            marginLeft: "10px",
            fontSize: "24px",
            color: isOrdered === "succeeded" ? "green" : "black",
            cursor: isOrdered === "succeeded" ? "default" : "pointer",
          },
        },

        onClick: (event) => {
          if (isOrdered === "pending") {
            handleMarkAsOrdered(event, rowData);
          }
        },
      };
    }

    // Return the action role object (will be empty if not noknok)
    return actionsChildRole;
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 6 }}>
          <DynamicDateRange
            defaultValue={dateRange}
            selectedOption={dateRange}
            handleDateChange={handleDateChange}
            hideDaterange={hideDaterange}
            hideDateRangeFn={setHideDaterange}
            isTodayIncluded
          />
          <MDBox display={filterApplied ? "block" : "none"} style={{ fontSize: "small" }}>
            Selected Date Range:{" "}
            {dateRange[0]?.startDate && !Number.isNaN(dateRange[0].startDate.getTime())
              ? dateRange[0].startDate.toDateString()
              : "Invalid date"}{" "}
            to{" "}
            {dateRange[0]?.endDate && !Number.isNaN(dateRange[0].endDate.getTime())
              ? dateRange[0].endDate.toDateString()
              : "Invalid date"}
          </MDBox>
        </Grid>
        <Grid item size={{ xs: 6 }}>
          <MDBox sx={{ display: "flex", justifyContent: "flex-start" }}>
            <MDButton
              color="dark"
              variant="gradient"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={handleApplyFilter}
            >
              Apply Filter
            </MDButton>
            <MDButton
              color="dark"
              variant="gradient"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={handleResetFilter}
            >
              Reset Filters
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox mt={3}>
        <MaterialTablePicker
          screen="LocationRequests"
          title=""
          isSearchable
          columns={[
            columnsMap.requestName,
            columnsMap.deliveryMethods,
            columnsMap.requestLastName,
            columnsMap.requestDateAsync,
          ]}
          tableRef={tableRef}
          data={requests}
          loading={loading}
          searchField={searchValue}
          filteringOptions={false}
          isExportable={false}
          actionsName="requestText"
          showAddButton={false}
          isRowClickable={false}
          onSearchChange={handleLocalSearchChange}
          searchRequestText={searchValue}
          setSearchRequestText={setSearchValue}
          actionsChild={actionsChildFn}
          actionsRole
        />
      </MDBox>
    </MDBox>
  );
};

LocationRequests.propTypes = {
  locationId: PropTypes.string.isRequired,
  currentTabValue: PropTypes.number.isRequired,
};

export default LocationRequests;
