/* eslint-disable no-unused-expressions */

/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import DetailList from "components/DetailList";
import OpeningHours from "components/OpeningHours";
import { exportDayName } from "utils/function/utilFunctions";
import { useFormikContext } from "formik";
import Danger from "components/Danger";

const DateAndTimeInfo = ({ formData, editPage = false, detailPage = true }) => {
  const { errors, values, setFieldValue } = useFormikContext();
  const { formField } = formData;
  const { openHours } = formField;
  const { openHours: openHoursV } = values;

  const handleDetailsList = () => {
    const list = {};
    openHoursV.map((openHour) => {
      let status = "";
      const {
        weekDay = 0,
        closed = false,
        open_all_day = false,
        openHours: openHoursRow = [],
      } = openHour;

      if (open_all_day) {
        status = "Open All Day";
      } else if (closed) {
        status = "Closed";
      } else {
        status = openHoursRow
          .map((item) => {
            const { open, close } = item;
            return `${open} - ${close}`;
          })
          .join(" & ");
      }

      list[exportDayName(weekDay)] = status;
      return null;
    });

    return list;
  };

  return (
    <MDBox>
      {!editPage && <MDTypography variant="h5">Date And Time Info</MDTypography>}

      {detailPage && editPage ? (
        <DetailList data={handleDetailsList()} />
      ) : (
        <>
          <OpeningHours key={openHours} setFieldValue={setFieldValue} openHours={openHoursV} />
          {errors?.openHours && <Danger validation={errors?.openHours} />}
        </>
      )}
    </MDBox>
  );
};

DateAndTimeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default DateAndTimeInfo;
