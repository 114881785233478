import * as Yup from "yup";
import checkout from "../../new-partner/schemas/form";

const {
  formField: { name, contactEmail, contactPhone, website, licence, logo },
} = checkout;

const validations = [
  Yup.object().shape({
    [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [name.name]: Yup.string()
      .matches(/([A-Za-z])/, name.invalidMsg)
      .required(name.errorMsg),
    [website.name]: Yup.string().matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      website.invalidMsg
    ),

    [licence.name]: Yup.number().notOneOf([0], licence.invalidMsg),
    // [contactEmail.name]: Yup.string().email(contactEmail.invalidMsg),
    [contactEmail.name]: Yup.string()
      .email(contactEmail.invalidMsg)
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, contactEmail.invalidMsg),
    [contactPhone.name]: Yup.string().matches(
      /^(\+|00)[0-9 ]{1,3}[0-9 ]{4,14}(?:x.+)?$/,
      contactPhone.invalidMsg
    ),
  }),
];

export default validations;
