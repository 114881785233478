import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// import Primary from "components/Typography/Primary";
// import GridContainer from "components/Grid/GridContainer";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { getOneElement } from "store/actions/widgetActions";
import MDTypography from "components/MDTypography";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ElementDetails = ({ open, widgetId = "", selectedElement = "", handleClose }) => {
  const dispatch = useDispatch();

  const element = useSelector((state) => state.widgets.element);

  useEffect(() => {
    if (selectedElement) dispatch(getOneElement(widgetId, selectedElement));
  }, [selectedElement]);

  return (
    // <GridContainer>
    <Grid item size={{ xs: 12, md: 6 }}>
      <div>
        <Dialog
          sx={{
            zIndex: 99999,
            "& .MuiDialog-paper": {
              bottom: 0,
              left: 0,
              right: 0,
              margin: "32px 0 0 0",
              height: "auto",
              width: { sm: "100%", md: "414px" },
            },
          }}
          open={open}
          // fullWidth
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          onClose={() => handleClose()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Element Review</DialogTitle>
          <DialogContent>
            <div style={{ marginBottom: 10 }}>
              <MDTypography style={{ fontSize: "medium" }}>
                Element title: <span style={{ color: "grey" }}>{element?.title}</span>
              </MDTypography>
            </div>
            {element?.description ? (
              <div style={{ marginBottom: 10 }}>
                <MDTypography style={{ fontSize: "medium" }}>
                  Description: <span style={{ color: "grey" }}>{element?.description}</span>
                </MDTypography>
              </div>
            ) : (
              <div style={{ marginBottom: 10 }}>
                <MDTypography style={{ fontSize: "medium" }}>
                  Description: <span style={{ color: "grey" }}>--</span>
                </MDTypography>
              </div>
            )}

            {element?.ctaTitle ? (
              <div style={{ marginBottom: 10 }}>
                <MDTypography style={{ fontSize: "medium" }}>
                  Call to Action Label: <span style={{ color: "grey" }}>{element?.ctaTitle}</span>
                </MDTypography>
              </div>
            ) : (
              <div style={{ marginBottom: 10 }}>
                <MDTypography style={{ fontSize: "medium" }}>
                  Call to Action Label: <span style={{ color: "grey" }}>--</span>
                </MDTypography>
              </div>
            )}

            {element?.ctaUrl && (
              <div style={{ marginBottom: 10 }}>
                <MDTypography style={{ fontSize: "medium" }}>
                  CTA (Email/Url):{" "}
                  <a
                    style={{ color: "grey" }}
                    href={element?.ctaUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {element?.ctaUrl}
                  </a>
                </MDTypography>
              </div>
            )}

            {element?.files?.length > 0 && (
              <MDTypography style={{ fontSize: "medium" }}>
                Pdfs:{" "}
                {element?.files.map((ele) => (
                  <div style={{ marginBottom: 10 }}>
                    <a style={{ color: "grey" }} href={ele} target="_blank" rel="noreferrer">
                      View Pdf - {ele?.split("/")[4]}
                    </a>
                  </div>
                ))}
              </MDTypography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Grid>
    // </GridContainer>
  );
};

ElementDetails.propTypes = {
  //   handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  widgetId: PropTypes.string,
  selectedElement: PropTypes.string,
};

export default ElementDetails;
