/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import RecommendationPicker from "components/AsyncSelect/RecommendationPicker";
import LocationPicker from "components/AutoComplete/LocationPicker";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import RecommendationLocPicker from "components/AutoComplete/RecommendationsLocPicker";
import { useEffect } from "react";
import { capitalizeFirstLetter } from "components/helper";
import LocationUserPartner from "components/LocationUserPartner";
import { useSelector } from "react-redux";

const PromotionInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  selectedLocation = {},
  handleLocationChange = () => {},
  handleRecommendationChange = () => {},
  selectedRecommendation = {},
  locationObj = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    title,
    summary,
    description,
    minCover,
    maxCover,
    location,
    recommendation,
    bookingEmail,
    needsConfirmation,
  } = formField;
  const {
    title: titleV,
    summary: summaryV,
    description: descriptionV,
    minCover: minCoverV,
    maxCover: maxCoverV,
    location: locationV,
    recommendation: recommendationV,
    bookingEmail: bookingEmailV,
    needsConfirmation: needsConfirmationV,
    source: sourceV,
  } = values;

  const user = useSelector((state) => state.users.user);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartnerId = useSelector((state) => state.locations.location);
  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);

  // UseEffect
  useEffect(() => {
    if (locationV && locationV?._id) {
      setFieldValue("locRec.location", locationV);
    } else setFieldValue("locRec.location", "");
  }, [locationV]);

  useEffect(() => {
    if (recommendationV && recommendationV?._id) {
      setFieldValue("locRec.recommendation", recommendationV);
    } else setFieldValue("locRec.recommendation", "");
  }, [recommendationV]);

  useEffect(() => {
    if (
      (user.role === "partner" && locationPartnerId?.length > 0) ||
      (user.role === "partner" && locations[0]?._id?.length > 0)
    ) {
      setFieldValue("locRec.location", locationPartner || locations[0]);
      setFieldValue("location", locationPartner || locations[0]);
    }
  }, [user.role, locationPartnerId, locations[0]?._id]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Promotion Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            summary: values?.summary,
            description: values?.description,
            bookingEmail: values?.bookingEmail,
            location: values?.location,
            recommendation: values?.recommendation?.title,
            minCover: values?.minCover,
            maxCover: values?.maxCover,
            needsConfirmation: values?.needsConfirmation,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={summary?.type}
                label={summary?.label}
                name={summary?.name}
                value={summaryV}
                placeholder={summary.placeholder}
                error={errors.summary && touched.summary}
                success={summaryV?.length > 0 && !errors.summary}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                multiline
                outlined
                rows={5}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={bookingEmail.type}
                label={bookingEmail.label}
                name={bookingEmail.name}
                value={bookingEmailV}
                placeholder={bookingEmail.placeholder}
                error={errors.bookingEmail && touched.bookingEmail}
                success={bookingEmailV?.length > 0 && !errors.bookingEmail}
              />
            </Grid>{" "}
            <Grid item size={{ xs: 6 }}>
              {user?.role === "partner" ? (
                <LocationUserPartner
                  defaultLocationId={locationPartner?._id || locations[0]?._id}
                  disabled
                  handleChange={handleLocationChange}
                  locations={locations || {}}
                  location={location}
                />
              ) : (
                <LocationPicker
                  setFieldValue={setFieldValue}
                  location={{ label: location.label, name: "locRec.location" }}
                  locationOnly
                  selectedLocation={selectedLocation?._id ? selectedLocation : locationObj}
                  handleLocationChange={(value) => handleLocationChange(value, setFieldValue)}
                  error={errors?.locRec?.location && touched.location}
                  success={locationV?._id && !errors.locRec?.location}
                />
              )}
            </Grid>
            {values?.location?.name ? (
              <Grid item size={{ xs: 12, sm: 6 }}>
                <RecommendationLocPicker
                  setFieldValue={setFieldValue}
                  locationObj={locationV}
                  handleChange={handleRecommendationChange}
                  selectedRecommendation={recommendationV}
                  recommendation={recommendation}
                />
              </Grid>
            ) : (
              <Grid item size={{ xs: 12, sm: 6 }}>
                <RecommendationPicker
                  recommendation={recommendation}
                  setFieldValue={setFieldValue}
                  selectedRecommendation={selectedRecommendation || values?.recommendation}
                  handleChange={handleRecommendationChange}
                />
              </Grid>
            )}
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={minCover.type}
                label={minCover.label}
                name={minCover.name}
                value={minCoverV}
                placeholder={minCover.placeholder}
                // error={errors.availableQuantity && touched.availableQuantity}
                // success={availableQuantityV?.length > 0 && !errors.availableQuantity}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={maxCover.type}
                label={maxCover.label}
                name={maxCover.name}
                value={maxCoverV}
                placeholder={maxCover.placeholder}
                // error={errors.availableQuantity && touched.availableQuantity}
                // success={availableQuantityV?.length > 0 && !errors.availableQuantity}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={sourceV === "lokalee" ? needsConfirmationV : false}
                      name={needsConfirmation.name}
                      onChange={(event) => setFieldValue("needsConfirmation", event.target.checked)}
                    />
                  }
                  label="Needs Confirmation"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for PromotionInfo
PromotionInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  locationObj: PropTypes.instanceOf(Object),
  selectedLocation: PropTypes.instanceOf(Object),
  handleRecommendationChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  selectedRecommendation: PropTypes.instanceOf(Object),
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default PromotionInfo;
