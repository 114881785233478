/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import TravelDeskPicker from "components/AutoComplete/TavelDeskPicker";
import Danger from "components/Danger";
import { getTravelDesks, setLoading } from "store/actions/travelDeskActions";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "components/helper";
import { useEffect } from "react";
import DestinationPicker from "components/AsyncSelect/DestinationPicker";

const AttractionInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleTravelDeskChange = () => {},
  selectedTravelDesk = {},
  handleDestinationChange = () => {},
  selectedDestination = {},
  // cityObj,
  travelDeskObj = {},
}) => {
  const dispatch = useDispatch();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, subHeader, travelDesk, destination } = formField;
  const { title: titleV, subHeader: subHeaderV, travelDesk: travelDeskV } = values;

  const travelDesks = useSelector((state) => state.travelDesks.travelDesks);
  const selectedTravelDesks = travelDesks.filter((travelDes) => travelDes.id === values.travelDesk);
  const selectedTravelDeskNames = selectedTravelDesks.map((travelDes) => travelDes.name);
  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTravelDesks());
  }, []);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Attraction info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            subHeader: values?.subHeader,
            travelDesk: selectedTravelDeskNames,
            destination:
              Array.isArray(values?.destinations) && values?.destinations.length > 0
                ? values?.destinations[0]?.label || values?.destinations[0]?.destinationName
                : undefined,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors?.name && touched?.name}
                success={titleV?.length > 0 && !errors?.name}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={subHeader.type}
                label={subHeader.label}
                name={subHeader.name}
                value={subHeaderV}
                placeholder={subHeader.placeholder}
                success={subHeaderV?.length > 0 && !errors?.subHeader}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <DestinationPicker
                destination={destination}
                // cityV={cityV}
                handleChange={handleDestinationChange}
                setFieldValue={setFieldValue}
                selectedDestination={selectedDestination || {}}
              />
              {errors?.destination && touched?.destination && (
                <Danger validation={errors?.destination} />
              )}
            </Grid>

            <Grid item size={{ xs: 6 }}>
              <TravelDeskPicker
                travelDesk={travelDesk}
                handleChange={handleTravelDeskChange}
                setFieldValue={setFieldValue}
                selectedTravelDesk={selectedTravelDesk || travelDeskObj || {}}
                error={errors.travelDesk && touched.travelDesk && errors.travelDesk?._id}
                success={travelDeskV?.name && !errors.travelDesk && errors.travelDesk?._id}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for AttractionInfo
AttractionInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleDestinationChange: PropTypes.func,
  handleTravelDeskChange: PropTypes.func,
  selectedDestination: PropTypes.instanceOf(Object),
  // cityObj: PropTypes.instanceOf(Object),
  selectedTravelDesk: PropTypes.instanceOf(Object),
  travelDeskObj: PropTypes.instanceOf(Object),
};

export default AttractionInfo;
