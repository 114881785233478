/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_TRAVELDESK_LOADING,
  CLEAR_ERRORS,
  TRAVELDESK_ADD_SUCCESS,
  TRAVELDESK_FAIL,
  UPDATE_TRAVELDESK,
} from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add TravelDesk
export const addTravelDesk = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.name) {
    data.name = data.name.trim();
  }
  if (data.contactName) {
    data.contactName = data.contactName.trim();
  }
  if (data.address) {
    data.address = data.address.trim();
  }

  form.append("name", data.name);
  form.append("website", data.website);
  form.append("address", data.address);
  form.append("contactName", data.contactName);
  form.append("contactEmail", data.contactEmail);
  form.append("contactPhone", data.contactPhone);

  if (image) form.append("image", image);
  try {
    const res = await axios.post(`${URL}/travelDesks`, form, config);

    dispatch({
      type: TRAVELDESK_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TRAVELDESK_FAIL,
      payload: err?.response.data.message,
    });
  }
};

//  Update TravelDesk
export const updateTravelDesk = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.name) {
    data.name = data.name.trim();
  }
  if (data.contactName) {
    data.contactName = data.contactName.trim();
  }
  if (data.address) {
    data.address = data.address.trim();
  }

  form.append("name", data.name);
  form.append("website", data.website);
  form.append("address", data.address);
  form.append("contactName", data.contactName);
  form.append("contactEmail", data.contactEmail);
  form.append("contactPhone", data.contactPhone);

  if (image) form.append("image", image);
  try {
    const res = await axios.patch(`${URL}/travelDesks/${data?._id}`, form, config);

    dispatch({
      type: UPDATE_TRAVELDESK,
      payload: res.data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: TRAVELDESK_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete TravelDesk - set active as false
export const deleteTravelDesk = (id) =>
  factory.patch(
    { active: false },
    `${URL}/travelDesks/${id}`,
    "DELETE_TRAVELDESK",
    "TRAVELDESK_FAIL"
  );

// Get All TravelDesks

export const getTravelDesks = () =>
  factory.get(`${URL}/travelDesks?sort=name&dir=asc`, "GET_TRAVELDESKS", "TRAVELDESK_FAIL");

// get One TravelDesk
export const getOneTravelDesk = (id) =>
  factory.get(`${URL}/travelDesks/${id}`, "GET_ONE_TRAVELDESK", "TRAVELDESK_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_TRAVELDESK_LOADING });
