/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// actions

// Newticketinfo page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
// import GreenSwitch from "components/AutoComplete/GreenSwitch";
import { capitalizeFirstLetter } from "components/helper";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import TagPicker from "components/AsyncSelect/TagPicker";
import Danger from "components/Danger";
import ThemesPicker from "components/AutoComplete/ThemesPicker";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import PrimaryDestinationPicker from "components/AutoComplete/PrimaryDestinationPicker";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProviderFields from "./components/ProviderFields";
import CuratorFields from "./components/CuratorFields";
import LocationFields from "./components/LocationFields";
import { typeOptions, curatorOrProvOptions } from "./components/constants";

const TicketInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleTagChange = () => {},
  selectedTag = {},
  handleLocationChange = () => {},
  setSelectedCurator = () => {},
  handleCuratorChange = () => {},
  handlePrimaryDestinationChange = () => {},
  selectedPrimaryDestination = {},
  handleSecondaryDestinationChange = () => {},
  selectedSecondaryDestination = {},
  setSelectedPrimaryDestination = () => {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    title,
    type,
    tag,
    themes,
    overview,
    location,
    curator,
    curatorOrProv,
    destinations,
    primaryDestination,
  } = formField;
  const {
    title: titleV,
    type: typeV,
    curatorOrProv: curatorOrProvV,
    // tag: tagV,
    themes: themesV,
    overview: overviewV,
    // skipTheLine: skipTheLineV,
    destinations: destinationsV,
    primaryDestination: primaryDestinationV,
  } = values;

  const [curOrProvVal, setCurOrProvVal] = useState(values?.CuratorPicker || "curator");
  const [typeVal, setTypeVal] = useState("ticket");

  const locations = useSelector((state) => state.locations.locations);
  const user = useSelector((state) => state.users.user);
  const specificUser = useSelector((state) => state.users.specificUser);
  const locationPartnerId = useSelector((state) => state.locations.location);

  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);

  const handleCuratorOrProviderChange = useCallback(() => {
    setCurOrProvVal(values?.curatorOrProvider);
    setSelectedCurator({});
    setFieldValue("curator", null);
    setFieldValue("providerName", "");
    setFieldValue("providerEmail", "");
    setFieldValue("provider", {});
  }, [setFieldValue, setSelectedCurator, values?.curatorOrProvider]);

  const getSelectedDestination = (destVs, selectedPrimaryDes, primaryDesV) => {
    if (!destVs.length) return null;

    if (
      selectedPrimaryDes &&
      Object.keys(selectedPrimaryDes).length > 0 &&
      destVs.some((destination) => destination.label === selectedPrimaryDes.label)
    ) {
      return selectedPrimaryDes;
    }

    if (destVs.some((destination) => destination.label === primaryDesV?.destinationName)) {
      return primaryDesV;
    }

    return null;
  };

  const getSelectedTag = (tagSel, tagValues) => {
    if (tagSel?.length > 0) return tagSel[0];
    if (tagValues?.tagSel?.value) return tagValues.tagSel;
    return tagValues?.tags[0] || tagSel;
  };

  const getDetailData = (val) => ({
    title: val?.title,
    type: val?.itinerary?.itineraryType === "STANDARD" && "ticket",
    tag: val?.tags[0]?.label,
    themes: val?.tags.slice(1).map((t) => t.label),
    overview: val?.overview,
    destinations: val?.destinations,
    primaryDestination: val?.primaryDestination?.destinationName,
    location: val?.location,
    providerName: val?.provider?.name,
    providerContact: val?.provider?.contact,
    providerEmail: val?.provider?.email,
    curatorName: val?.curator?.name,
    curatorContact: val?.curator?.id?.phone || val?.curator?.phone,
    curatorEmail: val?.curator?.id?.email || val?.curator?.email,
  });

  useEffect(() => {
    if (values?.curator?.name) {
      setCurOrProvVal("curator");
      setFieldValue("curatorOrProv", "curator");
    } else if (values?.providerName) {
      setCurOrProvVal("provider");
      setFieldValue("curatorOrProv", "provider");
      setFieldValue("curator", null);
    }

    if (user?.role === "partner") {
      setFieldValue("location", locationPartner?._id || locations[0]?._id);
    }

    if (values?.itinerary?.itineraryType === "STANDARD") {
      setTypeVal("ticket");
      setFieldValue("type", "ticket");
    }

    if (values?.curatorOrProv) {
      setCurOrProvVal(values?.curatorOrProv);
    }
  }, [
    values?.curator?.name,
    values?.providerName,
    values?.itinerary?.itineraryType,
    values?.curatorOrProv,
    user?.role,
    locationPartner,
    locations,
    setFieldValue,
  ]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Ticket Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList data={getDetailData(values)} />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <DynamicRadioGroup
                groupLabel={type.label}
                name={type.name}
                value={typeV || typeVal}
                options={typeOptions}
                setFieldValue={setFieldValue}
                disabled
              />
            </Grid>{" "}
            <Grid container spacing={2} item size={{ xs: 12 }}>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <TagPicker
                  ticketPage
                  tag={tag}
                  handleChange={handleTagChange}
                  setFieldValue={setFieldValue}
                  selectedTag={getSelectedTag(selectedTag, values)}
                />
                {errors?.tag && !values?.tag?.label && touched?.tag && (
                  <Danger validation={errors?.tag} />
                )}
              </Grid>

              <Grid item size={{ xs: 12, sm: 6 }}>
                <ThemesPicker
                  themes={themes}
                  themesV={themesV?.length > 0 ? themesV : values?.tags?.slice(1)}
                  setFieldValue={setFieldValue}
                  error={errors.themesV && touched.themesV}
                  success={values?.themes?.length > 0 && !errors.themes}
                  selectedTag={
                    selectedTag?.length > 0
                      ? selectedTag[0]
                      : values?.tag?.value
                      ? values?.tag
                      : values?.tags[0] || selectedTag
                  }
                />
              </Grid>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={overview.type}
                label={overview.label}
                name={overview.name}
                value={overviewV}
                multiline
                outlined
                rows={5}
                placeholder={overview.placeholder}
                error={errors.overview && touched.overview}
                success={overviewV?.length > 0 && !errors.overview}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <DestinationsPicker
                setFieldValue={setFieldValue}
                handleChange={handleSecondaryDestinationChange}
                destinations={destinations}
                destinationsV={destinationsV || selectedSecondaryDestination}
                setSelectedPrimaryDestination={setSelectedPrimaryDestination}
              />
              <Danger validation={errors?.destinations} />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <PrimaryDestinationPicker
                setFieldValue={setFieldValue}
                handleChange={handlePrimaryDestinationChange}
                selectedDestination={getSelectedDestination(
                  destinationsV,
                  selectedPrimaryDestination,
                  primaryDestinationV
                )}
                destination={primaryDestination}
                destinationsObj={destinationsV || selectedSecondaryDestination}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <LocationFields
                userRole={user.role}
                location={location}
                locationPartner={locationPartner}
                locations={locations}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleLocationChange={handleLocationChange}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <Grid item size={{ xs: 12 }}>
                <DynamicRadioGroup
                  groupLabel={curatorOrProv.label}
                  name={curatorOrProv.name}
                  value={curatorOrProvV || curOrProvVal}
                  options={curatorOrProvOptions}
                  setFieldValue={setFieldValue}
                  handleChange={handleCuratorOrProviderChange}
                />
              </Grid>
            </Grid>
            {values.curatorOrProv === "curator" ? (
              <CuratorFields
                values={specificUser?.role === "curator" ? specificUser : values}
                errors={errors}
                touched={touched}
                curator={curator}
                setFieldValue={setFieldValue}
                handleCuratorChange={handleCuratorChange}
              />
            ) : (
              <ProviderFields values={values} errors={errors} touched={touched} />
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for TicketInfo
TicketInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  handleTagChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  setSelectedCurator: PropTypes.func,
  handleCuratorChange: PropTypes.func,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  handlePrimaryDestinationChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  selectedSecondaryDestination: PropTypes.instanceOf(Object),
  setSelectedPrimaryDestination: PropTypes.func,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default TicketInfo;
