// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useHandleError } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import { updateBanner, clearErrors, getOneBanner, setLoading } from "store/actions/bannerActions";
import setAlert from "store/actions/alertActions";
// Schemas
import validations from "../new-banner/schemas/validations";

import form from "../new-banner/schemas/form";
import initialValues from "../new-banner/schemas/initialValues";
import BannerDetail from "./components/BannerDetail";

const EditBanner = () => {
  const { formId, formField } = form;
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const loading = useSelector((state) => state.banners.loading);
  const banner = useSelector((state) => state.banners.banner);
  //   const banners = useSelector((state) => state.banners.banners);
  const error = useSelector((state) => state.banners.error);
  // const added = useSelector((state) => state.banners.added);
  const user = useSelector((state) => state.users.user);
  //   const [tabValue, setTabValue] = useState(0);

  const [imgDetailPage, setImgDetailPage] = useState(true);
  //   const [image, _setImage] = useState(banner?.image);

  const [bannerPage, setBannerPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);

  const { banner_id } = useParams();

  useEffect(() => {
    if (banner_id) {
      dispatch(getOneBanner(banner_id));
    }
  }, [banner_id]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (banner) {
      const manipulated = {};
      manipulated.title = banner?.title || "";

      if (typeof banner?.position === "string") {
        if (banner.position === "top") {
          manipulated.position = { label: "Homepage Top", value: "top" };
        } else if (banner.position === "bottom") {
          manipulated.position = { label: "Homepage Bottom", value: "bottom" };
        } else if (banner.position === "leaderboard") {
          manipulated.position = { label: "Leaderboard", value: "leaderboard" }; // added leaderbaord
        }
      } else {
        manipulated.position = banner?.position || {};
      }

      // added destination and url
      manipulated.destination =
        banner?.destinations?.length > 0 && banner?.destinations[0]?._id
          ? {
              value: banner?.destinations[0]?._id,
              label: banner?.destinations[0]?.destinationName,
              _id: banner?.destinations[0]?._id,
            }
          : null;

      manipulated.image = banner?.image ? [banner.image] : [];
      manipulated.mobileImage = banner?.mobileImage ? [banner.mobileImage] : [];
      manipulated._id = banner?._id;
      manipulated.url = banner?.url || "";

      // Update the state
      setSelectedPosition(manipulated.position);
      setSelectedDestination(
        banner?.destinations?.length > 0 && banner?.destinations[0]?._id
          ? {
              value: banner?.destinations[0]?._id,
              label: banner?.destinations[0]?.destinationName,
              _id: banner?.destinations[0]?._id,
            }
          : null
      );
      setInitialState(manipulated);
    }
  }, [banner]);

  const handlePositionChange = (value) => {
    if (value?.value !== "leaderboard") {
      formRef.current.setFieldValue("destination", null);
      formRef.current.setFieldValue("url", "");
      formRef.current.setFieldValue("mobileImage", null);
      setSelectedDestination(null);
    }
    setSelectedPosition(value || {});
  };

  const handleDestinationChange = (value) => {
    if (value && value?.value) {
      setSelectedDestination(value);
    } else {
      setSelectedDestination({});
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values) => {
    const {
      title = "",
      position = "",
      destination = "",
      url = "",
      // image = "",
    } = values;

    const baseValues = { _id: values?._id };
    let additionalValues = {};

    additionalValues = {
      title,
      position,
      destination,
      url,
      //   image,
    };

    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updateBanner(updatedValues, values?.image, values?.mobileImage));
    setBannerPage(true);
    setImgDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/banners?`;
      navigate(url);
    }
  };

  const updateSelectedValues = (bannerData) => {
    if (bannerData) {
      // Reset position based on banner data
      if (typeof bannerData?.position === "string") {
        if (bannerData.position === "top") {
          setSelectedPosition({ label: "Homepage Top", value: "top" });
        } else if (bannerData.position === "bottom") {
          setSelectedPosition({ label: "Homepage Bottom", value: "bottom" });
        } else if (bannerData.position === "leaderboard") {
          setSelectedPosition({ label: "Leaderboard", value: "leaderboard" });
        }
      } else {
        setSelectedPosition(bannerData?.position || {});
      }

      // Reset destination based on banner data
      setSelectedDestination(
        bannerData?.destinations?.length > 0 && bannerData?.destinations[0]?._id
          ? {
              value: bannerData?.destinations[0]?._id,
              label: bannerData?.destinations[0]?.destinationName,
              _id: bannerData?.destinations[0]?._id,
            }
          : null
      );
    }
  };

  const handleClose = () => {
    // Reset to the current banner data from Redux store
    updateSelectedValues(banner);
    // Reset form
    formRef.current.resetForm();
    setImgDetailPage(true);
  };

  const handleDetailPageChange = (value, wasCanceled = false) => {
    setBannerPage(value);

    // If the edit was canceled, reset selected values to match banner data
    if (wasCanceled) {
      updateSelectedValues(banner);
    }
  };

  function getStepContent(type, formData) {
    if (type === "Information") {
      return (
        <BannerDetail
          type={type}
          selectedPosition={selectedPosition}
          handlePositionChange={handlePositionChange}
          selectedDestination={selectedDestination}
          handleDestinationChange={handleDestinationChange}
          formData={formData}
          detailPage={bannerPage}
          setDetailPage={handleDetailPageChange}
          handleEdit={() => {
            formData.resetForm();
            setBannerPage(false);
            setImgDetailPage(true);
          }}
        />
      );
    }
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar hide={user.role === "partner"} />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          {" "}
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <MDBox>
                    <DynamicHeader
                      user={banner}
                      noImg
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      <ViewEditActions
                        noEditIcon
                        detailPage={imgDetailPage}
                        stateName="banners"
                        handleEdit={() => {
                          setImgDetailPage(false);
                          setBannerPage(true);
                        }}
                        handleClose={handleClose}
                        handleSave={() => {
                          submitForm(values);
                          setImgDetailPage(true);
                        }}
                      />
                    </DynamicHeader>
                    <>
                      {["Information"].map((item, index) => (
                        <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item size={{ xs: 12 }}>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  {getStepContent(item, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    resetForm,
                                  })}
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Card>
                      ))}
                    </>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditBanner;
