import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { location },
} = checkout;

const validations = [
  Yup.object().shape({
    [location.name]: Yup.object().when("all", {
      is: true,
      then: () => Yup.object().nullable(),
      otherwise: () => Yup.object().required(location.errorMsg),
    }),
  }),
];

export default validations;
