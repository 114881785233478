/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import { Typography } from "@mui/material";

const VenuePicker = ({
  handleChange = () => {},
  venues,
  setFieldValue = () => {},
  selectedVenue = null,
  isClearable = false,
}) => {
  const filterRec = (data) =>
    data.map((venueData) => ({
      label: venueData.title,
      value: venueData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/venues?`;
      if (inputValue) url += `&title=${inputValue}`;
      url += "&sort=title&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          const sortedData = result.data.sort((a, b) => a.title.localeCompare(b.title));
          resolve(filterRec(sortedData));
        })
        .catch((error) => console.log(error.message));
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => console.error(error));
  }, 1000);

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {venues.label}
      </Typography>
      <AsyncSelect
        key={selectedVenue?.value}
        cacheOptions
        defaultOptions
        placeholder={selectedVenue?.title || selectedVenue?.label || "Search for a venue"}
        isClearable={isClearable}
        menuPosition="fixed"
        value={selectedVenue?.value ? selectedVenue : null}
        loadOptions={debouncedLoadOptions}
        onChange={(option) => {
          handleChange(option || null); // Call handleChange with selected option or null if cleared
          setFieldValue("venues", option || {});
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

VenuePicker.propTypes = {
  venues: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  selectedVenue: PropTypes.instanceOf(Object),
};

export default VenuePicker;
