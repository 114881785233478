import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { getUpdatedFilter } from "store/actions/userActions";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00850D", // Change this to your desired border color
    },
  },
});

const RoleFilter = ({ onFilterChanged, columnDef, filters = {} }) => {
  const [selectedVal, setSelectedVal] = useState("all");

  useEffect(() => {
    setSelectedVal(columnDef.defaultFilter || columnDef?.tableData?.filterValue || 0);
  }, [columnDef]);

  useEffect(() => {
    if (!filters.filters?.length) {
      setSelectedVal("all");
    }
  }, [filters]);

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedVal(val);
    onFilterChanged(columnDef.tableData.id, e.target.value);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>
            <CustomSelect style={{ width: 100 }} value={selectedVal} onChange={handleChange}>
              <MenuItem value="all">all</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="curator">Curator</MenuItem>
              <MenuItem value="partner">Partner</MenuItem>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="guest">Guest</MenuItem>
              <MenuItem value="concierge">Concierge</MenuItem>
            </CustomSelect>
          </th>
        </tr>
      </thead>
    </table>
  );
};

RoleFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
  filters: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  filters: state.users.filters,
});

export default connect(mapStateToProps, {
  getUpdatedFilter,
})(RoleFilter);
