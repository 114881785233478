import {
  SET_LOADING,
  GET_REQUESTS,
  REQUEST_FAIL,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
} from "../actions/Types";

const initialState = {
  requests: [],
  request: null,
  loading: false,
  error: null,
  added: false,
};

export default function requestReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_REQUESTS:
      return {
        ...state,
        requests: payload.data || [],
        loading: false,
        added: false,
        error: null,
      };
    case REQUEST_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
        added: true,
        requests: action.payload.data,
      };
    case MARK_AS_READ_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
