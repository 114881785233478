/* eslint-disable no-return-assign */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable react/jsx-no-bind */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

// @mui material components
import Grid from "@mui/material/Grid2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";

// Custom components
import DraftJsRichEditorExample from "components/DraftJsRichEditorExample";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import PartnerPicker from "components/AutoComplete/PartnerPicker";
import DetailList from "components/DetailList";
import CopytoClipboard from "components/CopytoClipboard";
import ColorPicker from "components/ColorPicker";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "components/helper";
import { ErrorMessage, useFormikContext } from "formik";
import AutocompleteEmail from "components/AutoComplete/AutoCompleteEmail";
import TierPicker from "components/AutoComplete/TierPicker";
import Danger from "components/Danger";

const env = process.env.REACT_APP_ENV;

const LocationInfo = ({
  formField,
  editPage = false,
  partnerObj = {},
  detailPage = true,
  selectedPartner = {},
  handleChange = () => {},
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const { top, name, partner, email, disclaimerText, primary, background, button, trn } = formField;
  const {
    top: topV,
    trn: trnV,
    name: nameV,
    partner: partnerV,
    email: emailV,
    disclaimer: disclaimerV,
    disclaimerText: disclaimerTextV,
    primary: primaryV,
    background: backgroundV,
    button: buttonV,
  } = values;

  const partners = useSelector((state) => state.partners.partners);
  const user = useSelector((state) => state.users.user);
  const selectedPartners = partners.filter((part) => part.id === values.partner);
  const selectedPartnerNames = selectedPartners.map((part) => part.name);

  const url = env === "production" ? "https://lokalee.app" : "https://staging.lokalee.app";

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const qrValue = `${url}/locations/${values?.slug}`;

    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [tier, setTier] = useState(
    typeof values?.tier === "string" && values?.tier
      ? {
          label: capitalizeFirstLetter(values?.tier),
          value: values?.tier,
        }
      : values?.tier
  );

  useEffect(() => {
    if (
      disclaimerTextV &&
      typeof disclaimerTextV === "object" &&
      // Object.keys(disclaimerTextV).length > 0
      disclaimerTextV?.blocks?.length > 0 &&
      disclaimerTextV?.blocks[0].text?.length > 0
    ) {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(disclaimerTextV?.blocks[0].text))
      );
    }
  }, []);

  useEffect(() => {
    if (values?.tier) {
      if (typeof values?.tier === "object") {
        setTier(tier);
      } else {
        setTier({
          label: capitalizeFirstLetter(values?.tier),
          value: values?.tier,
        });
      }
    }
  }, [values?.tier]);

  useEffect(() => {
    if (
      disclaimerTextV &&
      disclaimerTextV?.length > 0 &&
      disclaimerTextV !== "{}" &&
      !detailPage &&
      typeof disclaimerTextV === "string"
    ) {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(disclaimerTextV))));
    }
  }, [disclaimerTextV, detailPage]);

  useEffect(() => {
    if (user?.role === "partner") {
      setFieldValue("partner", user?.partner?._id);
    }
  }, [user?.role, setFieldValue]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Location info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <>
          <DetailList
            data={{
              name: values?.name,
              partner: values?.partner?.name || selectedPartnerNames,
              locationEmail: values?.email,
              primary: values?.primary,
              background: values?.background,
              button: values?.button,
              top: values?.top,
              TRN: values?.trn,
              Tier: values?.status === "published" && values?.tier,
              disclaimerText: values?.disclaimer && values?.disclaimerText,
            }}
          />
          {values?.slug && (
            <div style={{ marginBottom: 10 }}>
              <Divider
                sx={{ height: "1px", margin: "8px 0", borderWidth: "1px", backgroundImage: "none" }}
              />
              <CopytoClipboard text={`${url}/locations/${values?.slug}`} />
            </div>
          )}
          <div style={{ display: "flex", alignItems: "end", gap: 5 }}>
            <QRCode id="qr-gen" size={100} level="H" value={`${url}/locations/${values?.slug}`} />
            <div>
              <button type="button" onClick={downloadQRCode}>
                Download QR Code
              </button>
            </div>
          </div>
        </>
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={capitalizeFirstLetter(nameV)}
                placeholder={name.placeholder}
                error={errors?.name && touched?.name}
                success={nameV?.length > 0 && !errors?.name}
              />
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <PartnerPicker
                partner={partner}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                disabled={user?.role === "partner"}
                selectedPartner={
                  user?.role === "partner" ? user?.partner : selectedPartner || partnerObj || {}
                }
                error={errors.partner && touched.partner && errors.partner?._id}
                success={partnerV?.name && !errors.partner && errors.partner?._id}
              />
            </Grid>

            <Grid item size={{ sm: 12 }} sx={{ paddingTop: "0px!important" }}>
              <AutocompleteEmail
                setFieldValue={setFieldValue}
                email={email}
                emailV={typeof emailV === "string" ? [emailV] : emailV}
                error={errors?.email && touched?.email}
              />
            </Grid>
            <Grid container item size={{ xs: 12 }}>
              <Grid item size={{ xs: 4 }}>
                <ColorPicker
                  option={primary}
                  value={primaryV || "#00acc1"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item size={{ xs: 4 }}>
                <ColorPicker
                  option={background}
                  value={backgroundV || "#ccc9c9"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item size={{ xs: 4 }}>
                <ColorPicker
                  option={button}
                  value={buttonV || "#efebeb"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={values.top}
                      name={top.name}
                      value={topV}
                      onChange={(event) => setFieldValue("top", event.target.checked)}
                    />
                  }
                  label={top.label}
                />
              </FormGroup>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <FormField
                  type={trn.type}
                  label={trn.label}
                  name={trn.name}
                  value={trnV}
                  placeholder={trn.placeholder}
                  error={errors.trn && touched.trn}
                  success={trnV.length > 0 && !errors.trn}
                  onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                />
              </Grid>
              {editPage && values?.status === "published" && user?.role === "admin" && (
                <Grid item size={{ xs: 6 }} mt={3}>
                  <MDTypography
                    variant="regular"
                    style={{ marginRight: "0.5rem", fontSize: "14px" }}
                  >
                    Tier *
                  </MDTypography>
                  <TierPicker tierV={tier || {}} setTier={setTier} setFieldValue={setFieldValue} />
                  {(!values?.tier || !tier?.value) && <Danger validation="Tier is required!" />}
                </Grid>
              )}

              {/* <Grid item size={{ xs: 12 }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={values.disclaimer}
                        name={disclaimer.name}
                        value={disclaimerV}
                        onChange={(event) => setFieldValue("disclaimer", event.target.checked)}
                      />
                    }
                    label={disclaimer.label}
                  />
                </FormGroup>
              </Grid> */}
              {disclaimerV && values?.disclaimer && (
                <Grid container spacing={3}>
                  <Grid item size={{ xs: 12 }}>
                    <DraftJsRichEditorExample
                      editorState={editorState}
                      onChange={(event) => {
                        setEditorState(event);
                        setFieldValue("disclaimerText", convertToRaw(event.getCurrentContent()));
                      }}
                      placeholder={disclaimerText.label}
                    />
                    <MDBox mt={0.75}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        fontWeight="regular"
                      >
                        <ErrorMessage name={disclaimerText?.name} />
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for LocationInfo
LocationInfo.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  editPage: PropTypes.bool,
  partnerObj: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  detailPage: PropTypes.bool,
  selectedPartner: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
};

export default LocationInfo;
