// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import mealsList from "./mealsList";

const MealsPicker = ({ meals, mealsV, setFieldValue, handleMealsChange = () => {}, ...rest }) => (
  <Autocomplete
    id="meals"
    multiple
    filterSelectedOptions
    options={mealsList}
    onChange={(event, value) => {
      if (value) {
        handleMealsChange(value);
        setFieldValue("meals", value);
      } else {
        setFieldValue("meals", []);
      }
    }}
    value={mealsV || []}
    getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
    isOptionEqualToValue={(option, value) => option.value === value.value}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={meals.label}
        name={meals?.name}
        onChange={() => null}
      />
    )}
  />
);

// typechecking props for MealsPicker
MealsPicker.propTypes = {
  meals: PropTypes.instanceOf(Object).isRequired,
  mealsV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleMealsChange: PropTypes.func,
};

export default MealsPicker;
