import {
  SET_CATEGORY_LOADING,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_FAIL,
  GET_CATEGORIES,
  CLEAR_ERRORS,
  DELETE_CATEGORY,
  GET_ONE_CATEGORY,
  UPDATE_CATEGORY,
  LOGOUT,
} from "../actions/Types";

const initialState = {
  loading: false,
  categories: [],
  error: null,
  added: false,
  category: null,
};

const variable = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        categories: [action.payload.data, ...state.categories],
        added: true,
        category: null,
      };
    case GET_ONE_CATEGORY:
      return {
        ...state,
        loading: false,
        error: null,
        category: action.payload.data.data,
      };

    case GET_CATEGORIES:
      return {
        ...state,
        loading: false,
        error: null,
        categories: action.payload.data.data,
        added: false,
        category: null,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter((category) => category._id !== action.payload),
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category._id === action.payload.data.category._id
            ? action.payload.data.category
            : category
        ),
      };
    case CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };

    case LOGOUT:
      return {
        loading: false,
        categories: [],
        error: null,
        added: false,
        category: null,
      };

    default:
      return state;
  }
};

export default variable;
