// Libraries
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useHandleError, dateFormatWithDash } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions

import {
  updateVoucher,
  getOneVoucher,
  clearErrors,
  updateVoucherTranslations,
  setLoading,
} from "store/actions/voucherActions";
import setAlert from "store/actions/alertActions";

// Schemas
import DynamicTranslate from "components/DynamicTranslate";

import validations from "./schemas/validations";
import form from "../new-voucher/schemas/form";
import initialValues from "../new-voucher/schemas/initialValues";
import VoucherDetail from "./components/VoucherDetail";

const EditVoucher = () => {
  const { formId, formField } = form;
  const { voucher_id } = useParams();

  //   const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const voucher = useSelector((state) => state.vouchers.voucher);
  const error = useSelector((state) => state.vouchers.error);
  // const added = useSelector((state) => state.vouchers.added);
  const user = useSelector((state) => state.users.user);

  // state
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(voucher?.image || voucher?.logo);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [additionalInfoDetailPage, setAdditionalInfoDetailPage] = useState(true);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [voucherDetailPage, setVoucherDetailPage] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [voucherValidation, setVoucherValidation] = useState(validations[0]);
  //   const [formRefVal, setFormRefVal] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);

  const detailTabs = [
    { id: 1, title: "DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];
  // useEffect
  useEffect(() => {
    if (!voucherDetailPage) {
      setVoucherValidation(validations[0]);
    } else if (!additionalInfoDetailPage) {
      setVoucherValidation(validations[1]);
    } else {
      setVoucherValidation(undefined);
    }
  }, [voucherDetailPage, additionalInfoDetailPage]);

  useEffect(() => {
    if (voucher) {
      const arTranslation = voucher?.translations?.ar;

      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: arTranslation?.title || "",
          default: "",
          label: "Title",
        },
        {
          id: 2,
          name: "description",
          title: "Description",
          value: arTranslation?.description || "",
          default: "",
          label: "Description",
        },
      ];
      setTranslateItems(arr);
    }
  }, [voucher]);

  useEffect(() => {
    if (voucher_id) {
      dispatch(getOneVoucher(voucher_id));
    }
  }, [voucher_id]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (voucher?.logo || voucher?.image) {
      _setImage(voucher?.logo || voucher?.image);
    }
  }, [voucher?.logo, voucher?.image]);
  // We cant use the voucher as is because of address picker and map picker
  // const [originalTitle, setOriginalTitle] = useState("");

  useEffect(() => {
    if (voucher) {
      const manipulated = {};
      manipulated.title = voucher.title ? voucher.title : "";
      manipulated.description = voucher.description ? voucher.description : "";
      manipulated.startDate = voucher?.startDate ? dateFormatWithDash(voucher?.startDate) : "";
      manipulated.endDate = voucher?.endDate ? dateFormatWithDash(voucher?.endDate) : "";
      manipulated.location = voucher.location ? voucher.location : {};
      manipulated.recommendation = voucher.recommendation ? voucher.recommendation : {};
      manipulated.type = voucher.type ? voucher.type : "percentage";
      manipulated.value = voucher.value ? voucher.value : 0;
      manipulated.maxUsage = voucher.maxUsage ? voucher.maxUsage : 1;
      manipulated.dayRestriction = voucher.dayRestriction ? "per_day" : "no_day_restriction";
      manipulated.quantity = voucher.quantity ? voucher.quantity : 1;
      manipulated.quantityPerDay = voucher.quantityPerDay ? voucher.quantityPerDay : 1;
      manipulated.limit = voucher.limit ? voucher.limit : false;
      manipulated.openEnded = voucher.openEnded ? voucher.openEnded : false;

      manipulated._id = voucher._id;

      if (voucher?.image) _setImage(voucher.image);
      if (voucher?.logo) _setImage(voucher.logo);
      setInitialState(manipulated);
    }

    setSelectedLocation(voucher?.location);
    setSelectedRecommendation(voucher?.recommendation);
  }, [voucher]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    const {
      title = "",
      description = "",
      location = {},
      recommendation = {},
      limit = true,
      type = "percentage",
      openEnded = false,
      value = "",
      maxUsage = "",
      quantityPerDay = "",
      quantity = "",
      dayRestriction = "per_day",
      startDate = "",
      endDate = "",
    } = values;

    const baseValues = {
      _id: values?._id,
      title,
      description,
    };
    let additionalValues = {};
    additionalValues = {
      location,
      recommendation,
      type,
      value,
      limit,
      maxUsage,
      quantityPerDay,
      quantity,
      dayRestriction: dayRestriction === "per_day",
      openEnded,
      startDate,
      endDate,
    };
    const updatedValues = { ...baseValues, ...additionalValues };

    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    // values, partner, keyWords, imageBuffer, originalTitle
    dispatch(updateVoucher(updatedValues, values?.logo));
    setVoucherDetailPage(true);
    setAdditionalInfoDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleRecommendationChange = (value) => {
    if (value && value?._id) {
      setSelectedRecommendation(value);
    } else {
      setSelectedRecommendation({});
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneVoucher(voucher._id));
    }
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updateVoucherTranslations(values, voucher?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={voucher?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleLocationChange = (value, setFieldValue) => {
    if (value && value?._id) {
      setSelectedLocation(value);
      setSelectedRecommendation({});
      setFieldValue("recommendation", "");
    } else {
      setSelectedLocation({});
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "information":
        detailPage = voucherDetailPage;
        setDetailPage = setVoucherDetailPage;
        break;
      default:
        detailPage = additionalInfoDetailPage;
        setDetailPage = setAdditionalInfoDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "information") {
        setVoucherDetailPage(false);
        setAdditionalInfoDetailPage(true);
      }
      if (type === "additionalInfo") {
        setAdditionalInfoDetailPage(false);
        setVoucherDetailPage(true);
      }
      setImgDetailPage(true);
    };

    return (
      <VoucherDetail
        // availabilityType={availabilityType}
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        selectedRecommendation={selectedRecommendation}
        handleRecommendationChange={handleRecommendationChange}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        setSelectedRecommendation={setSelectedRecommendation}
        setSelectedLocation={setSelectedLocation}
      />
    );
  }

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/vouchers?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/vouchers?locationId=${voucher?.location?._id}`;
      navigate(url);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={voucherValidation}
              onSubmit={handleSubmit}
              //   innerRef={(f) => {
              //     setFormRefVal(f?.values);
              //     formRef.current = f;
              //   }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <MDBox>
                    <DynamicHeader
                      user={voucher}
                      image={image}
                      imageType="logo"
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="vouchers"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setAdditionalInfoDetailPage(true);
                            setVoucherDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(voucher?.image || voucher?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {["information", "additionalInfo"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item size={{ xs: 12 }}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditVoucher;
