/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */

// Material Dashboard 2 PRO React examples

// libraries
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// custom components
import Spinner from "components/Spinner";
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MaterialTablePicker from "components/MaterialTablePicker";

// redux actions

// material ui components
import { Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getCartReports, setLoading, getAverageCartValue } from "store/actions/reportActions";
import DynamicDateRange from "components/DynamicDateRange";
import { isSameDay, subDays } from "date-fns";
import MDButton from "components/MDButton";
import { dateFormatWithDash } from "components/helper";
import columnsMap from "components/MaterialTablePicker/TableColumns";
import pdfCartReports from "./PdfCartReports";
import TicketsTable from "./CartReportsList/TicketsTable";

const CartReports = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.reports.loading);
  const cartReports = useSelector((state) => state.reports.cartReports);
  const averageCartValue = useSelector((state) => state.reports.averageCartValue);

  const [hideDaterange, setHideDaterange] = useState(true);
  const [allCarts, setAllCarts] = useState(true);
  const [filterButton, setFilterButton] = useState(false);
  const [isAverageCartValue, setIsAverageCartValue] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getCartReports());
  }, []);

  useEffect(() => {
    if (dateRange[0]?.startDate && dateRange[0]?.endDate && filterButton) {
      dispatch(setLoading());
      dispatch(getAverageCartValue(dateRange[0]?.startDate, dateRange[0]?.endDate));
    }
  }, [filterButton]);

  useEffect(() => {
    if (filterButton) {
      if (dateRange[0]?.startDate && dateRange[0]?.endDate) {
        dispatch(
          getCartReports(
            dateFormatWithDash(dateFormatWithDash(dateRange[0]?.startDate)),
            dateFormatWithDash(dateFormatWithDash(dateRange[0]?.endDate))
          )
        );
      } else {
        dispatch(getCartReports());
      }

      setFilterButton(false);
    }
  }, [cartReports?.length, filterButton]);

  const handleDateChange = (date, event) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid style={{ marginTop: "15px" }} item size={{ xs: 6 }}>
          <DynamicDateRange
            defaultValue={dateRange}
            selectedOption={dateRange}
            isTodayIncluded
            handleDateChange={handleDateChange}
            hideDaterange={hideDaterange}
            hideDateRangeFn={setHideDaterange}
          />
          <MDBox display={!allCarts ? "block" : "none"} style={{ fontSize: "medium" }}>
            Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
            {dateRange[0].endDate.toDateString()}
          </MDBox>{" "}
        </Grid>{" "}
        <Grid item size={{ xs: 6 }}>
          <MDBox sx={{ display: "flex", justifyContent: "flex-start" }}>
            <MDButton
              color="primary"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={() => {
                setAllCarts(false);
                setHideDaterange(true);
                setFilterButton(true);
                setIsAverageCartValue(true);
              }}
            >
              Filter Date
            </MDButton>{" "}
            <MDButton
              color="primary"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={() => {
                setAllCarts(true);
                setIsAverageCartValue(false);
                dispatch(getCartReports());
              }}
            >
              Reset
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>

      <Grid item size={{ xs: 12 }} style={{ marginTop: 3 }}>
        {cartReports && (
          // eslint-disable-next-line react/no-array-index-key
          <MDBox>
            <MaterialTablePicker
              screen="CartReportsList"
              title="Carts"
              searchField="cartReports"
              currentUrl="cartReports"
              tableRef={tableRef}
              columns={[
                columnsMap.cartId,
                columnsMap.creationDateAsync,
                columnsMap.currency,
                columnsMap.totalCartAmount,
                columnsMap.totalAmountUSD,
                columnsMap.userNameCol,
                columnsMap.userEmail,
              ]}
              isRowClickable={false}
              data={cartReports}
              // eslint-disable-next-line react/no-unstable-nested-components
              detailPanel={(rowData) => (
                <MDBox sx={{ ml: 6, mr: 4 }}>
                  <TicketsTable orders={rowData.rowData} items={rowData.rowData.items} />
                </MDBox>
              )}
              tableInside
              isFreeDownloadAction
              isLoading={loading}
              filteringOptions={false}
              isExportable={false}
              actionsOnClick={() => {
                pdfCartReports(cartReports);
              }}
              actionsRole
              actionsName="cartReports"
            />
          </MDBox>
        )}
      </Grid>
      {isAverageCartValue && (
        <Grid item size={{ xs: 12 }} style={{ marginTop: 3 }}>
          <Card style={{ background: "white" }}>
            <CardContent>
              <Typography
                style={{ marginTop: "10px" }}
              >{`Average Cart Value: ${averageCartValue?.toFixed(2)}`}</Typography>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Footer />
      {/* <Spinner loading={loading} /> */}
    </DashboardLayout>
  );
};

export default CartReports;
