import React, { useEffect, useRef } from "react";
import MaterialTable from "@material-table/core";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid2";
import { Check, Clear } from "@mui/icons-material";
import { deleteTag, getOneTag } from "store/actions/tagActions";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "./CustomPagination";

const TagAndSubtagTables = ({
  currentType = "",
  handleChange = () => {},
  selectedTag = {},
  setSelectedTag = () => {},
  selectedTag2 = {},
  setSelectedTag2 = () => {},
}) => {
  const dispatch = useDispatch();
  const firstRenderRef = useRef(true);
  const tableRef = useRef(null);

  const added = useSelector((state) => state.tags.added);

  const getTagsColumns = (rowType) => [
    {
      title: "Tag",
      field: "title",
      cellStyle: {
        width: 120,
        maxWidth: 120,
        whiteSpace: "normal",
        lineHeight: "normal",
      },
      render: (rowData) => (
        <div style={{ maxWidth: 120, wordWrap: "break-word" }}>
          <span>{rowData.title}</span>
        </div>
      ),
      defaultFilter: "",
    },
    {
      title: "Source",
      field: "source",
      editable: "never",
      cellStyle: {
        width: 90,
        maxWidth: 90,
      },
    },
    {
      title: "Top",
      field: "top",
      editable: "never",
      type: "boolean",
      cellStyle: {
        width: 30,
        maxWidth: 30,
      },
      render: (rowData) =>
        rowData.top ? (
          <div>
            <Check style={{ color: "green", maxWidth: "30px" }} />
          </div>
        ) : (
          <div>
            <Clear style={{ color: "red", maxWidth: "30px" }} />
          </div>
        ),
    },
    ...(rowType !== "JOBS"
      ? [
          {
            title: "Companion",
            field: "persona",
            render: (rowData) =>
              rowData.persona && (
                <Tooltip title={rowData.persona.join(", ")}>
                  <div>
                    {rowData.persona.slice(0, 1).map((val) => (
                      <Chip
                        label={val}
                        href="#basic-chip"
                        key={val}
                        style={{ margin: "0px", fontSize: "12px", height: "22px" }}
                      />
                    ))}
                  </div>
                </Tooltip>
              ),
            editable: "never",
            cellStyle: {
              width: 90,
              maxWidth: 90,
            },
          },
          {
            title: "Interest",
            field: "interests",
            render: (rowData) =>
              rowData.interests && (
                <Tooltip title={rowData.interests.join(", ")}>
                  <div>
                    {rowData.interests.slice(0, 1).map((val) => (
                      <Chip
                        label={val}
                        href="#basic-chip"
                        key={val}
                        style={{ margin: "0px", fontSize: "12px", height: "22px" }}
                      />
                    ))}
                  </div>
                </Tooltip>
              ),
            editable: "never",
            cellStyle: {
              width: 90,
              maxWidth: 90,
            },
          },
        ]
      : []),
  ];
  const getSubTagsColumns = (rowType) => [
    {
      title: "Subtag",
      field: "title",
      cellStyle: {
        width: 120,
        maxWidth: 120,
        whiteSpace: "normal",
        lineHeight: "normal",
      },
      render: (rowData) => (
        <div style={{ maxWidth: 120, wordWrap: "break-word" }}>
          <span>{rowData.title}</span>
        </div>
      ),
      defaultFilter: "",
    },
    {
      title: "Source",
      field: "source",
      editable: "never",
      cellStyle: {
        width: 90,
        maxWidth: 90,
      },
    },
    {
      title: "Top",
      field: "top",
      editable: "never",
      type: "boolean",
      cellStyle: {
        width: 30,
        maxWidth: 30,
      },
      render: (rowData) =>
        rowData.top ? (
          <div>
            <Check style={{ color: "green", maxWidth: "30px" }} />
          </div>
        ) : (
          <div>
            <Clear style={{ color: "red", maxWidth: "30px" }} />
          </div>
        ),
    },
    ...(rowType !== "JOBS"
      ? [
          {
            title: "Companion",
            field: "persona",
            render: (rowData) =>
              rowData.persona && (
                <Tooltip title={rowData.persona.join(", ")}>
                  <div>
                    {rowData?.persona?.slice(0, 1).map((val) => (
                      <Chip
                        label={val}
                        href="#basic-chip"
                        key={val}
                        style={{ margin: "0px", fontSize: "12px", height: "22px" }}
                      />
                    ))}
                  </div>
                </Tooltip>
              ),
            editable: "never",
            cellStyle: {
              width: 90,
              maxWidth: 90,
            },
          },
          {
            title: "Interest",
            field: "interests",
            render: (rowData) =>
              rowData.interests && (
                <Tooltip title={rowData.interests.join(", ")}>
                  <div>
                    {rowData?.interests?.slice(0, 1).map((val) => (
                      <Chip
                        label={val}
                        href="#basic-chip"
                        key={val}
                        style={{ margin: "0px", fontSize: "12px", height: "22px" }}
                      />
                    ))}
                  </div>
                </Tooltip>
              ),
            editable: "never",
            cellStyle: {
              width: 90,
              maxWidth: 90,
            },
          },
        ]
      : []),
  ];

  const tagsCol = getTagsColumns(currentType);
  const subTagsCol = getSubTagsColumns(currentType);

  // Handle row click on main table
  const onTagRowClick = (event, rowData) => {
    setSelectedTag(rowData);
    setSelectedTag2(null);
  };

  const onTagRowClick2 = (event, rowData) => {
    setSelectedTag2(rowData);
  };

  useEffect(() => {
    if (firstRenderRef.current) {
      // Skip calling onQueryChange on the *very first* render
      firstRenderRef.current = false;
    } else if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [currentType, added]);

  return (
    <Grid container spacing={2}>
      <Grid item size={{ xs: 4 }}>
        <MaterialTable
          tableRef={tableRef}
          title="Tags"
          columns={tagsCol}
          data={(query) =>
            new Promise((resolve) => {
              let url;
              url = `${process.env.REACT_APP_API}/tags?`;
              if (
                currentType === "RECOMMENDATIONS" ||
                currentType === "TICKETS" ||
                currentType === "JOBS"
              ) {
                url += `type=${currentType}`;
              } else if (currentType === "BOTH") {
                url += `type=BOTH`;
              }
              url += `&limit=${query.pageSize}`;
              url += `&page=${query.page + 1}`;
              if (query.search.length > 0) url += `&title=${encodeURIComponent(query.search)}`;
              if (query.orderBy) {
                url += `&sort=${query.orderBy?.field}`;
                url += `&dir=${query.orderDirection}`;
              } else {
                url += "&sort=createdAt";
                url += "&dir=desc";
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result?.total,
                  });
                });
              // eslint-disable-next-line no-console
            }).catch((error) => console.log(error.message))
          }
          onRowClick={onTagRowClick}
          components={{
            Pagination: CustomPagination,
          }}
          options={{
            padding: "dense",
            headerStyle: {
              fontSize: "12px",
            },
            cellStyle: {
              fontSize: "12px",
            },
            actionsColumnIndex: -1,
            pageSize: 10,
            rowStyle: (rowData) => ({
              backgroundColor: selectedTag && selectedTag._id === rowData._id ? "#EEE" : "#FFF",
            }),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Tag",
              onClick: (event, rowData) => {
                dispatch(getOneTag(rowData?._id));
                handleChange(event, rowData);
              },
            },
          ]}
          editable={{
            onRowDelete: (oldData) => dispatch(deleteTag(oldData._id)),
          }}
        />
      </Grid>
      <Grid item size={{ xs: 4 }}>
        {selectedTag && (
          <MaterialTable
            key={selectedTag?._id}
            onRowClick={onTagRowClick2}
            title={
              <div
                style={{
                  wordWrap: "break-word",
                  maxWidth: "300px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >{`Subtags for "${selectedTag.title}"`}</div>
            }
            columns={subTagsCol}
            components={{
              Pagination: CustomPagination,
            }}
            editable={{
              onRowDelete: (oldData) => dispatch(deleteTag(oldData._id)),
            }}
            data={(query) =>
              new Promise((resolve) => {
                let url;
                url = `${process.env.REACT_APP_API}/tags?`;
                url += `parentTagIds=${selectedTag?._id}&`;
                url += `limit=${query.pageSize}`;
                url += `&page=${query.page + 1}`;
                if (query.search.length > 0) url += `&title=${encodeURIComponent(query.search)}`;
                if (query.orderBy) {
                  url += `&sort=${query.orderBy?.field}`;
                  url += `&dir=${query.orderDirection}`;
                } else {
                  url += "&sort=createdAt";
                  url += "&dir=desc";
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result?.total,
                    });
                  });
                // eslint-disable-next-line no-console
              }).catch((error) => console.log(error.message))
            }
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Tag",
                onClick: (event, rowData) => {
                  dispatch(getOneTag(rowData?._id));
                  handleChange(event, rowData);
                  // setRowSelected(rowData);
                  // setDialogOpen(true);
                  // onRowSelect(rowData);
                },
              },
            ]}
            options={{
              padding: "dense",
              headerStyle: {
                fontSize: "12px",
              },
              cellStyle: {
                fontSize: "12px",
              },
              actionsColumnIndex: -1,
              pageSize: 10,
              rowStyle: (rowData) => ({
                backgroundColor: selectedTag2 && selectedTag2._id === rowData._id ? "#EEE" : "#FFF",
              }),
            }}
          />
        )}
      </Grid>
      <Grid item size={{ xs: 4 }}>
        {selectedTag2 && (
          <MaterialTable
            key={selectedTag2?._id}
            // title={`Subtags for "${selectedTag.title}"`}
            title={
              <div
                style={{
                  wordWrap: "break-word",
                  maxWidth: "300px",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >{`Subtags for "${selectedTag2.title}"`}</div>
            }
            columns={subTagsCol}
            components={{
              Pagination: CustomPagination,
            }}
            editable={{
              onRowDelete: (oldData) => dispatch(deleteTag(oldData._id)),
            }}
            data={(query) =>
              new Promise((resolve) => {
                let url;
                url = `${process.env.REACT_APP_API}/tags?`;
                url += `parentTagIds=${selectedTag2?._id}&`;
                url += `limit=${query.pageSize}`;
                url += `&page=${query.page + 1}`;
                if (query.search.length > 0) url += `&title=${encodeURIComponent(query.search)}`;
                if (query.orderBy) {
                  url += `&sort=${query.orderBy?.field}`;
                  url += `&dir=${query.orderDirection}`;
                } else {
                  url += "&sort=createdAt";
                  url += "&dir=desc";
                }
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result?.total,
                    });
                  });
                // eslint-disable-next-line no-console
              }).catch((error) => console.log(error.message))
            }
            actions={[
              {
                icon: "edit",
                tooltip: "Edit Tag",
                onClick: (event, rowData) => {
                  dispatch(getOneTag(rowData?._id));
                  handleChange(event, rowData);
                  // setRowSelected(rowData);
                  // setDialogOpen(true);
                  // onRowSelect(rowData);
                },
              },
            ]}
            options={{
              padding: "dense",
              headerStyle: {
                fontSize: "12px",
              },
              cellStyle: {
                fontSize: "12px",
              },
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

TagAndSubtagTables.propTypes = {
  currentType: PropTypes.string,
  // setDialogOpen: PropTypes.func,
  // onRowSelect: PropTypes.func,
  handleChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  setSelectedTag: PropTypes.func,
  selectedTag2: PropTypes.instanceOf(Object),
  setSelectedTag2: PropTypes.func,
};

export default TagAndSubtagTables;
