/* eslint-disable no-unused-expressions */
import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButtonLoading from "components/MDButtonLoading";
import Visibility from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

// Authentication layout components
import IllustrationLayout from "pages/authentication/components/IllustrationLayout";
import Footer from "components/Footer";

// Image
import bgImage from "assets/images/illustrations/4-Sign-in.jpg";

// Redux
import setAlert from "store/actions/alertActions";
import { setLoading, clearErrors, loginUser, checkUser } from "store/actions/userActions";
import MDButton from "components/MDButton";

const Illustration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [formData, setFormData] = useState({ email: "", password: "", rememberMe: "NO" });
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const loading = useSelector((state) => state.users.loading);
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.users.error);
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordFn = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);
  useEffect(() => {
    if (!user && !isAuthenticated) {
      dispatch(setLoading());
      dispatch(checkUser());
    }
  }, []);

  useEffect(() => {
    if (rememberMe) {
      setFormData({ ...formData, rememberMe: "YES" });
    } else {
      setFormData({ ...formData, rememberMe: "NO" });
    }
  }, [rememberMe]);

  const handleLogin = () => {
    dispatch(setLoading());
    dispatch(loginUser(formData));
  };

  useEffect(() => {
    if (isLoggedIn) {
      user.role === "partner" && user?.partnerPower
        ? navigate("/partnerPower/dashboards")
        : navigate(`/${user.role}/dashboards`);
    }
  }, [isLoggedIn]);

  return (
    <IllustrationLayout title="Sign In" description="Welcome to Lokalee" illustration={bgImage}>
      <MDBox
        component="form"
        role="form"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            handleLogin();
          }
        }}
      >
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            onChange={(event) => setFormData({ ...formData, email: event.target.value })}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type={showPassword ? "text" : "password"}
            label="Password"
            fullWidth
            onChange={(event) => setFormData({ ...formData, password: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MDButton onClick={showPasswordFn}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </MDButton>
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButtonLoading
            loading={loading}
            loadingPosition="start"
            // eslint-disable-next-line react/jsx-no-useless-fragment
            startIcon={<></>}
            variant="gradient"
            color="primary"
            fullWidth
            onClick={handleLogin}
            size="large"
          >
            sign in
          </MDButtonLoading>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Forgot your password?{" "}
            <MDTypography
              component={Link}
              to="/authentication/reset-password"
              variant="button"
              color="primary"
              fontWeight="medium"
              textGradient
            >
              Recover
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <Footer isSignIn />
    </IllustrationLayout>
  );
};

export default Illustration;
