import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const BannerImageDisplay = ({ desktopImage, mobileImage = null, position, showLabels = true }) => {
  // Only show mobile image if position is 'leaderboard'
  const showMobileImage = position === "leaderboard";

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={showMobileImage ? 6 : 12}>
          {showLabels && position === "leaderboard" ? (
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Desktop Image:
            </MDTypography>
          ) : (
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Image:
            </MDTypography>
          )}
          <MDBox mt={1} mb={2} width="100%">
            {desktopImage && (
              <img
                src={
                  typeof desktopImage === "string"
                    ? desktopImage
                    : URL.createObjectURL(desktopImage)
                }
                alt="Desktop Banner"
                style={{
                  width: "100%",
                  maxHeight: "164px",
                  objectFit: "cover",
                }}
              />
            )}
          </MDBox>
        </Grid>
        {showMobileImage && (
          <Grid item xs={12} md={6}>
            {showLabels && (
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                Mobile Image:
              </MDTypography>
            )}
            <MDBox mt={1} mb={2} width="100%">
              {mobileImage && (
                <img
                  src={
                    typeof mobileImage === "string" ? mobileImage : URL.createObjectURL(mobileImage)
                  }
                  alt="Mobile Banner"
                  style={{
                    width: "100%",
                    maxHeight: "164px",
                    objectFit: "cover",
                  }}
                />
              )}
            </MDBox>
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
};

BannerImageDisplay.propTypes = {
  desktopImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  mobileImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  position: PropTypes.string.isRequired,
  showLabels: PropTypes.bool,
};

export default BannerImageDisplay;
