/* eslint-disable no-nested-ternary */

// Libraries
import { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

// Redux Actions

import {
  addTicketStep0,
  addTicketStep1,
  addTicketStep2,
  addTicketStep3,
  addTicketStep4,
  clearErrors,
} from "store/actions/itemAction";
import setAlert from "store/actions/alertActions";
import { clearErrors as clearCityErrors } from "store/actions/cityActions";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import * as Yup from "yup";
// Schema
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import BookingAndTickets from "./components/BookingAndTickets";
import Media from "./components/Media";
import ScheduleAndPricingInfo from "./components/SchedulesAndPricingInfo";
import Content from "./components/Content";
import TicketInfo from "./components/TicketInfo";

function getSteps() {
  return ["Ticket Info", "Content", "Media", "Schedules & Pricing", "Booking & Tickets"];
}

function getStepContent(
  stepIndex,
  formData,

  availableDateList,
  setAvailableDateList,
  price,
  setPrice,
  date,
  setDate,
  add,
  setAdd,
  handleTagChange,
  selectedTag,
  selectedVenue,
  setSelectedVenue,
  handleVenueChange,
  selectedCurator,
  handleCuratorChange,
  handlePinChange,
  selectedPin,
  setSelectedPin,
  handleCancellationPolicyChange,
  selectedCancellationPolicy,
  selectedPickupPoint,
  setSelectedPickupPoint,
  handlePickupPointChange,
  selectedActivity,
  handleActivityChange,
  selectedTimeAct,
  handleTimeActChange,
  selectedBookingConfirmation,
  handleBookingConfirmationChange,
  selectedCurrency,
  setSelectedCurrency,
  handleCurrencyChange,
  selectedTitleInc,
  handleTitleIncChange,
  selectedTitleExc,
  handleTitleExcChange,
  setSelectedTitleInc,
  setSelectedTitleExc,
  selectedLocation,
  handleLocationChange,
  handlePrimaryDestinationChange,
  selectedPrimaryDestination,
  handleSecondaryDestinationChange,
  selectedSecondaryDestination,
  setSelectedPrimaryDestination,
  setSelectedCurator,
  setAddSchedule,
  addSchedule,
  startDateProm,
  endDateProm,
  setStartDateProm,
  setEndDateProm
) {
  switch (stepIndex) {
    case 0:
      return (
        <TicketInfo
          formData={formData}
          selectedTag={selectedTag}
          handleTagChange={handleTagChange}
          selectedCurator={selectedCurator}
          setSelectedCurator={setSelectedCurator}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          handleCuratorChange={handleCuratorChange}
          handlePrimaryDestinationChange={handlePrimaryDestinationChange}
          selectedPrimaryDestination={selectedPrimaryDestination}
          handleSecondaryDestinationChange={handleSecondaryDestinationChange}
          selectedSecondaryDestination={selectedSecondaryDestination}
          setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        />
      );
    case 1:
      return (
        <Content
          formData={formData}
          selectedVenue={selectedVenue}
          setSelectedVenue={setSelectedVenue}
          handleVenueChange={handleVenueChange}
          selectedPin={selectedPin}
          setSelectedPin={setSelectedPin}
          handlePinChange={handlePinChange}
          selectedPickupPoint={selectedPickupPoint}
          setSelectedPickupPoint={setSelectedPickupPoint}
          handlePickupPointChange={handlePickupPointChange}
          selectedTitleInc={selectedTitleInc}
          handleTitleIncChange={handleTitleIncChange}
          selectedTitleExc={selectedTitleExc}
          handleTitleExcChange={handleTitleExcChange}
          setSelectedTitleInc={setSelectedTitleInc}
          setSelectedTitleExc={setSelectedTitleExc}
        />
      );
    case 2:
      return (
        <Media
          formData={formData}
          availableDateList={availableDateList}
          setAvailableDateList={setAvailableDateList}
          price={price}
          setPrice={setPrice}
          // netPriceVal={netPriceVal}
          // timeFrom={timeFrom}
          date={date}
          setDate={setDate}
          // timeTo={timeTo}
          add={add}
          setAdd={setAdd}
        />
      );
    case 3:
      return (
        <ScheduleAndPricingInfo
          formData={formData}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
          handleCurrencyChange={handleCurrencyChange}
          addSchedule={addSchedule}
          setAddSchedule={setAddSchedule}
          setStartDateProm={setStartDateProm}
          setEndDateProm={setEndDateProm}
          startDateProm={startDateProm}
          endDateProm={endDateProm}
        />
      );
    case 4:
      return (
        <BookingAndTickets
          formData={formData}
          selectedActivity={selectedActivity}
          handleActivityChange={handleActivityChange}
          handleCancellationPolicyChange={handleCancellationPolicyChange}
          selectedCancellationPolicy={selectedCancellationPolicy}
          handleTimeActChange={handleTimeActChange}
          selectedTimeAct={selectedTimeAct}
          handleBookingConfirmationChange={handleBookingConfirmationChange}
          selectedBookingConfirmation={selectedBookingConfirmation}
        />
      );

    default:
      return null;
  }
}

const NewTicket = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const sourceId = searchParams.get("sourceId");

  // selector
  const error = useSelector((state) => state.recommendations.error);
  const user = useSelector((state) => state.users.user);

  const ticketStep0 = useSelector((state) => state.items.itemStep0);

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    user?.role === "partner" && user?.partnerPower ? user?.locations[0] : null
  );
  // const [availabilityType, setAvailabilityType] = useState("");
  const [date, setDate] = useState(null);
  // const [timeFrom, setTimeFrom] = useState(null);
  // const [timeTo, setTimeTo] = useState(null);
  const [price, setPrice] = useState(null);
  // const [netPriceVal, setNetPriceVal] = useState(null);
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [addSchedule, setAddSchedule] = useState(false);
  const [startDateProm, setStartDateProm] = useState("");
  const [endDateProm, setEndDateProm] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedVenue, setSelectedVenue] = useState({});
  const [selectedPin, setSelectedPin] = useState({});
  const [selectedPickupPoint, setSelectedPickupPoint] = useState({});
  const [selectedCurator, setSelectedCurator] = useState({});
  const [selectedTitleInc, setSelectedTitleInc] = useState({});
  const [selectedTitleExc, setSelectedTitleExc] = useState({});
  const [selectedTimeAct, setSelectedTimeAct] = useState({ value: "12:00", label: "12:00" });
  const [selectedActivity, setSelectedActivity] = useState({
    name: "On day of Activity",
    value: 0,
  });
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedBookingConfirmation, setSelectedBookingConfirmation] = useState({
    name: "Instant confirmation",
    value: "INSTANT",
  });
  const [selectedCancellationPolicy, setSelectedCancellationPolicy] = useState({});
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);

  // const slots = [];
  // const [availableDate, setAvailableDate] = useState({
  //   date: "",
  //   slots: [{ timeFrom: {}, timeTo: {}, price: "", net_price: "" }],
  //   option: "",
  //   maxQty: "",
  //   minQty: "",
  // });
  const [availableDateList, setAvailableDateList] = useState([]);

  const { formId, formField } = form;

  const emailSchemaValidation = Yup.object().shape({
    provider: Yup.object().shape({
      email: Yup.string()
        .email("Your provider email address is invalid!")
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "Your email address is invalid!"),
    }),
  });

  const currentValidation =
    activeStep === 1
      ? user.role === "admin" && validations[1]
      : activeStep === 0
      ? validations[0].concat(emailSchemaValidation)
      : validations[activeStep];

  const isLastStep = activeStep === steps.length - 1;
  const [formRefVal, setFormRefVal] = useState(null);

  useEffect(() => {
    if (formRefVal?.city) {
      dispatch(clearCityErrors());
    }
  }, [formRefVal, formRefVal?.city]);

  useEffect(() => {
    if (ticketStep0 !== null) {
      setSelectedTag(ticketStep0?.tags);
      setSelectedLocation(ticketStep0?.location);
    }
  }, [ticketStep0]);
  const handleNavigation = () => {
    let url = null;
    if (user.role === "partner" && user?.partnerPower) {
      url = `/partnerPower/tickets?locationId=${selectedLocation?._id}`;
      navigate(url);
    } else if (user?.role === "admin") {
      url = source === "users" ? `/admin/users/${sourceId}` : "/admin/tickets";
    } else if (user?.role === "partner" && !user?.partnerPower) {
      url = `/partner/tickets?locationId=${Location}`;
      navigate(url);
    }
  };

  const handleTitleIncChange = (val) => {
    if (val) {
      setSelectedTitleInc(val);
    }
  };

  const handleTitleExcChange = (val) => {
    if (val) {
      setSelectedTitleExc(val);
    }
  };

  const handlePickupPointChange = (val) => {
    if (val) {
      setSelectedPickupPoint(val);
    }
  };

  const handlePrimaryDestinationChange = (value) => {
    if (value) {
      setSelectedPrimaryDestination(value);
    } else {
      setSelectedPrimaryDestination({});
    }
  };
  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };

  const handleLocationChange = useCallback(
    (loc) => {
      setSelectedLocation(loc || {});
    },
    [setSelectedLocation]
  );

  const handleCurrencyChange = (val) => {
    if (val) {
      setSelectedCurrency(val);
    } else setSelectedCurrency(null);
  };

  const handleActivityChange = (val) => {
    if (val) {
      setSelectedActivity(val);
    }
  };

  const handleBookingConfirmationChange = (val) => {
    if (val) {
      setSelectedBookingConfirmation(val);
    }
  };

  const handleTimeActChange = (val) => {
    if (val) {
      setSelectedTimeAct(val);
    }
  };

  const handleCuratorChange = (val) => {
    if (val) {
      setSelectedCurator(val);
    }
  };

  const handleCancellationPolicyChange = (val) => {
    if (val) {
      setSelectedCancellationPolicy(val);
    }
  };

  const handleVenueChange = (val) => {
    if (val) {
      setSelectedVenue(val);
    } else setSelectedVenue(null);
  };

  const handlePinChange = (val) => {
    if (val) {
      setSelectedPin(val);
    } else setSelectedPin(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
  };
  const submitForm = async (values, actions) => {
    await sleep(1000);

    const updatedValues = { ...values };

    // if (updatedValues && updatedValues?.logo) {
    //   delete updatedValues?.logo;
    // }
    if (updatedValues && updatedValues?.location && updatedValues?.location?._id) {
      updatedValues.location = updatedValues?.location?._id;
    }
    if (updatedValues) {
      updatedValues.approvalStatus = user?.role === "partner" ? "pending" : "approved";
    }

    if (activeStep === 0) {
      if (ticketStep0?._id?.length > 0) {
        dispatch(addTicketStep0(updatedValues, ticketStep0?._id));
      } else dispatch(addTicketStep0(updatedValues));
    }

    if (activeStep === 1 && ticketStep0?._id?.length > 0)
      dispatch(addTicketStep1(updatedValues, ticketStep0._id));

    if (activeStep === 2 && ticketStep0?._id?.length > 0)
      dispatch(addTicketStep2(updatedValues, ticketStep0._id));

    if (activeStep === 3 && ticketStep0?._id?.length > 0)
      dispatch(addTicketStep3(updatedValues, ticketStep0._id));

    if (activeStep === 4 && ticketStep0?._id?.length > 0)
      dispatch(addTicketStep4(updatedValues, ticketStep0._id));

    // eslint-disable-next-line no-alert
    // dispatch(addTicketStep0(updatedValues));
    actions.setSubmitting(false);
    // actions.resetForm();

    setActiveStep(activeStep + 1);
  };
  const handleSubmit = (values, actions) => {
    // if (isLastStep) {
    submitForm(values, actions);
    // setActiveStep(activeStep + 1);

    if (isLastStep) {
      handleNavigation();
      actions.resetForm();
    }
    // } else {
    setActiveStep(activeStep + 1);
    actions.setTouched({});
    actions.setSubmitting(false);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
    // }
  };

  const handleTagChange = (value) => {
    if (value && value?.value) {
      setSelectedTag(value);
    } else {
      setSelectedTag({});
    }
  };

  // useEffect
  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  const handleStep = (step) => {
    // If on the first step, do not allow clicking to change steps
    if (activeStep === 0) return;

    // Allow navigation if it's not the first step
    setActiveStep(step);
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert, clearErrors]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              innerRef={(f) => {
                setFormRefVal(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, dirty, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                          <Step
                            key={label}
                            onClick={() => {
                              if (dirty) {
                                setOpen(true);
                                setSelectedIndex(index);
                              } else {
                                handleStep(index);
                              }
                            }}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          availableDateList,
                          setAvailableDateList,
                          price,
                          setPrice,
                          date,
                          setDate,
                          add,
                          setAdd,
                          handleTagChange,
                          selectedTag,
                          selectedVenue,
                          setSelectedVenue,
                          handleVenueChange,
                          selectedCurator,
                          handleCuratorChange,
                          handlePinChange,
                          selectedPin,
                          setSelectedPin,
                          handleCancellationPolicyChange,
                          selectedCancellationPolicy,
                          selectedPickupPoint,
                          setSelectedPickupPoint,
                          handlePickupPointChange,
                          selectedActivity,
                          handleActivityChange,
                          selectedTimeAct,
                          handleTimeActChange,
                          selectedBookingConfirmation,
                          handleBookingConfirmationChange,
                          selectedCurrency,
                          setSelectedCurrency,
                          handleCurrencyChange,
                          selectedTitleInc,
                          handleTitleIncChange,
                          selectedTitleExc,
                          handleTitleExcChange,
                          setSelectedTitleInc,
                          setSelectedTitleExc,
                          selectedLocation,
                          handleLocationChange,
                          handlePrimaryDestinationChange,
                          selectedPrimaryDestination,
                          handleSecondaryDestinationChange,
                          selectedSecondaryDestination,
                          setSelectedPrimaryDestination,
                          setSelectedCurator,
                          setAddSchedule,
                          addSchedule,
                          startDateProm,
                          endDateProm,
                          setStartDateProm,
                          setEndDateProm
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => {
                                if (user?.role === "partner" && user?.partnerPower) {
                                  navigate(
                                    `/partnerPower/tickets?locationId=${selectedLocation?._id}`
                                  );
                                } else
                                  navigate(
                                    source === "users"
                                      ? `/admin/users/${sourceId}`
                                      : "/admin/tickets"
                                  );
                              }}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "Save" : "Save & Continue"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Unsaved Fields </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you don&apos;t want to save the changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => {
                  handleStep(selectedIndex);
                  handleClose();
                }}
              >
                Yes
              </MDButton>
              <MDButton onClick={handleClose} autoFocus>
                No
              </MDButton>
            </DialogActions>
          </Dialog>
        </div>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewTicket;
