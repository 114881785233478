import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const SubTagPicker = ({
  handleChange = () => {},
  subTag,
  setFieldValue = () => {},
  selectedTag = {},
  selectedSubTag = {},
  isClearable = false,
  ticketPage = false,
  isJobType = false,
}) => {
  // Maps each tag object from the API into { label, value }
  const filterRec = (data) =>
    data.map((tagData) => ({
      label: tagData.title,
      value: tagData._id,
    }));

  // Loads the subtag options (filtered by the selectedTag)
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tags?`;
      // Use selectedTag.value or selectedTag._id
      if (selectedTag?.value || selectedTag?._id) {
        url += `parentTagIds=${selectedTag.value || selectedTag._id}&`;
      }
      if (isJobType) {
        url += `type=JOBS&`;
      }
      if (inputValue) {
        url += `title=${inputValue}&`;
      }
      url += "sort=title&dir=asc";

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {subTag.label}
      </Typography>
      <AsyncSelect
        key={selectedTag?.value || selectedTag?._id || "subTagPicker"}
        cacheOptions
        defaultOptions
        isClearable={isClearable}
        menuPosition="fixed"
        loadOptions={promiseOptions}
        value={selectedSubTag?.value ? selectedSubTag : null}
        placeholder={selectedSubTag?.label || `Search for a ${subTag.label}`}
        onChange={(option, { action }) => {
          if (option) {
            // A new subTag is chosen
            handleChange(option);
            setFieldValue("subTag", option);
            if (ticketPage) {
              // Clear subTag again if needed by ticket workflow
              setFieldValue("subTag", {});
            }
          }
          if (action === "clear") {
            handleChange(null);
            setFieldValue("subTag", {});
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            overflow: "unset",
          }),
        }}
      />
    </div>
  );
};

SubTagPicker.propTypes = {
  subTag: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  ticketPage: PropTypes.bool,
  selectedTag: PropTypes.instanceOf(Object),
  selectedSubTag: PropTypes.instanceOf(Object),
  isJobType: PropTypes.bool,
};

export default SubTagPicker;
