import React from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";

const AvailabilityExclusionPanel = ({ setFieldValue, availabilities = [] }) => {
  const deleteAvailability = (i) => {
    const arr = availabilities;
    arr.splice(i, 1);
    setFieldValue("exclusions", arr);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        marginBottom: "0px",
      }}
    >
      {availabilities?.map((row, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={row?.description ? <ExpandMoreIcon /> : null}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container alignItems="center">
              <Grid item size={{ xs: "auto" }}>
                <Typography variant="body1" style={{ wordBreak: "break-word", maxWidth: "50ch" }}>
                  {row?.type?.value === "OTHER"
                    ? "Other"
                    : row?.type?.label || row?.typeDescription}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  edge="end"
                  onClick={() => {
                    deleteAvailability(index);
                  }}
                >
                  <DeleteIcon sx={{ color: "grey" }} />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          {row?.description && (
            <AccordionDetails>
              <Typography component="span" variant="body2">
                {row?.description}
              </Typography>
            </AccordionDetails>
          )}
        </Accordion>
      ))}
    </Box>
  );
};

AvailabilityExclusionPanel.propTypes = {
  availabilities: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
};

export default AvailabilityExclusionPanel;
