/* eslint-disable func-names */

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    title,
    provider,
    tag,
    curator,
    overview,
    destinations,
    primaryDestination,
    minTravelersPerBooking,
    maxTravelersPerBooking,
    // badge,
    url,
    images,
    ageBands,
    endDate,
    availabilitiesAdded,
    itineraryItems,
    // pricesObject,
    defaultCurrencyCode,
    // optionName,
    ageBandsObject,
    cutoff_days,
    cutoff_time,
    // tktDate,
  },
} = checkout;

const validations = [
  // validation for Ticket Info page
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [provider.name]: Yup.object().shape({
      email: Yup.string()
        .email("Your provider email address is invalid!")
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "Your email address is invalid!"),
    }),
    [tag.name]: Yup.object().required(tag.errorMsg),
    [overview.name]: Yup.string().required(overview.errorMsg),

    [curator.name]: Yup.mixed()
      .nullable()
      .test({
        name: "curator-required",
        message: curator?.errorMsg || "Curator is required",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          if (this.parent.curatorOrProv === "curator") {
            return value && Object.keys(value).length > 0; // Validate only if `curator` is an object with properties
          }
          return true; // Skip validation if `curatorOrProv` is not "curator"
        },
      }),

    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
    [primaryDestination.name]: Yup.string().required(primaryDestination.errorMsg),
  }),
  // validation for content page
  Yup.object().shape({
    [itineraryItems.name]: Yup.array().when("containStops", {
      is: (containStops) => containStops === true,
      then: (schema) =>
        schema
          .min(1, "At least 1 stop should be added!")
          .required("At least 1 stop should be added"),
    }),
    [minTravelersPerBooking.name]: Yup.number().notOneOf([0], minTravelersPerBooking.errorMsg),
    [maxTravelersPerBooking.name]: Yup.number().notOneOf([0], maxTravelersPerBooking.errorMsg),
    // [badge.name]: Yup.array().min(1, badge?.errorMsg).required(badge?.errorMsg),
  }),

  // validation for media page
  Yup.object().shape({
    [images.name]: Yup.array().min(1, images.errorMsg).required(),
  }),

  // validation for schedules and Price page
  Yup.object().shape({
    [ageBands.name]: Yup.array()
      .of(Yup.string())
      .test(
        "at-least-one-adult-or-traveler",
        "At least one age band (adult or traveler) must be selected!",
        (value) => value.includes("ADULT") || value.includes("TRAVELER")
      )
      .required("Age bands are required"),
    // [startDate.name]: Yup.string().required(startDate.errorMsg),
    // [days.name]: Yup.array().min(1, days?.errorMsg).required(days?.errorMsg),
    // [times.name]: Yup.array().min(1, times?.errorMsg).required(times?.errorMsg),
    [defaultCurrencyCode.name]: Yup.object().required(defaultCurrencyCode.errorMsg),
    // [defaultCurrencyCode.name]: Yup.object().required("Currency is required!"),
    // [optionName.name]: Yup.string().required(optionName.errorMsg),
    [endDate.name]: Yup.string().test({
      name: "greaterThanStartDate",
      exclusive: false,
      message: "End date must be greater than or equal to start date!",
      test(val) {
        const startDat = this.resolve(Yup.ref("startDate"));
        return !startDat || !val || startDat <= val;
      },
    }),
    // [optionName.name]: Yup.string().when("scheduleOrOption", {
    //   is: "option",
    //   then: () => Yup.string().required(optionName.errorMsg),
    //   otherwise: () => Yup.string(),
    // }),

    [ageBandsObject.name]: Yup.array()
      .of(
        Yup.object().shape({
          ageBand: Yup.string().required("Age band is required"),
          startAge: Yup.number().nullable(),
          endAge: Yup.number().nullable(),
        })
      )
      .test("start-end-age-required", "Both min age and max age are required!", (value) =>
        value?.every((obj) => obj.startAge !== undefined && obj.endAge !== undefined)
      )
      .test("length-match", "Both min age and max age are required!", function (value) {
        const { parent } = this; // Access the parent context
        return value?.length === parent.ageBands?.length;
      })
      .when("ageBands", {
        is: (ageBand) => ageBand.length > 0,
        then: (schema) =>
          schema
            .min(1, "Both min age and max age are required!")
            .required("Both min age and max age are required!"),
      }),
    // .test(
    //   "ageBand-match",
    //   "Each ageBand in ageBandsObject must match an ageBand in ageBands",
    //   function (value) {
    //     const { ageBands: ageBandsList } = this.parent;
    //     if (!ageBandsList) {
    //       return true; // Skip validation if ageBands is not defined
    //     }
    //     return value.every((obj) => ageBandsList.includes(obj.ageBand));
    //   }
    // ),

    [availabilitiesAdded.name]: Yup.array()
      .min(1, availabilitiesAdded?.invalidMsg)
      .required(availabilitiesAdded?.invalidMsg),

    /// // [pricesObject.name]: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       ageBand: Yup.string().required("Age band is required"),
    //       minTravelers: Yup.number().nullable(),
    //       netPrice: Yup.number().nullable(),
    //       maxTravelers: Yup.number().nullable(),
    //       retailPrice: Yup.number().nullable(),
    //     })
    //   )
    //   .test("max-min-required", "All fields are required!", (value) =>
    //     // const { addSchedule } = context.parent;
    //     // if (!addSchedule) return true; // Skip validation if addSchedule is false

    //     value.every(
    //       (obj) =>
    //         (obj.maxTravelers !== undefined || "") &&
    //         (obj.minTravelers !== undefined || "") &&
    //         (obj.netPrice !== undefined || "") &&
    //         (obj.ageBand !== undefined || "") &&
    //         (obj.retailPrice !== undefined || "")
    //     )
    //   )
    //   .test(
    //     "prices-match",
    //     "Each prices in pricesObject must match an price in prices",
    //     function (value) {
    //       const { prices: pricesList } = this.parent;
    //       if (!pricesList) {
    //         return true; // Skip validation if ageBands is not defined
    //       }
    //       return value.every((obj) => pricesList.includes(obj.ageBand));
    //     }
    //   ),
  }),
  // validations for booking and tickets Page
  Yup.object().shape({
    [url.name]: Yup.string().matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      url.invalidMsg
    ),
    [cutoff_days.name]: Yup.number().positive(cutoff_days.invalidMsg),
    [cutoff_time.name]: Yup.number().positive(cutoff_time.invalidMsg),
  }),
];

export default validations;
