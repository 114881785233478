import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, code, locations, tickets, value, destinations, startDate, endDate },
} = checkout;

const validations = [
  Yup.object().shape({
    [title?.name]: Yup.string()
      .matches(/([A-Za-z])/, title?.invalidMsg)
      .required(title?.errorMsg),
    [code?.name]: Yup.string().when("method", {
      is: "code",
      then: () => Yup.string().required(code?.errorMsg),
    }),
    [locations?.name]: Yup.array().when("eligibility", {
      is: "criteria",
      then: () => Yup.array().min(1, locations?.errorMsg).required(locations?.errorMsg),
    }),

    [value?.name]: Yup.string().when("type", {
      is: "percentage",
      then: () =>
        Yup.string()
          .test("isPercentage", "Value must be a percentage between 0 and 100", (valueV) => {
            if (!valueV) return true; // Allow empty values
            const percentageValue = parseFloat(valueV);
            return percentageValue >= 0 && percentageValue <= 100;
          })
          .required(value?.errorMsg1),
      otherwise: () => Yup.string().required(value?.errorMsg2),
    }),
    [destinations?.name]: Yup.array().when("appliesTo", {
      is: "criteria",
      then: () => Yup.array().min(1, destinations?.errorMsg).required(destinations?.errorMsg),
    }),
    [tickets?.name]: Yup.array().when("appliesTo", {
      is: "tickets",
      then: () => Yup.array().min(1, tickets?.errorMsg).required(tickets?.errorMsg),
    }),
    [startDate.name]: Yup.string().required(startDate.errorMsg),
    [endDate.name]: Yup.string()
      .required(endDate?.errorMsg)
      .test({
        name: "greaterThanStartDate",
        exclusive: false,
        message: "End date must be greater than or equal to start date",
        test(val) {
          const startDat = this.resolve(Yup.ref("startDate"));
          return !startDat || !val || startDat <= val;
        },
      }),
  }),
];

export default validations;
