/* eslint-disable no-param-reassign */

/* eslint-disable no-undef */
import axios from "axios";
import {
  MENU_FAIL,
  UPDATE_MENU,
  RESET_MENUS,
  CLEAR_ERRORS,
  SET_MENU_LOADING,
  MENU_ADD_SUCCESS,
  GET_UPDATED_MENU_TYPE,
  LOCATIONS_MENU_ADD_SUCCESS,
  DESTINATIONS_MENU_ADD_SUCCESS,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

/* *** Fetching Menus *** */
export const getOneMenu = (id) => factory.get(`${URL}/menus/${id}`, "GET_ONE_MENU", "MENU_FAIL");
export const getGlobalMenus = () =>
  factory.get(`${URL}/menus?type=global`, "GET_GLOBAL_MENUS", "GLOBAL_MENU_FAIL");
export const getDestinationMenus = (destinations) =>
  factory.get(
    `${URL}/menus?type=destinations&destinations=${destinations}`,
    "GET_DESTINATIONS_MENUS",
    "MENU_FAIL"
  );
export const getLocationMenus = (locations) =>
  factory.get(
    `${URL}/menus?type=location&locations=${locations}`,
    "GET_LOCATION_MENUS",
    "MENU_FAIL"
  );

/* *** Handling Menu *** */
export const resetMenus = () => ({ type: RESET_MENUS });
export const deleteMenu = (id) =>
  factory.patch({ active: false }, `${URL}/menus/${id}`, "DELETE_MENU", "MENU_FAIL");
export const sortMenu = (boxes, location) =>
  factory.post(
    { boxes },
    `${URL}/menus/order${location ? `?locations=${location}` : ""}`,
    "SORT_MENU",
    "MENU_FAIL"
  );
export const addMenu = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();
  if (data.title) {
    data.title = data.title.trim();
  }
  form.append("title", data.title);
  form.append("type", data.type);
  form.append("option", data.option);
  form.append("url", data.url);

  if (data.destinations) {
    data.destinations.map((destination) => {
      form.append("destinations", destination?.value);
      return destination?.value;
    });
  } else {
    form.append("destinations", []);
  }

  if (data.locations) {
    data.locations.map((location) => {
      form.append("locations", location?._id);
      return location?._id;
    });
  } else {
    form.append("locations", []);
  }
  if (data.tags?.length > 0) {
    data.tags.map((tag) => {
      form.append("tags", tag?.value);
      return tag?.value;
    });
  } else {
    form.append("tags", []);
  }
  if (data.tickets?.length > 0 && data.filterBy === "tickets") {
    data.tickets.map((ticket) => {
      form.append("tickets", ticket?.value);
      return ticket?.value;
    });
  } else {
    form.append("tickets", []);
  }

  if (data.filters) {
    data.filters.map((filter) => {
      form.append("filters", filter);
      return filter;
    });
  }
  if (data.top !== undefined) form.append("top", data.top);
  if (data.hidden !== undefined) form.append("hidden", data.hidden);
  if (data.expandable !== undefined) form.append("expandable", data.expandable);

  if (image) {
    form.append("hasLogo", true);
    form.append("images", image);
  }

  if (data.images) {
    form.append("hasIcon", true);
    form.append("images", data.images);
  }
  try {
    const res = await axios.post(`${URL}/menus`, form, config);

    let actionType = MENU_ADD_SUCCESS; // Default action type

    if (data.type === "destinations") {
      actionType = DESTINATIONS_MENU_ADD_SUCCESS;
    } else if (data.type === "locations") {
      actionType = LOCATIONS_MENU_ADD_SUCCESS;
    }

    dispatch({
      type: actionType,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MENU_FAIL,
      payload: err?.response.data.message,
    });
  }
};
export const updateMenu = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.title) {
    data.title = data.title.trim();
  }
  if (data.title) form.append("title", data.title);
  form.append("type", data.type);
  form.append("option", data.option);
  form.append("url", data.url);

  if (data.destinations) {
    data.destinations.map((destination) => {
      form.append("destinations", destination?.value);
      return destination?.value;
    });
  } else {
    form.append("destinations", []);
  }

  if (data.locations) {
    data.locations.map((location) => {
      form.append("locations", location?._id);
      return location?._id;
    });
  } else {
    form.append("locations", []);
  }

  if (data.tags?.length > 0) {
    data.tags.map((tag) => {
      form.append("tags", tag?.value);
      return tag?.value;
    });
  } else {
    form.append("tags", []);
  }
  if (data.tickets?.length > 0 && data.filterBy === "tickets") {
    data.tickets.map((ticket) => {
      form.append("tickets", ticket?.value);
      return ticket?.value;
    });
  } else {
    form.append("tickets", []);
  }
  if (data.filters) {
    data.filters.map((filter) => {
      form.append("filters", filter);
      return filter;
    });
  }

  if (data.top !== undefined) form.append("top", data.top);
  if (data.hidden !== undefined) form.append("hidden", data.hidden);
  if (data.expandable !== undefined) form.append("expandable", data.expandable);
  if (data.images) {
    form.append("hasIcon", true);
    form.append("images", data.images);
  } else if (image) {
    form.append("hasLogo", true);
    form.append("images", image);
  }

  try {
    const res = await axios.patch(`${URL}/menus/${data._id}`, form, config);

    dispatch({
      type: UPDATE_MENU,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MENU_FAIL,
      payload: err?.response.data.message,
    });
  }
};
export const updateMenuTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/menus/translations/${id}?language=ar`,
    "UPDATE_MENU_TRANSLATION",
    "MENU_FAIL"
  );
export const saveMenuType = (menuType) => ({
  type: GET_UPDATED_MENU_TYPE,
  payload: menuType,
});

/* *** Handling Action *** */
export const clearErrors = () => ({ type: CLEAR_ERRORS });
export const setLoading = () => ({ type: SET_MENU_LOADING });
