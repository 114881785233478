/* eslint-disable no-console */
import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";

const LocationsFilter = ({ onFilterChanged, columnDef }) => {
  const user = useSelector((state) => state.users.user);

  const [selectedLoc, setSelectedLoc] = useState(null);

  const handleChange = (value) => {
    if (!value) {
      setSelectedLoc(null);
    } else {
      setSelectedLoc(value);
    }
    const val = value ? value.map((v) => v.value).join(",") : "";
    onFilterChanged(columnDef.tableData.id, val);
  };

  const filterLoc = (data) =>
    data.map((locsData) => ({
      label: locsData?.name,
      value: locsData?._id,
      //   type: locsData?.destinationType,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/locations?`;
      if (inputValue) {
        url += `&name=${inputValue}`;
      }
      if (user && user.locations.length > 0) {
        const locationIds = user.locations.map((location) => location._id).join(",");
        url += `ids=${locationIds}`;
      }
      url += "&sort=name&dir=asc";

      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          const filteredData = filterLoc(result.data);
          resolve(filteredData);
        })
        .catch((error) => console.error(error.message));
    });

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>
            <AsyncSelect
              aria-label="Location"
              cacheOptions
              isMulti
              defaultOptions
              defaultValue={selectedLoc}
              isClearable
              menuPosition="fixed"
              loadOptions={promiseOptions}
              onChange={(option) => {
                handleChange(option);
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  height: "45px",
                  width: "155px",
                  overflow: "scroll",
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                input: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menuList: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                }),
                placeholder: (base) => ({
                  ...base,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "100%",
                  WebkitLineClamp: 1,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                singleValue: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: "0.875rem",
                  fontWeight: "normal",
                }),
              }}
            />
          </th>
        </tr>
      </thead>
    </table>
  );
};

LocationsFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
};

export default LocationsFilter;
