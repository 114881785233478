/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import TransportationServicesInfo from "pages/views/transportationServices/new-transportationService/components/TransportationServiceInfo";

const WidgetDetail = ({
  formData,
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  selectedLocation = {},
  handleLocationChange = () => {},
  selectedIcon = {},
  handleIconChange = () => {},
  locationObj = {},
  iconsObj = {},
  setSelectedLocation = () => {},
}) => {
  const { submitForm, resetForm } = useFormikContext();
  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          Transportation Services Info
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          stateName="widgets"
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
            setSelectedLocation(null);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>
        <TransportationServicesInfo
          formData={formData}
          editPage
          locationObj={locationObj}
          iconsObj={iconsObj}
          detailPage={detailPage}
          handleLocationChange={handleLocationChange}
          selectedLocation={selectedLocation}
          selectedIcon={selectedIcon}
          handleIconChange={handleIconChange}
        />
      </MDBox>
    </MDBox>
  );
};

// typechecking props for WidgetDetail
WidgetDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  // type: PropTypes.string,
  selectedIcon: PropTypes.instanceOf(Object),
  locationObj: PropTypes.instanceOf(Object),
  iconsObj: PropTypes.instanceOf(Object),
  handleIconChange: PropTypes.func,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  setSelectedLocation: PropTypes.func,
};

export default WidgetDetail;
