import {
  SET_CAMPAIGN_LOADING,
  CAMPAIGN_ADD_SUCCESS,
  CAMPAIGN_FAIL,
  GET_ALL_CAMPAIGNS,
  CLEAR_ERRORS,
  DELETE_CAMPAIGN,
  GET_ONE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  LOGOUT,
} from "../actions/Types";

const initialState = {
  loading: false,
  campaigns: [],
  error: null,
  added: false,
  campaign: {},
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        campaigns: [...state.campaigns, action.payload.data.data],
        added: true,
        campaign: {},
      };
    case GET_ONE_CAMPAIGN:
      return {
        ...state,
        loading: false,
        error: null,
        campaign: action.payload.data,
        added: false,
      };

    case GET_ALL_CAMPAIGNS:
      return {
        ...state,
        loading: false,
        error: null,
        campaigns: action.payload.data.data,
        added: false,
        campaign: {},
      };
    case DELETE_CAMPAIGN:
      return {
        ...state,
        loading: false,
        campaigns: state.campaigns.filter(
          (campaign) => campaign._id !== action.payload.data.data._id
        ),
      };
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        added: true,
        campaigns: state.campaigns.map((campaign) =>
          campaign._id === action.payload.data.data._id ? action.payload.data.data : campaign
        ),
      };
    case CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CAMPAIGN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        campaigns: [],
        error: null,
        added: false,
        campaign: {},
      };

    default:
      return state;
  }
};
export default variable;
