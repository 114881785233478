const onlineStoreForm = {
  onlineFormId: "onlineStoreForm",
  addProductsFields: {
    productName: {
      type: "text",
      name: "productName",
      label: "Job Name *",
      placeholder: "ex. Job Name",
      errorMessage: "Job Name is required!",
      invalidMessage: "Job Name should have at least 1 letter!",
    },

    jobCode: {
      type: "number",
      name: "jobCode",
      label: "Job Code",
      placeholder: "ex. 123456",
    },

    deliveryMethods: {
      type: "array",
      name: "deliveryMethods",
    },

    phone: {
      name: "phone",
      label: "Staff Phone Number *",
      type: "text",
      placeholder: "ex. 971XXXXXXXXXX",
      errorMessage: "Staff Phone Number is required!",
      invalidMsg:
        "Staff Phone Number is not valid / should start with Country Code followed by Phone Number",
    },

    email: {
      type: "text",
      name: "email",
      label: "Staff email *",
      placeholder: "ex. example@domain.com",
      errorMessage: "Staff email is required!",
      invalidMessage: "Please enter a valid email!",
    },

    tripPlanner: {
      name: "tripPlanner",
      label: "Trip Planner *",
    },

    price: {
      name: "price",
      type: "number",
      label: "Retail Price",
      placeholder: "ex. 100",
      errorMessage: "Price is required!",
      invalidMessage: "Price should be greater than 0!",
    },

    netPrice: {
      name: "netPrice",
      type: "number",
      label: "Net Price",
      placeholder: "ex. 100",
      errorMessage: "Net Price is required!",
      invalidMessage: "Net Price should be greater than 0!",
    },

    defaultCurrencyCode: {
      name: "defaultCurrencyCode",
      label: "Currency",
      type: "text",
    },

    tag: {
      type: "select",
      name: "tag",
      label: "Category *",
      errorMessage: "Category is required!",
    },

    subTag: {
      type: "select",
      name: "subTag",
      label: "Sub-category",
    },

    images: {
      type: "array",
      name: "images",
      label: "Media:",
    },

    description: {
      type: "text",
      name: "description",
      label: "Description:",
    },

    label: {
      type: "text",
      name: "label",
      label: "Label:",
    },
  },

  settingFields: {
    paymentMethod: {
      type: "checkbox",
      name: "paymentMethod",
      label: "Payment Method *",
      errorMessage: "Payment Method is required!",
      options: [
        { name: "cash", label: "Cash On Delivery (COD)" },
        { name: "credit", label: "Credit Card" },
        { name: "charges", label: "Room Charges" },
      ],
    },
    orderProcessingType: {
      type: "checkbox",
      name: "orderProcessingType",
      label: "Order Processing Type *",
      errorMessage: "Order Processing Type is required!",
      options: [
        { name: "emailToCs", label: "Email to CS" },
        { name: "guestRequest", label: "Guest Request Management System" },
      ],
    },

    systemPicker: {
      type: "select",
      name: "systemPicker",
      label: "System Picker",
    },
  },
};

export default onlineStoreForm;
