/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */

// Libraries
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

// Redux Actions
import {
  deactivate,
  activate,
  setLoadingUsers,
  getUsers,
  clearOneUser,
  clearErrors,
  getLocationUsers,
  updateSpecificUser,
} from "store/actions/userActions";
import DynamicDateRange from "components/DynamicDateRange";
import { isSameDay, subDays, startOfDay, endOfDay } from "date-fns";
import MDButton from "components/MDButton";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";
import setAlert from "store/actions/alertActions";
import LocationUserPartner from "components/LocationUserPartner";
import { setLocation } from "store/actions/locationAction";
import form from "../new-user/schemas/form";

const AllUsers = () => {
  const { location } = form.formField;
  const tableRef = createRef();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.users.loadingUsers);
  const users = useSelector((state) => state.users.users);
  const user = useSelector((state) => state.users.user);
  const error = useSelector((state) => state.users.error);
  const locationPartner = useSelector((state) => state.locations.location);

  const [hideDaterange, setHideDaterange] = useState(true);
  const [allUsers, setAllUsers] = useState(true);
  const [filterButton, setFilterButton] = useState(false);
  const [click, setClick] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        1
      ),
      key: "selection",
    },
  ]);

  useEffect(() => {
    dispatch(clearOneUser());
  }, []);

  useEffect(() => {
    dispatch(setLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    if (
      user?.location?._id &&
      locationId !== user?.location?._id &&
      user?.role === "partner" &&
      locationId
    ) {
      setLocationId(user?.location?._id);
      dispatch(setLocation(locationId || user?.location?._id));
      if (locationId === null) {
        dispatch(getLocationUsers(user, locationId || user?.location?._id));
      } else {
        dispatch(getLocationUsers(null, locationId || user?.location?._id));
      }
    }
  }, [user?.location?._id, locationId]);

  useEffect(() => {
    if (
      locationPartner &&
      locationId !== locationPartner &&
      user?.role === "partner" &&
      locationId
    ) {
      setLocationId(locationPartner?._id || locationPartner);
      dispatch(setLocation(locationPartner?._id || locationPartner));

      const id = locationPartner?._id || locationPartner;
      if (id === null) {
        dispatch(getLocationUsers(user, id));
      } else {
        dispatch(getLocationUsers(null, id));
      }
    }
  }, [locationPartner, locationId]);

  useEffect(() => {
    if (click) {
      tableRef.current.onQueryChange();
      setClick(false);
    }
  }, [click]);

  useEffect(() => {
    if (filterButton) {
      const startDate = dateRange[0]?.startDate;
      const endDate = dateRange[0]?.endDate;

      if (startDate && endDate) {
        const startOfDayAdjusted = startOfDay(startDate); // Sets time to 12:00 AM
        const endOfDayAdjusted = endOfDay(endDate); // Sets time to 11:59:59.999 PM

        dispatch(getUsers(startOfDayAdjusted, endOfDayAdjusted));
        setFilterButton(false);
      }
    }
  }, [filterButton, dateRange]);

  useEffect(() => {
    if (error) {
      if (error === "A password reset link has been sent to your email!") {
        dispatch(setAlert(error, "success"));
      } else {
        dispatch(setAlert(error, "error"));
      }
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  const handleDeactivate = (id) => {
    dispatch(setLoadingUsers());
    dispatch(deactivate(id));
    setClick(true);
  };
  const handleActivate = (id) => {
    dispatch(setLoadingUsers());
    dispatch(activate(id));
    setClick(true);
  };

  const updatedColumns =
    user?.role === "partner" && user?.partnerPower
      ? [
          columnsMap.partnerNameNotEditable,
          columnsMap.emailCol,
          columnsMap.locations,
          columnsMap.registrationDateAsync,
          columnsMap.lastLogin,
          columnsMap.activeCol,
          columnsMap.verified,
        ]
      : [
          columnsMap.partnerName,
          columnsMap.emailCol,
          columnsMap.roleCol,
          columnsMap.nbOfTickets,
          columnsMap.partnerField,
          columnsMap.sourceLocation,
          columnsMap.registrationDateAsync,
          columnsMap.loginType,
          columnsMap.activeCol,
          columnsMap.verified,
        ];

  const getApiUrl = () => {
    if (user?.role === "admin") {
      return `${process.env.REACT_APP_API}/users?`;
    }
    return locationId !== null && locationId !== undefined
      ? `/v2/users?locations=${locationId}&`
      : `/v2/users?partnerName=${user?.partner?.name}&`;
  };

  const handleDateChange = (date) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    }
  };

  const updateUserFn = (data) => {
    const updatedData = { _id: data._id, locations: data.locations, role: data?.role };
    dispatch(updateSpecificUser(updatedData));
  };
  const handleChange = (option, val) => {
    tableRef.current.onQueryChange();
    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
  };

  const actionsChildFn = (rowData) => {
    let actionsChildRole = {};
    actionsChildRole = {
      icon: rowData.active ? PersonOffOutlinedIcon : PersonOutlineOutlinedIcon,
      tooltip: rowData.active ? "Deactivate" : "Activate",

      iconProps: {
        style: {
          marginLeft: "10px",
          fontSize: "25px",
          color: rowData.active ? "#f50057" : "green",
        },
      },
      onClick: () => {
        if (rowData.active) {
          handleDeactivate(rowData._id);
        } else {
          handleActivate(rowData._id);
        }
        // refreshPage();
      },
    };
    return actionsChildRole;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12 }}>
          {user?.role === "partner" && user?.locations.length > 0 && (
            <LocationUserPartner
              defaultLocationId={locationId}
              isUserPage
              handleChange={handleChange}
              locations={user?.locations || {}}
              location={location}
            />
          )}
        </Grid>
        <Grid style={{ marginTop: "15px" }} item size={{ xs: 6 }}>
          <DynamicDateRange
            defaultValue={dateRange}
            selectedOption={dateRange}
            handleDateChange={handleDateChange}
            hideDaterange={hideDaterange}
            hideDateRangeFn={setHideDaterange}
          />
          <MDBox display={!allUsers ? "block" : "none"} style={{ fontSize: "medium" }}>
            Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
            {dateRange[0].endDate.toDateString()}
          </MDBox>
        </Grid>
        <Grid item size={{ xs: 6 }}>
          {" "}
          <MDBox sx={{ display: "flex", justifyContent: "flex-start" }}>
            <MDButton
              color="primary"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={() => {
                setAllUsers(false);
                setHideDaterange(true);
                setFilterButton(true);
              }}
            >
              Filter Date
            </MDButton>{" "}
            <MDButton
              color="primary"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                width: "150px",
                height: "25px",
                color: "white",
              }}
              onClick={() => {
                setAllUsers(true);
                setDateRange([
                  {
                    startDate: subDays(
                      new Date(
                        Date.UTC(
                          new Date().getUTCFullYear(),
                          new Date().getUTCMonth(),
                          new Date().getUTCDate()
                        )
                      ),
                      7
                    ),
                    endDate: subDays(
                      new Date(
                        Date.UTC(
                          new Date().getUTCFullYear(),
                          new Date().getUTCMonth(),
                          new Date().getUTCDate()
                        )
                      ),
                      1
                    ),
                    key: "selection",
                  },
                ]);
              }}
            >
              Reset
            </MDButton>
          </MDBox>
        </Grid>
        {allUsers && (
          <Grid item size={{ xs: 12 }}>
            <MDBox>
              <MaterialTablePicker
                title="Users"
                columns={updatedColumns}
                extraFields="city,sourceLocation"
                tableRef={tableRef}
                searchField="users"
                exportMenuField="users"
                asyncTable
                isActions={
                  (user?.role === "partner" && user?.partnerPower) || user?.role === "admin"
                }
                asyncURL={getApiUrl()}
                currentUrl="users"
                addUrl="users/new-user"
                onRowClickURL={!(user?.role === "partner" && user?.partnerPower) && "/admin/users"}
                idSynonymName="_id"
                editableUpdate={user?.role === "partner" && user?.partnerPower && updateUserFn}
                updateRoleAction
                isRowClickable={!(user?.role === "partner" && user?.partnerPower)}
                sortField="createdAt"
                direction="asc"
                isLoading={loading}
                actionsName="User"
                actionsChild={actionsChildFn}
                actionsRole
                editableRole
                usersTable
                getPdf={(value, query) => {
                  if (value) {
                    if (user?.role === "admin") {
                      dispatch(
                        getPdf(
                          value,
                          `stats/pdf${query}&fields=name,email,role,partner,locations,sourceLocation,lastLogin,createdAt,loginType,active,verified`,
                          "Users"
                        )
                      );
                    }
                  } else if (user?.role === "partner") {
                    dispatch(
                      getPdf(
                        `stats/csv${query}&fields=name,email,role,partner,sourceLocation,lastLogin,createdAt,loginType,active,verified`,
                        "Partner Users"
                      )
                    );
                  }
                }}
                getCsv={(query) => {
                  if (user?.role === "admin") {
                    dispatch(
                      getCsv(
                        `stats/csv${query}&fields=name,email,role,partner,sourceLocation,lastLogin,createdAt,loginType,active,verified`,
                        "Users"
                      )
                    );
                  } else if (user?.role === "partner") {
                    dispatch(
                      getCsv(
                        `stats/csv${query}&fields=name,email,role,partner,sourceLocation,lastLogin,createdAt,loginType,active,verified`,
                        "Partner Users"
                      )
                    );
                  }
                }}
                // getCsv={(query) => {
                //   dispatch(
                //     getCsv(
                //       `stats/csv${query}&fields=name,email,role,partner,sourceLocation,locations,createdAt,loginType,active,verified`,
                //       "Users"
                //     )
                //   );
                // }}
              />
            </MDBox>
          </Grid>
        )}
        {!allUsers && (
          <Grid item size={{ xs: 12 }}>
            <MDBox>
              <MaterialTablePicker
                title="Users"
                columns={updatedColumns}
                tableRef={tableRef}
                searchField="users"
                exportMenuField="users"
                data={users}
                isActions={
                  (user?.role === "partner" && user?.partnerPower) || user?.role === "admin"
                }
                currentUrl="users"
                addUrl="users/new-user"
                onRowClickURL={!(user?.role === "partner" && user?.partnerPower) && "/admin/users"}
                idSynonymName="_id"
                sortField="createdAt"
                direction="asc"
                isLoading={loading}
                isRowClickable={!(user?.role === "partner" && user?.partnerPower)}
                actionsName="User"
                actionsChild={actionsChildFn}
                actionsRole
                editableUpdate={user?.role === "partner" && user?.partnerPower && updateUserFn}
                updateRoleAction
                editableRole
                usersTable
                getPdf={(value) => {
                  if (value) {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?fields=name,email,role,partner,sourceLocation,createdAt,loginType,active,verified&startDate=${
                          dateRange[0]?.startDate?.toISOString().split("T")[0]
                        }&endDate=${dateRange[0]?.endDate?.toISOString().split("T")[0]}`,
                        "Users"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={() => {
                  if (user?.role === "admin") {
                    dispatch(
                      // getCsv(
                      //   `${process.env.REACT_APP_API}/users/csv?csv=true&fields=name,email,role,partner.name,sourceLocation,createdAt,loginType,active,verified`,
                      //   "Users"
                      // )
                      getCsv(
                        `stats/csv?fields=name,email,role,partner,sourceLocation,createdAt,loginType,active,verified&startDate=${
                          dateRange[0]?.startDate?.toISOString().split("T")[0]
                        }&endDate=${dateRange[0]?.endDate?.toISOString().split("T")[0]}`,
                        "Users"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for CSV export.");
                  }
                }}
              />
            </MDBox>
          </Grid>
        )}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default AllUsers;
