/* eslint-disable no-param-reassign */
import axios from "axios";

import { downloadCSV } from "components/helper";
import { CSV_FAIL, CSV_SUCCESS, PDF_FAIL, PDF_SUCCESS, SET_EXPORT_LOADING } from "./Types";

const URL = process.env.REACT_APP_API;

export const setLoading = () => ({ type: SET_EXPORT_LOADING });

export const getPdf = (body, urls, name, locationId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob", // Set the response type to blob
  };

  try {
    const res = await axios.post(
      `${URL}/${urls}`,
      {
        tableColumns: body,
        tableName: name,
        location: locationId?.length > 0 ? locationId : null,
      },
      config
    );
    // Create a blob URL for the PDF and open it in a new tab
    const url = window.URL.createObjectURL(res.data);
    window.open(url, "_blank");

    dispatch({
      type: PDF_SUCCESS,
      payload: res.data.data, // You can pass null here or handle the dispatch as needed
    });
  } catch (err) {
    dispatch({
      type: PDF_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

export const getCsv = (urls, name, locationId) => async (dispatch) => {
  try {
    const res = await axios.post(`${URL}/${urls}`, {
      location: locationId?.length > 0 ? locationId : null,
      tableName: name,
    });

    // Assuming res?.data contains the CSV data
    const csvData = res?.data;
    dispatch({
      type: CSV_SUCCESS,
      payload: csvData,
    });
    if (csvData) {
      // Call downloadCsv function to convert and download the CSV
      downloadCSV(csvData, name);
    } else {
      // Handle the case where the response data is empty
    }
  } catch (err) {
    dispatch({
      type: CSV_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};
