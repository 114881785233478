// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
// import { ErrorMessage, Field } from "formik";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";

const MyAutoComplete = ({
  data,
  // val,
  idName,
  // defaultValue = "",
  handleChange,
  setFieldValue,
  selectedOption = {},
  disabled = false,
  ...rest
}) => (
  <Autocomplete
    // id={idName}
    value={selectedOption}
    options={[...data, {}]}
    disabled={disabled}
    onChange={(event, value) => {
      if (value) {
        handleChange(event, value);
        setFieldValue(idName, idName === "city" ? value?.name || value?.title : value?._id);
      } else {
        handleChange(event, "");
        setFieldValue(idName, "");
      }
    }}
    getOptionLabel={(option) => option.name || ""}
    isOptionEqualToValue={(option, value) => option?._id === value?._id}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        disabled={disabled}
        onChange={() => null} // added to prevent the error while earasing the search value
      />
    )}
  />
);

// typechecking props for MyAutoComplete
MyAutoComplete.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  idName: PropTypes.string.isRequired,
  // val: PropTypes.string.isRequired,
  // defaultValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedOption: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
};

export default MyAutoComplete;
