// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Redux Actions

import { addRecommendation, clearErrors, setLoading } from "store/actions/recommendationActions";
import setAlert from "store/actions/alertActions";
import { clearErrors as clearCityErrors } from "store/actions/cityActions";
import { scrollToTop, useHandleError } from "components/helper";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

// Schema
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import AdditionalInfo from "./components/AdditionalInfo";
import Address from "./components/Address";
import Information from "./components/Information";
import Review from "./components/Review";
import DateAndTimeInfo from "./components/DateAndTimeInfo";

function getSteps() {
  return ["Recommendation Info", "Address", "Additional Info", "Date and Time Info", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  handleLocationChange,
  selectedLocation,
  handlePrimaryDestinationChange,
  selectedPrimaryDestination,
  handleSecondaryDestinationChange,
  selectedSecondaryDestination,
  selectedTags,
  handleTagsChange,
  setSelectedPrimaryDestination,
  handleDietsChange,
  selectedSpecialDiets,
  handleMealsChange,
  selectedMeals
) {
  switch (stepIndex) {
    case 0:
      return (
        <Information
          formField={formData?.formField}
          selectedTags={selectedTags}
          handleTagsChange={handleTagsChange}
        />
      );
    case 1:
      return (
        <Address
          formData={formData}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          handlePrimaryDestinationChange={handlePrimaryDestinationChange}
          selectedPrimaryDestination={selectedPrimaryDestination}
          handleSecondaryDestinationChange={handleSecondaryDestinationChange}
          selectedSecondaryDestination={selectedSecondaryDestination}
          setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        />
      );
    case 2:
      return (
        <AdditionalInfo
          formField={formData?.formField}
          handleDietsChange={handleDietsChange}
          selectedSpecialDiets={selectedSpecialDiets}
          handleMealsChange={handleMealsChange}
          selectedMeals={selectedMeals}
        />
      );
    case 3:
      return <DateAndTimeInfo formData={formData} />;
    case 4:
      return <Review formData={formData} selectedPrimaryDestination={selectedPrimaryDestination} />;
    default:
      return null;
  }
}

const NewRecommendation = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const error = useSelector((state) => state.recommendations.error);
  const user = useSelector((state) => state.users.user);
  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState({});
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);
  const [selectedSpecialDiets, setSelectedSpecialDiets] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    user?.role === "partner" && user?.partnerPower ? user?.locations[0] : null
  );
  const [formChange, setFormChange] = useState(null);

  const { formId, formField } = form;
  const currentValidation =
    activeStep === 1 ? user.role === "admin" && validations[1] : validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleNavigation = () => {
    let url = null;
    if (user.role === "partner" && user?.partnerPower) {
      url = `/partnerPower/recommendations?locationId=${selectedLocation?._id}`;
      navigate(url);
    }
    if (user.role === "admin") {
      url = `/admin/recommendations?`;
    } else if (user.role === "partner" && !user?.partnerPower) {
      url = `/partner/recommendations?locationId=${Location}`;
      navigate(url);
    }
  };
  const handleTagsChange = (val) => {
    if (val) {
      setSelectedTags(val);
    }
  };
  const handlePrimaryDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedPrimaryDestination({});
    } else {
      setSelectedPrimaryDestination(value);
    }
  };
  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };
  const handleLocationChange = (value) => {
    if (value && value?._id) {
      setSelectedLocation(value);
    } else {
      setSelectedLocation({});
    }
  };
  const handleDietsChange = (value) => {
    if (value) {
      setSelectedSpecialDiets(value);
    } else {
      setSelectedSpecialDiets([]);
    }
  };
  const handleMealsChange = (value) => {
    if (value) {
      setSelectedMeals(value);
    } else {
      setSelectedMeals([]);
    }
  };
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top when going back
  };

  const submitForm = async (values, actions) => {
    await sleep(1000);
    const updatedValues = user.role === "partner" ? { ...values, address: user.address } : values;

    if (Object.keys(updatedValues).length === 0) {
      return;
    }
    const {
      logo = "",
      location = {},
      cuisines = [],
      features = [],
      openHours = [],
      bookable = false,
    } = updatedValues;

    if (location?._id) {
      updatedValues.location = updatedValues.location._id;
    }
    if (cuisines.length > 0) {
      updatedValues.cuisines = updatedValues.cuisines.map((cuisine) => cuisine?.name || cuisine);
    }
    if (features.length > 0) {
      updatedValues.features = updatedValues.features.map((feature) => feature?.name || feature);
    }
    if (bookable) {
      delete updatedValues.bookable;
    }

    if (openHours.length > 0) {
      updatedValues.openHours = updatedValues.openHours.flatMap((item) => {
        if (item.openHours.length > 0) {
          return item.openHours.map((time) => ({
            open: time.open,
            close: time.close,
            closed: item.closed,
            weekDay: item.weekDay,
            open_all_day: item.open_all_day,
          }));
        }

        return {
          open: null,
          close: null,
          weekDay: item.weekDay,
          closed: item.closed,
          open_all_day: item.open_all_day,
        };
      });
    }

    dispatch(setLoading());
    dispatch(addRecommendation(updatedValues, logo));
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      handleNavigation();
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  // useEffect
  useEffect(() => {
    setSteps(getSteps("admin"));
  }, []);

  useEffect(() => {
    if (formChange?.city) {
      dispatch(clearCityErrors());
    }
  }, [formChange, formChange?.city]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          handleLocationChange,
                          selectedLocation,
                          handlePrimaryDestinationChange,
                          selectedPrimaryDestination,
                          handleSecondaryDestinationChange,
                          selectedSecondaryDestination,
                          selectedTags,
                          handleTagsChange,
                          setSelectedPrimaryDestination,
                          handleDietsChange,
                          selectedSpecialDiets,
                          handleMealsChange,
                          selectedMeals
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() => handleNavigation()}
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewRecommendation;
