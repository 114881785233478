/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, forwardRef, useMemo } from "react";
import { useNavigate } from "react-router";
import MaterialTable, { MTableBodyRow, MTableToolbar } from "@material-table/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatedFilter } from "store/actions/userActions";
import { Button, Typography } from "@mui/material";
import { setLoading } from "store/actions/exportAction";
import { Delete, Edit, Refresh } from "@mui/icons-material";
import Loader from "components/Loader";
import { urlApiBuilder } from "./urlApiBuilder";
import useDynamicTable from "./useDynamicTable";
import useTableDragDrop from "./useTableDragDrop";

const DynamicMaterialTable = ({
  title,
  data = [],
  columns = [],
  searchField = "",
  components = {},
  tableRef = {},
  isLoading = false,
  style = {},
  sortField = "",
  isFreeActions = true,
  isFreeDownloadAction = false,
  direction = "asc",
  customActions = [],
  editableDelete = () => {},
  deleteRoleAction = false,
  editableUpdate = () => {},
  isRowClickable = true,
  editableRole = false,
  idSynonymName = "",
  actionsOnClick = null,
  stopsTable = false,
  usersTable = false,
  ticketsTable = false,
  elementsTable = false,
  locationBoxesTable = false,
  boxesTable = false,
  detailPanel = null,
  itemType = "",
  onRowClickHandler = "",
  asyncTable = false,
  filteringOptions = true,
  page = null,
  asyncURL = "",
  onRowClickURL = "",
  currentUrl = "",
  addUrl = "",
  isGrouping = false,
  actionsChild = () => {},
  handlePublishUnPublishLoc = () => {},
  actionsRole = false,
  isSearchable = true,
  isExportable = true,
  isMenuDownload = true,
  isActions = true,
  actionsName = "",
  // exportMenuField,
  updateRoleAction = false,
  onRowClickFn = () => {},
  sortFn = () => {},
  isOrder = false,
  locationName = "",
  tableInside = false,
  expId = "",
  widgetId = "",
  locationId = "",
  cityName = "",
  getPdf = () => {},
  getCsv = () => {},
  onSearchChange = () => {},
  invoiceTable = false,
  showRefresh = false,
  handleRefresh = () => {},
  refreshLoading = false,
  searchRequestText = "",
  screen = "",
  extraFields = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [searchParams] = useSearchParams();
  const row_nb = useRef(parseInt("0", 10) || 10);
  const [sourceName, setSourceName] = useState("");

  const user = useSelector((state) => state.users.user);
  const reduxFilters = useSelector((state) => state.users.filters);
  const onlineStoreFilter = useSelector((state) => state.users.onlineStoreFilter);
  const exportLoading = useSelector((state) => state.exports.loading);

  const [updatedCol, setUpdatedCol] = useState([]);
  const [filtersSearch, setFiltersSearch] = useState("");
  const [editableProps, setEditableProps] = useState({});

  const {
    pageValue,
    setPageValue,
    queryString,
    total,
    setQueryString,
    setTotal,
    handleFilterChange,
    handleSearchChange,
    handlePageChange,
  } = useDynamicTable(0, currentUrl);

  const { tableData, dragHandlers } = useTableDragDrop({
    data,
    stopsTable,
    elementsTable,
    isOrder,
    sortFn,
    locationBoxesTable,
    boxesTable,
    locationId,
    cityName,
    dispatch,
    expId,
    widgetId,
  });

  const handleOnRowClick = (type, rowData) => {
    const obj = {
      filters: filtersSearch,
      search: reduxFilters?.search,
      page: pageValue,
      rows: row_nb.current,
    };
    let url = null;
    if (searchField === "attractionsList") {
      if (type === "admin") {
        url = `/${user.role}/attractions/${rowData._id}?`;
      } else if (type === "partner") {
        url = `/${user.role}/attractions/${rowData._id}?location=${locationName}`;
      }
    } else if (searchField === "attractions") {
      if (type === "admin" || type === "partner") {
        url = `/${user.role}/attraction/${rowData._id}?`;

        if (sourceName) obj.source = sourceName;
        if (reduxFilters?.search) obj.sourceAttraction = reduxFilters?.search;

        delete obj.filters;
        delete obj.search;
      }
    } else if (searchField === "items?itemType=ticket&") {
      if (type === "admin" || type === "partner") {
        url = `/${user.role}/${onRowClickHandler}/${rowData._id}?itemType=${itemType}&`;
      }
    } else if (searchField === "experiences") {
      if (type === "admin" || type === "partner") {
        url = `/${user.role}/experiences/${rowData._id}?`;
      }
    } else if (type === "admin" || type === "partner") {
      url = `${onRowClickURL}/${rowData._id}?`;
    }

    if (url) {
      url += urlApiBuilder(obj);
      navigate(url);
    }
  };

  const handleRowPerPage = (e) => {
    if (screen !== "LocationRequests" && screen !== "OnlineStoreList") {
      dispatch(
        getUpdatedFilter({
          filters: reduxFilters?.filters,
          search: reduxFilters?.search,
          row_nb: e,
          pageValue: reduxFilters?.pageValue,
        })
      );
    }
    // row_nb.current = e;
  };

  const getColumns = () => {
    setUpdatedCol(columns);
  };

  const tableIcons = useMemo(
    () => ({
      Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),

      Export: forwardRef((props, ref) => {
        if (exportLoading) {
          return (
            <CircularProgress
              {...props}
              ref={ref}
              style={{ fontSize: "16px", width: "16px", height: "16px" }}
            />
          );
        }
        return <FileDownloadIcon {...props} ref={ref} />;
      }),
    }),
    [exportLoading]
  );

  const asyncDataFn = useMemo(
    () => (query) =>
      new Promise((resolve) => {
        let url = asyncURL;
        url += `limit=${query.pageSize}`;
        url += `&page=${query.page + 1}`;
        url += `&search=${encodeURIComponent(query.search)}`;
        if (query.orderBy && query.orderDirection !== "") {
          if (query.orderBy?.field === "retail_price.formatted_iso_value") {
            url += "&sort=retail_price.value";
          } else {
            url += `&sort=${query.orderBy?.field}`;
            url += `&dir=${query.orderDirection}`;
          }
        } else {
          url += `&sort=${sortField}`;
          url += `&dir=${direction}`;
        }

        // Process filters
        const copyFilters = query.filters?.length > 0 ? query.filters : reduxFilters.filters;
        copyFilters?.forEach(({ column, value }) => {
          const { field } = column;
          let apiField = field;
          let processedValue = value;

          // Skip 'all' values for specific fields
          if (["source", "role", "loginType"].includes(field) && value === "all") return;
          // Map UI fields to API fields
          switch (field) {
            case "retail_price.formatted_iso_value":
              processedValue = Number(value.toString().replace(/[^0-9.-]+/g, ""));
              break;
            case "top":
              processedValue = value === "checked" ? "true" : "false";
              break;
            case "city":
              apiField =
                title === "Attractions" || title === "Experiences" || title === "Jobs"
                  ? "itemCity"
                  : "city";
              break;
            case "tag":
              apiField = ticketsTable ? "tags" : "tag";
              break;
            case "price.fromPrice":
              apiField = ticketsTable ? "price" : "price.fromPrice";
              break;
            case "category.name":
              apiField = "catName";
              break;
            case "partner.name":
              apiField = "partnerName";
              break;
            case "country.iso_code":
              apiField = "isoCode";
              break;
            case "country.name":
              apiField = "cityCountry";
              break;
            case "travelDesk.name":
              apiField = "travelDesk";
              break;
            case "location":
              apiField = usersTable ? "sourceLocation" : "location";
              break;
            default:
              break;
          }
          url += `&${apiField}=${encodeURIComponent(processedValue)}`;
        });
        setQueryString(url.replace(/^[^?]*\?/, "?"));
        setFiltersSearch(url.split("search")[1]?.length > 1 && url.split("search")[1]);
        const fields = columns
          .map((col) => {
            const fieldParts = col.field.split(".");
            return fieldParts.length > 1 ? fieldParts[0] : col.field;
          })
          .join(",");
        url += `&fields=${fields}${extraFields ? `,${extraFields}` : ""}`;
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            resolve({
              data: result.data,
              page: result.page - 1,
              totalCount: result.total,
            });
            setTotal(result.total);
          })
          .catch((error) => console.error("Error fetching data:", error));
      }),
    [asyncURL, setTotal, setQueryString]
  );

  const handleClickCondition = (rowData, togglePanel) => {
    if (!isRowClickable) {
      return detailPanel ? togglePanel() : {};
    }

    if (searchField === "recommendationsDuplicates" || searchField === "elements") {
      return onRowClickFn(rowData);
    }

    if (searchField === "users" || searchField === "news" || user.role === "admin") {
      return handleOnRowClick("admin", rowData);
    }

    return handleOnRowClick("partner", rowData);
  };

  useEffect(() => {
    if (window.location.href.indexOf("travelDesk") > 0) {
      setSourceName("travelDesk");
    } else {
      setSourceName("partner");
    }
  }, []);

  useEffect(() => {
    if (asyncTable && reduxFilters?.filters?.length === 0) tableRef.current.onQueryChange(); // add this to handle the filter clear effect
  }, [reduxFilters?.filters]);

  useEffect(() => {
    if (reduxFilters?.pageValue) setPageValue(parseInt(reduxFilters?.pageValue, 10));
  }, [reduxFilters?.pageValue]);

  useEffect(() => {
    getColumns();
    if (updateRoleAction && user?.role === "partner" && user?.partnerPower) {
      if (editableRole) {
        if (editableUpdate) {
          setEditableProps({
            onRowUpdate: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  editableUpdate(newData);
                  resolve();
                }, 1000);
              }),
          });
        }
      }
    } else if (updateRoleAction && deleteRoleAction) {
      setEditableProps({
        onRowUpdate: (newData) => {
          newData.city = newData.geoLocation?.city || newData.city;
          return new Promise((resolve) => {
            setTimeout(() => {
              editableUpdate(newData);
              resolve();
            }, 1000);
            // eslint-disable-next-line no-console
          }).catch((error) => console.log(error.message));
        },
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (searchField === "recommendations" || searchField === "tickets")
                editableDelete(oldData);
              else editableDelete(oldData?._id);
              resolve();
            }, 1000);
            // eslint-disable-next-line no-console
          }).catch((error) => console.log(error.message)),
      });
    } else if (deleteRoleAction) {
      setEditableProps({
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (searchField === "stops" || searchField === "elements") {
                editableDelete(oldData);
              } else editableDelete(oldData._id);
              resolve();
            }, 1000);
            // eslint-disable-next-line no-console
          }).catch((error) => console.log(error.message)),
      });
    } else {
      // Reset editableProps when editableRole is false
      setEditableProps({});
    }
  }, [editableRole, deleteRoleAction, updateRoleAction]);

  const updatedColumns = [
    ...columns,
    ...(user?.role === "admin"
      ? [
          {
            title: "Actions",
            field: "actions",
            render: (rowData) => (
              <Button
                variant="contained"
                color="transparent"
                onClick={(event) => {
                  event.stopPropagation();
                  handlePublishUnPublishLoc(rowData);
                }}
                sx={{
                  width: "120px", // Fixed width for uniform size
                  textAlign: "center", // Center-align the text
                  padding: "8px", // Optional padding for consistent height
                  whiteSpace: "nowrap", // Prevent text wrapping
                  fontSize: "10px", // Adjust font size to fit text on one line
                }}
              >
                {rowData.status === "published"
                  ? "Unpublish"
                  : rowData.status === "draft"
                  ? "Request approval"
                  : rowData.status === "archived"
                  ? "Publish"
                  : rowData.status === "pending"
                  ? "Publish"
                  : "Request approval"}
              </Button>
            ),
          },
        ]
      : user?.role === "partner" && user?.partnerPower
      ? [
          {
            title: "Actions",
            field: "actions",
            render: (rowData) => {
              if (rowData?.status === "published") {
                return (
                  <Button
                    variant="contained"
                    color="transparent"
                    onClick={(event) => {
                      event.stopPropagation();
                      handlePublishUnPublishLoc(rowData);
                    }}
                    sx={{
                      width: "120px",
                      textAlign: "center",
                      padding: "8px",
                      whiteSpace: "nowrap",
                      fontSize: "10px",
                    }}
                  >
                    Unpublish
                  </Button>
                );
              }
              return null; // Return null to display nothing if status is not "published"
            },
          },
        ]
      : []),
  ];

  return (
    <MaterialTable
      title=""
      onRowsPerPageChange={handleRowPerPage}
      onSearchChange={
        currentUrl === "menus"
          ? onSearchChange
          : actionsName !== "requestText" && handleSearchChange
      }
      onFilterChange={handleFilterChange}
      onPageChange={handlePageChange}
      page={page}
      columns={actionsName === "Location" ? updatedColumns : updatedCol}
      data={asyncTable ? asyncDataFn : !isOrder ? data : tableData}
      tableRef={tableRef}
      components={{
        ...components,
        Toolbar: (props) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #e8eaf5",
              padding: "16px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="h4">{title}</Typography>
            </div>

            <div>
              <MTableToolbar {...props} />
            </div>
          </div>
        ),
        Row: (props) => {
          if (isOrder) {
            return (
              <MTableBodyRow
                {...props}
                draggable="true"
                onDragStart={() => dragHandlers.onDragStart(props.index)}
                onDragEnter={(e) => dragHandlers.onDragEnter(e, props.index)}
                onDragEnd={dragHandlers.onDragEnd}
              />
            );
          }
          return <MTableBodyRow {...props} />;
        },
      }}
      style={style}
      asyncURL={asyncURL}
      detailPanel={detailPanel && detailPanel}
      isLoading={isLoading}
      onRowClick={(event, rowData, togglePanel) => handleClickCondition(rowData, togglePanel)}
      editable={editableProps}
      icons={tableIcons}
      actions={
        customActions.length > 0
          ? customActions
          : isActions && actionsRole
          ? [
              {
                icon:
                  actionsOnClick && (stopsTable || elementsTable)
                    ? "add"
                    : actionsOnClick
                    ? FileDownloadIcon
                    : "add",

                tooltip:
                  actionsOnClick && (stopsTable || elementsTable)
                    ? `Add ${actionsName}`
                    : actionsOnClick
                    ? "Download as PDF"
                    : `Add ${actionsName}`,
                isFreeAction: isFreeActions,
                onClick: (event, item) =>
                  actionsOnClick
                    ? actionsOnClick(item)
                    : user?.role === "partner" && user?.partnerPower
                    ? navigate(`/partnerPower/${addUrl}`)
                    : navigate(`/${user.role}/${addUrl}`),
                iconProps: {
                  style: {
                    marginLeft: "10px",
                    fontSize: "25px",
                    color: isFreeActions && !isFreeDownloadAction ? "red" : "black",
                    boxShadow: "none", // Remove shadow on hover
                  },
                },
              },
              // Conditionally render the refresh icon
              ...(showRefresh
                ? [
                    {
                      icon: refreshLoading ? () => <Loader size={24} /> : Refresh,
                      tooltip: "Refresh",
                      isFreeAction: true,
                      onClick: () => handleRefresh(),
                      iconProps: {
                        style: {
                          marginLeft: "10px",
                          fontSize: "25px",
                          color: "black",
                        },
                      },
                    },
                  ]
                : []),
              (rowData) => actionsChild(rowData),
            ]
          : []
      }
      options={{
        emptyRowsWhenPaging: false,
        grouping: isGrouping,
        search: isSearchable,
        searchText:
          actionsName === "onlineStore"
            ? onlineStoreFilter
            : actionsName === "requestText"
            ? searchRequestText
            : reduxFilters?.search,
        idSynonym: `${idSynonymName}`, // location report pageTitle
        initialPage: reduxFilters?.pageValue ? parseInt(reduxFilters?.pageValue, 10) : pageValue,
        actionsColumnIndex: tableInside ? -2 : -1,
        exportAllData: true,
        exportMenu:
          isExportable && isMenuDownload
            ? [
                !invoiceTable && {
                  label: "Export PDF",
                  exportFunc: (option) => {
                    if (option && option.length > 0) {
                      const body = option.map((item) => ({
                        name: item.title,
                        field: item.field,
                      }));
                      // // Additional logic to include location when user role is partner
                      getPdf(body, queryString.replace(/limit=\d+/, `limit=${total}`));
                      dispatch(setLoading());
                    } else {
                      // Handle the case where 'option' is undefined or empty
                    }
                  },
                },
                {
                  label: "Export CSV",
                  exportFunc: (option) => {
                    if (option && option.length > 0) {
                      dispatch(setLoading());
                      getCsv(
                        queryString
                          .replace(/limit=\d+/, `limit=${total}`)
                          .replace(/dir=[^&]+/, "dir=asc")
                      );
                    } else {
                      // Handle the case where 'option' is undefined or empty
                    }
                  },
                },
              ]
            : [],
        exportButton: isExportable,
        pageSize: reduxFilters?.row_nb ? parseInt(reduxFilters?.row_nb, 10) : 10,
        headerStyle: {
          // textAlign: "center",
          fontSize: "15px",
          lineHeight: "1.25rem",
          fontWeight: 600,
          backgroundColor: tableInside && title !== "Locations" && "#BEBEBE",
        },
        rowStyle: {
          backgroundColor: tableInside && title !== "Locations" && "#EEE",
        },
        debounceInterval: 1000,
        filtering: filteringOptions,
      }}
    />
  );
};

DynamicMaterialTable.propTypes = {
  customActions: PropTypes.instanceOf(Array),
  invoiceTable: PropTypes.bool,
  onRowClickFn: PropTypes.func,
  sortFn: PropTypes.func,
  itemType: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
  page: PropTypes.number,
  columns: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  asyncTable: PropTypes.bool,
  filteringOptions: PropTypes.bool,
  tableInside: PropTypes.bool,
  asyncURL: PropTypes.string,
  onRowClickURL: PropTypes.string,
  currentUrl: PropTypes.string,
  addUrl: PropTypes.string,
  isFreeActions: PropTypes.bool,
  isGrouping: PropTypes.bool,
  isFreeDownloadAction: PropTypes.bool,
  sortField: PropTypes.string,
  idSynonymName: PropTypes.string,
  updateRoleAction: PropTypes.bool,
  isActions: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  actionsOnClick: PropTypes.func,
  detailPanel: PropTypes.func,
  direction: PropTypes.string,
  searchField: PropTypes.string,
  deleteRoleAction: PropTypes.bool,
  tableRef: PropTypes.instanceOf(Object),
  isMenuDownload: PropTypes.bool,
  isLoading: PropTypes.bool,
  actionsName: PropTypes.string,
  // exportMenuField: PropTypes.string,
  editableUpdate: PropTypes.func,
  editableDelete: PropTypes.func,
  editableRole: PropTypes.bool,
  components: PropTypes.instanceOf(Object),
  actionsChild: PropTypes.func,
  handlePublishUnPublishLoc: PropTypes.func,
  style: PropTypes.instanceOf(Object),
  isSearchable: PropTypes.bool,
  isOrder: PropTypes.bool,
  isExportable: PropTypes.bool,
  actionsRole: PropTypes.bool,
  stopsTable: PropTypes.bool,
  usersTable: PropTypes.bool,
  ticketsTable: PropTypes.bool,
  elementsTable: PropTypes.bool,
  locationBoxesTable: PropTypes.bool,
  boxesTable: PropTypes.bool,
  expId: PropTypes.string,
  widgetId: PropTypes.string,
  locationId: PropTypes.string,
  cityName: PropTypes.string,
  isRowClickable: PropTypes.bool,
  onRowClickHandler: PropTypes.string,
  locationName: PropTypes.string,
  getPdf: PropTypes.func,
  getCsv: PropTypes.func,
  onSearchChange: PropTypes.func,
  showRefresh: PropTypes.bool,
  handleRefresh: PropTypes.func,
  refreshLoading: PropTypes.bool,
  searchRequestText: PropTypes.string,
  screen: PropTypes.string,
  extraFields: PropTypes.string,
};

export default React.memo(DynamicMaterialTable);
