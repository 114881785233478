/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Custom Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";

// Pages
import DateAndTimeInfo from "pages/items/promotions/new-promotion/components/DateAndTimeInfo";
import PromotionInfo from "pages/items/promotions/new-promotion/components/PromotionInfo";
import { resetFormToInitialState } from "components/helper";

const PromotionDetail = ({
  formData,
  formRef = "",
  initialState = {},
  type = "",
  detailPage = true,
  setDetailPage = () => {},
  handleEdit,
  handleRecommendationChange = () => {},
  selectedRecommendation = {},
  selectedLocation = {},
  handleLocationChange = () => {},
  setSelectedRecommendation = () => {},
  setSelectedLocation = () => {},
}) => {
  const { submitForm, resetForm } = useFormikContext();

  const handleCardTitle = () => {
    if (type === "promotionInfo") {
      return "Promotion info";
    }
    return "Date And Time Info";
  };

  const handleInfoCard = () => {
    if (type === "promotionInfo") {
      return (
        <PromotionInfo
          formData={formData}
          editPage
          detailPage={detailPage}
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          selectedRecommendation={selectedRecommendation}
          handleRecommendationChange={handleRecommendationChange}
        />
      );
    }
    return <DateAndTimeInfo formData={formData} editPage detailPage={detailPage} />;
  };
  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          {handleCardTitle()}
        </MDTypography>
        <ViewEditActions
          detailPage={detailPage}
          handleEdit={handleEdit}
          handleClose={() => {
            resetForm();
            setDetailPage(true);
            // Reset the form to its initial state
            resetFormToInitialState(formRef, initialState);
            setSelectedRecommendation(null);
            setSelectedLocation(null);
          }}
          handleSave={() => {
            submitForm();
          }}
        />
      </MDBox>
      <MDBox>{handleInfoCard()}</MDBox>
    </MDBox>
  );
};

// typechecking props for TicketDetail
PromotionDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string,
  selectedRecommendation: PropTypes.instanceOf(Object),
  handleRecommendationChange: PropTypes.func,
  selectedLocation: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  formRef: PropTypes.instanceOf(Object),
  initialState: PropTypes.instanceOf(Object),
  setSelectedRecommendation: PropTypes.func,
  setSelectedLocation: PropTypes.func,
};

export default PromotionDetail;
