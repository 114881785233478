import axios from "axios";
import {
  SET_PROMOCODE_LOADING,
  CLEAR_ERRORS,
  PROMOCODE_ADD_SUCCESS,
  PROMOCODE_FAIL,
  UPDATE_PROMOCODE,
  SET_PROMOCODE_EMPTY,
} from "./Types";

// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Promocode
export const addPromoCode = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  try {
    const res = await axios.post(`${URL}/promoCodes`, data, config);

    dispatch({
      type: PROMOCODE_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROMOCODE_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

// Update Promocode
export const updatePromoCode = (data) => async (dispatch) => {
  const updatedData = {
    ...data,
    title: data?.title.trim(),
  };

  const config = {
    headers: { "Content-Type": "application/json" },
  };

  try {
    const res = await axios.patch(`${URL}/promoCodes/${data?._id}`, updatedData, config);

    dispatch({
      type: UPDATE_PROMOCODE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: PROMOCODE_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Promocode - set active as false
export const deletePromoCode = (id) =>
  factory.deleteById(id, `${URL}/Promocodes`, "DELETE_PROMOCODE", "PROMOCODE_FAIL");

// get One Promocode
export const getOnePromoCode = (id) =>
  factory.get(`${URL}/Promocodes/${id}`, "GET_ONE_PROMOCODE", "PROMOCODE_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_PROMOCODE_LOADING });

export const setPromoCodeEmpty = () => ({ type: SET_PROMOCODE_EMPTY });
