import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import positionsList from "./PositionsList";

const PositionPicker = ({
  handlePositionChange = () => {},
  position = {},
  setFieldValue = () => {},
  isDisabledPositionPicker = false,
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));

  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });

  return (
    <SelectF
      options={positionsList}
      placeholder="Select a position"
      value={position}
      onChange={(event) => {
        if (event) {
          setFieldValue("position", event);
          handlePositionChange(event);
        } else {
          setFieldValue("position", {});
          handlePositionChange(event);
        }
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      isDisabled={isDisabledPositionPicker}
    />
  );
};

// typechecking props for PositionPicker
PositionPicker.propTypes = {
  handlePositionChange: PropTypes.func,
  position: PropTypes.instanceOf(Object),
  setFieldValue: PropTypes.func,
  isDisabledPositionPicker: PropTypes.bool,
};

export default PositionPicker;
