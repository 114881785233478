import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { name, website, contactEmail, contactPhone },
} = checkout;

const validations = [
  Yup.object().shape({
    [name?.name]: Yup.string()
      .matches(/([A-Za-z])/, name?.invalidMsg)
      .required(name?.errorMsg),
    [contactEmail.name]: Yup.string()
      .email(contactEmail.invalidMsg)
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, contactEmail.invalidMsg),
    [website?.name]: Yup.string().matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      website?.invalidMsg
    ),
    [contactPhone?.name]: Yup.string().matches(
      /^[0-9+\-/]+$/,
      "Please enter a valid phone number with digits, +, -, or /"
    ),
  }),
];

export default validations;
