/* eslint-disable no-param-reassign */

import axios from "axios";
import { createFormData } from "components/helper";
import {
  SET_ATTRACTION_LOADING,
  CLEAR_ERRORS,
  ATTRACTION_ADD_SUCCESS,
  ATTRACTION_FAIL,
  UPDATE_ATTRACTION,
  DELETE_SINGLE_ATTRACTION,
} from "./Types";
// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// // Add Attraction
export const addAttraction = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const form = createFormData(data);

  if (image) {
    form.append("image", image);
  }
  try {
    const res = await axios.post(`${URL}/attractions`, form, config);

    dispatch({
      type: ATTRACTION_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ATTRACTION_FAIL,
      payload: err?.response.data.message,
    });
  }
};

//  Update Attraction
export const updateAttraction = (data, image) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const updatedData = {
    ...data,
    title: data?.title?.trim(),
  };

  const form = createFormData(updatedData);

  if (image) {
    form.append("image", image);
  }
  if (data?.subHeader?.length === 0) {
    form.append("subHeader", "");
  }
  if (data?.type === "url" && data?.callToAction?.url?.length === 0) {
    form.append("callToAction.url", "");
  }
  try {
    const res = await axios.patch(`${URL}/attractions/${data._id}`, form, config);

    dispatch({
      type: UPDATE_ATTRACTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ATTRACTION_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Attraction - set active as false
export const deleteAttraction = (id) =>
  factory.patch(
    { active: false },
    `${URL}/attractions/${id}`,
    "DELETE_ATTRACTION",
    "ATTRACTION_FAIL"
  );

// get One Attraction
export const getOneAttraction = (id) =>
  factory.get(`${URL}/attractions/${id}`, "GET_ONE_ATTRACTION", "ATTRACTION_FAIL");

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_ATTRACTION_LOADING });

export const updateAttractionTranslations = (data, id) =>
  factory.patch(
    {
      translations: {
        ar: data,
      },
    },
    `${URL}/attractions/translations/${id}?language=ar`,
    "UPDATE_ATTRACTION_TRANSLATION",
    "ATTRACTION_FAIL"
  );

// Clear single Item
export const deleteSingleAttraction = () => ({ type: DELETE_SINGLE_ATTRACTION });

export const getTravelDeskAttractions = (travelDesk_id) =>
  factory.get(
    `${URL}/attractions?travelDesk=${travelDesk_id}`,
    "GET_ATTRACTIONS",
    "ATTRACTION_FAIL"
  );

export const getLocationAttractions = (location) =>
  factory.get(`${URL}/locations/${location}/attractions`, "GET_ATTRACTIONS", "ATTRACTION_FAIL");
