import React from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";

const AvailabilitySchedulePanel = ({
  setFieldValue,
  availabilitiesAdded = [],
  scheduleOrOption = "",
}) => {
  const deleteAvailability = (i) => {
    const arr = [...availabilitiesAdded];
    arr.splice(i, 1);
    setFieldValue("availabilitiesAdded", arr);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        marginBottom: "0px",
      }}
    >
      {availabilitiesAdded?.length > 0 &&
        availabilitiesAdded?.map((row, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Grid container alignItems="center">
                <Grid item size={{ xs: "auto" }}>
                  {row?.productOption?.title && (
                    <Typography
                      variant="body1"
                      style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                    >
                      {row?.productOption?.title}
                    </Typography>
                  )}
                  {scheduleOrOption === "schedule" && (
                    <>
                      {row?.startDate && !row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate}
                        </Typography>
                      )}
                      {row?.startDate && row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate} {"    "}- {"    "} {row?.endDate}
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item>
                  <IconButton
                    key="close"
                    aria-label="Delete"
                    color="inherit"
                    edge="end"
                    onClick={() => deleteAvailability(index)}
                  >
                    <DeleteIcon sx={{ color: "grey" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="span" variant="body2">
                <div>
                  {scheduleOrOption === "option" && (
                    <>
                      {row?.startDate && !row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate}
                        </Typography>
                      )}
                      {row?.startDate && row?.endDate && (
                        <Typography
                          variant="body1"
                          style={{ wordBreak: "break-word", maxWidth: "50ch" }}
                        >
                          {row?.startDate} {"    "}- {"    "} {row?.endDate}
                        </Typography>
                      )}
                    </>
                  )}
                  {row?.daysOfWeek?.length > 0 && (
                    <span style={{ display: "block" }}>{row.daysOfWeek.join(", ")}</span>
                  )}
                  {row?.timedEntries?.length > 0 && (
                    <span style={{ display: "block" }}>{row.timedEntries.join(", ")}</span>
                  )}
                  {row?.prices?.length > 0 &&
                    row?.prices.map((eachPrice, priceIndex) => (
                      <span key={priceIndex} style={{ display: "block" }}>
                        <span>
                          {eachPrice?.ageBand}: {eachPrice?.retailPrice}
                        </span>
                      </span>
                    ))}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

AvailabilitySchedulePanel.propTypes = {
  availabilitiesAdded: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
  scheduleOrOption: PropTypes.string,
};

export default AvailabilitySchedulePanel;
