import * as Yup from "yup";
import onlineStoreForm from "./form";

const {
  productName,
  price,
  defaultCurrencyCode,
  tag,
  netPrice,
  jobCode,
  phone,
  email,
  deliveryMethods,
} = onlineStoreForm.addProductsFields;
const { paymentMethod, orderProcessingType, systemPicker } = onlineStoreForm.settingFields;
const onlineStoreValidation = {
  addProductValidation: Yup.object().shape({
    [productName.name]: Yup.string()
      .required(productName.errorMessage)
      .matches(/[a-zA-Z]/, productName.invalidMessage),
    [price.name]: Yup.number()
      .nullable()
      .typeError(price.invalidMessage)
      .min(0.01, price.invalidMessage),
    [netPrice.name]: Yup.number()
      .nullable()
      .typeError(netPrice.invalidMessage)
      .min(0.01, netPrice.invalidMessage),
    [jobCode.name]: Yup.string().when("deliveryMethods", {
      is: (deliveryMethod) =>
        Array.isArray(deliveryMethod) &&
        (deliveryMethod.includes("grms") || deliveryMethod.length === 0),
      then: (schema) => schema.required("Job code is required!"),
      otherwise: (schema) => schema.notRequired(),
    }),

    [tag.name]: Yup.object()
      .test("tag-required", tag.errorMessage, (value) => value && value.value)
      .nullable(),
    [defaultCurrencyCode.name]: Yup.object()
      .test("currency-required", defaultCurrencyCode.errorMessage, (value) => value && value.code)
      .nullable(),
    [phone.name]: Yup.string()
      .matches(/^(\+|00)[0-9 ]{1,3}[0-9 ]{4,14}(?:x.+)?$/, phone.invalidMsg)
      .when("deliveryMethods", {
        is: (deliveryMethod) => deliveryMethod && deliveryMethod.includes("whatsapp"),
        then: () =>
          Yup.string()
            .required(phone?.errorMessage)
            .matches(/^[0-9]{4,14}$/, phone.invalidMsg),
        otherwise: () => Yup.string().notRequired(),
      }),
    [deliveryMethods.name]: Yup.array()
      .of(Yup.string())
      .min(1, "At least one delivery method is required!")
      .required("Delivery methods are required!"),

    [email.name]: Yup.string()
      .email(email.invalidMessage)
      .when("deliveryMethods", {
        is: (deliveryMethod) => deliveryMethod && deliveryMethod.includes("email"),
        then: (schema) => schema.required(email.errorMessage),
        otherwise: (schema) => schema.notRequired(),
      })
      .test("email-invalid", email.invalidMessage, (value) => {
        if (value && !Yup.string().email().isValidSync(value)) {
          return false;
        }
        return true;
      }),
  }),

  settingValidation: Yup.object().shape({
    [paymentMethod.name]: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(paymentMethod.errorMessage),
        })
      )
      .min(1, paymentMethod.errorMessage)
      .required(paymentMethod.errorMessage),

    [orderProcessingType.name]: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(orderProcessingType.errorMessage),
        })
      )
      .min(1, orderProcessingType.errorMessage)
      .required(orderProcessingType.errorMessage),

    [systemPicker.name]: Yup.string()
      .nullable()
      .test(
        "is-required-for-guestRequest",
        "System picker is required.",
        function isRequiredForGuestRequest(value) {
          const { orderProcessingType: parentOrderProcessingType } = this.parent;
          return Array.isArray(parentOrderProcessingType) &&
            parentOrderProcessingType.some((item) => item.name === "guestRequest")
            ? !!value
            : true;
        }
      ),
  }),
};

export default onlineStoreValidation;
