import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { partner },
} = checkout;
const validations = [
  Yup.object().shape({
    [partner?.name]: Yup.object().required(partner.errorMsg),
  }),
];

export default validations;
