import { Box, Checkbox, FormGroup, Typography, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Form, Formik } from "formik";

import { useDispatch } from "react-redux";
import { updateSettingsConfigs } from "store/actions/locationAction";

import MDButton from "components/MDButtonLoading";

import onlineStoreForm from "../../schemas/OnlineStore/form";
import validations from "../../schemas/OnlineStore/validation";

const ConfigureSettings = ({ setIconPressed, settingsConfigs, locationId }) => {
  /* ** Constants ** */
  const dispatch = useDispatch();
  const { settingValidation } = validations;
  const { onlineFormId, settingFields } = onlineStoreForm;
  const { paymentMethod } = settingFields;
  const initialValues = {
    email: settingsConfigs.email,
    orderProcessingType: [{ name: "emailToCs", label: "Email to CS" }],
    paymentMethod: settingsConfigs.paymentMethod?.length
      ? paymentMethod.options.filter((option) =>
          settingsConfigs.paymentMethod.includes(option.name)
        )
      : paymentMethod.options,
  };

  const resetSettingsForm = (resetForm) => {
    setIconPressed("");
    resetForm();
  };

  const handleSaveBttn = async (values, resetForm, validateForm) => {
    const validationErrors = await validateForm();
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const updatedValues = {
      staffEmail: values.staffEmail,
      paymentMethod: values.paymentMethod.map((item) => item.name),
    };

    dispatch(updateSettingsConfigs(updatedValues, locationId));
    resetSettingsForm(resetForm);
  };

  const styles = {
    formStyle: {
      gap: "20px",
      display: "flex",
      padding: "20px",
      flexDirection: "column",
    },

    paymentContainer: {
      gap: "10px",
      display: "flex",
      flexDirection: "column",
    },

    checkBoxContainer: {
      width: "100%",
      padding: "16px",
      borderRadius: "8px",
      border: "1px solid #ccc",
    },

    errorStyle: {
      marginTop: "5px",
      marginLeft: "10px",
    },

    actionBttnContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={settingValidation}
    >
      {({ values, errors, resetForm, setErrors, setFieldValue, validateForm }) => (
        <Form id={onlineFormId} autoComplete="off" style={styles.formStyle}>
          <Box style={styles.paymentContainer}>
            <Typography variant="h6" component="legend">
              Payment Method *
            </Typography>

            <Box>
              <FormGroup style={styles.checkBoxContainer}>
                {paymentMethod?.options.map(({ name, label }) => (
                  <FormControlLabel
                    key={name}
                    label={label}
                    control={
                      <Checkbox
                        name={name}
                        checked={values.paymentMethod.map((item) => item.name).includes(name)}
                        onChange={(e) => {
                          const updatedPaymentMethods = e.target.checked
                            ? [...values.paymentMethod, { name, label }]
                            : values.paymentMethod.filter((item) => item.name !== e.target.name);

                          if (errors.paymentMethod) {
                            const { paymentMethod: _, ...rest } = errors;
                            setErrors(rest);
                          }
                          setFieldValue("paymentMethod", updatedPaymentMethods);
                        }}
                      />
                    }
                  />
                ))}
              </FormGroup>

              {errors.paymentMethod && (
                <Typography variant="body2" color="error" style={styles.errorStyle}>
                  *{errors.paymentMethod}
                </Typography>
              )}
            </Box>
          </Box>

          <div style={styles.actionBttnContainer}>
            <MDButton
              color="dark"
              rel="noreferrer"
              variant="outlined"
              onClick={() => resetSettingsForm(resetForm)}
            >
              Cancel
            </MDButton>

            <div style={styles.actionBttns}>
              <MDButton
                color="dark"
                rel="noreferrer"
                variant="outlined"
                onClick={() => handleSaveBttn(values, resetForm, validateForm)}
              >
                Save
              </MDButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigureSettings;

ConfigureSettings.propTypes = {
  locationId: PropTypes.string.isRequired,
  setIconPressed: PropTypes.func.isRequired,
  settingsConfigs: PropTypes.instanceOf(Object).isRequired,
};
