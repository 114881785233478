// libraries
import PropTypes from "prop-types";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";

// custom components
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label": {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 0.9,
    height: "-webkit-fill-available",
  },
  "& .MuiInputLabel-shrink": {
    lineHeight: 1.5,
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& .css-1yoptbl-MuiInputBase-root-MuiInput-root:before, &:hover": {
    borderColor: "#D2D2D2!important",
    borderWidth: "1px !important",
  },
});

const LocationUserPartner = ({
  isUserPage = false,
  defaultLocationId = "",
  location = {},
  handlePage = () => {},
  handleChange = () => {},
  locations = [],
  disabled = false,
  ...rest
}) => {
  const modifiedOptions = isUserPage
    ? [{ name: "All", value: "all" }, ...locations]
    : [...locations, {}];

  return (
    <Autocomplete
      // id={idName}
      options={modifiedOptions}
      key={defaultLocationId}
      disableClearable
      disabled={disabled}
      value={
        isUserPage && defaultLocationId === null
          ? modifiedOptions[0]
          : locations.find((loc) => loc._id === defaultLocationId) || modifiedOptions[0]
      }
      onChange={(option, val) => {
        handlePage(0);
        handleChange(option, val);
      }}
      getOptionLabel={(option) => option.name || ""}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      placeholder={locations.filter((loc) => loc._id === defaultLocationId)[0]?.name}
      renderInput={(params) => (
        <CssTextField
          {...params}
          {...rest}
          name={location.name}
          variant="outlined"
          label={location.label}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
};

// typechecking props for LocationUserPartner
LocationUserPartner.propTypes = {
  defaultLocationId: PropTypes.string,
  itemType: PropTypes.string,
  disabled: PropTypes.bool,
  isUserPage: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  locations: PropTypes.instanceOf(Array),
  handlePage: PropTypes.func,
  handleChange: PropTypes.func,
};

export default LocationUserPartner;
