/* eslint-disable consistent-return */
/* eslint-disable camelcase, no-underscore-dangle, no-nested-ternary, react/no-danger, react/no-array-index-key */
import PropTypes from "prop-types";
import { format } from "date-fns";
import draftToHtml from "draftjs-to-html";
import { Fragment, useEffect, useState, useMemo } from "react";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { capitalizeFirstLetter, formatString } from "components/helper";

// Material UI components
import { Card, Chip, ImageList, ImageListItem, Avatar } from "@mui/material";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import SlidersList from "components/SlidersList";
import MDButton from "components/MDButton";
import styled from "@emotion/styled";
import Taxi from "assets/images/Taxi.svg";
import Train from "assets/images/TrainMetro.svg";
import Bus from "assets/images/Bus.svg";
import ConciergePromocodes from "pages/dashboards/concierge/components/Promocodes";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getOneUser } from "store/actions/userActions";
import PickupPointsList from "components/PickupPointsList";
import ItineraryItemsList from "components/ItineraryItemsLists";
import LanguagesDetailsList from "components/LanguagesDetailsList";
import AvailabilitieSchedulesDetailsList from "components/AutoComplete/AvailabilitiesSchedulesDetailsList";
import CopytoClipboard from "components/CopytoClipboard";

const BigAvatar = styled(Avatar)`
  width: 74px;
  height: 74px;
`;

// ----------------------------------------
// A small custom hook for converting files or strings to URLs
// ----------------------------------------
function useImageURLs({ arrayOfImages, shouldCleanUp = true }) {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    if (!arrayOfImages || !Array.isArray(arrayOfImages)) return;

    const urls = arrayOfImages
      .map((item) => {
        if (typeof item === "string") {
          return item;
        }
        if (item instanceof File) {
          return URL.createObjectURL(item);
        }
        return null;
      })
      .filter(Boolean);

    setImageUrls(urls);

    // Clean up
    return () => {
      if (shouldCleanUp) {
        urls.forEach((url) => {
          if (url?.startsWith("blob:")) {
            URL.revokeObjectURL(url);
          }
        });
      }
    };
  }, [arrayOfImages, shouldCleanUp]);

  return imageUrls;
}

// ----------------------------------------
// Helper function to parse JSON-based details or disclaimers
// ----------------------------------------
function parseRichTextDraft(data, hashtagConfig) {
  if (!data) return null;

  // data can be a string containing JSON or an object with blocks
  if (typeof data === "string" && data !== "{}") {
    try {
      const parsed = JSON.parse(data);
      return draftToHtml(parsed, hashtagConfig, true);
    } catch (error) {
      // If it's not valid JSON, just return null or the raw data
      return null;
    }
  }

  if (typeof data === "object" && data.blocks?.length > 0) {
    const contentState = ContentState.createFromText(data.blocks[0]?.text);
    const editorState = EditorState.createWithContent(contentState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(rawContentState, hashtagConfig);
  }

  return null;
}

const DetailList = ({
  title = "",
  data = {},
  conciergeDetailsPage = false,
  bannerDetailsPage = false,
}) => {
  const dispatch = useDispatch();
  const { user_id } = useParams();

  const user = useSelector((state) => state.users.specificUser);
  const userConcierge = useSelector((state) => state.users.user);

  const hashtagConfig = {
    trigger: "#",
    separator: " ",
  };

  // If we need to fetch user detail for the concierge details page
  useEffect(() => {
    if (user_id && conciergeDetailsPage) {
      dispatch(getOneUser(user_id));
    }
  }, [user_id, conciergeDetailsPage, dispatch]);

  // We want to figure out which array of images to pass to the hook
  let imagesToProcess = null;
  if (bannerDetailsPage && Array.isArray(data?.image)) {
    imagesToProcess = data?.image;
  } else if (Array.isArray(data?.images)) {
    imagesToProcess = data?.images;
  } else if (Array.isArray(data?.media)) {
    imagesToProcess = data?.media;
  } else if (Array.isArray(data?.coverPhoto)) {
    imagesToProcess = data?.coverPhoto;
  }

  const imageUrls = useImageURLs({ arrayOfImages: imagesToProcess });

  // Parse disclaimers or details as HTML
  const disclaimerMarkup = useMemo(
    () => parseRichTextDraft(data?.disclaimerText, hashtagConfig),
    [data?.disclaimerText]
  );
  const detailsMarkup = useMemo(
    () => parseRichTextDraft(data?.details, hashtagConfig),
    [data?.details]
  );

  // ----------------------------------------
  // Specialized rendering logic for each item type
  // ----------------------------------------
  const renderDetailValue = (value, itemName) => {
    // 1. Booleans => a chip
    if (typeof value === "boolean" && itemName !== "disclaimer") {
      return (
        <Chip
          label={capitalizeFirstLetter(formatString(itemName))}
          href="#basic-chip"
          clickable
          key={itemName}
          sx={{
            fontSize: "0.8rem",
            padding: "0rem",
            borderRadius: "0.5rem",
            width: "fit-content",
            height: "fit-content",
          }}
        />
      );
    }

    // 2. URL => copy to clipboard
    if (itemName === "url" && typeof value === "string") {
      return <CopytoClipboard text={value} />;
    }

    // 3. Strings (generic)
    if (typeof value === "string") {
      if (itemName === "dob" && value) {
        return format(new Date(value), "MM/dd/yyyy");
      }
      if (itemName === "file" && value) {
        const fileName = value.split("/").pop();
        return (
          <MDButton component="a" href={value} target="_blank" rel="noreferrer">
            {`View PDF - ${fileName}`}
          </MDButton>
        );
      }
      if (itemName === "logoName") {
        const logoUrl = value === "Bus" ? Bus : value === "Train" ? Train : Taxi;
        return (
          <>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {value}
            </MDTypography>
            <BigAvatar
              alt="Logo"
              src={logoUrl}
              sx={{ width: 100, height: 100, mt: 3, mb: 1 }}
              imgProps={{ style: { objectFit: "cover" } }}
            />
          </>
        );
      }
      // Media or coverPhoto (single string)
      if (
        (itemName === "media" || itemName === "coverPhoto") &&
        /\.(jpeg|jpg|png|gif|jfif)$/i.test(value)
      ) {
        return <img src={value} alt="media" style={{ width: 300, height: "164px" }} />;
      }
      if (itemName === "ctaLink") {
        return <span style={{ wordBreak: "break-all", whiteSpace: "normal" }}>{value}</span>;
      }
      return value || "--"; // fallback
    }

    // 4. Arrays
    if (Array.isArray(value)) {
      if (
        itemName === "images" ||
        itemName === "media" ||
        itemName === "coverPhoto" ||
        (itemName === "image" && bannerDetailsPage)
      ) {
        return (
          <ImageList cols={3} rowHeight={164} gap={16}>
            {imageUrls.map((imgSrc, i) => (
              <ImageListItem key={i}>
                <img
                  src={imgSrc}
                  alt={`images${i}`}
                  loading="lazy"
                  style={{ height: 164, backgroundColor: "black" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        );
      }
      if (itemName === "Promocodes") {
        return (
          <ConciergePromocodes
            detailsPage
            key={user?._id}
            title="Promocodes"
            promoCodes={value}
            user={userConcierge?.role === "concierge" ? userConcierge : user}
          />
        );
      }
      if (itemName === "additionalInfo") {
        return (
          <ul style={{ paddingLeft: "20px" }}>
            {value.map((item, index) => (
              <li key={index}>{item?.description || item}</li>
            ))}
          </ul>
        );
      }
      if (itemName === "sliders") {
        return <SlidersList data={value} />;
      }
      // Arrays of "included" / "not_included" / "highlights" => bullet each
      if (["included", "not_included", "highlights"].includes(itemName)) {
        return value.map((val, idx) => (
          <Fragment key={idx}>
            - {val}
            <br />
          </Fragment>
        ));
      }
      // Arrays of "exclusions" / "inclusions" => join by comma
      if (["exclusions", "inclusions"].includes(itemName)) {
        return value.map((obj) => obj?.type?.label || obj).join(", ");
      }
      // if array is empty return "--"
      if (value?.length === 0 || value === null || (value.length === 1 && value[0] === "")) {
        return "--";
      }
      // Default array fallback => show .name/.title or fallback
      return value
        .map(
          (obj) =>
            obj?.name ||
            obj?.title ||
            obj?.destinationName ||
            obj?.typeDescription ||
            obj?.label ||
            obj
        )
        .join(", ");
    }

    // 5. Objects
    // if (typeof value === "object" && value?.length === 0) {
    //   return "--";
    // }
    if (typeof value === "object" && value !== null) {
      // logistical data
      if (itemName === "logistics") {
        return <PickupPointsList data={value} />;
      }
      // itinerary
      if (itemName === "itinerary") {
        return <ItineraryItemsList data={value} />;
      }
      // languages
      if (itemName === "languageGuides") {
        return <LanguagesDetailsList data={value} />;
      }
      // availabilities
      if (itemName === "availabilities") {
        return <AvailabilitieSchedulesDetailsList data={value?.bookableItems} />;
      }
      // If the object has a .formatted_iso_value
      if (value.formatted_iso_value) {
        return value.formatted_iso_value;
      }
      // cta link or label
      if (
        (itemName === "callToAction" ||
          itemName === "callToAction2" ||
          itemName === "callToAction3") &&
        value.url
      ) {
        return `${value?.label} - ${value?.url}`;
      }
      // fallback for any other object with a name
      if (value.name) {
        return value.name;
      }
    }

    // 6. Numbers
    if (typeof value === "number") {
      return value;
    }

    // 7. Fallback
    return "--";
  };

  // Handle special logic for "available_quantity"
  const checkAvailableQuantity = (value) => (value >= 0 ? value : "--");

  // ----------------------------------------
  // Rendering the entire detail list
  // ----------------------------------------
  return (
    <Card sx={{ boxShadow: "none", width: "100%" }}>
      {title && (
        <MDBox pb={2}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize">
            {title}
          </MDTypography>
        </MDBox>
      )}

      {Object.entries(data || {}).map(([itemName, itemValue], index) => {
        // Render a spacer if itemName === "__space"
        if (itemName === "__space") {
          return <MDBox key={index} mt={1.5} />;
        }

        // If there's no value, skip
        const hasValue =
          itemValue || itemValue === 0 || (typeof itemValue === "boolean" && itemValue !== null);

        if (!hasValue && !["Promocodes", "details", "disclaimerText"].includes(itemName)) {
          return null;
        }

        // Special rendering for disclaimers
        if (itemName === "disclaimerText" && disclaimerMarkup) {
          return (
            <MDBox key={index} display="flex" pb="4px" lineHeight={1.25}>
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                Disclaimer:&nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                <div dangerouslySetInnerHTML={{ __html: disclaimerMarkup }} />
              </MDTypography>
            </MDBox>
          );
        }

        // Special rendering for details
        if (itemName === "details" && detailsMarkup) {
          return (
            <MDBox key={index} display="flex" pb="4px" lineHeight={1.25}>
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                Details:&nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                <div dangerouslySetInnerHTML={{ __html: detailsMarkup }} />
              </MDTypography>
            </MDBox>
          );
        }

        // if boolean and false retutn null
        if (typeof itemValue === "boolean" && !itemValue) {
          return null;
        }

        // Render all other items
        return (
          <MDBox key={index} display="flex" pb="4px" lineHeight={1.25} sx={{ overflow: "auto" }}>
            {/* Item Name Label (unless it is disclaimers or details) */}
            {!["disclaimerText", "details"].includes(itemName) &&
              typeof itemValue !== "boolean" &&
              ![
                "logistics",
                "availabilities",
                "languageGuides",
                "itinerary",
                "additionalInfo",
              ].includes(itemName) && (
                <MDTypography
                  component="span"
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                  whiteSpace="nowrap"
                  pr="4px"
                >
                  {itemName === "TRN" ? "TRN" : capitalizeFirstLetter(formatString(itemName))}:
                </MDTypography>
              )}
            <MDTypography component="span" variant="button" fontWeight="regular" color="text">
              {itemName === "available_quantity"
                ? checkAvailableQuantity(itemValue)
                : renderDetailValue(itemValue, itemName)}
            </MDTypography>
          </MDBox>
        );
      })}
    </Card>
  );
};

DetailList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({}),
  conciergeDetailsPage: PropTypes.bool,
  bannerDetailsPage: PropTypes.bool,
};

export default DetailList;
