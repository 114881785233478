/* eslint-disable no-nested-ternary */

import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";

// Redux Actions
import setAlert from "store/actions/alertActions";
import { clearErrors, deleteVoucher, deleteSingleVoucher } from "store/actions/voucherActions";
import LocationUserPartner from "components/LocationUserPartner";
import columnsMap from "components/MaterialTablePicker/TableColumns";
import { getUserPartnerLocations, setLoading, setLocation } from "store/actions/locationAction";
import { getPdf, getCsv } from "store/actions/exportAction";
import form from "../new-voucher/schemas/form";

const AllVouchers = () => {
  const { location } = form.formField;

  const tableRef = createRef();
  const dispatch = useDispatch();

  const vouchers = useSelector((state) => state.vouchers.vouchers);

  const error = useSelector((state) => state.vouchers.error);
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.vouchers.loading);
  const locations = useSelector((state) => state.locations.locations);
  const locationVoucher = useSelector((state) => state.locations.location);
  const added = useSelector((state) => state.vouchers.added);

  const [locationId, setLocationId] = useState("");
  const deleteVoucherFn = (id) => {
    dispatch(deleteVoucher(id));
  };

  const handleChange = (option, val) => {
    if (locationVoucher) dispatch(deleteSingleVoucher());
    if (val?._id) {
      setLocationId(val._id);
      dispatch(setLocation(val._id));
      tableRef.current?.onQueryChange();
    }
  };

  useEffect(() => {
    if (locations?.length > 0 && !locationVoucher) {
      setLocationId(locations[0]?._id);
    }
  }, [locations]);

  useEffect(() => {
    if (user.role === "partner") {
      dispatch(setLoading());
      dispatch(getUserPartnerLocations());
    } else if (user.role === "admin" && added) {
      dispatch(setLoading());
      // dispatch(getAllVouchers());
    }
  }, [user.role, added]);

  useEffect(() => {
    if (locationVoucher && locationId !== locationVoucher) {
      setLocationId(locationVoucher);
      dispatch(setLocation(locationVoucher));
      // dispatch(getLocationVoucher(locationId));
    }
  }, [locationVoucher, locationId]);

  // to add use Effect for set locaton ID
  useEffect(() => {
    if (locationVoucher && locationId !== locationVoucher) {
      setLocationId(locationVoucher);
      dispatch(setLocation(locationVoucher));
    }
  }, [locationVoucher]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  const selectedId =
    locationId?.length > 0 ? locationId : locationVoucher?.length > 0 ? locationVoucher : null;

  const getAsyncUrl = () => {
    if (user.role === "admin") {
      return `${process.env.REACT_APP_API}/vouchers?`;
    }
    if (selectedId?.length > 0 && user.role === "partner") {
      return `/v2/locations/${selectedId}/vouchers?`;
    }
    return `${process.env.REACT_APP_API}/vouchers?`;
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3}>
          <Grid item size={{ xs: 4 }}>
            {user?.role === "partner" && locations.length > 0 && (
              <LocationUserPartner
                defaultLocationId={locationId}
                handleChange={handleChange}
                locations={locations || {}}
                location={location}
              />
            )}
          </Grid>
          <Grid item size={{ xs: 12 }}>
            {(user.role === "admin" || (user.role === "partner" && locationId)) && (
              <MaterialTablePicker
                screen="VouchersList"
                title="Vouchers"
                key={added}
                searchField="vouchers"
                exportMenuField="vouchers"
                onRowClickURL={`/${user.role}/vouchers`}
                currentUrl="vouchers"
                tableRef={tableRef}
                columns={[
                  columnsMap.titleCol,
                  columnsMap.recommendation,
                  columnsMap.type,
                  columnsMap.valueCol,
                  columnsMap.quantityPerDay,
                  columnsMap.quantityRemaining,
                  columnsMap.startDate,
                  columnsMap.endDate,
                ]}
                asyncTable
                isLoading={loading}
                filteringOptions={false}
                addUrl="vouchers/new-voucher"
                actionsName="Voucher"
                asyncURL={getAsyncUrl()}
                editableDelete={deleteVoucherFn}
                deleteRoleAction
                editableRole={user.role === "admin" || user.role === "partner"}
                actionsRole={user.role === "admin" || user.role === "partner"}
                getPdf={(value) => {
                  if (value) {
                    if (user?.role === "partner") {
                      dispatch(
                        getPdf(
                          value,
                          `stats/pdf?limit=${vouchers?.length}&page=1&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers",
                          locationId
                        )
                      );
                    } else {
                      dispatch(
                        getPdf(
                          value,
                          `stats/pdf?limit=${vouchers?.length}&page=1&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers"
                        )
                      );
                    }
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    // eslint-disable-next-line no-console
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={(value) => {
                  if (value) {
                    if (user?.role === "partner") {
                      dispatch(
                        getCsv(
                          `stats/csv?limit=${vouchers?.length}&page=1&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers",
                          locationId
                        )
                      );
                    } else {
                      dispatch(
                        getCsv(
                          `stats/csv?limit=${vouchers?.length}&page=1&fields=title,recommendation,type,value,quantityPerDay,quantityRemaining,startDate,endDate`,
                          "Vouchers"
                        )
                      );
                    }
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    // eslint-disable-next-line no-console
                    console.error("Error: No data for CSV export.");
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </div>
  );
};

export default AllVouchers;
