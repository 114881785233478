import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
// import { getDroplistTrafficReports, setLoading } from "store/actions/reportActions";
// import { useDispatch } from "react-redux";

const CityReportPicker = ({
  setValCity = () => {},
  invoices = false,
  setFieldValue,
  handleChange = () => {},
  cities,
  loading = false,
  city = {},
}) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });
  //   const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(setLoading());
  //     dispatch(getDroplistTrafficReports());
  //     // eslint-disable-next-line
  //   }, []);
  return (
    <div>
      <SelectF
        onMenuOpen={document.body}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        closeMenuOnScroll={(e) => !e.target?.classList?.contains("select__menu-list")}
        className="basic-single"
        classNamePrefix="select"
        value={city?.name ? city : null}
        isLoading={loading}
        // isDisabled={isDisabled}
        isClearable
        onChange={(option) => {
          handleChange(option);
          if (invoices) {
            setFieldValue("city", option || {});
            setValCity(option);
          } else {
            setFieldValue("city", null);
            setValCity(null);
          }
          if (city) setFieldValue("city", option || {});
          else {
            setFieldValue("city", option || "");
          }
        }}
        isSearchable
        getOptionValue={(option) => `${option.value}`}
        getOptionLabel={(option) => `${option.name}`}
        name="city"
        id="city"
        options={cities}
        placeholder={invoices ? "Select a City" : "All"}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      />
    </div>
  );
};

CityReportPicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  cities: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  city: PropTypes.instanceOf(Object),
  invoices: PropTypes.bool,
  setValCity: PropTypes.func,
};

export default CityReportPicker;
