// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getPartnerLocations, setLoading } from "store/actions/locationAction";

const PartnerLocationPicker = ({ partnerId, locations, locationsV, setFieldValue, ...rest }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.locations.loading);
  const partnerLocations = useSelector((state) => state.locations.locations);

  // get partners location
  useEffect(() => {
    if (partnerId) {
      dispatch(setLoading());
      dispatch(getPartnerLocations(partnerId));
    }
  }, [partnerId]);

  return (
    <Autocomplete
      id="locations"
      multiple
      filterSelectedOptions
      //   renderTags={() => null}
      options={partnerLocations}
      onChange={(event, value) => {
        if (value) {
          setFieldValue("locations", value);
        } else {
          setFieldValue("locations", "");
        }
      }}
      loading={loading}
      value={locationsV || []}
      getOptionLabel={(option) => option.name || ""}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          label={locations.label}
          name={locations?.name}
          onChange={() => null}
        />
      )}
    />
  );
};

// typechecking props for PartnerLocationPicker
PartnerLocationPicker.propTypes = {
  partnerId: PropTypes.string.isRequired,
  locations: PropTypes.instanceOf(Object).isRequired,
  locationsV: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default PartnerLocationPicker;
