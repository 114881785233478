// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import badgesList from "./BadgesList";

const BadgesPicker = ({ handleBadgeChange = () => {}, badge = {} }) => {
  const SelectF = styled(Select)(() => ({
    width: "100%",
    fontSize: "15px",
  }));
  const getOptionStyle = (base) => ({
    ...base,
    fontSize: "15px",
  });
  return (
    <SelectF
      options={badgesList}
      placeholder="Select a badge"
      value={badge}
      onChange={(event) => {
        handleBadgeChange(event);
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option: getOptionStyle }}
      isSearchable
      isClearable
    />
  );
};

// typechecking props for BadgesPicker
BadgesPicker.propTypes = {
  handleBadgeChange: PropTypes.func,
  badge: PropTypes.instanceOf(Object),
};

export default BadgesPicker;
