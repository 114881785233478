import { Tooltip, Input, Chip, Avatar, Typography } from "@mui/material";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import PendingIcon from "@mui/icons-material/Pending";
import React from "react";

import RoleFilter from "components/Filters/RoleFilter";
import SourceFilter from "components/Filters/SourceFilter";
import CityFilter from "components/Filters/CityFilter";
import CategoryRecFilter from "components/Filters/CategoryRecFilter";
import LoginFilter from "components/Filters/LoginFilter";
import TravelDeskFilter from "components/Filters/TravelDeskFilter";
import TagFilter from "components/Filters/TagFilter";
import DestinationsFilter from "components/Filters/DestinationsFilter";
import { dateFormatWithDash } from "components/helper";

import LocationsFilter from "components/Filters/LocationsFilter";
import LocationsQuickeditPicker from "components/PickersOnQuickedit/LocationsQuickeditPicker";

const generalCellStyle = {
  maxWidth: 200,
  minWidth: 100,
  width: 100,
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

/**
 * A dictionary of reusable column definitions keyed by descriptive IDs.
 * You can import this map, then pick whichever columns you need by referencing
 * these keys. For example:
 *
 *   import columnsMap from "./columnsMap";
 *   const partnerColumns = [
 *     columnsMap.partnerName,
 *     columnsMap.numberOfLicences,
 *     columnsMap.contactName,
 *     ...
 *   ];
 */

const renderStatus = (rowData) => {
  if (rowData?.status === "published") {
    return (
      <span>
        <span style={{ color: "green", marginRight: "5px" }}>●</span>
        <span>{rowData.name}</span>
      </span>
    );
  }
  if (rowData?.status === "archived") {
    return (
      <span>
        <span style={{ color: "red", marginRight: "5px" }}>●</span>
        <span>{rowData.name}</span>
      </span>
    );
  }
  return <span>{rowData.name}</span>;
};

// function for this one too: {rowData.type === "subCategory"
// ? rowData.subCategory
// : rowData.type === "category"
// ? rowData.categoryName
// : rowData.tag}

const renderSubCategory = (rowData) => {
  if (rowData.type === "subCategory") {
    return rowData.subCategory;
  }
  return rowData.type === "category" ? rowData.categoryName : rowData.tag;
};

const columnsMap = {
  recommendationsTitles: {
    title: "Recommendations Titles",
    field: "title",

    defaultGroupOrder: 0,
  },
  titleCol: {
    title: "Title",
    field: "title",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.title}>
        <span>{rowData.title}</span>
      </Tooltip>
    ),
    defaultFilter: "",
  },
  bannerText: {
    title: "Banner Text",
    field: "title",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.title}>
        <span>{rowData.title}</span>
      </Tooltip>
    ),
  },
  loginType: {
    title: "Login Type",
    field: "loginType",
    cellStyle: generalCellStyle,
    // eslint-disable-next-line react/no-unstable-nested-components
    filterComponent: (props) => <LoginFilter {...props} />,
    defaultFilter: "",
  },
  description: {
    title: "Description",
    field: "description",
    cellStyle: generalCellStyle,
  },
  // //////////////////////////////////////////
  jobCategory: {
    title: "Job Category",
    field: "category",
    editable: "never",
    render: (rowData) => (
      <Tooltip title={rowData?.category || rowData?.tags?.[0]?.title}>
        <span>{rowData?.category || rowData?.tags?.[0]?.title}</span>
      </Tooltip>
    ),
    cellStyle: generalCellStyle,
  },
  jobSubcategory: {
    title: "Job Subcategory",
    field: "subcategory",
    editable: "never",
    render: (rowData) => (
      <Tooltip
        title={rowData?.subCategory || rowData?.subTags?.[0]?.title || rowData?.subTags?.[0]?.label}
      >
        <span>
          {rowData?.subCategory || rowData?.subTags?.[0]?.title || rowData?.subTags?.[0]?.label}
        </span>
      </Tooltip>
    ),
    cellStyle: generalCellStyle,
  },
  jobName: {
    title: "Job Name",
    field: "title",
    cellStyle: generalCellStyle,
  },
  jobCode: {
    title: "Job Code",
    field: "code",
    cellStyle: generalCellStyle,
  },
  tier: {
    title: "Tier",
    field: "tier",
    cellStyle: generalCellStyle,
    render: (rowData) => {
      const Render = () => <span>{rowData.status === "published" && rowData?.tier}</span>;
      return <Render {...rowData} />;
    },
  },
  partnerName: {
    title: "Name",
    field: "name",
    cellStyle: generalCellStyle,
    render: (rowData) => <Tooltip title={rowData?.name}>{renderStatus(rowData)}</Tooltip>,
    defaultFilter: "",
  },

  partnerNameNotEditable: {
    title: "Name",
    field: "name",
    cellStyle: generalCellStyle,
    render: (rowData) => <Tooltip title={rowData?.name}>{renderStatus(rowData)}</Tooltip>,
    defaultFilter: "",
    editable: "never",
  },

  cityName: {
    title: "City Name",
    field: "name",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.name}>
        <span>{rowData.name}</span>
      </Tooltip>
    ),
    defaultFilter: "",
  },

  slug: {
    title: "Slug",
    field: "slug",
    editable: "never",
  },

  emailCol: {
    title: "Email",
    field: "email",
    editable: "never",
  },

  dateCol: {
    title: "Date",
    field: "date",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    cellStyle: generalCellStyle,
    editable: "never",
    filterComponent: () => "",
  },

  // pass an empty string when there is no title
  emptyTitle: {
    title: "",
    field: "pageTitle",
    cellStyle: {
      maxWidth: 200,
      whiteSpace: "wrap",
    },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.pageTitle}</Typography>
      </div>
    ),
    editable: "never",
  },

  totalPrice: {
    title: "Total Price",
    field: "total",
    render: (rowData) =>
      rowData.total && (
        <div>
          {parseFloat(rowData?.total).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      ),
    defaultFilter: "",
  },

  ticketSource: {
    title: "Ticket Source",
    field: "source",
    editable: "never",
    cellStyle: generalCellStyle,
    filterComponent: (props) => <SourceFilter {...props} pageName="items" />,
  },
  sourceRecommendations: {
    title: "Source",
    field: "source",
    editable: "never",
    cellStyle: generalCellStyle,
    render: (rowData) => (rowData.source === "lassad" ? "Trip advisor" : rowData.source),
    filterComponent: (props) => <SourceFilter {...props} pageName="recommendations" />,
  },
  sourceInvoices: {
    title: "Source",
    field: "source",
    editable: "never",
    cellStyle: generalCellStyle,
    filterComponent: (props) => <SourceFilter {...props} pageName="invoices" />,
  },

  roleCol: {
    title: "Role",
    field: "role",
    // eslint-disable-next-line react/no-unstable-nested-components
    filterComponent: (props) => <RoleFilter {...props} />,
    defaultFilter: "",
  },

  partnerField: {
    title: "Partner",
    field: "partner.name",
    cellStyle: { ...generalCellStyle, maxWidth: 400 },
    editable: "never",
  },

  valueCol: {
    title: "Value",
    field: "value",
    cellStyle: { ...generalCellStyle, maxWidth: 400 },
    editable: "never",
  },

  topCol: {
    title: "Top",
    field: "top",
    cellStyle: { ...generalCellStyle, maxWidth: 400 },
    editable: "never",
  },
  topFilterDisabled: {
    title: "Top",
    field: "top",
    cellStyle: { ...generalCellStyle, maxWidth: 400 },
    editable: "never",
    filterComponent: () => "",
  },

  quantityPerDay: {
    title: "Quantity",
    field: "quantityPerDay",
    cellStyle: { ...generalCellStyle, maxWidth: 400 },
    editable: "never",
  },

  quantityRemaining: {
    title: "Quantity Remaining",
    field: "quantityRemaining",
    cellStyle: { ...generalCellStyle, maxWidth: 400 },
    editable: "never",
  },

  lastLogin: {
    title: "Last login date",
    field: "lastLogin",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    // type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    render: (rowData) => rowData?.lastLogin && <div>{dateFormatWithDash(rowData?.lastLogin)}</div>,
    editable: "never",
    filterComponent: () => "",
  },

  registrationDateAsync: {
    title: "Registration Date",
    field: "createdAt",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    // type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    render: (rowData) => rowData.createdAt && <div>{dateFormatWithDash(rowData?.createdAt)}</div>,
    editable: "never",
    filterComponent: () => "",
  },

  registrationDateNonAsync: {
    title: "Registration Date",
    field: "createdAt",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    // type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    render: (rowData) => rowData.createdAt && <div>{dateFormatWithDash(rowData?.createdAt)}</div>,
  },
  creationDateAsync: {
    title: "Creation Date",
    field: "createdAt",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    // type: "date",
    render: (rowData) => rowData.createdAt && <div>{dateFormatWithDash(rowData?.createdAt)}</div>,
    dateSetting: {
      locale: "en-GB",
    },
    editable: "never",
    filterComponent: () => "",
  },

  creationDateNonAsync: {
    title: "Creation Date",
    field: "createdAt",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    // type: "date",
    render: (rowData) => rowData.createdAt && <div>{dateFormatWithDash(rowData?.createdAt)}</div>,
    dateSetting: {
      locale: "en-GB",
    },
  },

  activeCol: {
    title: "Active",
    field: "active",
    type: "boolean",
    editable: "never",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) =>
      rowData.active ? (
        <div>
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div>
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  pdfFiles: {
    title: "Pdfs",
    field: "files",
    type: "boolean",
    render: (rowData) =>
      rowData?.files?.length > 0 ? (
        <div>
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div>
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  verified: {
    title: "Verified",
    field: "verified",
    type: "boolean",
    editable: "never",
    render: (rowData) =>
      rowData.verified ? (
        <div>
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div>
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  numberOfLicences: {
    title: "Number of Licences",
    field: "licence",
    // type: "numeric",
    cellStyle: generalCellStyle,
    // eslint-disable-next-line react/no-unstable-nested-components
    editComponent: (props) => (
      <Input
        type="number"
        value={props.value}
        onChange={(e) => (e.target.value >= 1 ? props.onChange(e.target.value) : 1)}
      />
    ),
  },

  contactName: {
    title: "Contact Name",
    field: "contactName",
    cellStyle: generalCellStyle,
  },
  contactEmail: {
    title: "Contact Email",
    field: "contactEmail",
    cellStyle: generalCellStyle,
    filterComponent: () => "",
  },
  userName: {
    title: "User Name",
    field: "userName",
  },
  cartId: {
    title: "Cart ID",
    field: "_id",
    editable: "never",
    render: (rowData) => <span>{rowData?._id}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  totalCartAmount: {
    title: "Cart Amount",
    field: "totalAmount",
    editable: "never",
    render: (rowData) => <span>{rowData?.totalAmount?.value}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  totalAmountUSD: {
    title: "Cart Amount (USD)",
    field: "totalAmountUSD",
    editable: "never",
    render: (rowData) => <span>{rowData?.totalAmountUSD?.value}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  screenPageViews: {
    title: "Total views",
    field: "screenPageViews",
    editable: "never",
    render: (rowData) => <span>{rowData?.traffic?.screenPageViews}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  reservation: {
    title: "Bookable",
    field: "reservation",
    editable: "never",
    render: (rowData) => (
      <span>{rowData?.reservation?.bookingEmail ? "Bookable" : "Unbookable"}</span>
    ),
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  invoiceNumber: {
    title: "Invoice Number",
    field: "invoiceNumber",
    editable: "never",
    render: (rowData) => <span>{rowData?.invoiceNumber}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  nameField: {
    title: "Name",
    field: "name",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.name}>
        <span>{rowData.name}</span>
      </Tooltip>
    ),
  },
  userNameCol: {
    title: "User Name",
    field: "user.name",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.user?.name}>
        <span>{rowData.user?.name}</span>
      </Tooltip>
    ),
  },

  userEmail: {
    title: "User Email",
    field: "user.email",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.user?.email}>
        <span>{rowData.user?.email}</span>
      </Tooltip>
    ),
  },
  quantity: {
    title: "Quantity Number",
    field: "quantity",
    editable: "never",
    render: (rowData) => <span>{rowData?.quantity}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  quantitytotalPax: {
    title: "Quantity",
    field: "totalPax",
    editable: "never",
    render: (rowData) => <span>{rowData?.totalPax}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  purchaseDate: {
    title: "Purchase Date",
    field: "purchaseDate",
    editable: "never",
    render: (rowData) => <span>{rowData?.purchaseDate}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  purchaseCreatedDate: {
    title: "Purchase Date",
    field: "createdAt",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    editable: "never",
    render: (rowData) => <span>{dateFormatWithDash(rowData?.createdAt)}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  bookingDate: {
    title: "Booking Date",
    field: "bookingDate",
    editable: "never",
    render: (rowData) => <span>{rowData?.bookingDate}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  subTotalAmount: {
    title: "Subtotal Amount",
    field: "subTotal",
    editable: "never",
    render: (rowData) => <span>{rowData?.subTotal?.toFixed(2)}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  subTotal: {
    title: "Subtotal",
    field: "subTotal",
    editable: "never",
    render: (rowData) => {
      // Provide a default value (0) if rowData.discount or rowData.totalAmount is undefined
      const discount = rowData?.discount || 0;
      const totalAmount =
        rowData?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice || 0;

      return (
        <span>
          {discount + totalAmount} {rowData?.currency}
        </span>
      );
    },
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  orderAmountUSD: {
    title: "Order Total",
    field: "orderAmountUSD",
    editable: "never",
    render: (rowData) => <span>{rowData?.orderAmount?.toFixed(2)}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  discount: {
    title: "Discount Amount",
    field: "discount",
    editable: "never",
    render: (rowData) => <span>{rowData?.discount?.toFixed(2)}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  discountCurrency: {
    title: "Discount Amount",
    field: "discount",
    editable: "never",
    render: (rowData) => (
      <span>
        {rowData?.discount?.toFixed(2) || 0} {rowData?.currency}
      </span>
    ),
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  totalOrderAmount: {
    title: "Order Total",
    field: "totalAmount",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <span>
        {rowData?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice}{" "}
        {rowData?.currency}
      </span>
    ),
  },
  commissionPaid: {
    title: "Partner commission",
    field: "commissionPaid",
    editable: "never",
    render: (rowData) => <span>{rowData?.commissionPaid?.toFixed(2)}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  conciergeCommission: {
    title: "Commission ($)",
    field: "conciergeCommission",
    editable: "never",
    render: (rowData) => (
      <span>
        {rowData?.conciergeCommissionOrder?.commissionUSD
          ? rowData?.conciergeCommissionOrder?.commissionUSD
          : 0}
      </span>
    ),
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  promoCodes: {
    title: "Promocode",
    field: "promoCodes",
    editable: "never",
    render: (rowData) =>
      rowData.promoCodes && (
        <div>
          {rowData?.promoCodes?.map((val) => (
            <Chip
              label={val}
              href="#basic-chip"
              key={val}
              style={{ margin: "0px", fontSize: "12px", height: "22px" }}
            />
          ))}
        </div>
      ),
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },

  tag: {
    title: "Tag",
    field: "tag",
    editable: "never",
    render: (rowData) => <span>{rowData?.tags[0]?.title}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    filterComponent: (props) => <TagFilter {...props} />,
  },

  tagstheme: {
    title: "Theme",
    field: "tags",
    editable: "never",
    render: (rowData) =>
      rowData.tags && (
        <div>
          <Chip
            label={rowData?.tags[1]?.title}
            href="#basic-chip"
            key={rowData?.tags[1]?.title}
            style={{ margin: "0px", fontSize: "12px", height: "22px" }}
          />
        </div>
      ),
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    filterComponent: (props) => <TagFilter {...props} />,
  },

  nbOfTickets: {
    title: "Nbr of Tickets",
    field: "tickets",
    editable: "never",
    render: (rowData) => <span>{rowData?.tickets?.length}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  trafficTotalUsers: {
    title: "Total users",
    field: "totalUsers",
    editable: "never",
    render: (rowData) => <span>{rowData?.traffic?.totalUsers}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  newUsers: {
    title: "New users",
    field: "newUsers",
    editable: "never",
    render: (rowData) => <span>{rowData?.traffic?.newUsers}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  signups: {
    title: "Number of sign ups",
    field: "signups",
    editable: "never",
    render: (rowData) => <span>{rowData?.events?.signups}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  logins: {
    title: "Number of logins",
    field: "logins",
    editable: "never",
    render: (rowData) => <span>{rowData?.events?.logins}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  purchaseRevenue: {
    title: "Revenue",
    field: "purchaseRevenue",
    editable: "never",
    render: (rowData) => <span>{rowData?.traffic?.purchaseRevenue}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  totalTickets: {
    title: "Tickets sold",
    field: "totalTickets",
    editable: "never",
    render: (rowData) => <span>{rowData?.totalTickets}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  averagePurchaseRevenuePerUser: {
    title: "Conversion ratio (purchases/users)",
    field: "averagePurchaseRevenuePerUser",
    editable: "never",
    render: (rowData) => <span>{rowData?.traffic?.averagePurchaseRevenuePerUser}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  userEmailCol: {
    title: "User Email",
    field: "userEmail",
  },
  restaurantName: {
    title: "Restaurant",
    field: "restaurantName",
  },
  type: {
    title: "Type",
    field: "type",
    cellStyle: generalCellStyle,
    editable: "never",
    render: (rowData) => {
      const Render = () => <Typography variant="body2">{rowData.type}</Typography>;
      return <Render {...rowData} />;
    },
  },

  typeCat: {
    title: "Type Name",
    field: "type",
    cellStyle: generalCellStyle,
    editable: "never",
    render: (rowData) => {
      const Render = () => <Typography variant="body2">{renderSubCategory(rowData)}</Typography>;
      return <Render {...rowData} />;
    },
  },

  statusDisabled: {
    title: "Status",
    field: "disabled",
    cellStyle: generalCellStyle,
    editable: "never",
    render: (rowData) => {
      const Render = () => (
        <Typography variant="body2">
          {rowData.disabled === true ? "disabled" : "enabled"}
        </Typography>
      );
      return <Render {...rowData} />;
    },
  },

  status: {
    title: "Status",
    field: "status",
  },
  statusDisplay: {
    title: "Status",
    field: "display",
  },
  bookedAt: {
    title: "Booked For",
    field: "bookedAt",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
  },
  alternative: {
    title: "Alternatives Tag",
    field: "alternative",
    cellStyle: generalCellStyle,
    render: (rowData) => {
      const Render = () => (
        <Tooltip title={rowData.alternative}>
          <span>{rowData.alternative}</span>
        </Tooltip>
      );
      return <Render {...rowData} />;
    },
    editable: "never",
    defaultFilter: "",
  },
  stopName: {
    title: "Stop Name",
    field: "stop.title",
    cellStyle: generalCellStyle,
    render: (rowData) => {
      <Typography variant="body2">{rowData.stop?.title}</Typography>;
      const Render = () => (
        <Tooltip title={rowData.stop?.title}>
          <span> {rowData.stop?.title ? rowData.stop?.title : "Stop not found"}</span>
        </Tooltip>
      );
      return <Render {...rowData} />;
    },
    editable: "never",
    defaultFilter: "",
  },
  groupTitle: {
    title: "Group Title",
    field: "title",
    defaultGroupOrder: 0,
  },

  stopType: {
    title: "Stop Type",
    field: "type",
    cellStyle: generalCellStyle,
    headerStyle: {
      fontSize: "11px!important", // Set the font size to 11px
    },
    render: (rowData) => {
      <Typography variant="body2">{rowData?.type}</Typography>;
      const Render = () => (
        <Tooltip title={rowData?.type}>
          <span> {rowData?.type ? rowData?.type : "Type not found"}</span>
        </Tooltip>
      );
      return <Render {...rowData} />;
    },
    editable: "never",
  },
  message: {
    title: "Message",
    field: "message",
    cellStyle: generalCellStyle,
    render: (rowData) => {
      const Render = () => <Typography variant="body2">{rowData.message}</Typography>;
      return <Render {...rowData} />;
    },

    editable: "never",
    defaultFilter: "",
  },
  duration: {
    title: "Duration",
    field: "duration",
    cellStyle: generalCellStyle,
    render: (rowData) => {
      const Render = () => <Typography variant="body2">{rowData.duration}</Typography>;
      return <Render {...rowData} />;
    },

    editable: "never",
    defaultFilter: "",
  },
  order: {
    title: "Order",
    field: "order",
    cellStyle: {
      maxWidth: 50,
      minWidth: 50,
      width: 50,
      textAlign: "left",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    editable: "never",
  },
  cityWithFilter: {
    title: "City",
    field: "city",
    cellStyle: generalCellStyle,
    editable: "never",

    // -------------AttractionsList-ExperiencesList-JobsList------------

    filterComponent: (props) => <CityFilter {...props} />,
  },
  retailPrice: {
    title: "Price",
    field: "retail_price.formatted_iso_value",
    cellStyle: generalCellStyle,
    editable: "never",
  },
  fromPrice: {
    title: "Price",
    field: "price.fromPrice",
    cellStyle: generalCellStyle,
    editable: "never",
  },
  // price without filtering
  fromPriceWithFilter: {
    title: "Price",
    field: "price.fromPrice",
    cellStyle: generalCellStyle,
    editable: "never",
    filterComponent: () => "",
  },
  travelDeskName: {
    title: "TravelDesk",
    field: "travelDesk.name",
    cellStyle: generalCellStyle,
    editable: "never",

    filterComponent: (props) => <TravelDeskFilter {...props} />,
  },

  country: {
    title: "Country",
    field: "country.name",
    editable: "never",
    cellStyle: generalCellStyle,
  },
  countryCode: {
    title: "Country Code",
    field: "country.iso_code",
    editable: "never",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  latitude: {
    title: "Latitude",
    field: "latitude",
    editable: "never",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  longitude: {
    title: "Longitude",
    field: "longitude",
    editable: "never",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  primaryDestination: {
    title: "Destinations",
    field: "primaryDestination",
    editable: "never",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData?.primaryDestination?.destinationName}>
        <span>{rowData?.primaryDestination?.destinationName}</span>
      </Tooltip>
    ),
    filterComponent: (props) => <DestinationsFilter {...props} />,
  },
  productCount: {
    title: "Product Count",
    field: "productCount",
    editable: "never",
    cellStyle: generalCellStyle,
  },
  primaryDestinationName: {
    title: "Primary Destination",
    field: "primaryDestinationName",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.primaryDestinationName}>
        <span>{rowData.primaryDestinationName}</span>
      </Tooltip>
    ),
    filterComponent: (props) => <DestinationsFilter {...props} />,
  },
  primaryDestinationWithFilter: {
    title: "Primary Destination",
    field: "primaryDestination",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData?.primaryDestination}>
        <span>{rowData?.primaryDestination}</span>
      </Tooltip>
    ),
    filterComponent: (props) => <DestinationsFilter {...props} />,
  },
  primaryDestinationField: {
    title: "Primary Destination",
    field: "primaryDestination",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData?.primaryDestination}>
        <span>{rowData?.primaryDestination}</span>
      </Tooltip>
    ),
    filterComponent: () => "",
  },
  destinationName: {
    title: "Destination Name",
    field: "destinationName",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData?.destinationName}>
        <span>{rowData?.destinationName}</span>
      </Tooltip>
    ),
    defaultFilter: "",
  },
  destinations: {
    title: "Destination",
    field: "destinations",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.destinations[0]?.destinationName}>
        <span>{rowData.destinations[0]?.destinationName}</span>
      </Tooltip>
    ),
    filterComponent: (props) => <DestinationsFilter {...props} />,
  },
  destinationType: {
    title: "Destination Type",
    field: "destinationType",
    editable: "never",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.destinationType}>
        <span>{rowData.destinationType}</span>
      </Tooltip>
    ),
    defaultFilter: "",
  },
  geoLocationLatitude: {
    title: "Latitude",
    field: "latitude",
    editable: "never",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <Tooltip title={rowData?.geoLocation?.coordinates[1]}>
        <span>{rowData?.geoLocation?.coordinates[1]}</span>
      </Tooltip>
    ),
  },
  geoLocationLongitude: {
    title: "Longitude",
    field: "longitude",
    editable: "never",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <Tooltip title={rowData?.geoLocation?.coordinates[0]}>
        <span>{rowData?.geoLocation?.coordinates[0]}</span>
      </Tooltip>
    ),
  },
  top: {
    title: "Top",
    field: "top",
    editable: "never",
    type: "boolean",
    render: (rowData) =>
      rowData.top ? (
        <div>
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div>
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  timeNeededToFinishExperience: {
    title: "Time Experience",
    field: "timeNeededToFinishExperience",
    cellStyle: generalCellStyle,
    editable: "never",
  },
  option: {
    title: "Option",
    field: "option",
    cellStyle: generalCellStyle,
    editable: "never",
  },
  attributetype: {
    title: "Attribute",
    field: "type",
    cellStyle: generalCellStyle,
    render: (rowData) => {
      if (rowData.tag) {
        return <Typography variant="body2">{rowData.tag}</Typography>;
      }
      if (rowData.subCategory) {
        return <Typography variant="body2">{rowData.subCategory}</Typography>;
      }
      return <Typography variant="body2">{rowData.categoryName}</Typography>;
    },
    editable: "never",
  },

  pdfFileCol: {
    title: "Pdf",
    field: "pdf",
    type: "boolean",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) =>
      rowData?.file?.length > 0 ? (
        <div>
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div>
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  hasItems: {
    title: "Has Items",
    field: "hasItems",
    type: "boolean",
    render: (rowData) =>
      !rowData.hasItems ? (
        <Typography variant="body2" sx={{ color: "red" }}>
          Empty!
        </Typography>
      ) : (
        <Typography variant="body2" sx={{ color: "green" }}>
          Total: {rowData.total}
        </Typography>
      ),
  },
  departmentCol: {
    title: "Department",
    field: "department",
    cellStyle: { ...generalCellStyle, maxWidth: 190 },
    editable: "never",
  },
  trendingStart: {
    title: "Trending Start",
    field: "trendingStart",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    cellStyle: generalCellStyle,
    editable: "never",
    filterComponent: () => "",
    render: (rowData) => rowData?.trendingStart && dateFormatWithDash(rowData.trendingStart),
  },
  trendingEnd: {
    title: "Trending End",
    field: "trendingEnd",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    cellStyle: generalCellStyle,
    editable: "never",
    filterComponent: () => "",
    render: (rowData) => rowData?.trendingEnd && dateFormatWithDash(rowData.trendingEnd),
  },
  approvalStatusWithFilter: {
    title: "Approval Status",
    field: "approvalStatus",
    type: "boolean",
    filterComponent: () => "",
    render: (rowData) =>
      // eslint-disable-next-line no-nested-ternary
      rowData.approvalStatus === "approved" ? (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : rowData.approvalStatus === "rejected" ? (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Clear style={{ color: "red" }} />
        </div>
      ) : (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <PendingIcon style={{ color: "grey" }} />
        </div>
      ),
  },
  approvalStatusBool: {
    title: "Approval Status",
    field: "approvalStatus",
    type: "boolean",
    render: (rowData) =>
      // eslint-disable-next-line no-nested-ternary
      rowData.approvalStatus === "approved" ? (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : rowData.approvalStatus === "rejected" ? (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Clear style={{ color: "red" }} />
        </div>
      ) : (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <PendingIcon style={{ color: "grey" }} />
        </div>
      ),
  },
  // these start date and end date are for the async tables
  startDateWithFilter: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    render: (rowData) => <span>{dateFormatWithDash(rowData?.startDate)}</span>,
    cellStyle: generalCellStyle,
    editable: "never",
    filterComponent: () => "",
  },
  endDateWithFilter: {
    title: "End Date",
    field: "endDate",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    render: (rowData) => <span>{dateFormatWithDash(rowData?.endDate)}</span>,
    cellStyle: generalCellStyle,
    editable: "never",
    filterComponent: () => "",
  },
  // these start date and date are for the non-async tables
  startDate: {
    title: "Start Date",
    field: "startDate",
    type: "date",
    render: (rowData) => (
      <span>{rowData?.startDate ? dateFormatWithDash(rowData?.startDate) : ""}</span>
    ),
    dateSetting: {
      locale: "en-GB",
    },
    cellStyle: generalCellStyle,
  },
  endDate: {
    title: "End Date",
    field: "endDate",
    type: "date",
    render: (rowData) => (
      <span>{rowData?.endDate ? dateFormatWithDash(rowData?.endDate) : ""}</span>
    ),
    dateSetting: {
      locale: "en-GB",
    },
    cellStyle: generalCellStyle,
  },
  address: {
    title: "Address",
    field: "geoLocationData.address",
    cellStyle: { ...generalCellStyle, maxWidth: 300 },
  },
  // display city for field geolocation.city with space after city
  geoLocationCityWithFilter: {
    title: "City",
    field: "geoLocationData.city",
    cellStyle: generalCellStyle,
    filterComponent: (props) => <CityFilter {...props} />,
  },
  city: {
    title: "City Name",
    field: "geoLocationData.city",
    cellStyle: generalCellStyle,
  },
  ndOfRooms: {
    title: "Number of Rooms",
    field: "numRooms",
    cellStyle: { ...generalCellStyle, textAlign: "left" },
    render: (rowData) => rowData.numRooms || 0,
  },
  expandable: {
    title: "Expandable",
    field: "expandable",
    type: "boolean",
    render: (rowData) =>
      rowData.expandable === true ? (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  Author: {
    title: "Author",
    field: "author",
    cellStyle: { ...generalCellStyle, maxWidth: 190 },
    editable: "never",
  },
  summary: {
    title: "Summary",
    field: "summary",
    editable: "never",
    render: (rowData) => (
      <Tooltip title={rowData.summary}>
        <span>{rowData.summary}</span>
      </Tooltip>
    ),
    cellStyle: generalCellStyle,
    defaultFilter: "",
  },
  recommendationName: {
    title: "Recommendation",
    field: "recommendationName",
    editable: "never",
    render: (rowData) => <span>{rowData.placeName}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    defaultFilter: "",
  },

  recommendation: {
    title: "Recommendation Title",
    field: "recommendation",
    editable: "never",
    render: (rowData) => <span>{rowData.recommendation?.title}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    defaultFilter: "",
  },
  locations: {
    title: "Locations",
    field: "locations",
    type: "array",
    filterComponent: (props) => <LocationsFilter {...props} />,

    editComponent: (props) => (
      <LocationsQuickeditPicker value={props.rowData.locations} onChange={props.onChange} />
    ),

    render: (rowData) =>
      rowData.locations && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {rowData?.locations?.slice(0, 3).map((val) => (
            <Chip
              label={val?.name}
              key={val._id}
              style={{ margin: "2px", fontSize: "12px", height: "22px" }}
            />
          ))}
        </div>
      ),
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },

  sourceLocation: {
    title: "Source",
    field: "location",
    cellStyle: generalCellStyle,
    editable: "never",
    render: (rowData) => {
      const Render = () => (
        <Typography variant="body2" style={{ fontSize: "inherit", fontWeight: "inherit" }}>
          {rowData.role === "concierge"
            ? rowData.location?.slug || rowData?.city
            : rowData.sourceLocation}
        </Typography>
      );
      return <Render {...rowData} />;
    },
  },

  position: {
    title: "Position",
    field: "position",
    cellStyle: generalCellStyle,
    editable: "never",
    render: (rowData) => {
      const Render = () => (
        <Typography variant="body2" style={{ fontSize: "inherit", fontWeight: "inherit" }}>
          {rowData?.position?.label}
        </Typography>
      );
      return <Render {...rowData} />;
    },
  },
  locationForPartnerPower: {
    title: "Location",
    field: "location",
    editable: "never",
    render: (rowData) => <span>{rowData?.location}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },

  location: {
    title: "Location",
    field: "location?.name",
    editable: "never",
    render: (rowData) => <span>{rowData?.name}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  // to change the title name and not conflict with other locations fields since we cannot change it from backend we change the title to "Location with 2 spaces at the end
  locationNameWithFilter: {
    title: "Location",
    field: "locationName",
    editable: "never",
    render: (rowData) => <span>{rowData.location?.name}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    defaultFilter: "",
  },
  locationName: {
    title: "Location Name",
    field: "location.name",
    cellStyle: { ...generalCellStyle, textAlign: "left" },
    editable: "never",
  },
  category: {
    title: "Category",
    field: "category.name",
    cellStyle: generalCellStyle,
    filterComponent: (props) => <CategoryRecFilter {...props} />,
    editable: "never",
    defaultFilter: "",
  },
  tagsWithFilter: {
    title: "Tags",
    field: "tags",
    editable: "never",
    render: (rowData) =>
      rowData.tags && (
        <div>
          {rowData?.tags?.slice(0, 1).map((val) => (
            <Chip
              label={val?.title || val}
              href="#basic-chip"
              key={val}
              style={{ margin: "0px", fontSize: "12px", height: "22px" }}
            />
          ))}
        </div>
      ),
    cellStyle: { maxWidth: 200 },
    filterComponent: (props) => <TagFilter {...props} />,
    defaultFilter: "",
  },

  // this for async table with no space at the end
  paidAtWithFilter: {
    title: "Paid At", // Booking Date=ProductionsReport
    field: "paidAt",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },

    editable: "never",
    cellStyle: generalCellStyle,
    filterComponent: () => "",

    // eslint-disable-next-line react/no-unstable-nested-components
  },
  // not async table with space at the end
  paidAt: {
    title: "Paid At", // Booking Date=ProductionsReport
    field: "paidAt",
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
    cellStyle: generalCellStyle,

    // eslint-disable-next-line react/no-unstable-nested-components
  },
  invoiceNumberWithFilter: {
    title: "Invoice #",
    field: "invoiceNumber",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData?.invoiceNumber || ""}>
        <span>{rowData?.invoiceNumber || ""}</span>
      </Tooltip>
    ),
    defaultFilter: "",
    // eslint-disable-next-line react/no-unstable-nested-components
  },
  customerName: {
    title: "Customer Name",
    field: "customerName",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.customerName}>
        <span>{rowData.customerName}</span>
      </Tooltip>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
  },

  // Registration Date for non async table
  createdAt: {
    title: "Registration Date",
    field: "createdAt",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    type: "date",
    dateSetting: {
      locale: "en-GB",
    },
  },
  // Customer Email for non async table
  customerEmail: {
    title: "Customer Email",
    field: "customerEmail",
    cellStyle: generalCellStyle,
  },
  // Customer Email for  async table
  customerEmailWithFilter: {
    title: "Email",
    field: "customerEmail",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData.customerEmail}>
        <span>{rowData.customerEmail}</span>
      </Tooltip>
    ),
    defaultFilter: "",
    // eslint-disable-next-line react/no-unstable-nested-components
  },
  totalAmount: {
    title: "Total Amount",
    field: "totalAmount",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <Tooltip title={rowData?.type}>
        {rowData?.type === "refund" ? (
          <span>
            {rowData?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice?.toFixed(2)}
            {rowData?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice.toFixed(2) && (
              <span style={{ color: "red", marginLeft: "5px" }}>●</span>
            )}
          </span>
        ) : (
          <span>
            {rowData?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice.toFixed(2)}
            {rowData?.totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice.toFixed(2) && (
              <span style={{ color: "green", marginLeft: "5px" }}>●</span>
            )}
          </span>
        )}
      </Tooltip>
    ),
    filterComponent: () => "",
  },
  pageTitle: {
    title: "",
    field: "pageTitle",
    cellStyle: { ...generalCellStyle, maxWidth: 200, whiteSpace: "wrap" },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.pageTitle}</Typography>
      </div>
    ),
    editable: "never",
  },
  views: {
    title: "Views",
    field: "views",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.views}</Typography>
      </div>
    ),
  },
  engagementRate: {
    title: "Engagement",
    field: "engagementRate",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.engagementRate}%</Typography>
      </div>
    ),
    editable: "never",
  },
  screenPageViewsPerSession: {
    title: "Page views per session",
    field: "screenPageViewsPerSession",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.screenPageViewsPerSession}</Typography>
      </div>
    ),
    editable: "never",
  },
  totalUsers: {
    title: "Users",
    field: "totalUsers",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.totalUsers}</Typography>
      </div>
    ),
    editable: "never",
  },
  bounceRate: {
    title: "Abandon rate",
    field: "bounceRate",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.bounceRate}%</Typography>
      </div>
    ),
    editable: "never",
  },
  orderId: {
    title: "Order ID",
    field: "orderId",
    cellStyle: generalCellStyle,
    render: (rowData) => (
      <div>
        <Tooltip title={rowData.orderId}>
          <Typography variant="body2">{rowData.orderId}</Typography>
        </Tooltip>
      </div>
    ),
    defaultFilter: "",
  },
  totalPax: {
    title: "Pax",
    field: "totalPax",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData.totalPax}</Typography>
      </div>
    ),
    editable: "never",
    defaultFilter: "",
  },
  orderAmountAED: {
    title: "Transacted Value AED",
    field: "orderAmountAED",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    editable: "never",
  },
  transactedOrderAmountUSD: {
    title: "Transacted Value $",
    field: "orderAmountUSD",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    editable: "never",
  },
  commisionPaid: {
    title: "Com AED",
    field: "commisionPaid",
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
    render: (rowData) => (
      <div>
        <Typography variant="body2">{rowData?.commisionPaid?.toFixed(2)}</Typography>
      </div>
    ),
    editable: "never",
    defaultFilter: "",
  },
  subcategories: {
    title: "Sub Category",
    field: "subcategories",
    editable: "never",
    render: (rowData) =>
      rowData.subcategories && (
        <div>
          {rowData?.subcategories?.slice(0, 1).map((val) => (
            <Chip
              label={val}
              href="#basic-chip"
              key={val}
              style={{ margin: "0px", fontSize: "12px", height: "22px" }}
            />
          ))}
        </div>
      ),
    cellStyle: { maxWidth: 200 },
    defaultFilter: "",
  },
  ctaLabel: {
    title: "CTA Label",
    field: "cta_label",
  },
  cta: {
    title: "CTA",
    field: "cta",
  },
  currencyNoDefault: {
    title: "Currency",
    field: "currency",
    cellStyle: {
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    render: (rowData) => (
      <Tooltip title={rowData?.currency}>
        <span>{rowData?.currency}</span>
      </Tooltip>
    ),
    defaultFilter: "",
  },
  // currency without filtering
  currencyWithFilter: {
    title: "Currency",
    field: "currency",
    cellStyle: {
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    render: (rowData) => (
      <Tooltip title={rowData?.currency}>
        <span>{rowData?.currency}</span>
      </Tooltip>
    ),
    filterComponent: () => "",
  },
  // Currency fot non-async table

  currency: {
    title: "Currency",
    field: "currency",
    cellStyle: {
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    render: (rowData) => (
      <Tooltip title={rowData?.currency}>
        <span>{rowData?.currency}</span>
      </Tooltip>
    ),
  },
  totalNetProfitAED: {
    title: "AED Revenue",
    field: "totalNetProfitAED",
    cellStyle: {
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    render: (rowData) => (
      <Tooltip title={rowData?.totalNetProfitAED}>
        <span>{rowData?.totalNetProfitAED?.toFixed(2)}</span>
      </Tooltip>
    ),
    defaultFilter: "",
  },
  code: {
    title: "Code",
    field: "code",
    editable: "never",
    render: (rowData) => (
      <Tooltip title={rowData.code}>
        <span>{rowData.code}</span>
      </Tooltip>
    ),
    defaultFilter: "",
    cellStyle: {
      maxWidth: 200,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  method: {
    title: "Method",
    field: "method",
    editable: "never",
    render: (rowData) => <span>{rowData.method}</span>,
    defaultFilter: "",

    cellStyle: {
      maxWidth: 200,
    },
  },
  valueType: {
    title: "Value Type",
    field: "type",
    editable: "never",
    render: (rowData) => <span>{rowData.type}</span>,

    cellStyle: {
      maxWidth: 200,
    },
  },
  used: {
    title: "Used",
    field: "used",
    editable: "never",
    cellStyle: {
      maxWidth: 200,
    },
  },
  approvalStatus: {
    title: "Approval Status",
    field: "approvalStatus",
    cellStyle: {
      maxWidth: 190,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    editable: "never",
  },
  logo: {
    title: "Logo",
    field: "logo",
    cellStyle: {
      textAlign: "left",
      maxWidth: 100,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    editable: "never",
    render: (rowData) =>
      rowData.logo ? (
        <img
          src={rowData.logo} // Assuming rowData.logo is the image source
          alt="Logo"
          style={{ width: "50px", height: "38px" }} // Adjust the width as needed
        />
      ) : (
        <Avatar />
      ),
  },
  day: {
    title: "Day",
    field: "day",
    type: "boolean",
    editable: () => {}, // Dummy function since this column is not editable
    render: (rowData) =>
      rowData.day ? (
        <div>
          <Check
            style={{
              color: "green",
            }}
          />
        </div>
      ) : (
        <div>
          <Clear style={{ color: "red" }} />
        </div>
      ),
  },
  travelStyle: {
    title: "Travel Style",
    field: "label",
    cellStyle: generalCellStyle,
  },
  curator: {
    title: "Curator",
    field: "curator",
    editable: "never",
    render: (rowData) => <span>{rowData?.curator?.name}</span>,
    cellStyle: { ...generalCellStyle, maxWidth: 200 },
  },
  nbOfDays: {
    title: "Number of days",
    field: "days",
    cellStyle: generalCellStyle,
  },

  // Online Store
  productName: {
    field: "title",
    title: "Job Name",
    cellStyle: generalCellStyle,
  },
  productCode: {
    field: "code",
    title: "Job Code",
    cellStyle: generalCellStyle,
  },
  currencyNonEditable: {
    field: "currency",
    title: "Currency",
    cellStyle: generalCellStyle,
    editable: "never",
  },
  price: {
    title: "Price",
    field: "price",
    cellStyle: generalCellStyle,
    render: (rowData) => <span>{rowData?.price?.recommendedRetailPrice}</span>,
    editable: "never",
  },
  tags: {
    title: "Job Category",
    field: "tags",
    cellStyle: generalCellStyle,
    render: (rowData) => <span>{rowData.tags[0]?.title}</span>,
    editable: "never",
  },
  // subTags: {
  //   title: "Subtag",
  //   field: "subTags",
  //   cellStyle: generalCellStyle,
  //   render: (rowData) => <span>{rowData.subTags[0]?.title}</span>,
  //   editable: "never",
  // },
  requestName: {
    title: "Request Name",
    field: "reference.title",
    render: (rowData) =>
      rowData.reference && rowData.reference?.title ? rowData.reference?.title : "-",
    cellStyle: { maxWidth: 200 },
    editable: "never",
  },
  deliveryMethods: {
    title: "Delivery Method",
    field: "deliveryMethods",
    cellStyle: { maxWidth: 200 },
    render: (rowData) => {
      if (!rowData.deliveryMethods || !rowData.deliveryMethods.length) {
        return <Typography variant="body2">-</Typography>;
      }

      const formattedMethods = rowData.deliveryMethods.map((method) => {
        if (method.toLowerCase() === "grms") {
          const source = rowData.source || "";
          const formattedSource =
            source.toLowerCase() === "messagebox"
              ? "MessageBox"
              : source.charAt(0).toUpperCase() + source.slice(1).toLowerCase();

          return `GRMS (${formattedSource})`;
        }

        if (method.toLowerCase() === "whatsapp") {
          return "Whatsapp";
        }

        if (method.toLowerCase() === "email") {
          return "Email";
        }

        return method.charAt(0).toUpperCase() + method.slice(1).toLowerCase();
      });

      return <Typography variant="">{formattedMethods.join(" - ")}</Typography>;
    },
  },
  requestLastName: {
    title: "Requesting LastName",
    field: "user",
    render: (rowData) => (
      <Tooltip title={rowData.user?.name || "N/A"}>
        <span>{rowData.user?.name || "-"}</span>
      </Tooltip>
    ),
    cellStyle: { maxWidth: 200 },
    editable: "never",
  },
  requestDateAsync: {
    title: "Request Date",
    field: "createdAt",
    cellStyle: { ...generalCellStyle, minWidth: 200, maxWidth: 200 },
    render: (rowData) => (
      <div>{rowData.createdAt ? dateFormatWithDash(rowData?.createdAt) : "-"}</div>
    ),
    dateSetting: {
      locale: "en-GB",
    },
    editable: "never",
    filterComponent: () => "",
  },
};
export default columnsMap;
