// Libraries
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

// Material UI components
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { areAllValuesEmpty } from "components/helper";

const Review = ({ formData, editPage = false }) => {
  const { values } = formData;

  const [partnerInfoData, setPartnerInfoData] = useState({});
  const [contactInfoData, setContactInfoData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (values) {
      setPartnerInfoData({
        Name: values?.name,
        Website: values?.website,
        Licence: values?.licence,
        commissionPercentage: values?.commissionNumber,
        Top: values?.top,
      });
      setContactInfoData({
        ContactEmail: values?.contactEmail,
        ContactName: values?.contactName,
        ContactPhone: values?.contactPhone,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Partner info", data: partnerInfoData },
      { id: 1, title: "Contact info", data: contactInfoData },
    ]);
  }, [partnerInfoData, contactInfoData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item size={{ xs: 12, sm: 6 }}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          <Grid item size={{ xs: 12 }}>
            {fields?.map((field, i) => (
              <Grid
                item
                key={field.id}
                size={{ xs: 12 }}
                sx={{
                  display: areAllValuesEmpty(field.data) ? "none" : "flex",
                  flexDirection: "column",
                }}
              >
                <DetailList data={field.data} title={field?.title} />
                {i + 1 !== fields?.length && (
                  <div>
                    <Divider sx={{ height: "1px" }} />
                  </div>
                )}{" "}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
