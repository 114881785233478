// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";
import { exportDayName } from "utils/function/utilFunctions";

const Review = ({ formData, editPage = false }) => {
  const { values } = formData;
  const [PromotionInfoData, setPromotionInfoData] = useState({});
  const [dateAndTimeData, setDateAndTimeData] = useState({});
  const [fields, setFields] = useState([]);

  const handleDetailsList = (data) => {
    const list = {};
    data.forEach((openHour) => {
      let status = "";
      const {
        weekDay = 0,
        closed = false,
        open_all_day = false,
        openHours: openHoursRow = [],
      } = openHour;

      if (open_all_day) {
        status = "Open All Day";
      } else if (closed) {
        status = "Closed";
      } else {
        status = openHoursRow
          .map((item) => {
            const { open, close } = item;
            return `${open} - ${close}`;
          })
          .join(" & ");
      }

      list[exportDayName(weekDay)] = status;
    });

    return list;
  };

  useEffect(() => {
    if (values) {
      setPromotionInfoData({
        title: values?.title,
        summary: values?.summary,
        description: values?.description,
        bookingEmail: values?.bookingEmail,
        recommendation: values?.recommendation?.title
          ? values?.recommendation?.title
          : values?.recommendation?.label,
        location: values?.location,
        minCover: values?.minCover,
        maxCover: values?.maxCover,
        needsConfirmation: values?.needsConfirmation,
      });

      const openHoursList = handleDetailsList(values?.openHours || []);

      setDateAndTimeData({
        ...openHoursList,
        StartDate: values?.startDate,
        EndDate: !values?.openEnded && values?.endDate,
      });
    }
  }, [values]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Promotion Info", data: PromotionInfoData },
      { id: 1, title: "Date And Time Info", data: dateAndTimeData },
    ]);
  }, [PromotionInfoData, dateAndTimeData]);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage ? (
            <Grid item size={{ xs: 12, sm: 6 }}>
              {values?.logo ? (
                <MDAvatar
                  src={URL.createObjectURL(values?.logo)}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Change your profile photo"
                  src={URL.revokeObjectURL(values?.logo)}
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          ) : (
            ""
          )}
          {fields?.map((field, i) => (
            <Grid
              item
              key={field.id}
              size={{ xs: 12 }}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList
                data={
                  typeof field.data === "object" && field.data.openHours
                    ? field.data.openHours
                    : field.data
                }
                title={field?.title}
              />
              {i + 1 !== fields?.length && (
                <div>
                  <Divider sx={{ height: "1px" }} />
                </div>
              )}{" "}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for Review
Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
};

export default Review;
