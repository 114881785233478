import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setLoading, getLocations } from "store/actions/locationAction";

const LocationsPicker = ({
  location,
  locationArr = [],
  handleChange = () => {},
  setFieldValue,
  ...rest
}) => {
  const dispatch = useDispatch();

  const locations = useSelector((state) => state.locations.locations);

  // Ensure that locationArr is always an array
  const selectedValue = Array.isArray(locationArr) ? locationArr : [];
  // get locations
  useEffect(() => {
    dispatch(setLoading());
    dispatch(getLocations());
  }, []);
  return (
    <Autocomplete
      id="locations"
      multiple
      filterSelectedOptions
      options={locations}
      onChange={(event, newValue) => {
        // Ensure that newValue is always an array
        const updatedValue = Array.isArray(newValue) ? newValue : [];
        setFieldValue("locations", updatedValue);
        handleChange(updatedValue);
      }}
      value={selectedValue}
      getOptionLabel={(option) => option.name || ""}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          label={location.label}
          name={location?.name}
          onChange={() => null}
        />
      )}
    />
  );
};

// typechecking props for LocationsPicker
LocationsPicker.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  locationArr: PropTypes.instanceOf(Array),
};

export default LocationsPicker;
