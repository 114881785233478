import { dateFormatWithDash } from "components/helper";
import checkout from "./form";

const {
  formField: {
    // Promotion Info page
    title,
    summary,
    description,
    logo,
    minCover,
    maxCover,
    recommendation,
    location,
    bookingEmail,
    locRec,

    // time and date page
    openEnded,
    needsConfirmation,
    startDate,
    endDate,
    openHours,
    source,
  },
} = checkout;

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const initialValues = {
  // PromotionInfo page
  [title.name]: "",
  [summary.name]: "",
  [description.name]: "",
  [logo.name]: "",
  [minCover.name]: 0,
  [maxCover.name]: 0,
  [location.name]: "",
  [recommendation.name]: {},
  [bookingEmail.name]: "",
  [locRec.name]: { location: "", recommendation: "" },

  // time and date page
  [openEnded.name]: false,
  [needsConfirmation.name]: true,
  [startDate.name]: dateFormatWithDash(today),
  [endDate.name]: dateFormatWithDash(tomorrow),
  [openHours.name]: [],
  [source.name]: "lokalee",
};

export default initialValues;
