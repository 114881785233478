import {
  LOGOUT,
  SORT_MENU,
  MENU_FAIL,
  RESET_MENUS,
  UPDATE_MENU,
  DELETE_MENU,
  CLEAR_ERRORS,
  GET_ONE_MENU,
  MENU_ADD_SUCCESS,
  SET_MENU_LOADING,
  GET_GLOBAL_MENUS,
  GET_LOCATION_MENUS,
  GET_UPDATED_MENU_TYPE,
  GET_DESTINATIONS_MENUS,
  UPDATE_MENU_TRANSLATION,
  LOCATIONS_MENU_ADD_SUCCESS,
  DESTINATIONS_MENU_ADD_SUCCESS,
} from "../actions/Types";

const initialState = {
  menu: null,
  error: null,
  added: false,
  loading: false,
  globalMenus: [],
  locationMenus: [],
  destinationsMenus: [],
  menuType: { type: "global", destinationSearch: [], locationSearch: [] },
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case MENU_ADD_SUCCESS:
      return {
        ...state,
        menu: null,
        error: null,
        added: true,
        loading: false,
        globalMenus: [...state.globalMenus, action.payload.data?.data],
      };
    case DESTINATIONS_MENU_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        destinationsMenus: [...state.destinationsMenus, action.payload.data],
        added: true,
        menu: null,
      };
    case LOCATIONS_MENU_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        locationMenus: [...state.locationMenus, action.payload.data],
        added: true,
        menu: null,
      };
    case GET_ONE_MENU:
      return {
        ...state,
        loading: false,
        error: null,
        menu: action.payload.data,
        added: false,
      };
    case GET_GLOBAL_MENUS:
      return {
        ...state,
        error: null,
        added: false,
        loading: false,
        globalMenu: null,
        globalMenus: action.payload.data,
      };
    case GET_DESTINATIONS_MENUS:
      return {
        ...state,
        loading: false,
        error: null,
        destinationsMenus: action.payload.data,
      };
    case GET_LOCATION_MENUS:
      return {
        ...state,
        loading: false,
        error: null,
        locationMenus: action.payload.data,
      };
    case UPDATE_MENU_TRANSLATION:
      return {
        ...state,
        menu: action.payload.data?.menu,
      };
    case GET_UPDATED_MENU_TYPE:
      return {
        ...state,
        menuType: action.payload,
      };
    case MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_MENU_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        globalMenus: [],
        locationMenus: [],
        destinationsMenus: [],
        error: null,
        added: false,
        menu: null,
      };
    case RESET_MENUS:
      return {
        ...state,
        globalMenus: [],
        locationMenus: [],
        destinationsMenus: [],
        menu: null,
        added: false,
        error: null,
        loading: false,
      };
    case DELETE_MENU: {
      const { type, _id } = action.payload.data.data;
      return {
        ...state,
        added: true,
        loading: false,
        ...(type === "global" && {
          globalMenus: state.globalMenus.filter((menu) => menu._id !== _id),
        }),
        ...(type === "location" && {
          locationMenus: state.locationMenus.filter((menu) => menu._id !== _id),
        }),
        ...(type === "destinations" && {
          destinationsMenus: state.destinationsMenus.filter((menu) => menu._id !== _id),
        }),
      };
    }
    case UPDATE_MENU: {
      const { type, _id } = action.payload.data;
      return {
        ...state,
        menu: action.payload.data.data,
        loading: false,
        added: true,
        ...(type === "global" && {
          globalMenus: state.globalMenus.map((menu) =>
            menu._id === _id ? action.payload.data?.menu : menu
          ),
        }),
        ...(type === "location" && {
          locationMenus: state.locationMenus.map((menu) =>
            menu._id === action.payload.data?.menu?._id ? action.payload.data?.menu : menu
          ),
        }),
        ...(type === "destinations" && {
          destinationsMenus: state.destinationsMenus.map((menu) =>
            menu._id === _id ? action.payload.data?.menu : menu
          ),
        }),
      };
    }
    case SORT_MENU: {
      const { data, type } = action.payload;
      return {
        ...state,
        added: false,
        loading: false,
        ...(type === "global" && {
          globalMenus: data,
        }),
        ...(type === "location" && {
          locationMenus: data,
        }),
        ...(type === "destinations" && {
          destinationsMenus: data,
        }),
      };
    }
    default:
      return state;
  }
};
export default variable;
