/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable react/jsx-no-bind */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import DetailList from "components/DetailList";
import CopytoClipboard from "components/CopytoClipboard";
import MenusPicker from "components/AsyncSelect/MenusPicker";
import React, { useEffect, useState } from "react";
import Danger from "components/Danger";
import { ImageListItem } from "@mui/material";
import { useFormikContext } from "formik";

const env = process.env.REACT_APP_ENV;

const Wifi = ({ formField, editPage = false, detailPage = true, id = "" }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const { title, subTitle, menus, exploreUrl, chat } = formField;
  const {
    title: titleV,
    subTitle: subTitleV,
    menus: menusV,
    exploreUrl: exploreUrlV,
    chat: chatV,
  } = values;

  const url = env === "production" ? "https://lokalee.app" : "https://staging.lokalee.app";

  const expUrl = env === "production" ? "https://lokalee.app" : "https://staging.lokalee.app";

  const [selectedMenus, setSelectedMenus] = useState([]);

  useEffect(() => {
    if (values?.slug?.length > 0) {
      setFieldValue("exploreUrl", `${expUrl}/locations/${values?.slug}`);
    }
  }, [values?.slug]);

  const handleMenusChange = (val) => {
    if (val) {
      setSelectedMenus(val);
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Location info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <>
          <DetailList
            data={{
              title: values?.wifiInfo?.title,
              subTitle: values?.wifiInfo?.subTitle,
              menus: values?.wifiInfo?.menus,
              logo: values?.wifiInfo?.logo,
              exploreUrl: values?.wifiInfo?.exploreUrl,
              chat: values?.wifiInfo?.chat,
            }}
          />
          {values?.slug && values?.location?.wifiInfo && (
            <div style={{ marginBottom: 10 }}>
              <Divider
                sx={{ height: "1px", margin: "8px 0", borderWidth: "1px", backgroundImage: "none" }}
              />
              <CopytoClipboard text={`${url}/locations/${values?.slug}/wifi`} />
            </div>
          )}
        </>
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              {values?.slug && (
                <div style={{ marginBottom: 10 }}>
                  <Divider
                    sx={{
                      height: "1px",
                      margin: "8px 0",
                      borderWidth: "1px",
                      backgroundImage: "none",
                    }}
                  />
                  <CopytoClipboard text={`${url}/locations/${values?.slug}/wifi`} />
                </div>
              )}

              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
              <Grid item size={{ xs: 12 }} mt={2}>
                {" "}
                <FormField
                  type={title.type}
                  label={title.label}
                  name={title.name}
                  value={titleV}
                  placeholder={title.placeholder}
                  error={errors?.title && touched?.title}
                  success={titleV?.length > 0 && !errors?.title}
                />
              </Grid>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={subTitle.type}
                label={subTitle.label}
                name={subTitle.name}
                value={subTitleV}
                placeholder={subTitle.placeholder}
                error={errors?.subTitle && touched?.subTitle}
                success={subTitleV?.length > 0 && !errors?.subTitle}
              />
            </Grid>{" "}
            <Grid item size={{ xs: 12 }}>
              <MenusPicker
                menus={menus}
                menusV={
                  selectedMenus?.length > 0 && selectedMenus[0]?._id?.length > 0
                    ? selectedMenus.map((item) => ({
                        label: item?.title,
                        value: item?._id,
                        icon: item?.icon,
                      }))
                    : menusV
                }
                setFieldValue={setFieldValue}
                locationId={id}
                handleChange={handleMenusChange}
              />
              <Danger validation={errors?.menus} />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              {values?.menus?.length > 0 && (
                <MDBox display="flex" flexWrap="wrap" gap={2}>
                  {values?.menus?.length > 0 && (
                    <MDBox display="flex" flexWrap="wrap" gap={2}>
                      {values.menus.map(
                        (menu) =>
                          menu?.icon && (
                            <ImageListItem
                              key={menu?.title || menu?.label}
                              sx={{ flex: "1 1 auto", "& img": { height: "164px!important" } }}
                            >
                              <MDTypography
                                variant="button"
                                fontWeight="medium"
                                textTransform="capitalize"
                              >
                                {menu?.title || menu?.label}:&nbsp;
                              </MDTypography>
                              <img
                                src={`${menu?.icon}`}
                                srcSet={`${menu?.icon}`}
                                alt="images"
                                loading="lazy"
                              />
                            </ImageListItem>
                          )
                      )}
                    </MDBox>
                  )}
                </MDBox>
              )}
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                key={exploreUrlV}
                type={exploreUrl.type}
                label={exploreUrl.label}
                name={exploreUrl.name}
                value={exploreUrlV}
                placeholder={exploreUrl.placeholder}
                error={errors?.exploreUrl && touched?.exploreUrl}
                success={exploreUrlV?.length > 0 && !errors?.exploreUrl}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={values.chat}
                      name={chat.name}
                      value={chatV}
                      onChange={(event) => setFieldValue("chat", event.target.checked)}
                    />
                  }
                  label={chat.label}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

export default React.memo(Wifi);

// typechecking props for Wifi
Wifi.propTypes = {
  formField: PropTypes.instanceOf(Object).isRequired,
  editPage: PropTypes.bool,
  id: PropTypes.string,
  detailPage: PropTypes.bool,
};
