// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useHandleError } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  getOneItinerary,
  clearErrors,
  updateItinerary,
  setLoading,
} from "store/actions/itineraryActions";
import setAlert from "store/actions/alertActions";
import initialValues from "../new-experience/schemas/initialValues";
import validations from "../new-experience/schemas/validations";
import form from "../new-experience/schemas/form";
import ItineraryDetail from "./components/ItineraryDetail";

const EditExperience = () => {
  const { formId, formField } = form;
  const { experience_id } = useParams();

  const formRef = useRef();
  const pageTitle = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const sourceId = searchParams.get("sourceId");

  // selector
  const itinerary = useSelector((state) => state.itineraries.itinerary);
  const error = useSelector((state) => state.itineraries.error);
  const user = useSelector((state) => state.users.user);
  // const added = useSelector((state) => state.itineraries.added);

  // state
  const [sections, setSections] = useState(["itineraryInfo", "stops"]);
  const [itineraryDetailPage, setItineraryDetailPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [selectedCurator, setSelectedCurator] = useState({});
  const [selectedSlot, setSelectedSlot] = useState({});
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState("none");
  const [tabValue, setTabValue] = useState(0);

  // useEffect
  useEffect(() => {
    if (experience_id) {
      dispatch(getOneItinerary(experience_id));
    }
  }, [experience_id]);

  const handleCuratorChange = (val) => {
    if (val) {
      setSelectedCurator(val);
    }
  };

  useEffect(() => {
    const { aiGenerated = false, reviews = [], title, ...rest } = itinerary || {};
    if (reviews.length > 0) {
      setSections(["itineraryInfo", "rating", "stops"]);
    } else {
      setSections(["itineraryInfo", "stops"]);
    }

    if (aiGenerated) {
      pageTitle.current = {
        reviews,
        aiGenerated,
        title: `Your ${itinerary.days}-${itinerary.days > 1 ? "days" : "day"} Itinerary in ${
          itinerary.destination.destinationName
        }`,
        ...rest,
      };
    } else {
      pageTitle.current = {
        title: itinerary?.title,
        ...rest,
      };
    }

    if (itinerary) {
      const manipulated = {};
      manipulated.aiGenerated = itinerary?.aiGenerated ? itinerary?.aiGenerated : false;
      manipulated.title = itinerary?.title ? itinerary?.title : "";
      manipulated.introText = itinerary?.description ? itinerary?.description : "";
      manipulated.curator = itinerary?.curator ? itinerary?.curator : null;
      manipulated.destination = itinerary?.destination ? itinerary?.destination : null;

      manipulated.travelStyle = itinerary?.label ? itinerary?.label : "";
      manipulated.days = itinerary?.days ? itinerary?.days : 1;
      manipulated.stops = itinerary?.stops ? itinerary?.stops : [];
      manipulated._id = itinerary?._id;
      manipulated.reviews = itinerary?.reviews ? itinerary?.reviews : [];
      manipulated.city = itinerary?.destination?.destinationName
        ? itinerary?.destination?.destinationName
        : "";
      manipulated.companion = itinerary?.persona ? itinerary?.persona : "";
      manipulated.interests =
        itinerary?.interests?.length > 0 ? itinerary?.interests?.join(",") : [];
      manipulated.slug = itinerary?.slug ? itinerary?.slug : "";
      manipulated.images = itinerary?.image ? [itinerary.image] : [];
      setInitialState(manipulated);

      handleCuratorChange(itinerary?.curator);
    }
  }, [itinerary]);

  useHandleError(error, dispatch, setAlert, clearErrors);

  const handleSlotChange = (val) => {
    if (val) {
      setSelectedSlot(val);
    }
  };

  const handleTicketChange = (value) => {
    if (value) {
      setSelectedTickets(value);
    } else {
      setSelectedTickets([]);
    }
  };

  const handleMediaChange = (value) => {
    if (value) {
      setSelectedMedia(value);
    } else {
      setSelectedMedia("none");
    }
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    await sleep(1000);
    dispatch(setLoading());

    const updatedValues = { ...values };

    if (updatedValues?.title === itinerary?.title) delete updatedValues?.title;

    // eslint-disable-next-line no-alert
    dispatch(updateItinerary(updatedValues));
    setItineraryDetailPage(true);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = source === "users" ? `/admin/users/${sourceId}` : `/admin/experiences?`;
      navigate(url);
    }
  };

  function getStepContent(type) {
    return (
      <ItineraryDetail
        type={type}
        formField={formField}
        detailPage={itineraryDetailPage}
        setDetailPage={setItineraryDetailPage}
        handleEdit={() => {
          setItineraryDetailPage(false);
        }}
        handleCuratorChange={handleCuratorChange}
        selectedCurator={selectedCurator}
        expId={experience_id}
        handleSlotChange={handleSlotChange}
        selectedSlot={selectedSlot}
        handleTicketChange={handleTicketChange}
        selectedTickets={selectedTickets}
        handleMediaChange={handleMediaChange}
        selectedMedia={selectedMedia}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <MDBox>
                    <DynamicHeader
                      noImg
                      user={pageTitle.current}
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      <ViewEditActions noEditIcon stateName="itineraries" />
                    </DynamicHeader>
                    <>
                      {sections.map((item, index) => (
                        <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                          <MDBox p={2}>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item size={{ xs: 12 }}>
                                <MDBox height="100%" mt={0.5} lineHeight={1}>
                                  {getStepContent(item, {
                                    values,
                                    touched,
                                    formField,
                                    errors,
                                    setFieldValue,
                                    resetForm,
                                  })}
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Card>
                      ))}
                    </>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditExperience;
