/** 
  All of the routes for the LCM Dashboard are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "pages/dashboards/analytics";
import ProfileOverview from "pages/profile/profile-overview";
import NewUser from "pages/users/new-user";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Pages
import AllUsers from "pages/users/all-users";
import EditUser from "pages/users/edit-user";
import Partners from "pages/content/partners/all-partners";
import NewPartner from "pages/content/partners/new-partner";
import EditPartner from "pages/content/partners/edit-partner";
import Recommendations from "pages/content/recommendations/all-recommendations";
import Attractions from "pages/content/attractions/all-attractions";
// import OldTickets from "pages/items/old-tickets/all-old-tickets";
import Promotions from "pages/items/promotions/all-promotions";
import AllLocations from "pages/content/locations/all-locations";
import NewLocation from "pages/content/locations/new-location";
import AllUniqueExperiences from "pages/views/uniqueExperiences/all-uniqueExperiences";
import NewUniqueExperience from "pages/views/uniqueExperiences/new-uniqueExperience";
import EditLocation from "pages/content/locations/edit-location";
import NewRecommendation from "pages/content/recommendations/new-recommendation";
import NewAttraction from "pages/content/attractions/new-attraction";
import EditAttraction from "pages/content/attractions/edit-attraction";
import EditRecommendation from "pages/content/recommendations/edit-recommendation";
import TrafficByCountry from "pages/reports/trafficReportsByCountry/all-trafficReportsByCountry";
import LocationReports from "pages/reports/locationReports/all-locationReports";
import ProductionReports from "pages/reports/productionReports/all-productionReports";
import WidgetsList from "pages/views/transportationServices/all-transportationServices";
import NewWidget from "pages/views/transportationServices/new-transportationService";
import EditWidget from "pages/views/transportationServices/edit-transportationService";
import TrafficByPartner from "pages/reports/trafficReportsByPartner/all-trafficReportsByPartner";
import TravelDesks from "pages/settingsPages/travelDesks/all-travelDesks";
import NewTravelDesk from "pages/settingsPages/travelDesks/new-travelDesk";
import EditTravelDesk from "pages/settingsPages/travelDesks/edit-travelDesk";
import BannersList from "pages/content/banners/all-banners";
import NewBanner from "pages/content/banners/new-banner";
import EditBanner from "pages/content/banners/edit-banner";
import Menus from "pages/settingsPages/menus/all-menus";
import NewMenu from "pages/settingsPages/menus/new-menu";
import EditMenu from "pages/settingsPages/menus/edit-menu";
import Facilities from "pages/content/otherFacilities/all-facilities";
import NewFacility from "pages/content/otherFacilities/new-facility";
import EditFacility from "pages/content/otherFacilities/edit-facility";
// import AddOldTicket from "pages/items/old-tickets/new-old-ticket";
// import EditOldTicket from "pages/items/old-tickets/edit-old-ticket";
import NewPromotion from "pages/items/promotions/new-promotion";
import EditPromotion from "pages/items/promotions/edit-promotion";
import Vouchers from "pages/items/vouchers/all-vouchers";
import NewVoucher from "pages/items/vouchers/new-voucher";
import EditVoucher from "pages/items/vouchers/edit-voucher";
import Invoices from "pages/reports/Invoices";
import CartsReport from "pages/reports/CartsReport/all-cartReports";
import PromoCodes from "pages/corporate/promoCodes/all-promoCodes";
import NewPromoCode from "pages/corporate/promoCodes/new-promoCodes";
import Filters from "pages/filters";
import EditPromoCode from "pages/corporate/promoCodes/edit-promoCodes";
import Tags from "pages/settingsPages/tags/all-tags";
import Destinations from "pages/content/destinations/all-destinations";
import NewDestination from "pages/content/destinations/new-destination";
import EditDestination from "pages/content/destinations/edit-destination";
import Tickets from "pages/items/tickets/all-tickets";
import NewTicket from "pages/items/tickets/new-ticket";
import RecommendationsDuplicatesList from "pages/content/recommendations/recommendations-duplicates";
import WelcomeEmail from "pages/emails/Welcome";
import ReminderEmail from "pages/emails/ReminderEmail";
import RegisterUserEmail from "pages/emails/RegisterUserEmail";
import LcmForgetPassword from "pages/emails/ForgetPasswords/LcmForgetPassword";
import WebForgetPassword from "pages/emails/ForgetPasswords/WebForgetPassword";
import AdminResetPasswordEmail from "pages/emails/ForgetPasswords/AdminForgetPassword";
import UpdateUserProfileEmail from "pages/emails/UpdateUserProfile";
import ConciergeEmail from "pages/emails/ConciergeEmail";
import ReviewsEmail from "pages/emails/ReviewsEmail";
import EmailToProvider from "pages/emails/ProviderEmail";
import BookingCancelledEmail from "pages/emails/TicketsEmails/BookingCancelledEmail";
import BookingReminderEmail from "pages/emails/TicketsEmails/BookingReminderEmail";
import BookingConfirmationEmail from "pages/emails/BookingConfirmationEmail";
import MultiTicketBookingConfirmationEmail from "pages/emails/BookingConfirmationEmail/MultiTicketConfirmationEmail";
import ManualBookingConfirmationEmail from "pages/emails/BookingConfirmationEmail/ManualConfirmationEmail";
import RejectedBookingEmail from "pages/emails/BookingConfirmationEmail/RejectedBookingEmail";
import AbandonmentEmail from "pages/emails/AbandonmentEmail";
import OnboardingEmailForWeb from "pages/emails/OnboardingEmailForWebUser";
import AllVenues from "pages/corporate/venues/all-venues";
import NewVenue from "pages/corporate/venues/new-venue";
import EditVenue from "pages/corporate/venues/edit-venue";
import EditTicket from "pages/items/tickets/edit-ticket";
import Experiences from "pages/content/experiences/all-experiences";
import NewExperience from "pages/content/experiences/new-experience";
import EditExperience from "pages/content/experiences/edit-experience";
import UserConfirmation from "pages/emails/ReservationConfirmation/UserConfirmation";
import ClientConfirmation from "pages/emails/ReservationConfirmation/ClientConfirmation";
import ClientAutoConfirmation from "pages/emails/ReservationConfirmation/ClientAutoConfirmation";
import ClientReservationCancelled from "pages/emails/ReservationConfirmation/ClientReservationCancelled";
import EmailToGuest from "pages/emails/EmailToGuest";
import EmailToHotelCustomerService from "pages/emails/EmailToHotelCustomerService";
import B2B2COffersPromotionsConfirmation from "pages/emails/B2B2C/Offers & Promotions Confirmation";
import B2B2COffersPromotionsCancellation from "pages/emails/B2B2C/Offers & Promotions Cancelation";
import B2POffersPromotionsConfirmation from "pages/emails/B2P/Offers & Promotions Confirmation";
import B2POffersPromotionsNoConfirmation from "pages/emails/B2P/Offers & Promotions Confirmed (no approval)";
import B2POffersPromotionsCancellation from "pages/emails/B2P/Offers & Promotions Cancelation";
import GroceryOrderEmail from "pages/emails/GroceryOrderEmail";
import GroceryOrderEmailToLocationStaff from "pages/emails/GroceryOrderEmailToLocationStaff";
import GroceryOrderEmailBeforeConfirmation from "pages/emails/GroceryEmailBeforeConfirmation";
import GroceryOrderEmailConfirmation from "pages/emails/GroceryOrderEmailConfirmation";

const routes = [
  {
    type: "collapse",
    name: "USER FULL NAME",
    key: "my-profile",
    noCollapse: true,
    icon: <MDAvatar alt="User Avatar" size="sm" />,
    route: "/profile/my-profile",
    component: <ProfileOverview />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    component: <Analytics />,
    route: "/dashboards",
  },

  { type: "title", title: "Pages", key: "title-pages" },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: "group",
    component: <AllUsers />,
    noCollapse: true,
    route: "/users",
  },
  // Emails list
  {
    type: "collapse",
    name: "Emails",
    key: "emails",
    icon: <Icon fontSize="medium">emails</Icon>,
    collapse: [
      // Emails
      {
        type: "collapse",
        name: "Welcome Email",
        key: "welcomeEmail",
        icon: "accessibility_icon",
        component: <WelcomeEmail />,
        route: "/welcomeEmail",
      },
      {
        type: "collapse",
        name: "Sign up reminder",
        key: "reminderEmail",
        icon: "add_alert_icon",
        component: <ReminderEmail />,
        route: "/reminderEmail",
      },
      {
        type: "collapse",
        name: "LCM Register User",
        key: "registerUserEmail",
        icon: " vpn_key_icon",
        component: <RegisterUserEmail />,
        route: "/registerUserEmail",
      },
      {
        type: "collapse",
        name: "LCM Forget Password",
        key: "LCmForgetPasswordEmail",
        icon: " lock_icon",
        component: <LcmForgetPassword />,
        route: "/LCmForgetPasswordEmail",
      },
      {
        type: "collapse",
        name: "Forget Password",
        key: "webForgetPasswordEmail",
        icon: " lock_icon",
        component: <WebForgetPassword />,
        route: "/webForgetPasswordEmail",
      },
      {
        type: "collapse",
        name: "Forget password by admin",
        key: "adminResetPasswordEmail",
        icon: " lock_icon",
        component: <AdminResetPasswordEmail />,
        route: "/adminResetPasswordEmail",
      },
      {
        type: "collapse",
        name: "Update Email",
        key: "updateUserProfileEmail",
        icon: " lock_icon",
        component: <UpdateUserProfileEmail />,
        route: "/updateUserProfileEmail",
      },
      {
        type: "collapse",
        name: "Concierge Email",
        key: "conciergeEmail",
        icon: " contact_mail_icon",
        component: <ConciergeEmail />,
        route: "/conciergeEmail",
      },
      {
        type: "collapse",
        name: "Reviews Email",
        key: "reviewsEmail",
        icon: "rate_review_icon",
        component: <ReviewsEmail />,
        route: "/reviewsEmail",
      },
      {
        type: "collapse",
        name: "Email To Provider",
        key: "emailToProvider",
        icon: "perm_contact_calendar_icon",
        component: <EmailToProvider />,
        route: "/emailToProvider",
      },
      {
        type: "collapse",
        name: "Ticket Refund",
        key: "bookingCancelledEmail",
        icon: "confirmation_number_icon ",
        component: <BookingCancelledEmail />,
        route: "/bookingCancelledEmail",
      },
      {
        type: "collapse",
        name: "Booking Reminders",
        key: "bookingReminderEmail",
        icon: "confirmation_number_icon ",
        component: <BookingReminderEmail />,
        route: "/bookingReminderEmail",
      },
      {
        type: "collapse",
        name: "Booking Confirmation",
        key: "bookingConfirmationEmail",
        icon: "confirmation_number_icon ",
        component: <BookingConfirmationEmail />,
        route: "/bookingConfirmationEmail",
      },
      {
        type: "collapse",
        name: "Multiple Ticket Confirmation",
        key: "multiTicketBookingConfirmationEmail",
        icon: "confirmation_number_icon ",
        component: <MultiTicketBookingConfirmationEmail />,
        route: "/multiTicketBookingConfirmationEmail",
      },
      {
        type: "collapse",
        name: "Manual Confirmation",
        key: "manualBookingConfirmationEmail",
        icon: "confirmation_number_icon ",
        component: <ManualBookingConfirmationEmail />,
        route: "/manualBookingConfirmationEmail",
      },
      {
        type: "collapse",
        name: "Booking rejected",
        key: "rejectedBookingEmail",
        icon: "confirmation_number_icon ",
        component: <RejectedBookingEmail />,
        route: "/rejectedBookingEmail",
      },
      {
        type: "collapse",
        name: "Abandonment Email - Ticket",
        key: "abandonmentEmail",
        icon: "exit_to_app_icon",
        component: <AbandonmentEmail />,
        route: "/abandonmentEmail",
      },
      {
        type: "collapse",
        name: "Onboarding Email For Web User",
        key: "onboardingEmailForWeb",
        icon: "assistant_icon",
        component: <OnboardingEmailForWeb />,
        route: "/onboardingEmailForWeb",
      },
      {
        type: "collapse",
        name: "User Reservation Confirmation",
        key: "userConfirmation",
        icon: "confirmation_number_icon",
        component: <UserConfirmation />,
        route: "/reservationConfirmation/userConfirmation",
      },
      {
        type: "collapse",
        name: "Client Reservation Confirmation",
        key: "clientConfirmation",
        icon: "confirmation_number_icon",
        component: <ClientConfirmation />,
        route: "/reservationConfirmation/clientConfirmation",
      },

      {
        type: "collapse",
        name: "Client Auto Confirmation Reservation",
        key: "clientAutoConfirmation",
        icon: "confirmation_number_icon",
        component: <ClientAutoConfirmation />,
        route: "/reservationConfirmation/clientAutoConfirmation",
      },
      {
        type: "collapse",
        name: "Reservation Cancellation",
        key: "clientReservationCancelled",
        icon: "confirmation_number_icon",
        component: <ClientReservationCancelled />,
        route: "/reservationConfirmation/clientReservationCancelled",
      },
      {
        type: "collapse",
        name: "Email To Hotel Customer Service",
        key: "emailToHotelCustomerService",
        icon: "room_service_icon",
        component: <EmailToHotelCustomerService />,
        route: "/emailToHotelCustomerService",
      },
      {
        type: "collapse",
        name: "Email To Guest",
        key: "emailtoGuest",
        icon: "confirmation_number_icon ",
        component: <EmailToGuest />,
        route: "/emailtoGuest",
      },
      {
        type: "collapse",
        name: "Grocery Order Email",
        key: "groceryOrderEmail",
        icon: "confirmation_number_icon",
        component: <GroceryOrderEmail />,
        route: "/groceryOrderEmail",
      },
      {
        type: "collapse",
        name: "Grocery Order Email To Location Staff",
        key: "groceryOrderEmailToLocationStaff",
        icon: "confirmation_number_icon",
        component: <GroceryOrderEmailToLocationStaff />,
        route: "/groceryOrderEmailToLocationStaff",
      },
      {
        type: "collapse",
        name: "Grocery Order Email Before Confirmation",
        key: "groceryOrderEmailBeforeConfirmation",
        icon: "confirmation_number_icon",
        component: <GroceryOrderEmailBeforeConfirmation />,
        route: "/groceryOrderEmailBeforeConfirmation",
      },
      {
        type: "collapse",
        name: "Grocery Order Email Confirmation",
        key: "groceryOrderEmailConfirmation",
        icon: "confirmation_number_icon",
        component: <GroceryOrderEmailConfirmation />,
        route: "/groceryOrderEmailConfirmation",
      },
      {
        type: "collapse",
        name: "B2B2C - Offers & Promotions Confirmation",
        key: "B2B2COffersPromotionsConfirmation",
        icon: "confirmation_number_icon ",
        component: <B2B2COffersPromotionsConfirmation />,
        route: "/b2b2c/B2B2COffersPromotionsConfirmation",
      },
      {
        type: "collapse",
        name: "B2B2C - Offers & Promotions Cancellation",
        key: "B2B2COffersPromotionsCancellation",
        icon: "confirmation_number_icon ",
        component: <B2B2COffersPromotionsCancellation />,
        route: "/b2b2c/B2B2COffersPromotionsCancellation",
      },
      {
        type: "collapse",
        name: "B2P - Offers & Promotions Confirmation",
        key: "B2POffersPromotionsConfirmation",
        icon: "confirmation_number_icon ",
        component: <B2POffersPromotionsConfirmation />,
        route: "/b2p/B2POffersPromotionsConfirmation",
      },
      {
        type: "collapse",
        name: "B2P - Offers & Promotions Confirmation (no approval)",
        key: "B2POffersPromotionsNoConfirmation",
        icon: "confirmation_number_icon ",
        component: <B2POffersPromotionsNoConfirmation />,
        route: "/b2p/B2POffersPromotionsNoConfirmation",
      },
      {
        type: "collapse",
        name: "B2P - Offers & Promotions Cancellation",
        key: "B2POffersPromotionsCancellation",
        icon: "confirmation_number_icon ",
        component: <B2POffersPromotionsCancellation />,
        route: "/b2p/B2POffersPromotionsCancellation",
      },
    ],
  },
  {
    name: "New User",
    type: "hidden",
    key: "new-user",
    route: "/users/new-user",
    component: <NewUser />,
  },
  {
    type: "hidden",
    name: "Edit User",
    key: "edit-user",
    route: "/users/:user_id",
    component: <EditUser />,
  },
  // content group list
  {
    type: "collapse",
    name: "Content",
    key: "content",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      // Partners
      {
        name: "Partners",
        key: "partners",
        route: "/partners",
        component: <Partners />,
        icon: "group_work",
      },
      {
        // type: "hidden",
        name: "New Partner",
        type: "hidden",
        key: "new-partner",
        route: "/partners/new-partner",
        component: <NewPartner />,
      },
      {
        type: "hidden",
        name: "Edit Partner",
        key: "edit-experience",
        route: "/experiences/:experience_id",
        component: <EditExperience />,
      },
      // Experiences
      {
        name: "Experiences",
        key: "experiences",
        route: "/experiences",
        component: <Experiences />,
        icon: "science",
      },
      {
        // type: "hidden",
        name: "New Experience",
        type: "hidden",
        key: "new-experience",
        route: "/experiences/new-experience",
        component: <NewExperience />,
      },
      {
        type: "hidden",
        name: "Edit Experience",
        key: "edit-partner",
        route: "/partners/:partner_id",
        component: <EditPartner />,
      },
      // Location
      {
        name: "Locations",
        key: "locations",
        route: "/locations",
        component: <AllLocations />,
        icon: "location_city",
      },
      {
        name: "New Location",
        type: "hidden",
        key: "new-location",
        route: "/locations/new-location",
        component: <NewLocation />,
      },
      {
        type: "hidden",
        name: "Edit Location",
        key: "edit-location",
        route: "/locations/:location_id",
        component: <EditLocation />,
      },
      // Recommendation
      {
        name: "Recommendations",
        key: "recommendations",
        route: "/recommendations",
        component: <Recommendations />,
        icon: "theater_comedy",
      },
      {
        name: "New Recommendation",
        type: "hidden",
        key: "new-recommendation",
        route: "/recommendations/new-recommendation",
        component: <NewRecommendation />,
      },
      {
        type: "hidden",
        name: "Recommendation Duplicates",
        key: "recommendation-duplicates",
        route: "/recommendations/Dup-Recommendations",
        component: <RecommendationsDuplicatesList />,
      },
      {
        type: "hidden",
        name: "Edit Recommendation",
        key: "edit-recommendation",
        route: "/recommendations/:recommendation_id",
        component: <EditRecommendation />,
      },
      // Attraction
      {
        name: "Attractions",
        key: "attractions",
        route: "/attractions",
        component: <Attractions />,
        icon: "engineering",
      },
      {
        name: "New Attraction",
        type: "hidden",
        key: "new-attraction",
        route: "/attractions/new-attraction",
        component: <NewAttraction />,
      },
      {
        type: "hidden",
        name: "Edit Attraction",
        key: "edit-attraction",
        route: "/attractions/:attraction_id",
        component: <EditAttraction />,
      },

      // Destinations
      {
        name: "Destinations",
        key: "destinations",
        route: "/destinations",
        component: <Destinations />,
        icon: "map_icon",
      },
      {
        name: "New Destination",
        type: "hidden",
        key: "new-destination",
        route: "/destinations/new-destination",
        component: <NewDestination />,
      },
      {
        type: "hidden",
        name: "Edit Destination",
        key: "edit-destination",
        route: "/destinations/:destination_id",
        component: <EditDestination />,
      },
      // Other facilities
      {
        name: "Other Facilities",
        key: "otherFacilities",
        route: "/otherFacilities",
        component: <Facilities />,
        icon: "home_work",
      },
      {
        name: "New facility",
        type: "hidden",
        key: "new-facility",
        route: "/otherFacilities/new-facility",
        component: <NewFacility />,
      },
      {
        type: "hidden",
        name: "Edit Facility",
        key: "edit-facility",
        route: "/otherFacilities/:facility_id",
        component: <EditFacility />,
      },
      // Transportation services
      {
        name: "Transportation Services",
        key: "widgets",
        route: "/widgets",
        component: <WidgetsList />,
        icon: "widgets",
      },
      {
        name: "New Transportation Service",
        type: "hidden",
        key: "new-transportation-service",
        route: "/widgets/new-widget",
        component: <NewWidget />,
      },
      {
        name: "Edit Transportation Service",
        type: "hidden",
        key: "edit-transportation-service",
        route: "/widgets/:widget_id",
        component: <EditWidget />,
      },
      // banners
      {
        name: "Banners",
        key: "banners",
        route: "/banners",
        component: <BannersList />,
        icon: "featured_video_icon",
      },
      {
        name: "New Banner",
        type: "hidden",
        key: "new-banner",
        route: "/banners/new-banner",
        component: <NewBanner />,
      },
      {
        name: "Edit Banner",
        type: "hidden",
        key: "edit-banner",
        route: "/banners/:banner_id",
        component: <EditBanner />,
      },
    ],
  },

  // items group list
  {
    type: "collapse",
    name: "Items",
    key: "items",
    icon: <Icon fontSize="medium">local_activity</Icon>,
    collapse: [
      {
        name: "Tickets",
        key: "tickets",
        route: "/tickets",
        component: <Tickets />,
        icon: "branding_watermark",
      },

      {
        name: "New Ticket",
        type: "hidden",
        key: "new-ticket",
        route: "/tickets/new-ticket",
        component: <NewTicket />,
      },
      {
        name: "Edit Ticket",
        type: "hidden",
        key: "edit-ticket",
        route: "/tickets/:ticket_id",
        component: <EditTicket />,
      },

      {
        name: "Promotions",
        key: "promotions",
        route: "/promotions",
        component: <Promotions />,
        icon: "military_tech",
      },
      {
        name: "New Promotion",
        type: "hidden",
        key: "new-promotion",
        route: "/promotions/new-promotion",
        component: <NewPromotion />,
      },
      {
        name: "Edit Promotion",
        type: "hidden",
        key: "edit-promotion",
        route: "/promotions/:promotion_id",
        component: <EditPromotion />,
      },

      {
        name: "Vouchers",
        key: "vouchers",
        route: "/vouchers",
        component: <Vouchers />,
        icon: "token",
      },
      {
        name: "New Voucher",
        type: "hidden",
        key: "new-voucher",
        route: "/vouchers/new-voucher",
        component: <NewVoucher />,
      },
      {
        name: "Edit Voucher",
        type: "hidden",
        key: "edit-voucher",
        route: "/vouchers/:voucher_id",
        component: <EditVoucher />,
      },
    ],
  },

  // reports group list
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">summarize</Icon>,
    collapse: [
      {
        name: "Location Reports",
        key: "location-reports",
        route: "/location-reports",
        component: <LocationReports />,
        icon: "pin_drop",
      },
      {
        name: "Production Reports",
        key: "production-reports",
        route: "/production-reports",
        component: <ProductionReports />,
        icon: "assessment",
      },
      {
        name: "Traffic by country",
        key: "traffic-by-country",
        route: "/traffic-by-country",
        component: <TrafficByCountry />,
        icon: "summarize",
      },
      {
        name: "Traffic by partner",
        key: "traffic-by-partner",
        route: "/traffic-by-partner",
        component: <TrafficByPartner />,
        icon: "summarize",
      },
      {
        name: "Invoices",
        key: "invoices",
        route: "/invoices",
        component: <Invoices />,
        icon: "attach_money_two_tone",
      },
      {
        name: "Carts",
        key: "carts",
        route: "/carts",
        type: "hidden",
        component: <CartsReport />,
        icon: "shopping_cart_icon",
      },
    ],
  },

  // Views group list
  {
    type: "collapse",
    name: "Views",
    key: "views",
    icon: <Icon fontSize="medium">grid_view</Icon>,
    collapse: [
      {
        name: "Unique Experiences",
        key: "uniqueExperiences",
        route: "/uniqueExperiences",
        component: <AllUniqueExperiences />,
        icon: "whatshot",
      },
      {
        name: "New Unique Experience",
        type: "hidden",
        key: "new-uniqueExperience",
        route: "/uniqueExperiences/new-uniqueExperience",
        component: <NewUniqueExperience />,
      },
    ],
  },

  // settings group list
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">settings_applications</Icon>,
    collapse: [
      // traveldesks
      {
        name: "Travel Desks",
        key: "travelDesks",
        route: "/travelDesks",
        component: <TravelDesks />,
        icon: "card_travel",
      },
      {
        // type: "hidden",
        name: "New Travel Desk",
        type: "hidden",
        key: "new-travelDesk",
        route: "/travelDesks/new-travelDesk",
        component: <NewTravelDesk />,
      },
      {
        type: "hidden",
        name: "Edit Travel Desk",
        key: "edit-travelDesk",
        route: "/travelDesks/:travelDesk_id",
        component: <EditTravelDesk />,
      },

      {
        name: "Tags",
        key: "tags",
        route: "/tags",
        component: <Tags />,
        icon: "style_icon",
      },

      // {
      //   name: "Menus",
      //   key: "menus",
      //   route: "/menus",
      //   component: <Menus />,
      //   icon: "menu",
      // },
      // {
      //   // type: "hidden",
      //   name: "New Menu",
      //   type: "hidden",
      //   key: "new-menu",
      //   route: "/menus/new-menu",
      //   component: <NewMenu />,
      // },
      {
        name: "Menus",
        key: "menus",
        route: "/menus",
        component: <Menus />,
        icon: "menu",
      },
      {
        // type: "hidden",
        name: "New Menu",
        type: "hidden",
        key: "new-menu",
        route: "/menus/new-menu",
        component: <NewMenu />,
      },
      {
        type: "hidden",
        name: "Edit Menu",
        key: "edit-menu",
        route: "/menus/:menu_id",
        component: <EditMenu />,
      },
      {
        name: "Filters",
        key: "filters",
        route: "/filters",
        component: <Filters />,
      },
    ],
  },
  // Coporate group list
  {
    type: "collapse",
    name: "Corporate",
    key: "corporate",
    icon: <Icon fontSize="medium">corporate_fare</Icon>,
    collapse: [
      // PromoCode
      {
        name: "PromoCodes",
        key: "promoCodes",
        route: "/promoCodes",
        component: <PromoCodes />,
        icon: "discount",
      },
      {
        name: "New PromoCode",
        type: "hidden",
        key: "new-promoCode",
        route: "/promoCodes/new-promoCode",
        component: <NewPromoCode />,
      },
      {
        type: "hidden",
        name: "Edit PromoCode",
        key: "edit-promoCode",
        route: "/promoCodes/:promoCode_id",
        component: <EditPromoCode />,
      },
      // Venues
      {
        name: "Venues",
        key: "venues",
        route: "/venues",
        component: <AllVenues />,
        icon: "festival_icon",
      },
      {
        name: "New Venue",
        type: "hidden",
        key: "new-venue",
        route: "/venues/new-venue",
        component: <NewVenue />,
      },
      {
        type: "hidden",
        name: "Edit Venue",
        key: "edit-venue",
        route: "/venues/:venue_id",
        component: <EditVenue />,
      },
    ],
  },
];

export default routes;
