import {
  SET_CITY_LOADING,
  CLEAR_ERRORS,
  CITY_ADD_SUCCESS,
  CITY_FAIL,
  UPDATE_CITY,
  GET_ONE_CITY,
  DELETE_CITY,
  GET_CITIES,
  LOGOUT,
  UPDATE_CITY_TRANSLATION,
  SLIDE_CITY_ADD_SUCCESS,
  SLIDE_CITY_UPDATE,
  DELETE_CITY_SLIDER,
  CITY_SLIDERS_FAIL,
  CHECK_CITIES,
  CHECK_CITIES_FAIL,
  CHECK_COUNTRIES,
  CHECK_COUNTRIES_FAIL,
} from "../actions/Types";

const initialState = {
  loading: false,
  cities: [],
  error: null,
  added: false,
  city: null,
  checkCities: [],
  countryError: null,
};
const variable = (state = initialState, action) => {
  switch (action.type) {
    case CITY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        cities: [...state.cities, action.payload.data],
        added: true,
        city: null,
      };

    case SLIDE_CITY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sliders: action.payload?.data?.city?.slider,
        city: action.payload?.data?.city,
        added: true,
      };
    case SLIDE_CITY_UPDATE:
      return {
        ...state,
        sliders: action.payload?.data?.city?.slider,
        city: action.payload?.data?.city,
        added: true,
      };

    case DELETE_CITY_SLIDER:
      return {
        ...state,
        loading: false,
        sliders: state.city?.slider,
        city: action.payload?.data?.city,
        added: true,
      };
    case GET_ONE_CITY:
      return {
        ...state,
        loading: false,
        error: null,
        city: action.payload.data.data,
        added: false,
      };

    case GET_CITIES:
      return {
        ...state,
        loading: false,
        error: null,
        cities: action.payload.data.data,
        added: false,
        // city: null,
      };
    case DELETE_CITY:
      return {
        ...state,
        loading: false,
        cities: state.cities.filter((city) => city._id !== action.payload.data.city._id),
      };
    case UPDATE_CITY:
      return {
        ...state,
        added: true,
        cities: state.cities.map((city) =>
          city._id === action.payload.data.city._id ? action.payload.data.city : city
        ),
      };
    case UPDATE_CITY_TRANSLATION:
      return {
        ...state,
        city: action.payload.data?.city,
      };
    case CITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CITY_SLIDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CITY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        cities: [],
        error: null,
        added: false,
        city: null,
      };
    case CHECK_CITIES:
      return {
        ...state,
        checkCities: action.payload.cities,
        error: null,
      };
    case CHECK_CITIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CHECK_COUNTRIES:
      return {
        ...state,
        countryError: null,
      };
    case CHECK_COUNTRIES_FAIL:
      return {
        ...state,
        countryError: action.payload,
      };

    default:
      return state;
  }
};
export default variable;
