/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import LocationPicker from "components/AutoComplete/LocationPicker";
import DynamicSinglePdf from "components/DynamicSinglePdf";
import { useState } from "react";
import { capitalizeFirstLetter } from "components/helper";

const Information = ({
  formData,
  editPage = false,
  detailPage = true,
  selectedLocation = {},
  handleLocationChange = () => {},
  locationObj = {},
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, openHours, subHeader, ctaTitle, ctaLink, description, location } = formField;
  const {
    title: titleV,
    openHours: openHoursV,
    subHeader: subHeaderV,
    ctaTitle: ctaTitleV,
    ctaLink: ctaLinkV,
    description: descriptionV,
    location: locationV,
  } = values;

  const [pdfFile, setPdfFile] = useState(values?.file || null);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            openHours: values?.openHours,
            subHeader: values?.subHeader,
            ctaTitle: values?.ctaTitle,
            ctaLink: values?.ctaLink,
            description: values?.description,
            location: values?.location,
            file: values?.file,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="image" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={title?.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors?.title && touched?.title}
                success={titleV?.length > 0 && !errors?.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                label={subHeader.label}
                name={subHeader.name}
                value={subHeaderV}
                placeholder={subHeader.placeholder}
                success={subHeaderV.length > 0 && !errors.subHeader}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <LocationPicker
                setFieldValue={setFieldValue}
                location={location}
                locationOnly
                selectedLocation={selectedLocation?._id ? selectedLocation : locationObj}
                handleLocationChange={handleLocationChange}
                error={errors.location && touched.location}
                success={locationV?.name && !errors.location}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                // type={openHours.type}
                label={openHours.label}
                name={openHours.name}
                value={openHoursV}
                placeholder={openHours.placeholder}
                error={errors?.openHours && touched?.openHours}
                success={openHoursV?.length > 0 && !errors?.openHours}
              />
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                multiline
                outlined
                rows={5}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
            <Grid item size={{ xs: 3 }}>
              <FormField
                // type={ctaTitle.type}
                label={ctaTitle.label}
                name={ctaTitle.name}
                value={ctaTitleV}
                placeholder={ctaTitle.placeholder}
                error={errors.ctaTitle && touched.ctaTitle}
                success={ctaTitleV.length > 0 && !errors.ctaTitle}
              />
            </Grid>
            <Grid item size={{ xs: 9 }}>
              <FormField
                // type={ctaLink.type}
                label={ctaLink.label}
                name={ctaLink.name}
                value={ctaLinkV}
                placeholder={ctaLink.placeholder}
                error={errors?.ctaLink && touched?.ctaLink}
                success={ctaLinkV?.length > 0 && !errors?.ctaLink}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <DynamicSinglePdf
                values={values}
                setFieldValue={setFieldValue}
                setPdfFile={setPdfFile}
                pdfFile={pdfFile}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  selectedLocation: PropTypes.instanceOf(Object),
  locationObj: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default Information;
