/* eslint-disable no-nested-ternary */

import { useState, useEffect, useCallback } from "react";

const useTableDragDrop = ({
  data,
  stopsTable,
  elementsTable,
  isOrder,
  sortFn,
  locationBoxesTable,
  boxesTable,
  locationId,
  cityName,
  dispatch,
  expId,
  widgetId,
}) => {
  const [tableData, setTableData] = useState([]);
  const [sortBool, setSortBool] = useState(false);
  const [sortedArr, setSortedArr] = useState([]);
  const dragState = { row: -1, dropIndex: -1 };

  const offsetIndex = (from, to, arr = []) => {
    if (from < to) {
      const start = arr.slice(0, from);
      const between = arr.slice(from + 1, to + 1);
      const end = arr.slice(to + 1);
      return [...start, ...between, arr[from], ...end];
    }
    if (from > to) {
      const start = arr.slice(0, to);
      const between = arr.slice(to, from);
      const end = arr.slice(from + 1);
      return [...start, arr[from], ...between, ...end];
    }
    return arr;
  };

  const handleStopIndex = useCallback((mainStop) => {
    const newStateArray = mainStop?.map((stop, i) => {
      if (stop.type === "items" || stop.type === "recommendations") {
        let newState = {
          stop: 0,
          order: 0,
          alternative: "",
          type: "",
          duration: "",
          message: "",
        };
        newState = {
          stop: stop?.stop?._id,
          order: i,
          alternative: stop?.alternative,
          type: stop?.type,
          duration: stop?.duration,
          message: stop?.message,
        };
        newStateArray.push(newState);
        return null;
      }
      return null;
    });
    setSortedArr(newStateArray);
  }, []);

  const handleElementIndex = (mainElement) => {
    const newStateArray = [];

    mainElement?.forEach((element, i) => {
      let newState = {
        element: 0,
        order: 0,
        title: "",
        description: "",
        ctaTitle: "",
        ctaUrl: "",
        files: [],
      };
      newState = {
        element: element?._id,
        order: i,
        title: element?.title,
        description: element?.description,
        ctaTitle: element?.ctaTitle,
        ctaUrl: element?.ctaUrl ? element?.ctaUrl : "",
        files: element?.files ? element?.files : [],
      };
      newStateArray.push(newState);
    });
    setSortedArr(newStateArray);
  };

  const handleIndex = (mainMenu) => {
    const newStateArray = [];
    mainMenu.map((item, i) => {
      let newState = { id: 0, order: 0 };
      newState = { id: item?._id, order: i };
      newStateArray.push(newState);
      return item;
    });
    setSortedArr(newStateArray);
  };

  const reOrderRow = (from, to) => {
    const newtableData = offsetIndex(from, to, tableData);
    // Update react state
    setTableData(newtableData);
    if (stopsTable) {
      handleStopIndex(newtableData);
    } else if (elementsTable) handleElementIndex(newtableData);
    else handleIndex(newtableData);
    setSortBool(true);
  };

  useEffect(() => {
    if (sortBool && isOrder) {
      if (stopsTable) {
        dispatch(sortFn(sortedArr, expId));
        setSortBool(false);
      } else if (elementsTable) {
        dispatch(sortFn(sortedArr, widgetId));
        setSortBool(false);
      } else if (locationBoxesTable) {
        dispatch(sortFn(sortedArr, locationId));
        setSortBool(false);
      } else if (boxesTable) {
        dispatch(sortFn(sortedArr, cityName));
        setSortBool(false);
      } else {
        dispatch(sortFn(sortedArr));
        setSortBool(false);
      }
    }
  }, [sortBool]);

  useEffect(() => {
    if (isOrder) {
      setTableData(data);
      if (stopsTable) {
        handleStopIndex(data);
      } else {
        handleIndex(data);
      }
    }
  }, [data]);

  const dragHandlers = {
    onDragStart: (index) => {
      dragState.row = index;
    },
    onDragEnter: (e, index) => {
      e.preventDefault();
      if (index !== dragState.row) {
        dragState.dropIndex = index;
      }
    },
    onDragEnd: () => {
      if (dragState.dropIndex !== -1) {
        reOrderRow(dragState.row, dragState.dropIndex);
      }
      dragState.row = -1;
      dragState.dropIndex = -1;
    },
  };

  return {
    tableData,
    dragHandlers,
  };
};
export default useTableDragDrop;
