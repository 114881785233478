/* eslint-disable no-nested-ternary */

/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import DetailList from "components/DetailList";
import UploadImage from "pages/content/locations/new-location/components/Sliders/UploadImage";
import Danger from "components/Danger";
import PositionPicker from "components/AutoComplete/PositionPicker";
import DestinationPicker from "components/AsyncSelect/DestinationPicker";
import { useFormikContext } from "formik";

const Information = ({
  formData,
  editPage = false,
  detailPage = true,
  handlePositionChange = () => {},
  handleDestinationChange = () => {},
  selectedPosition = {},
  selectedDestination = {},
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const { formField } = formData;
  const { title, image, destination, url } = formField;
  const { title: titleV, image: imageV, url: urlV, mobileImage: mobileImageV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          bannerDetailsPage
          data={{
            bannerText: values?.title,
            position: values?.position?.label
              ? values?.position?.label
              : values?.position === "top"
              ? "Homepage Top"
              : values?.position === "bottom"
              ? "Homepage Bottom"
              : values?.position === "leaderboard"
              ? "Leaderboard"
              : values?.position,
            destination:
              values?.position?.value === "leaderboard"
                ? selectedDestination?.label || values?.destination?.label
                : null,
            url: values?.position?.value === "leaderboard" ? values?.url : null,
            // image: values?.image,
            // mobileImage: values?.position?.value === "leaderboard" ? values?.mobileImage : null,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12 }}>
              <MDTypography variant="h6" color="text" fontWeight="regular">
                Position *
              </MDTypography>
              <MDBox
                style={{
                  marginTop: "5px",
                  backgroundColor: "white",
                }}
              >
                <PositionPicker
                  setFieldValue={setFieldValue}
                  position={selectedPosition}
                  handlePositionChange={handlePositionChange}
                />
              </MDBox>
              {selectedPosition?.value === "leaderboard" && (
                <>
                  <Grid size={{ xs: 12 }}>
                    <MDBox mt={1.625}>
                      <DestinationPicker
                        setFieldValue={setFieldValue}
                        handleChange={handleDestinationChange}
                        destination={destination}
                        selectedDestination={selectedDestination || {}}
                      />
                    </MDBox>
                    {errors?.destination && touched?.destination && (
                      <Danger validation={errors?.destination} />
                    )}
                  </Grid>
                  <Grid size={{ xs: 12 }} mt={4}>
                    <FormField
                      type={url?.type}
                      label={url.label}
                      name={url.name}
                      value={urlV}
                      placeholder={url.placeholder}
                      error={errors?.url && touched?.url}
                      success={values?.url?.length > 0 && !errors?.url}
                    />
                    {/* {errors?.url && touched?.url && <Danger validation={errors?.url} />} */}
                  </Grid>
                </>
              )}
              {errors?.position && touched?.position && <Danger validation={errors?.position} />}
            </Grid>
            <Grid size={{ xs: 12 }}>
              <FormField
                // type={title?.type}
                label={title.label}
                name={title.name}
                value={titleV}
                placeholder={title.placeholder}
                error={errors?.title && touched?.title}
                success={titleV?.length > 0 && !errors?.title}
              />
            </Grid>

            {selectedPosition?.value === "leaderboard" ? (
              <>
                <Grid size={{ sm: 6 }}>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Select the desktop image *
                  </MDTypography>
                  <UploadImage
                    key={values?.image}
                    defaultImage={imageV?.[0]}
                    setFieldValue={setFieldValue}
                    fieldName="image"
                  />
                  {errors?.image && touched?.image && <Danger validation={errors?.image} />}
                </Grid>
                <Grid size={{ sm: 6 }}>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    Select the mobile image *
                  </MDTypography>
                  <UploadImage
                    key={values?.mobileImage}
                    defaultImage={mobileImageV?.[0]}
                    setFieldValue={setFieldValue}
                    fieldName="mobileImage"
                  />
                  {errors?.mobileImage && touched?.mobileImage && (
                    <Danger validation={errors?.mobileImage} />
                  )}
                </Grid>
              </>
            ) : (
              <Grid size={{ sm: 12 }}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {image?.label}
                </MDTypography>
                <UploadImage
                  key={values?.image}
                  defaultImage={imageV?.[0]}
                  setFieldValue={setFieldValue}
                  fieldName="image"
                />
                {errors?.image && touched?.image && <Danger validation={errors?.image} />}
              </Grid>
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handlePositionChange: PropTypes.func,
  handleDestinationChange: PropTypes.func,
  selectedPosition: PropTypes.instanceOf(Object),
  selectedDestination: PropTypes.instanceOf(Object),
};

export default Information;
