// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
// import { useEffect } from "react";

const LanguagesPicker = ({
  languagesList,
  languages,
  languagesV,
  setFieldValue,
  isObj = false,
  ...rest
}) => (
  <Autocomplete
    key={languagesV?.length}
    id="languages"
    multiple
    filterSelectedOptions
    //   renderTags={() => null}
    options={languagesList}
    onChange={(event, value) => {
      if (value) {
        setFieldValue("languages", value);
      } else {
        setFieldValue("languages", []);
      }
    }}
    value={languagesV}
    getOptionLabel={(option) => (isObj ? option?.name || "" : option || "")}
    // isOptionEqualToValue={(option, value) => option._id === value._id}
    isOptionEqualToValue={(option, value) =>
      isObj ? option.code === value.code : option === value
    }
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={languages.label}
        name={languages?.name}
        onChange={() => null}
        // value={languagesV}
      />
    )}
  />
);

// typechecking props for LanguagesPicker
LanguagesPicker.propTypes = {
  languagesList: PropTypes.instanceOf(Array).isRequired,
  languages: PropTypes.instanceOf(Object).isRequired,
  languagesV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isObj: PropTypes.bool,
};

export default LanguagesPicker;
