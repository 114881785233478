/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import CurrencyField from "components/CurrencyField";
import DynamicSwitch from "components/DynamicSwitch";

// pages
import TagPicker from "components/AsyncSelect/TagPicker";
import MsgOrUrl from "../MsgOrUrl";

const AdditionalInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleTagChange = () => {},
  selectedTag = {},
}) => {
  const { formField, values, setFieldValue } = formData;
  const {
    tag,
    retail_price,
    newLabel,
    sponsored,
    currated,
    special,
    experienceX,
    exclusive,
    insider,
  } = formField;

  const {
    retail_price: retailPriceV,
    newLabel: newLabelV,
    sponsored: sponsoredV,
    currated: curratedV,
    special: specialV,
    experienceX: experienceXV,
    exclusive: exclusiveV,
    insider: insiderV,
  } = values;

  const switches = [
    { id: 1, name: newLabel?.name, label: newLabel?.label, value: newLabelV },
    { id: 2, name: sponsored?.name, label: sponsored?.label, value: sponsoredV },
    { id: 3, name: currated?.name, label: currated?.label, value: curratedV },
    { id: 4, name: special?.name, label: special?.label, value: specialV },
    { id: 5, name: experienceX?.name, label: experienceX?.label, value: experienceXV },
    { id: 6, name: exclusive?.name, label: exclusive?.label, value: exclusiveV },
    { id: 7, name: insider?.name, label: insider?.label, value: insiderV },
  ];

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Additional info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            tag: values?.tag?.label || values?.tag?.title,
            retail_price: values?.retail_price?.formatted_value,
            label: values?.callToAction?.label,
            url: values?.type === "url" && values?.callToAction?.url,
            sponsored: values?.sponsored,
            currated: values?.currated,
            special: values?.special,
            experienceX: values?.experienceX,
            exclusive: values?.exclusive,
            insider: values?.insider,
            description: values?.type === "msg" && values?.description,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container>
            <Grid item size={{ xs: 12 }}>
              <MsgOrUrl formData={formData} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12 }}>
              <TagPicker
                tag={tag}
                handleChange={handleTagChange}
                setFieldValue={setFieldValue}
                selectedTag={selectedTag || {}}
              />
            </Grid>
            <Grid item size={{ xs: 4 }}>
              <Grid container>
                <Grid item size={{ xs: 12 }}>
                  <CurrencyField
                    retailPriceObj={values?.retail_price || retailPriceV || {}}
                    retailPrice={retail_price}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            {switches?.map((item) => (
              <Grid item size={{ xs: "auto" }} key={item.id} mr={2}>
                <DynamicSwitch
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for AdditionalInfo
AdditionalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleTagChange: PropTypes.func,
  selectedTag: PropTypes.instanceOf(Object),
  // categoryObj: PropTypes.instanceOf(Object),
};

export default AdditionalInfo;
