import React, { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Layout
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";

// Material UI components
import Grid from "@mui/material/Grid2";

// Redux Actions
import setAlert from "store/actions/alertActions";
import {
  getTravelDesks,
  setLoading,
  clearErrors,
  deleteTravelDesk,
  // eslint-disable-next-line import/no-unresolved
} from "store/actions/travelDeskActions";
import { getPdf, getCsv } from "store/actions/exportAction";
import columnsMap from "components/MaterialTablePicker/TableColumns";

const AllTravelDesks = () => {
  const tableRef = createRef();
  const dispatch = useDispatch();

  const travelDesks = useSelector((state) => state.travelDesks.travelDesks);
  const error = useSelector((state) => state.travelDesks.error);
  const user = useSelector((state) => state.users.user);
  const loading = useSelector((state) => state.travelDesks.loading);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      clearErrors();
    }
  }, [error, setAlert]);

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTravelDesks());
  }, []);

  const deleteTravelDeskFn = (id) => {
    dispatch(deleteTravelDesk(id));
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} pt={3}>
          <Grid item size={{ xs: 12 }}>
            <MDBox>
              <MaterialTablePicker
                screen="TravelDesksList"
                title="Travel Desks"
                searchField="travelDesks"
                exportMenuField="travelDesks"
                onRowClickURL="/admin/travelDesks"
                currentUrl="travelDesks"
                tableRef={tableRef}
                columns={[
                  columnsMap.partnerName,
                  columnsMap.contactName,
                  columnsMap.contactEmail,
                  columnsMap.createdAt,
                ]}
                data={travelDesks}
                isLoading={loading}
                filteringOptions={false}
                addUrl="travelDesks/new-travelDesk"
                actionsName="TravelDesk"
                editableDelete={deleteTravelDeskFn}
                deleteRoleAction
                editableRole={user.role === "admin"}
                actionsRole={user.role === "admin"}
                getPdf={(value) => {
                  if (value) {
                    dispatch(
                      getPdf(
                        value,
                        `stats/pdf?limit=${travelDesks?.length}&page=1&search=&sort=name&dir=asc&fields=name,contactName,contactEmail,createdAt`,
                        "Travel Desks"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for PDF export.");
                  }
                }}
                getCsv={() => {
                  if (user?.role === "admin") {
                    dispatch(
                      getCsv(
                        `stats/csv?limit=${travelDesks?.length}&page=1&search=&sort=name&dir=asc&fields=name,contactName,contactEmail,createdAt`,
                        "Travel Desks"
                      )
                    );
                  } else {
                    // Handle the case where 'value' is undefined or empty
                    console.error("Error: No data for CSV export.");
                  }
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </div>
  );
};

export default AllTravelDesks;
