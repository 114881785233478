const form = {
  formId: "new-facility-form",
  formField: {
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    position: {
      name: "position",
      label: "Position",
      type: "text",
      errorMsg: "Position is required!",
    },
    // added destination and url
    destination: {
      name: "destination",
      label: "Destination *",
      type: "text",
      errorMsg: "Destination is required!",
    },
    url: {
      name: "url",
      label: "URL *",
      type: "text",
      errorMsg: "URL is required!",
      invalidMsg: "Please enter a valid contact website! (http://www.url.com)!",
    },
    image: {
      name: "image",
      label: "Image *",
      type: "text",
      errorMsg: "Image is required!",
    },
    mobileImage: {
      name: "mobileImage",
      label: "Mobile Image *",
      type: "text",
      errorMsg: "Mobile Image is required!",
    },
  },
};

export default form;
