import checkout from "./form";

const {
  formField: {
    // user page
    name,
    email,
    role,
    partnerPower,
    // user additional info
    partner,
    allLocations,
    locations,
    location,
    balance,
    commission,
    locDestinations,
    // additional curator info
    description,
    photo,
    bio,
    languages,
    interests,
    destinations,
    // destinations,
    tickets,
    top,
    reference,
  },
} = checkout;

const initialValues = {
  // user page
  [name.name]: "",
  [email.name]: "",
  [role.name]: "admin",
  [partnerPower.name]: false,
  // user additional info
  [partner.name]: "",
  [allLocations.name]: true,
  [locations.name]: [],
  [location.name]: "",
  [location.name]: "",
  [balance.name]: 0,
  [commission.name]: "",
  [locDestinations.name]: { location: "", destinations: "" || {} },
  [reference.name]: "",
  // additional curator info
  [description.name]: "",
  [photo.name]: "",
  [bio?.name]: "",
  [languages?.name]: [],
  [interests?.name]: [],
  // [destinations?.name]: [],
  [destinations?.name]: [],
  [tickets?.name]: [],
  [top?.name]: false,
};

export default initialValues;
