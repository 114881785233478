/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import DetailList from "components/DetailList";
import { capitalizeFirstLetter } from "components/helper";

const PartnerInfo = ({ formData, editPage = false, detailPage = true }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, website, licence, commissionNumber, top } = formField;
  const {
    name: nameV,
    website: websiteV,
    licence: licenceV,
    commissionNumber: commissionNumberV,
    top: topV,
  } = values;
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Partner info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            name: values?.name,
            website: values?.website,
            licence: values?.licence,
            commissionPercentage: values?.commissionNumber,
            top: values?.top,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={name.type}
                label={name.label}
                name={name.name}
                value={capitalizeFirstLetter(nameV)}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={website.type}
                label={website.label}
                name={website.name}
                value={websiteV}
                placeholder={website.placeholder}
                error={errors.website && touched.website}
                success={websiteV.length > 0 && !errors.website}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={licence.type}
                label={licence.label}
                name={licence.name}
                value={licenceV}
                placeholder={licence.placeholder}
                error={errors.licence && touched.licence}
                success={licenceV.length > 0 && !errors.licence}
              />
            </Grid>{" "}
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={commissionNumber.type}
                label={commissionNumber.label}
                name={commissionNumber.name}
                value={
                  // eslint-disable-next-line no-nested-ternary
                  commissionNumberV > 100 ? 100 : commissionNumberV < 0 ? 0 : commissionNumberV
                }
                placeholder={commissionNumber.placeholder}
                error={errors.commissionNumber && touched.commissionNumber}
                success={commissionNumberV.length > 0 && !errors.commissionNumber}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={topV}
                      name={top.name}
                      onChange={(event) => setFieldValue("top", event.target.checked)}
                    />
                  }
                  label="Top"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for PartnerInfo
PartnerInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  // imageBuffer: PropTypes.instanceOf(Object),
  // _setImageBuffer: PropTypes.func,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default PartnerInfo;
