import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

import { clearSubTags, updateProduct } from "store/actions/locationAction";
import { useDispatch } from "react-redux";

import MDBox from "components/MDBox";
import DetailList from "components/DetailList";
import ViewEditActions from "components/DetailList/ViewEditActions";

import validations from "../../schemas/OnlineStore/validation";
import initialValues from "../../schemas/OnlineStore/initialValues";
import form from "../../schemas/OnlineStore/form";
import ProductInfo from "./ProductInfo";

const EditStoreProduct = ({ productDetails, locationId, setIconPressed }) => {
  /* ** Refs ** */
  const formRef = useRef();
  /* ** Constants ** */
  const { addProductValidation } = validations;

  const { formId, addProductsFields: formField } = form;

  const dispatch = useDispatch();

  /* ** State ** */
  const [showDetailsList, setShowDetailsList] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState({ code: "USD", name: "USD" });
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedSubTag, setSelectedSubTag] = useState(null);
  const [initialState, setInitialState] = useState(initialValues);

  useEffect(() => {
    const product = productDetails?.current;

    if (product) {
      const manipulated = {};
      manipulated.productName = product?.title || "";
      manipulated.jobCode = product?.code || product?.partnerId || null;
      manipulated.price = product?.price ? product?.price?.recommendedRetailPrice : null;
      manipulated.netPrice = product?.price ? product?.price?.partnerPrice : null;
      manipulated.phone = product?.phone || "";
      manipulated.email = product?.email || "";
      manipulated.deliveryMethods = product?.deliveryMethods || ["grms"];
      manipulated.defaultCurrencyCode = product?.currency
        ? { code: product?.currency, name: product?.currency }
        : { code: "USD", name: "USD" };
      manipulated.tag =
        product?.tags?.length > 0
          ? { label: product?.tags[0]?.title, value: product?.tags[0]?._id }
          : {};
      manipulated.subTag =
        product?.subTags?.length > 0
          ? { label: product?.subTags[0]?.title, value: product?.subTags[0]?._id }
          : {};

      // Handle images consistently
      if (product?.images) {
        // If images is a string (URL), wrap it in an array
        if (typeof product.images === "string") {
          manipulated.images = [product.images];
        }
        // If images is already an array, use it as is
        else if (Array.isArray(product.images)) {
          manipulated.images = product.images;
        }
        // Otherwise, initialize as empty array
        else {
          manipulated.images = [];
        }
      } else {
        manipulated.images = [];
      }

      manipulated.description = product?.description || "";
      manipulated.label = product?.label || "";

      manipulated._id = product?._id;

      setSelectedTag(manipulated.tag);
      setSelectedSubTag(manipulated.subTag);
      setSelectedCurrency(manipulated.defaultCurrencyCode);

      setInitialState(manipulated);
    }
  }, [productDetails?.current]);

  const resetEditForm = (resetForm) => {
    resetForm();
    setIconPressed("");
    dispatch(clearSubTags());
  };

  const handleCurrencyChange = (value) => {
    if (!value || !value.name) {
      setSelectedCurrency({ code: "USD", name: "USD" });
    } else {
      setSelectedCurrency(value);
    }
  };

  const handleTagChange = (value) => {
    if (value && value?.value) {
      setSelectedTag(value);
      setSelectedSubTag({});
    } else {
      setSelectedTag({});
      setSelectedSubTag({});
    }
  };

  const handleSubTagChange = (value) => {
    if (value && value?.value) {
      setSelectedSubTag(value);
    } else {
      setSelectedSubTag({});
    }
  };

  const handleSubmit = async (values) => {
    const { _id } = values;

    const hasFiles =
      values.deliveryMethods?.includes("noknok") &&
      values.images?.length > 0 &&
      (values.images[0] instanceof File || values.images[0] instanceof Blob);
    dispatch(updateProduct(values, _id, locationId, hasFiles));

    setShowDetailsList(true);
  };

  return (
    <MDBox shadow="md" bgColor="white" borderRadius="md" variant="contained" p={2}>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={addProductValidation}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        // validateOnBlur={false}
        // validateOnChange={false}
        innerRef={(f) => {
          formRef.current = f;
        }}
      >
        {({ values, errors, touched, setFieldValue, resetForm, submitForm }) => (
          <Form id={formId} autoComplete="off">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Button
                color="secondary"
                startIcon={<ArrowBack />}
                onClick={() => resetEditForm(resetForm)}
              >
                Back
              </Button>

              <ViewEditActions
                stateName="locations"
                detailPage={showDetailsList}
                handleEdit={() => setShowDetailsList(false)}
                handleSave={(event) => {
                  event.preventDefault();
                  submitForm();
                }}
                handleClose={() => {
                  resetForm();
                  setShowDetailsList(true);
                }}
              />
            </Box>

            {showDetailsList ? (
              <Box paddingLeft={3}>
                <DetailList
                  data={{
                    JobName: values?.productName,
                    JobCode: values?.jobCode,
                    RetailPrice: values?.price,
                    NetPrice: values?.netPrice,
                    Currency: values?.defaultCurrencyCode?.code,
                    Tag: values?.tag?.label,
                    SubTag: values?.subTag?.label,
                    Phone: values?.phone,
                    Email: values?.email,
                    DeliveryMethods: values?.deliveryMethods,
                    ...(values?.deliveryMethods?.includes("noknok") && {
                      Description: values?.description,
                      Label: values?.label,
                      images: values?.images && values?.images.length > 0 ? values?.images : [],
                    }),
                  }}
                />
              </Box>
            ) : (
              <ProductInfo
                formData={{ values, touched, formField, errors, setFieldValue }}
                handleCurrencyChange={handleCurrencyChange}
                selectedCurrency={selectedCurrency}
                handleTagChange={handleTagChange}
                selectedTag={selectedTag}
                handleSubTagChange={handleSubTagChange}
                selectedSubTag={selectedSubTag}
              />
            )}
          </Form>
        )}
      </Formik>
    </MDBox>
  );
};

export default EditStoreProduct;

EditStoreProduct.propTypes = {
  locationId: PropTypes.string.isRequired,
  setIconPressed: PropTypes.func.isRequired,
  productDetails: PropTypes.shape({
    current: PropTypes.instanceOf(Object),
  }).isRequired,
};
