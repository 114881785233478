/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import ImagePicker from "components/AutoComplete/ImagePicker";
import DetailList from "components/DetailList";
import RecommendationPicker from "components/AsyncSelect/RecommendationPicker";
import LocationPicker from "components/AutoComplete/LocationPicker";
import RecommendationLocPicker from "components/AutoComplete/RecommendationsLocPicker";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "components/helper";
import Danger from "components/Danger";

const Information = ({
  formData,
  editPage = false,
  detailPage = true,
  selectedLocation = {},
  handleLocationChange = () => {},
  handleRecommendationChange = () => {},
  selectedRecommendation = {},
  // locationObj,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { title, description, location, recommendation } = formField;
  const {
    title: titleV,
    description: descriptionV,
    location: locationV,
    recommendation: recommendationV,
  } = values;
  const user = useSelector((state) => state.users.user);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartnerId = useSelector((state) => state.locations.location);
  const locationPartner = locations
    .map((loc) => (loc._id === locationPartnerId ? loc : null))
    .find((loc) => loc !== null);

  useEffect(() => {
    if (user.role === "partner" && locationPartner?._id?.length === undefined) {
      setFieldValue("location", locations[0]);
    } else {
      setFieldValue("location", locationPartner);
    }
  }, [user.role, locationPartnerId]);

  // UseEffect
  useEffect(() => {
    if (selectedLocation && selectedLocation?._id) {
      setFieldValue("locRec.location", selectedLocation);
    } else setFieldValue("locRec.location", "");
  }, [selectedLocation]);

  useEffect(() => {
    if (recommendationV && recommendationV?._id) {
      setFieldValue("locRec.recommendation", recommendationV);
    } else setFieldValue("locRec.recommendation", "");
  }, [recommendationV]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Information</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            title: values?.title,
            description: values?.description,
            location: values?.location,
            recommendation: values?.recommendation?.title,
            // images: values?.images,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!editPage ? (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="logo" />
              </Grid>
            ) : (
              ""
            )}
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={title.type}
                label={title.label}
                name={title.name}
                value={capitalizeFirstLetter(titleV)}
                placeholder={title.placeholder}
                error={errors.title && touched.title}
                success={titleV.length > 0 && !errors.title}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                multiline
                outlined
                rows={5}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
            <Grid item size={{ xs: 6 }}>
              <LocationPicker
                setFieldValue={setFieldValue}
                location={{ label: location.label, name: "locRec.location" }}
                locationOnly
                disabled={user.role === "partner"}
                selectedLocation={
                  user.role === "admin" && selectedLocation?._id
                    ? selectedLocation
                    : user.role === "partner" && selectedLocation?._id
                    ? selectedLocation
                    : user.role === "partner" && locationPartner?._id
                    ? locationPartner
                    : user.role === "partner" && locations[0]
                }
                handleLocationChange={handleLocationChange}
                // error={errors?.locRec?.location && touched.location}
                // success={locationV?._id && !errors.locRec?.location}
              />
            </Grid>
            {locationV?._id || locationPartner?._id ? (
              <Grid item size={{ xs: 12, sm: 6 }}>
                <RecommendationLocPicker
                  setFieldValue={setFieldValue}
                  locationObj={
                    selectedLocation?._id
                      ? selectedLocation
                      : locationV?._id
                      ? locationV
                      : locationPartner?._id
                      ? locationPartner
                      : user.role === "partner" && locations
                      ? locations[0]
                      : {}
                  }
                  handleChange={handleRecommendationChange}
                  selectedRecommendation={selectedRecommendation || recommendationV || {}}
                  recommendation={recommendation}
                />
              </Grid>
            ) : (
              user.role === "admin" && (
                <Grid item size={{ xs: 12, sm: 6 }}>
                  <RecommendationPicker
                    recommendation={recommendation}
                    setFieldValue={setFieldValue}
                    selectedRecommendation={selectedRecommendation || values?.recommendation || {}}
                    handleChange={handleRecommendationChange}
                  />
                  {errors?.recommendation && touched?.recommendation && (
                    <Danger validation={errors.recommendation} />
                  )}
                </Grid>
              )
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Information
Information.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  // locationObj: PropTypes.instanceOf(Object),
  selectedLocation: PropTypes.instanceOf(Object),
  handleRecommendationChange: PropTypes.func,
  handleLocationChange: PropTypes.func,
  selectedRecommendation: PropTypes.instanceOf(Object),
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
};

export default Information;
