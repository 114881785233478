import checkout from "./form";

const {
  formField: {
    // Ticket Info page
    title,
    type,
    tag,
    themes,
    overview,
    destinations,
    primaryDestination,
    location,
    curator,
    curatorEmail,
    curatorContact,
    curatorName,
    curatorOrProv,
    provider,
    providerEmail,
    providerContact,
    providerName,

    // content
    isOneVenue,
    venues,
    skipTheLine,
    containStops,
    durationRadio,
    durationRadio2,
    description,
    day,
    hour,
    min,
    from,
    to,
    day2,
    hour2,
    min2,
    from2,
    to2,
    timeType2,
    itineraryItems,
    badge,
    pin,
    pinsStart,
    pinsEnd,
    pickupType,
    pickupOptionType,
    allowCustomTravelerPickup,
    travelerPickup,
    additionalPickupInfo,
    minutesBeforeDepartureTimeForPickup,
    percentageRefundable,
    dayRangeMax,
    ticketType,
    ticketsPerBooking,
    languageGuides,
    // exclusions,
    // inclusions,
    titlePkr,
    typeText,
    titlePkrExc,
    typeTextExc,
    minTravelersPerBooking,
    maxTravelersPerBooking,

    // additional info
    additionalInfo,
    physicalDifficultyLevel,
    healthRestrictions,
    physicalRestrictions,

    // badges
    top,
    highlight,

    // schedules and pricing
    days,
    times,
    descriptionOption,
    optionName,
    dateFrom,
    dateTo,
    ageBandsObject,
    startDate,
    endDate,
    defaultCurrencyCode,
    // booking and tickets
    cancellationPolicy,
    cancelIfBadWeather,
    bookingCutoffType,
    daysCutoff,
    timeCutoff,
    hoursAct,
    confirmationType,

    // address page
    // location,
    // address,
    // city,
    region,
    country,
    // lat,
    // lng,
    // Schedule And Price info page
    pricingInfo,
    scheduleOrOption,
    availability_type,
    option,
    availabilities,
    availabilitiesAdded,
    tktDate,
    source,
    ageBands,
    accompanied,
    // commission,
    // additional  information page
    tags,
    video,
    // highlight,
    insider,
    sponsored,
    currated,
    special,
    experienceX,
    exclusive,
    last_chance,
    top_seller,
    likely_to_sell_out,
    must_see,
    free_cancellation,
    daily,
    cancellable,
    // top,
    altTextField,
    metaFields,
    // cancellationPolicy,
    metaTitleField,
    pricesObject,
    images,
    // What to Expect page
    // included,
    // not_included,
    url,
    // day,
    // hour,
    // min,
    languages,
    cutoff_time,
    cutoff_days,
    about,
    meeting_point,
    // highlights,
    // Trip planner
    kidsFriendly,
    style,
    interests,
    timeSlotPreference,
    seasonality,
    genderPreference,
  },
} = checkout;

const initialValues = {
  // ticket info  page
  [title.name]: "",
  [type.name]: "ticket" || "STANDARD",
  [tag.name]: "",
  [themes.name]: [],
  [overview.name]: "",
  [destinations.name]: [],
  [primaryDestination.name]: "",
  [skipTheLine.name]: true,
  [containStops.name]: true,
  [location.name]: {},
  [curator.name]: null,
  [curatorName.name]: "",
  [curatorContact.name]: "",
  [curatorEmail.name]: "",
  [curatorOrProv.name]: "curator",
  [provider.name]: {},
  [providerName.name]: "",
  [providerContact.name]: "",
  [providerEmail.name]: "",

  // content
  [isOneVenue.name]: "no",
  [venues.name]: [],
  [durationRadio.name]: "specificDuration",
  [durationRadio2.name]: "specificDuration",
  [timeType2.name]: "MINUTE",
  [description.name]: "",
  [day.name]: 0,
  [hour.name]: 0,
  [min.name]: 0,
  [from.name]: 0,
  [to.name]: 0,
  [day2.name]: 0,
  [hour2.name]: 0,
  [min2.name]: 0,
  [from2.name]: 0,
  [to2.name]: 0,
  [itineraryItems.name]: [],
  [skipTheLine.name]: false,
  [badge.name]: [],
  [pin.name]: {},
  [pickupOptionType.name]: "PICKUP_EVERYONE",
  [pickupType.name]: "",
  [allowCustomTravelerPickup.name]: true,
  [travelerPickup.name]: [],
  [additionalPickupInfo.name]: "",
  [minutesBeforeDepartureTimeForPickup.name]: 0,
  [minTravelersPerBooking.name]: 0,
  [maxTravelersPerBooking.name]: 0,
  [dayRangeMax.name]: 0,
  [percentageRefundable.name]: 0,
  [ticketType.name]: "MOBILE_ONLY",
  [ticketsPerBooking.name]: "ONE_PER_BOOKING",
  [titlePkr.name]: "",
  [typeText.name]: "",
  [titlePkrExc.name]: "",
  [typeTextExc.name]: "",
  // [inclusions.name]: [],
  // [exclusions.name]: [],

  // additional info
  [additionalInfo.name]: [
    "WHEELCHAIR_ACCESSIBLE",
    "STROLLER_ACCESSIBLE",
    "PETS_WELCOME",
    "INFANTS_MUST_SIT_ON_LAPS",
    "PHYSICAL_EASY",
  ],
  [physicalDifficultyLevel.name]: "PHYSICAL_EASY",
  [healthRestrictions.name]: [],
  [physicalRestrictions.name]: [],
  [languageGuides.name]: [],
  [pinsStart.name]: [],
  [pinsEnd.name]: [],

  // badges
  [top.name]: true,
  [highlight.name]: true,

  // schedules and pricing
  [days.name]: [],
  [times.name]: [],
  [dateFrom.name]: "",
  [dateTo.name]: "",
  [startDate.name]: "",
  [endDate.name]: "",
  [defaultCurrencyCode.name]: null,
  [ageBandsObject.name]: [],

  // booking and tickets
  [cancellationPolicy.name]: "STANDARD",
  [cancelIfBadWeather.name]: true,
  [bookingCutoffType.name]: "FIXED_TIME",
  [daysCutoff.name]: { name: "On day of Activity", value: 0 },
  [timeCutoff.name]: { value: "12:00", label: "12:00" },
  [hoursAct.name]: 0,
  [confirmationType.name]: { name: "Instant confirmation", value: "INSTANT" },

  // address page
  // [address.name]: "",
  [location.name]: "",
  // [city.name]: "",
  [region.name]: "",
  [country.name]: "",
  // [lat.name]: "",
  // [lng.name]: "",

  // Schedule And Price info page
  [pricingInfo.name]: "PER_PERSON",
  [scheduleOrOption.name]: "schedule",
  [optionName.name]: "",
  [descriptionOption.name]: "",
  [availability_type.name]: "daily",
  [source.name]: "lokalee",
  [option.name]: "",
  [availabilities.name]: {},
  [availabilitiesAdded.name]: [],
  [pricesObject.name]: [],
  [tktDate.name]: "",
  [ageBands.name]: [],
  [accompanied.name]: true,
  // [commission.name]: "",

  // additional info
  [tags.name]: [],
  [video.name]: "",
  // [cancellationPolicy.name]: "",
  [sponsored.name]: false,
  [currated.name]: false,
  // [highlight.name]: false,
  [special.name]: false,
  [experienceX.name]: false,
  [exclusive.name]: false,
  [insider.name]: false,
  [top_seller.name]: false,
  [likely_to_sell_out.name]: false,
  [must_see.name]: false,
  [free_cancellation.name]: false,
  [daily.name]: false,
  [cancellable.name]: false,
  // [top.name]: false,
  [last_chance.name]: false,
  [altTextField.name]: "",
  [metaFields.name]: "",
  [metaTitleField.name]: "",
  [images.name]: [],

  // What to Expect page
  // [not_included.name]: [],
  // [included.name]: [],
  [languages.name]: [
    { code: "en", name: "English", default: true },
    { code: "ar", name: "Arabic", default: false },
  ],
  // [highlights.name]: [],
  [cutoff_time.name]: "",
  [cutoff_days.name]: "",
  [about.name]: "",
  [meeting_point.name]: "",
  // [day.name]: "",
  // [hour.name]: "",
  // [min.name]: "",
  [url.name]: "",
  // Trip planner
  [kidsFriendly.name]: false,
  [style?.name]: [],
  [interests?.name]: [],
  [timeSlotPreference?.name]: "Afternoon",
  [seasonality?.name]: "Winter",
  [genderPreference?.name]: "Men",
};

export default initialValues;
