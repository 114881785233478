/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */

// Libraries
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

// Pages
import AdditionalPartnerInfo from "pages/users/new-user/components/AdditionalPartnerInfo";
import AdditionalCuratorInfo from "pages/users/new-user/components/AdditionalCuratorInfo";

// Custom Components
import RolePicker from "components/AutoComplete/RolePicker";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useSelector } from "react-redux";
import AdditionalConciergeInfo from "pages/users/new-user/components/AdditionalConciergeInfo";
import { formatDateTime } from "components/helper";

const AdditionalInfo = ({
  formData,
  cityObj = {},
  partnerObj = {},
  selectedCity = {},
  selectedPartner = {},
  selectedLocation = {},
  selectedPromocodes = {},
  detailPage = true,
  setDetailPage = () => {},
  handleCityChange = () => {},
  handlePartnerChange = () => {},
  handleLocationChange = () => {},
  handlePromocodesChange = () => {},
  selectedDestinations = [],
  setSelectedDestinations = () => {},
  setSelectedLocation = () => {},
  handleDestinationChange = () => {},
  handleDestinationsChange = () => {},
  handleEdit,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { role } = formField;
  const { role: roleV } = values;

  // Selector
  const user = useSelector((state) => state.users.user);

  const { submitForm, resetForm } = useFormikContext();

  const additionalForm = () => {
    if (roleV === "curator") {
      return (
        <AdditionalCuratorInfo
          formData={formData}
          cityObj={cityObj}
          edit
          detailPage={detailPage}
          selectedCity={selectedCity}
          handleChange={handleCityChange}
        />
      );
    }
    if (roleV === "partner") {
      return (
        <AdditionalPartnerInfo
          formData={formData}
          partnerObj={partnerObj}
          edit
          detailPage={detailPage}
          selectedPartner={selectedPartner}
          // handleChange={handlePartnerChange}
          handlePartnerChange={handlePartnerChange}
        />
      );
    }

    if (roleV === "concierge") {
      return (
        <AdditionalConciergeInfo
          formData={formData}
          edit
          detailPage={detailPage}
          selectedLocation={selectedLocation}
          selectedDestinations={selectedDestinations}
          setSelectedLocation={setSelectedLocation}
          setSelectedDestinations={setSelectedDestinations}
          handleDestinationChange={handleDestinationChange}
          handleDestinationsChange={handleDestinationsChange}
          selectedPromocodes={selectedPromocodes}
          handlePromocodesChange={handlePromocodesChange}
          handleLocationChange={handleLocationChange}
        />
      );
    }
    return null;
  };

  return (
    <MDBox>
      <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
          Additional info
        </MDTypography>
        {user.role !== "partner" && user.role !== "concierge" && (
          <ViewEditActions
            detailPage={detailPage}
            handleEdit={handleEdit}
            handleClose={() => {
              resetForm();
              setDetailPage(true);
            }}
            handleSave={() => {
              submitForm();
            }}
          />
        )}
      </MDBox>
      <MDBox lineHeight={0}>
        <MDBox mt={1.5}>
          {detailPage ? (
            <>
              <MDBox
                display="flex"
                // pt="8px"
                pb="4px"
                lineHeight={1.25}
              >
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  Role:&nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {roleV}
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                // pt="8px"
                pb="4px"
                lineHeight={1.25}
              >
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  Login Type:&nbsp;
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {values?.facebookID
                    ? "facebook"
                    : values?.googleID
                    ? "google"
                    : values?.appleID
                    ? "apple"
                    : "email"}
                </MDTypography>
              </MDBox>
              {values?.lastLogin && (
                <MDBox
                  display="flex"
                  // pt="8px"
                  pb="4px"
                  lineHeight={1.25}
                >
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    Last Login:&nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    {formatDateTime(values?.lastLogin)}
                  </MDTypography>
                </MDBox>
              )}
            </>
          ) : (
            <RolePicker
              role={role}
              roleV={roleV}
              setFieldValue={setFieldValue}
              error={errors.role && touched.role}
              success={roleV?.name && !errors.role}
              disabled={user.role === "partner"}
            />
          )}
        </MDBox>
        <MDBox>{additionalForm()}</MDBox>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for AdditionalInfo
AdditionalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  cityObj: PropTypes.instanceOf(Object),
  partnerObj: PropTypes.instanceOf(Object),
  detailPage: PropTypes.bool,
  setDetailPage: PropTypes.func,
  handleEdit: PropTypes.func.isRequired,
  handleCityChange: PropTypes.func,
  selectedCity: PropTypes.instanceOf(Object),
  handlePromocodesChange: PropTypes.func,
  selectedPromocodes: PropTypes.instanceOf(Array),
  handlePartnerChange: PropTypes.func,
  selectedPartner: PropTypes.instanceOf(Object),
  handleLocationChange: PropTypes.func,
  selectedLocation: PropTypes.instanceOf(Object),
  selectedDestinations: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]),
  setSelectedDestinations: PropTypes.func,
  handleDestinationChange: PropTypes.func,
  handleDestinationsChange: PropTypes.func,
  setSelectedLocation: PropTypes.func,
};

export default AdditionalInfo;
