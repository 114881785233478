import * as Yup from "yup";
import axios from "axios";
import checkout from "../../new-location/schemas/form";

const URL = process.env.REACT_APP_API;

const {
  formField: {
    name,
    partner,
    email,
    address,
    slider,
    slideDuration,
    disclaimerText,
    destinations,
    title,
    subTitle,
    menus,
    exploreUrl,
  },
} = checkout;

const emailLazyValidation = Yup.lazy((value) => {
  // If the form value is an array, validate each item as an email string
  if (Array.isArray(value)) {
    return Yup.array().of(
      Yup.string()
        .email("invalid email address")
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "invalid email address")
    );
  }

  // Otherwise, validate a single email string
  return Yup.string()
    .email("invalid email address")
    .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "invalid email address");
});

const locationCheckValidation = Yup.string()
  .required("Location is required!")
  .matches(/([A-Za-z])/, "Invalid location name")
  .test(
    "checkNameOnServer",
    "Name check failed; please try again",
    async function checkNameOnServer(value) {
      if (!value) return true; // name is empty => let other checks handle it or skip
      const original = this.parent.originalName;
      // If user hasn't changed the name from the original, skip the server check
      if (value === original) {
        return true;
      }

      // Otherwise, call the server to validate
      try {
        await axios.post(`${URL}/locations/checkName`, { name: value });
        return true;
      } catch (err) {
        const msg = err?.response?.data?.message || "Name is invalid";
        return this.createError({ message: msg });
      }
    }
  );

const partnerCheckValidation = Yup.mixed()
  .required("Partner is required!")
  .test(
    "checkPartnerOnServer",
    "Partner check failed; please try again",
    async function checkPartnerOnServer(value) {
      if (!value) return true;
      try {
        await axios.post(`${URL}/locations/liscenceCheck`, { partner: value });
        return true;
      } catch (err) {
        const msg = err?.response?.data?.message || "Partner is invalid";
        return this.createError({ message: msg });
      }
    }
  );

const validations = [
  Yup.object().shape({
    [email.name]: emailLazyValidation,
    [name.name]: locationCheckValidation,
    // We store the originalName in the same object
    originalName: Yup.string().nullable(),
    [partner.name]: partnerCheckValidation,
  }),

  Yup.object().shape({
    [address.name]: Yup.string().required(address.errorMsg),
    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
    [slideDuration.name]: Yup.number()
      .positive("Duration must be a positive number")
      .required("Duration is required"),
  }),

  Yup.object().shape({
    [disclaimerText.name]: Yup.mixed().when("disclaimer", {
      is: true,
      then: () =>
        Yup.mixed()
          .test("editorState", disclaimerText.errorMsg, (value) => {
            let parsedValue = value;
            // Check if the value is a string and try to parse it
            if (typeof value === "string") {
              try {
                parsedValue = JSON.parse(value);
              } catch (error) {
                return false; // Invalid JSON, the test will fail
              }
            }
            // Perform the original check on the parsed object
            if (parsedValue && parsedValue.blocks && parsedValue.blocks[0]?.text?.length) {
              return true;
            }
            return false;
          })
          .required("This field is required."),
    }),
  }),

  Yup.object().shape({
    [slider.name]: Yup.array().when("showSlider", {
      is: true,
      then: () => Yup.array().min(1, slider?.invalidMsg).required(slider?.errorMsg),
    }),
  }),
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [subTitle.name]: Yup.string()
      .matches(/([A-Za-z])/, subTitle.invalidMsg)
      .required(subTitle.errorMsg),
    [menus.name]: Yup.array().min(1, menus.errorMsg).required(menus.errorMsg),
    [exploreUrl.name]: Yup.string()
      .matches(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
        exploreUrl.invalidMsg
      )
      .required(exploreUrl.errorMsg),
  }),
];

export default validations;
