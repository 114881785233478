import React from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";

const AvailabilityDurationPanel = ({ setFieldValue, availabilities = [] }) => {
  // const [listEdited, setListEdited] = useState([]);

  const deleteAvailability = (i) => {
    const arr = [...availabilities];
    arr.splice(i, 1);

    setFieldValue("itineraryItems", arr);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
        marginBottom: "0px",
      }}
    >
      {availabilities?.map((row, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container alignItems="center">
              <Grid item size={{ xs: "auto" }}>
                <Typography variant="body1" style={{ wordBreak: "break-word", maxWidth: "50ch" }}>
                  {row?.pointOfInterestLocation?.pin?.label ||
                    row?.pointOfInterestLocation?.pin?.id?.name}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  edge="end"
                  onClick={() => {
                    deleteAvailability(index);
                    // setFieldValue("availabilities", listEdited);
                  }}
                >
                  <DeleteIcon sx={{ color: "grey" }} />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="span" variant="body2">
              {(row.duration?.from != null ||
                row.duration?.to != null ||
                row?.duration?.variableDurationFromMinutes != null ||
                row?.duration?.variableDurationToMinutes != null) && (
                <div>
                  {(row.duration?.from != null || row?.duration?.variableDurationFromMinutes) && (
                    <>
                      <span style={{ fontWeight: 500 }}>From: </span>
                      {row?.duration?.from || row?.duration?.variableDurationFromMinutes}
                    </>
                  )}
                  {(row.duration?.to != null ||
                    row?.duration?.variableDurationToMinutes != null) && (
                    <>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- To: </span>
                      {row.duration?.to || row?.duration?.variableDurationToMinutes}
                    </>
                  )}
                  {row.duration?.timeType && (
                    <>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Time type: </span>
                      {row.duration?.timeType}
                    </>
                  )}
                  {row.pointOfInterestLocation?.attractionId && (
                    <>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Venue: </span>
                      {row.pointOfInterestLocation?.attractionId?.label ||
                        row.pointOfInterestLocation?.attractionId?.title}
                    </>
                  )}
                  <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Description: </span>
                  {row?.description}
                </div>
              )}

              {(row.duration?.day != null ||
                row.duration?.hour != null ||
                row.duration?.minute != null) && (
                <div>
                  {row.duration?.day != null && (
                    <>
                      <span style={{ fontWeight: 500 }}>Day: </span>
                      {row?.duration?.day}
                    </>
                  )}
                  {row.duration?.hour != null && (
                    <>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Hour: </span>
                      {row.duration?.hour}
                    </>
                  )}
                  {row.duration?.minute != null && (
                    <>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Minute: </span>
                      {row.duration?.minute}
                    </>
                  )}
                  {row.pointOfInterestLocation?.attractionId && (
                    <>
                      <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Venue: </span>
                      {row.pointOfInterestLocation?.attractionId?.label ||
                        row.pointOfInterestLocation?.attractionId?.title}
                    </>
                  )}
                  <span style={{ fontWeight: 500, marginLeft: "20px" }}>- Description: </span>
                  {row?.description}
                </div>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

AvailabilityDurationPanel.propTypes = {
  availabilities: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func.isRequired,
};

export default AvailabilityDurationPanel;
