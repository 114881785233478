/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { InputAdornment, Snackbar, Alert } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// import axios from "axios";
import { useDispatch } from "react-redux";
import { changePass } from "store/actions/userActions";

// components
import FormField from "components/FormField";
import MDButton from "components/MDButton";
import initialValues from "../../schemas/initialValues";
import validations from "../../schemas/validations";
import form from "../../schemas/form";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ChangeMyPasswordDialog = ({ handleCloseDialog = () => {}, openDialog = false }) => {
  const { currentPassword, newPassword, passwordConfirm } = form.formField;
  const currentValidation = validations[0];

  const dispatch = useDispatch();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const showCurrentPasswordFn = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const showNewPasswordFn = () => {
    setShowNewPassword(!showNewPassword);
  };
  const showConfirmPasswordFn = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitFormFn = async (val, actions) => {
    await sleep(1000);
    dispatch(changePass(val));
    actions.setSubmitting(false);
    actions.resetForm();
    setOpenSnackbar(true); // Show the Snackbar on successful password change
  };

  const handleSubmit = (values, actions) => {
    submitFormFn(values, actions);
    handleCloseDialog();
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, submitForm, resetForm }) => (
          <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                // Handle close only when the backdrop is clicked
                return;
              }
              // Call the function to handle closing the dialog when clicking outside
              handleCloseDialog();
              resetForm(); // Reset the form if needed
            }}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Change Password
              </DialogContentText>

              <Grid item size={{ xs: 12 }} mt={2}>
                <FormField
                  type={showCurrentPassword ? "text" : currentPassword.type}
                  label={currentPassword?.label}
                  name={currentPassword.name}
                  value={values?.currentPassword}
                  placeholder={currentPassword.placeholder}
                  error={errors.currentPassword && touched.currentPassword}
                  success={values?.currentPassword.length > 0 && !errors.currentPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDButton onClick={showCurrentPasswordFn}>
                          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                        </MDButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item size={{ xs: 12 }}>
                <FormField
                  type={showNewPassword ? "text" : newPassword?.type}
                  label={newPassword?.label}
                  name={newPassword?.name}
                  value={values?.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDButton onClick={showNewPasswordFn}>
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </MDButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item size={{ xs: 12 }}>
                <FormField
                  type={showConfirmPassword ? "text" : passwordConfirm?.type}
                  label={passwordConfirm?.label}
                  name={passwordConfirm?.name}
                  value={values?.passwordConfirm}
                  error={errors.passwordConfirm && touched.passwordConfirm}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDButton onClick={showConfirmPasswordFn}>
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </MDButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseDialog();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button onClick={submitForm}>Change Password</Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          Password changed successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

ChangeMyPasswordDialog.propTypes = {
  handleCloseDialog: PropTypes.func,
  openDialog: PropTypes.bool,
};

export default ChangeMyPasswordDialog;
