/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/FormField";
import RolePicker from "components/AutoComplete/RolePicker";
import { capitalizeFirstLetter } from "components/helper";
import { useSelector } from "react-redux";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";

const UserInfo = ({ formData }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, email, role, partnerPower } = formField;
  const { name: nameV, email: emailV, role: roleV } = values;
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    if (user.role === "partner" && user.partnerPower) {
      setFieldValue("role", "partner");
    }
  }, [user?.role, user?.partnerPower]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">User info</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12, sm: 6 }}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={capitalizeFirstLetter(nameV)}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item size={{ xs: 12, sm: 6 }}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              // error={errors.email && touched.email}
              // success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item size={{ xs: 12 }}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <RolePicker
                role={role}
                roleV={user?.role === "partner" && user?.partnerPower ? "partner" : roleV}
                setFieldValue={setFieldValue}
                error={errors.role && touched.role}
                success={roleV?.name && !errors.role}
                disabled={user.role === "partner"}
                onChange={() => {
                  setFieldValue("partnerUser", false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {values?.role === "partner" && (
          <Grid item size={{ xs: 3 }}>
            <FormControlLabel
              label={partnerPower?.label}
              disabled={user.role === "partner" && user.partnerPower}
              control={
                <Checkbox
                  name={partnerPower}
                  checked={values?.partnerPower}
                  onChange={(event) => {
                    const isChecked = event?.target?.checked;
                    setFieldValue("partnerPower", isChecked);
                  }}
                />
              }
            />
          </Grid>
        )}
      </MDBox>
    </MDBox>
  );
};

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
};

export default UserInfo;
