// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";

const DaysPicker = ({
  daysList,
  days,
  daysV,
  setFieldValue,
  handleDaysChange = () => {},
  isObj = false,
  ...rest
}) => (
  <Autocomplete
    id="days"
    multiple
    filterSelectedOptions
    options={daysList}
    onChange={(event, value) => {
      if (value) {
        handleDaysChange(value);
        setFieldValue("days", value);
      } else {
        setFieldValue("days", []);
      }
    }}
    value={daysV || []}
    getOptionLabel={(option) => (isObj ? option?.name || "" : option || "")}
    isOptionEqualToValue={(option, value) =>
      isObj ? option.value === value.value : option === value
    }
    renderInput={(params) => (
      <FormField {...params} {...rest} label={days.label} name={days?.name} onChange={() => null} />
    )}
  />
);

// typechecking props for DaysPicker
DaysPicker.propTypes = {
  daysList: PropTypes.instanceOf(Array).isRequired,
  days: PropTypes.instanceOf(Object).isRequired,
  daysV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleDaysChange: PropTypes.func,
  isObj: PropTypes.bool,
};

export default DaysPicker;
