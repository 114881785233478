/* eslint-disable import/prefer-default-export */
import axios from "axios";
import {
  SET_LOADING,
  GET_REQUESTS,
  REQUEST_FAIL,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
} from "./Types";

const factory = require("./actionsFactory");

// URL
const URL = process.env.REACT_APP_API;
// Set loading
export const setLoading = () => (dispatch) => {
  dispatch({
    type: SET_LOADING,
  });
};

export const getLocationRequests = (locationId, startDate, endDate) => {
  let queryParams = "";

  if (startDate && endDate) {
    queryParams = `startDate=${startDate}&endDate=${endDate}`;
  }

  return factory.get(
    `${URL}/locations/${locationId}/jobsRequests?${queryParams}`,
    GET_REQUESTS,
    REQUEST_FAIL
  );
};

export const markAsOrdered = (id) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const body = {};

  try {
    const response = await axios.post(`${URL}/order/${id}/confirmStoreOrder `, body, config);
    dispatch({ type: MARK_AS_READ_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: MARK_AS_READ_FAILURE, error: error.message });
  }
};
