/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";

import { Typography } from "@mui/material";

const RecommendationsPicker = ({
  recommendations = {},
  destination = "",
  // locationId = "",
  tags = {},
  // byRecPage = false,
  recommendationsV = [],
  handleChange = () => {},
  setFieldValue,
  city = "",
}) => {
  const filterRec = (data) =>
    data?.map((recommendationData) => ({
      label: recommendationData.title,
      value: recommendationData._id,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/recommendations?limit=20${
        city ? `&city=${city}` : ""
      }`;
      if (tags?.length > 0) {
        const tagsParam = tags.map((tag) => encodeURIComponent(tag.value)).join(",");
        url += `&tags=${tagsParam}`;
      }
      if (destination) url += `&destinations=${encodeURIComponent(destination)}&parent=true`;
      // if (locationId && byRecPage) url += `&location=${encodeURIComponent(locationId)}&parent=true`;

      if (inputValue) url += `&search=${inputValue}`;
      url += "&sort=title";
      url += "&dir=asc";
      setTimeout(() => {
        // resolve();
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterRec(result.data);
            resolve(filteredData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000); // Adjust the delay time (in milliseconds) as needed

  return (
    <div>
      <Typography
        style={{
          wordBreak: "break-word",
          fontSize: "0.875rem",
          fontWeight: 400,
        }}
      >
        {recommendations.label}
      </Typography>{" "}
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={recommendationsV}
        placeholder="Search for a Recommendation"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={(options) => {
          // Handle an array of selected options
          if (!options || options.length === 0) {
            setFieldValue("recommendations", []);
            handleChange(options);
          } else {
            setFieldValue("recommendations", options);
            handleChange(options);
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

RecommendationsPicker.propTypes = {
  recommendations: PropTypes.instanceOf(Object),
  tags: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  recommendationsV: PropTypes.instanceOf(Array),
  city: PropTypes.string,
  destination: PropTypes.string,
  // byRecPage: PropTypes.bool,
  // locationId: PropTypes.string,
};

export default RecommendationsPicker;
