/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Body, Container, Html, Section, Text } from "@react-email/components";
import PropTypes from "prop-types";

import HeadSection from "../HeadSection";
import LogoSection from "../LogoSection";
import FooterSection from "../FooterSection";

const GroceryOrderEmailBeforeConfirmation = (data = {}) => {
  const { data: emailData = {} } = data;

  const {
    // status,
    // firstName = "John",
    // lastName = "Doe",
    address = "Beitamanna, Unit 5 Gemmayzeh main Road 352",
    // phone = "1234567890",
    paymentMethod = "stripe",
    cardNumber = "1234",
    type = "room",
    // email = "guest@example.com",
    // createdAt = new Date().toDateString(),
    items = [{ ref: { title: "Item 1" } }],
    unitNumber = "5",
  } = emailData;

  //   const getStatus = (requestStatus, method) => {
  //     if (requestStatus) return requestStatus;
  //     return method === "stripe" ? "Paid" : "Pending";
  //   };
  return (
    <Html>
      <HeadSection />
      <Body
        style={{
          lineHeight: "1.625",
          backgroundColor: "#f7fafc",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <Container
          style={{
            padding: "0",
            maxWidth: "600px",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "#ffffff",
            border: "1px solid #eaeaea",
          }}
        >
          {/* Logo Section */}
          <LogoSection />
          {/* Greeting Section */}

          <Section
            style={{
              textAlign: "left",
              padding: "1.5rem 2rem 1rem 2rem",
              //   margin: "0 auto 10px auto",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "25px",
                color: "#222D29",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Thank you.
            </Text>
            <Text style={{ fontSize: "24px", color: "#222D29", marginBottom: "0rem" }}>
              We’ve received your groceries order and are reviewing the details. You’ll receive
              confirmation once the request has been processed. Here are the details of your request
            </Text>
          </Section>

          <Section
            style={{
              maxWidth: "536px",
              padding: "16px 24px",
              borderRadius: "11.54px",
              backgroundColor: "#F2F2F2",
              margin: "0 auto 24px auto",
            }}
          >
            <Text
              style={{
                fontSize: "20px",
                color: "#222D29",
                marginTop: "0rem",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Order Summary
            </Text>
            <Text style={{ fontSize: "20px", color: "#222D29", marginTop: "0rem" }}>
              {type === "unit" ? "Unit Number:" : "Room Number:"} {unitNumber}
            </Text>
            <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "8px" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid #ddd" }}>
                  <th
                    colSpan="2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#222D29",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    ORDER DETAILS
                  </th>

                  <th
                    style={{
                      width: "100px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#222D29",
                      textAlign: "center",
                      padding: "8px",
                    }}
                  >
                    QUANTITY
                    {/* {totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice
                      ? "QUANTITY"
                      : ""} */}
                  </th>
                  <th
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#222D29",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  >
                    PRICE
                  </th>
                </tr>
              </thead>

              <tbody>
                {items.map((item) => {
                  const {
                    ref,
                    // itemTotalPrice
                  } = item;

                  return (
                    <tr key={ref?.title || item.title} style={{ borderBottom: "1px solid #ddd" }}>
                      <td
                        colSpan="2"
                        style={{
                          fontSize: "14px",
                          color: "#222D29",
                          padding: "8px",
                          textAlign: "left",
                          colSpan: 2,
                        }}
                      >
                        {ref?.title || item.title}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "14px",
                          color: "#222D29",
                          textAlign: "center",
                          padding: "8px",
                        }}
                      >
                        {item.totalPax}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          fontSize: "14px",
                          color: "#222D29",
                          textAlign: "right",
                          padding: "8px",
                        }}
                      >
                        {item.itemTotalPrice?.price?.recommendedRetailPrice
                          ? `${item.currency} ${item.itemTotalPrice.price.recommendedRetailPrice}`
                          : "Free"}
                      </td>
                    </tr>
                  );
                })}

                {/* <tr>
                  <td
                    colSpan="3"
                    style={{
                      fontSize: "14px",
                      color: "#222D29",
                      fontWeight: "700",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    TOTAL
                  </td>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "#222D29",
                      fontWeight: "bold",
                      textAlign: "right",
                      padding: "8px",
                    }}
                  > */}
                {/* {totalAmount?.priceAfterLokaleeDiscount?.recommendedRetailPrice
                      ? `${currency} ${totalAmount.priceAfterLokaleeDiscount.recommendedRetailPrice}`
                      : "Free"} */}
                {/* USD 100
                  </td>
                </tr> */}
              </tbody>
            </table>
            {paymentMethod === "No Payment Method" ? null : (
              <>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#222D29",
                    marginTop: "30px",
                    fontWeight: "700",
                    lineHeight: "25.75px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  PAYMENT METHOD
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#222D29",
                    marginBottom: "16px",
                  }}
                >
                  {paymentMethod === "stripe" ? `VISA ****${cardNumber}` : paymentMethod}
                </Text>
              </>
            )}
            <Text
              style={{
                fontSize: "14px",
                color: "#222D29",
                marginTop: "30px",
                fontWeight: "700",
                lineHeight: "25.75px",
                borderBottom: "1px solid #ddd",
              }}
            >
              DELIVERY ADDRESS
            </Text>
            <div style={{ display: "flex", marginBottom: "4px" }}>
              <Text
                style={{ fontSize: "14px", color: "#222D29", margin: "0px", marginRight: "5px" }}
              >
                {address}
              </Text>
            </div>
          </Section>

          {/* Footer Section */}

          <Section
            style={{
              textAlign: "left",
              padding: "24px",
              marginLeft: "5px",
            }}
          >
            <Text
              style={{
                margin: "0px",
                fontSize: "16px",
                // fontWeight: "700",
                color: "#222D29",
              }}
            >
              For any inquiries, we would be happy to assist.
            </Text>{" "}
            <Text
              style={{
                margin: "0px",
                fontSize: "16px",
                color: "#222D29",
                marginBottom: "20px",
              }}
            >
              Please feel free to contact our{" "}
              <a
                href="mailto:cs@lokalee.app"
                style={{
                  fontSize: "16px",
                  color: "#222D29",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                <u>Help Center</u>
              </a>
            </Text>
            <Text
              style={{
                margin: "0px",
                fontSize: "20px",
                fontWeight: "700",
                color: "#222D29",
                marginBottom: "20px",
              }}
            >
              Thank you,
            </Text>
            <Text style={{ fontSize: "20px", color: "#222D29", fontWeight: "400", margin: "0px" }}>
              Lokalee Operations Team
            </Text>
          </Section>
          <FooterSection hasSocialLinks={false} />
        </Container>
      </Body>
    </Html>
  );
};

export default GroceryOrderEmailBeforeConfirmation;

GroceryOrderEmailBeforeConfirmation.propTypes = {
  data: PropTypes.shape({}),
};
