/* eslint-disable no-undef */
import axios from "axios";
import { dateFormatWithDash } from "components/helper";
import {
  SET_REPORT_LOADING,
  CLEAR_ERRORS,
  CLEAR_HEADER_AND_REPORTS,
  REPORT_ADD_SUCCESS,
  INVOICE_ADD_SUCCESS,
  HEADER_INFO_SUCCESS,
  CLEAR_INVOICE,
  REPORT_FAIL,
  INVOICE_FAIL,
  PRODUCTION_REPORTS_ADD_SUCCESS,
  CART_REPORTS_ADD_SUCCESS,
  TRAFFIC_REPORTS_BY_COUNTRY_ADD_SUCCESS,
  AVERAGE_CART_VALUE_SUCCESS,
} from "./Types";
// import factory from './actionsFactory';

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Location Report
export const addLocationReport = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  let reportsUrl = `${URL}/stats/analytics/categoryReport?`;
  if (data?.dateRange[0]?.startDate)
    reportsUrl += `startDate=${data.dateRange[0]?.startDate
      ?.toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-")}`;
  if (data?.dateRange[0]?.endDate)
    reportsUrl += `&endDate=${data.dateRange[0]?.endDate
      ?.toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-")}`;
  if (data?.slug) reportsUrl += `&slug=${data?.slug}`;

  try {
    const res = await axios.get(reportsUrl, config);
    dispatch({
      type: REPORT_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REPORT_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Invoices Report
export const addInvoicesReport = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  let reportsUrl = `${URL}/invoices?`;
  if (data?.dateRange?.length > 0) {
    if (data?.dateRange[0]?.startDate)
      reportsUrl += `startDate=${data?.dateRange[0]?.startDate
        ?.toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-")}`;
    if (data?.dateRange[0]?.endDate)
      reportsUrl += `&endDate=${data?.dateRange[0]?.endDate
        ?.toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-")}`;
  }

  if (data?.status) reportsUrl += `&type=${data?.status}`;
  if (data?.selectedCity) reportsUrl += `&itemCity=${data?.selectedCity?.label}`;
  if (data?.selectedCurrency?.name) reportsUrl += `&currencyCode=${data?.selectedCurrency?.name}`;

  try {
    const res = await axios.get(reportsUrl, config);
    dispatch({
      type: INVOICE_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: INVOICE_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// get cart reports
export const getCartReports = (startDate, endDate) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  // Construct the base URL
  let url = `${URL}/carts?cartItems=true`;

  // Add startDate to the URL if it is provided
  if (startDate) {
    url += `&startDate=${startDate}`;
  }

  // Add endDate to the URL if it is provided
  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  try {
    const res = await axios.get(url, config);
    dispatch({
      type: CART_REPORTS_ADD_SUCCESS,
      payload: res.data.data.data,
    });
  } catch (err) {
    dispatch({
      type: REPORT_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// get production reports
export const getProductionReports = (data, selectedCurrency) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  let reportsUrl = `${URL}/stats/analytics/productionReport?`;

  // Formated the start and end dates
  if (data?.dateRange[0]?.startDate) {
    reportsUrl += `startDate=${dateFormatWithDash(data?.dateRange[0]?.startDate)}`;
  }

  if (data?.dateRange[0]?.endDate) {
    reportsUrl += `&endDate=${dateFormatWithDash(data?.dateRange[0]?.endDate)}`;
  }
  if (data?.all) {
    reportsUrl += `&all=true`;
  }
  if (selectedCurrency?.length > 0) reportsUrl += `&currencyCode=${selectedCurrency}`;
  if (data?.slug) reportsUrl += `&location=${data?.slug}`;
  if (data?.isReport) reportsUrl += `&report=true`;

  try {
    const res = await axios.get(reportsUrl, config);
    dispatch({
      type: PRODUCTION_REPORTS_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REPORT_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Get header reports
export const getHeaderReport = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  let reportsUrl = `${URL}/stats/analytics/headerReport?`;
  if (data?.dateRange[0]?.startDate)
    reportsUrl += `startDate=${data.dateRange[0]?.startDate
      ?.toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-")}`;
  if (data?.dateRange[0]?.endDate)
    reportsUrl += `&endDate=${data.dateRange[0]?.endDate
      ?.toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-")}`;
  if (data?.slug) reportsUrl += `&slug=${data?.slug}`;

  try {
    const res = await axios.get(reportsUrl, config);
    dispatch({
      type: HEADER_INFO_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REPORT_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// get CountryTrafficReports
export const getCountryPartnerTrafficReports = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  let reportsUrl = `${URL}/stats/analytics/trafficReport?`;
  if (data?.dateRange[0]?.startDate)
    reportsUrl += `startDate=${data?.dateRange[0]?.startDate
      ?.toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-")}`;
  if (data?.dateRange[0]?.endDate)
    reportsUrl += `&endDate=${data?.dateRange[0]?.endDate
      ?.toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-")}`;
  if (data?.selectedLocation?.name)
    reportsUrl += `&location=${encodeURIComponent(data?.selectedLocation?.name)}`;
  if (data?.selectedCity) reportsUrl += `&city=${encodeURIComponent(data?.selectedCity?.name)}`;
  if (data?.selectedCountry) reportsUrl += `&country=${data?.selectedCountry?.name}`;
  if (data?.selectedPartner) reportsUrl += `&partner=${data?.selectedPartner?.value}`;

  try {
    const res = await axios.get(reportsUrl, config);
    dispatch({
      type: TRAFFIC_REPORTS_BY_COUNTRY_ADD_SUCCESS,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch({
      type: REPORT_FAIL,
      payload: err,
    });
  }
};

function getURLParams(country, city, loc, partner) {
  let params = "";
  if (country) {
    params += `?country=${country?.name}`;
  }
  if (city) {
    params += country
      ? `&city=${encodeURIComponent(city?.name)}`
      : `?city=${encodeURIComponent(city?.name)}`;
  }
  if (loc?.name?.length > 0) {
    params +=
      country || city
        ? `&location=${encodeURIComponent(loc?.name)}`
        : `?location=${encodeURIComponent(loc?.name)}`;
  }
  if (partner) {
    params +=
      country || city || loc !== null
        ? `&partner=${encodeURIComponent(partner)}`
        : `?partner=${encodeURIComponent(partner)}`;
  }
  return params;
}

export const getDroplistTrafficReports = (country, city, loc, partner) =>
  factory.post(
    {},
    `${URL}/stats/analytics/trafficReport/trafficReportDroplist${getURLParams(
      country,
      city,
      loc,
      partner?.value
    )}`,
    "GET_DROPLIST_TRAFFIC_REPORTS",
    "REPORT_FAIL"
  );

// Get average Cart Value
export const getAverageCartValue = (startDate, endDate) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  let reportsUrl = `${URL}/stats/carts/averageCartValue?`;
  if (startDate) reportsUrl += `startDate=${startDate}`;
  if (endDate) reportsUrl += `&endDate=${endDate}`;

  try {
    const res = await axios.get(reportsUrl, config);
    dispatch({
      type: AVERAGE_CART_VALUE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REPORT_FAIL,
      payload: err?.response.data.message,
    });
  }
};

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Clear Header and reports
export const clearHeaderAndReports = () => ({ type: CLEAR_HEADER_AND_REPORTS });

// CLEAR INVOICES REPORTS
export const clearInvoicesReport = () => ({ type: CLEAR_INVOICE });

// Set Loading
export const setLoading = () => ({ type: SET_REPORT_LOADING });
