import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { Typography } from "@mui/material";

const TagPicker = ({
  handleChange = () => {},
  tag,
  setFieldValue = () => {},
  selectedTag = {},
  isClearable = false,
  ticketPage = false,
  isJobType = false,
}) => {
  const filterRec = (data) =>
    data.map((tagData) => ({
      label: tagData.title,
      value: tagData._id,
      parentTagIds: tagData.parentTagIds,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      let url = `${process.env.REACT_APP_API}/tags?`;
      if (inputValue) url += `title=${inputValue}&`;
      if (isJobType) url += `type=JOBS&parentTags=true&`;
      url += "sort=title&dir=asc";
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          resolve(filterRec(result.data));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.message);
        });
    });

  return (
    <div>
      <Typography style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}>
        {tag.label}
      </Typography>{" "}
      <AsyncSelect
        // ref={selectRef}
        cacheOptions
        defaultOptions
        placeholder={
          selectedTag?.label || selectedTag === null
            ? selectedTag?.label
            : selectedTag?.title || `Search for a ${tag.label}`
        }
        isClearable={isClearable}
        // key={tag}
        menuPosition="fixed"
        loadOptions={promiseOptions}
        onChange={(option, { action }) => {
          if (option) {
            handleChange(option);
            setFieldValue("tag", option);
            if (ticketPage) {
              setFieldValue("themes", []);
              setFieldValue("tags", []);
            }
          }
          if (action === "clear") {
            handleChange(null);
            setFieldValue("tag", {});
          }
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            // border: "none!important",
            // boxShadow: "none",
            height: "40px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          // option: (base) => ({
          //   ...base,
          //   padding: "18px 18px", // Adjust the padding as needed
          // }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
            overflow: "unset",
          }),
        }}
      />
    </div>
  );
};

TagPicker.propTypes = {
  tag: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  ticketPage: PropTypes.bool,
  selectedTag: PropTypes.instanceOf(Object),
  isJobType: PropTypes.bool,
};

export default TagPicker;
