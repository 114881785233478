/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Material UI components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Redux Actions
import { registerUser, clearErrors, setLoading, checkEmail } from "store/actions/userActions";

// Layouts
import DashboardLayout from "layouts/DashboardLayout";

// Custom Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import * as Yup from "yup";
import { scrollToTop } from "components/helper";
import UserInfo from "./components/UserInfo";
// Schema
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import AdditionalCuratorInfo from "./components/AdditionalCuratorInfo";
import AdditionalPartnerInfo from "./components/AdditionalPartnerInfo";
import Review from "./components/Review";
import AdditionalConciergeInfo from "./components/AdditionalConciergeInfo";

function getSteps(type) {
  if (type === "admin") return ["User info", "Review"];
  return ["User Info", "Additional info", "Review"];
}

function getStepContent(
  stepIndex,
  formData,
  selectedDestinations,
  handleDestinationsChange,
  selectedDestination,
  setSelectedDestination,
  handleDestinationChange,
  selectedLocation,
  handleLocationChange,
  selectedPartner,
  handlePartnerChange,
  setSelectedPartner
) {
  if (formData?.values?.role === "admin") {
    switch (stepIndex) {
      case 0:
        return <UserInfo formData={formData} />;
      case 1:
        return <Review formData={formData} />;
      default:
        return null;
    }
  } else {
    switch (stepIndex) {
      case 0:
        return <UserInfo formData={formData} />;
      case 1:
        if (formData?.values?.role === "curator")
          return (
            <AdditionalCuratorInfo
              formData={formData}
              detailPage={false}
              selectedDestinations={selectedDestinations}
              handleChange={handleDestinationsChange}
            />
          );
        if (formData?.values?.role === "concierge")
          return (
            <AdditionalConciergeInfo
              formData={formData}
              detailPage={false}
              selectedDestination={selectedDestination}
              setSelectedDestination={setSelectedDestination}
              handleDestinationChange={handleDestinationChange}
              selectedLocation={selectedLocation}
              handleLocationChange={handleLocationChange}
            />
          );
        return (
          <AdditionalPartnerInfo
            formData={formData}
            detailPage={false}
            selectedPartner={selectedPartner}
            // handleChange={handlePartnerChange}
            handlePartnerChange={handlePartnerChange}
            setSelectedPartner={setSelectedPartner}
          />
        );
      case 2:
        return <Review formData={formData} selectedDestination={selectedDestination} />;
      default:
        return null;
    }
  }
}

const NewUser = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const error = useSelector((state) => state.users.error);
  const registered = useSelector((state) => state.users.registered);
  const emailError = useSelector((state) => state.users.errorEmail);
  const user = useSelector((state) => state.users.user);

  // state
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const { formId, formField } = form;
  const [formChange, setFormChange] = useState(null);

  const emailSchemaValidation = Yup.object().shape({
    email: Yup.string()
      .email("Your email address is invalid!")
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "Your email address is invalid!")
      .required("Email address is required!")
      .test("checkEmail", emailError, async (value) => {
        if (value) {
          // Call your checkPartners function and handle the response
          dispatch(checkEmail({ email: value }));
        }
      }),
  });

  const currentValidation =
    formChange?.role === "curator" && activeStep === 1
      ? validations[2]
      : formChange?.role === "concierge" && activeStep === 1
      ? validations[3]
      : validations[0] && activeStep === 0
      ? validations[0].concat(emailSchemaValidation)
      : validations[activeStep];

  const isLastStep = activeStep === steps.length - 1;
  const [selectedDestinations, setSelectedDestinations] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  // useEffect
  useEffect(() => {
    if (error) {
      // dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, clearErrors]);

  useEffect(() => {
    if (formChange?.email) {
      dispatch(clearErrors());
    }
  }, [formChange?.email]);

  useEffect(() => {
    if (formChange?.role === "admin") {
      setSteps(getSteps("admin"));
    } else {
      setSteps(getSteps("other-info"));
    }
  }, [formChange, formChange?.role]);

  useEffect(() => {
    if (registered) {
      user?.role === "partner" && user?.partnerPower
        ? navigate(`/partnerPower/users`)
        : navigate(`/admin/users`);
      setActiveStep(0);
    }
  }, [registered, navigate]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTop(); // Scroll to the top
  };

  const submitForm = async (values, actions) => {
    const updatedValues = { ...values };

    if (updatedValues && updatedValues?.locDestinations) {
      delete updatedValues?.locDestinations;
    }
    // change the locations array of objects to array od id strings
    if (updatedValues && updatedValues?.locations) {
      const newLocationArr = updatedValues?.locations.map((item) => item._id);
      updatedValues.locations = newLocationArr;
    }

    if (updatedValues && updatedValues?.promoCodes) {
      updatedValues.promoCodes = updatedValues.promoCodes.map((item) => item.value);
    }

    // Change the location object to its id string if it exists
    if (updatedValues?.location && updatedValues.location._id) {
      updatedValues.location = updatedValues.location._id;
    } else delete updatedValues.location;
    await sleep(1000);

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(updatedValues, null, 2));
    dispatch(registerUser(updatedValues));
    if (registered) {
      actions.setSubmitting(false);
      actions.resetForm();
    }
  };

  const handleSubmit = (values, actions) => {
    if ((isLastStep && values.role === "admin") || isLastStep) {
      dispatch(setLoading());

      submitForm(values, actions);

      if (!registered) {
        setActiveStep(activeStep);
      }
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      scrollToTop(); // Scroll to the top
    }
  };

  function handleLocationChange(value) {
    if (!value || !value?.name) {
      setSelectedLocation({});
    } else {
      setSelectedLocation(value);
      setSelectedDestinations({});
    }
  }

  function handleDestinationsChange(value) {
    if (!value || !value?.label) {
      setSelectedDestinations({});
    } else {
      setSelectedDestinations(value);
      setSelectedLocation({});
    }
  }

  function handleDestinationChange(value) {
    if (!value || !value?.label) {
      setSelectedDestination({});
    } else {
      setSelectedDestination(value);
      setSelectedLocation({});
    }
  }

  function handlePartnerChange(e, value) {
    if (!value || !value._id) {
      setSelectedPartner({});
    } else {
      setSelectedPartner(value);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={steps[activeStep] === "Review" ? undefined : currentValidation}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              innerRef={(f) => {
                setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(
                          activeStep,
                          {
                            values,
                            touched,
                            formField,
                            errors,
                            setFieldValue,
                          },
                          selectedDestinations,
                          handleDestinationsChange,
                          selectedDestination,
                          setSelectedDestination,
                          handleDestinationChange,
                          selectedLocation,
                          handleLocationChange,
                          selectedPartner,
                          handlePartnerChange,
                          setSelectedPartner
                        )}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <div>
                            <MDButton
                              onClick={() =>
                                user?.role === "partner" && user?.partnerPower
                                  ? navigate(`/partnerPower/users`)
                                  : navigate(`/admin/users`)
                              }
                              rel="noreferrer"
                              variant="outlined"
                              color="dark"
                              sx={{ marginRight: "8px" }}
                            >
                              Cancel
                            </MDButton>
                            <MDButton
                              // disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "add" : "next"}
                            </MDButton>
                          </div>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default NewUser;
