import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, position, destination, url, image, mobileImage },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [position?.name]: Yup.object().required(position?.errorMsg),
    // added destination and url
    [destination?.name]: Yup.object().when("position", {
      is: (val) => val?.value === "leaderboard",
      then: () => Yup.object().required(destination?.errorMsg),
      otherwise: () => Yup.object().nullable(),
    }),
    [url.name]: Yup.string().when("position", {
      is: (val) => val?.value === "leaderboard",
      then: () =>
        Yup.string()
          .matches(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
            url.invalidMsg
          )
          .required(url.errorMsg),
      otherwise: () => Yup.string().nullable(),
    }),
    [image.name]: Yup.array().min(1, image.errorMsg).required(),
    [mobileImage.name]: Yup.array()
      .min(1, mobileImage.errorMsg)
      .when("position", {
        is: (val) => val?.value === "leaderboard",
        then: () => Yup.array().min(1, mobileImage.errorMsg).required(),
        otherwise: () => Yup.array().nullable(),
      }),
  }),
];

export default validations;
