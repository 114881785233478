/* eslint-disable no-undef */
import axios from "axios";
import {
  SET_TAG_LOADING,
  CLEAR_ERRORS,
  UPDATE_TAG,
  TAG_FAIL,
  TAG_ADD_SUCCESS,
  CLEAR_ONE_TAG,
} from "./Types";

const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Add Tag
export const addTag = (data) => async (dispatch) => {
  try {
    let payload;
    let config;

    if (data?.type?.value === "JOBS" || data?.type === "JOBS") {
      // Scenario for JOBS: Use FormData
      payload = new FormData();
      payload.append("title", data?.title);
      payload.append("top", data?.top);
      payload.append("type", data?.type?.value || data?.type);
      payload.append("messageBoxCategoryId", data?.messageBoxCategoryId);

      if (data?.disclaimer) {
        payload.append("disclaimer", data?.disclaimer);
      }

      if (Array.isArray(data?.parents)) {
        data.parents.forEach((parent) => payload.append("parentTagIds", parent.value));
      }

      // Add the icon field for JOBS type
      if (data?.icon) {
        payload.append("icon", data.icon);
      }

      config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
    } else {
      // Default scenario
      payload = {
        title: data?.title,
        top: data?.top,
        type: data?.type?.value || data?.type,
        interests: data?.interests,
        disclaimer: data?.disclaimer || "",
      };

      if (Array.isArray(data?.parents)) {
        const parentTagIds = data?.parents?.map((obj) => obj?.value);
        payload.parentTagIds = parentTagIds;
      }

      if (Array.isArray(data?.persona)) {
        const personasValues = data?.persona?.map((obj) => obj.value);
        payload.persona = personasValues;
      }

      config = {
        headers: { "Content-Type": "application/json" },
      };
    }

    const res = await axios.post(`${URL}/tags`, payload, config);

    dispatch({
      type: TAG_ADD_SUCCESS,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: TAG_FAIL,
      payload: err?.response?.data?.message || "An error occurred",
    });
  }
};

// update tag
export const updateTag = (data) => async (dispatch) => {
  try {
    let payload;
    let config;

    if (data?.type?.value === "JOBS" || data?.type === "JOBS") {
      // Scenario for JOBS: Use FormData
      payload = new FormData();
      payload.append("title", data?.title);
      payload.append("top", data?.top);
      payload.append("type", data?.type?.value || data?.type);
      payload.append("messageBoxCategoryId", data?.messageBoxCategoryId);

      if (data?.disclaimer) {
        payload.append("disclaimer", data?.disclaimer);
      }

      if (Array.isArray(data?.interests) && data?.type !== "JOBS") {
        data.interests.forEach((interest) => payload.append("interests", interest));
      }

      if (Array.isArray(data.parents)) {
        if (data.parents.length > 0) {
          data.parents.forEach((parent) => payload.append("parentTagIds", parent.value));
        } else {
          payload.append("parentTagIds", []);
        }
      }

      if (Array.isArray(data?.persona) && data?.type !== "JOBS") {
        if (data.persona.length > 0) {
          data.persona.forEach((persona) => payload.append("persona", persona.value));
        } else {
          payload.append("persona", []);
        }
      }

      // if (Array.isArray(data?.persona) && data?.type === "JOBS") {
      //   payload.append("persona", undefined);
      // }

      if (data?.icon) {
        payload.append("icon", data.icon);
      }

      config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
    } else {
      // Default scenario
      payload = {
        title: data?.title,
        top: data?.top,
        type: data?.type?.value,
        interests: data?.interests || [],
        disclaimer: data?.disclaimer || "",
      };

      if (Array.isArray(data.parents)) {
        payload.parentTagIds = data.parents.length > 0 ? data.parents.map((obj) => obj.value) : [];
      }

      if (Array.isArray(data?.persona)) {
        payload.persona =
          data.persona.length > 0 ? data.persona.map((obj) => obj.value || obj) : [];
      }

      config = {
        headers: { "Content-Type": "application/json" },
      };
    }

    const res = await axios.patch(`${URL}/tags/${data._id}`, payload, config);

    dispatch({
      type: UPDATE_TAG,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TAG_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};

// Delete Tag - set active as false
export const deleteTag = (id) =>
  factory.patch({ active: false }, `${URL}/tags/${id}`, "DELETE_TAG", "TAG_FAIL");

// Get All tags

export const getTags = () => factory.get(`${URL}/tags?sort=name&dir=asc`, "GET_TAGS", "TAG_FAIL");

// get One Tag
export const getOneTag = (id) => factory.get(`${URL}/tags/${id}`, "GET_ONE_TAG", "TAG_FAIL");

export const clearTag = () => ({ type: CLEAR_ONE_TAG });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Set Loading
export const setLoading = () => ({ type: SET_TAG_LOADING });
