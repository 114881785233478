// prop-type is a library for typechecking of props
import Autocomplete from "@mui/material/Autocomplete";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCuisines } from "store/actions/recommendationActions";

const CuisinesPicker = ({ cuisines, cuisinesV, setFieldValue, ...rest }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const allCuisines = useSelector((state) => state.recommendations.cuisines);

  const cuisinesVObjects = cuisinesV.map((cuisine) => ({ name: cuisine, label: cuisine }));

  useEffect(() => {
    dispatch(getCuisines());
  }, [dispatch]);

  useEffect(() => {
    if (allCuisines?.length > 0) {
      setList(allCuisines?.map((t) => ({ name: t, label: t })));
    }
  }, [allCuisines]);

  return (
    <Autocomplete
      id="cuisines"
      multiple
      filterSelectedOptions
      options={list}
      onChange={(event, value) => {
        if (value) {
          const selectedCuisines = value.map((cuisine) => cuisine.name);
          setFieldValue("cuisines", selectedCuisines);
        } else {
          setFieldValue("cuisines", "");
        }
      }}
      value={cuisinesVObjects}
      getOptionLabel={(option) => option?.label || ""}
      renderInput={(params) => (
        <FormField
          {...params}
          {...rest}
          label={cuisines?.label}
          name={cuisines?.name}
          onChange={() => null}
        />
      )}
    />
  );
};

// typechecking props for CuisinesPicker
CuisinesPicker.propTypes = {
  cuisines: PropTypes.instanceOf(Object).isRequired,
  cuisinesV: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CuisinesPicker;
