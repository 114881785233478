import PropTypes from "prop-types";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveTicketFilter } from "store/actions/filterActions";
import ViewEditActions from "components/DetailList/ViewEditActions";
import TicketsPicker from "components/AsyncSelect/TicketsPicker";

const ByTicket = ({ locationId = "", filters = {}, city = "", destination = "" }) => {
  const dispatch = useDispatch();

  const [detail, setDetail] = useState(true);
  const [selectedTickets, setSelectedTickets] = useState([]);

  useEffect(() => {
    if (filters?.tickets?.length > 0) {
      setSelectedTickets(filters.tickets);
    }
    if (filters?.tickets?.length === 0) setSelectedTickets([]);
  }, [filters]);

  const handleTicketChange = (value) => {
    selectedTickets?.map((val) => ({ value: val, label: val }));
    if (value) {
      setSelectedTickets(value);
    } else {
      setSelectedTickets([]);
    }
  };

  const handleSave = (event) => {
    event.preventDefault(); // Prevent form submission
    const newState = selectedTickets.map((val) => val.value);
    dispatch(saveTicketFilter(newState, locationId));
    setDetail(true);
  };

  return (
    <Card id="ticket" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Filter by Ticket</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item size={{ xs: 12 }}>
            <MDBox sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="body2">
                Choose the tickets you don&#39;t want your visitors to see:
              </MDTypography>

              <ViewEditActions
                detailPage={detail}
                handleEdit={() => setDetail(false)}
                handleClose={() => {
                  setDetail(true);
                  setSelectedTickets(filters.tickets);
                }}
                handleSave={handleSave}
              />
            </MDBox>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="end"
              sx={{
                width: "100%",
              }}
            >
              <Grid item size={{ xs: 12 }}>
                {detail ? (
                  <MDBox style={{ display: "flex", flexDirection: "column" }}>
                    {selectedTickets?.length > 0 &&
                      selectedTickets?.map((item) => (
                        <MDTypography
                          key={item?._id || item?.value}
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          - {item?.title || item?.label || item?.name}
                        </MDTypography>
                      ))}
                  </MDBox>
                ) : (
                  <TicketsPicker
                    city={city}
                    destination={destination}
                    tickets={{ name: "ticket", Label: "Ticket" }}
                    ticketsV={
                      selectedTickets?.length > 0 && selectedTickets[0]?._id?.length > 0
                        ? selectedTickets?.map((item) => ({
                            label: item?.title || item?.name,
                            value: item?._id,
                          }))
                        : selectedTickets
                    }
                    setFieldValue={() => {}}
                    handleChange={handleTicketChange}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

// typechecking props for ByTicket
ByTicket.propTypes = {
  locationId: PropTypes.string,
  city: PropTypes.string,
  destination: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};
export default ByTicket;
